import React from 'react'
import { SVGProps, Svg } from '..'

export const RemoveVerIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M20.0031 7.9983H16.0014C15.4489 7.9983 15.001 7.5504 15.001 6.99788V2.99622" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.99805 7.9983V4.99705C5.99805 3.89202 6.89385 2.99622 7.99888 2.99622H15.1739C15.7045 2.99622 16.2133 3.2072 16.5885 3.58246L19.4176 6.41164C19.7929 6.78678 20.0039 7.29562 20.0039 7.82623V19.0029C20.0039 20.1079 19.1081 21.0037 18.003 21.0037H7.99888C6.89385 21.0037 5.99805 20.1079 5.99805 19.0029V18.0025" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.24909 11.7499L6.74805 14.2509" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.74805 11.7499L9.24909 14.2509" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="2.99609" y="7.99829" width="10.0042" height="10.0042" rx="3" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

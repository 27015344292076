import { useRef, useState, useEffect } from "react";
import { useNavigate } from "react-router-dom";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Components
import {
  ArrowLongIcon,
  ArrowUpperRightIcon,
  IdeaIcon,
} from "../../components/IconsSvg";
// Styles
import {
  HeroBlock,
  HeroBlockInfoWrapper,
  HowToUseTitleWrapper,
  HowToUseBlock,
  HowToUseSection,
  Footer,
  TextWrapper,
  VideoWrapper,
  CtaBlock,
  ButtonsWrapper,
  ButtonCTA,
  FooterContentWrapper,
  TextBlock,
} from "./why-talk.style";
// Images
import PhonesFrame from "./phonesFrame.png";
// Videos

const howToUseData = [
  {
    title: "1. Hit the record button",
    description: (
      <p>
        This is actually the{" "}
        <span style={{ fontWeight: 700 }}>hardest step</span>. You need to slow
        down enough to say one word at a time. You might not know what to say.
        Do it anyway! Once you start talking it will come together. Your
        colleagues will appreciate your hard work.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_1.mp4",
  },
  {
    title: "2. Say what you’re trying to create",
    description: (
      <p>
        Start with a phrase that describes what you want. 50% of our users are
        simply capturing notes. But if you have a vision of the output, it helps
        to describe it up front. The more specific you can be about what you
        want, the more the the system will work to adapt itself to your needs.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_2.mp4",
  },
  {
    title: "3. Talk out the details",
    description: (
      <p>
        Some people jot down a few bullet points before they talk, others stand
        by a whiteboard or their computer screen. Sometimes the eureka moment
        hits you when you’re on the go. Don’t let your best ideas get lost!
        Ramble away! Our system is happy to hear you out.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_3.mp4",
  },
  {
    title: "4. Convert it to writing or get a question",
    description: (
      <p>
        If you feel good about what you said, click convert to writing and
        review the result. You can also pause and add more. If you don’t feel
        confident in what you said, click “Get Question.” It will help you
        clarify your ideas.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_4.mp4",
  },
  {
    title: "5. Review the result",
    description: (
      <p>
        This is where the real fun begins. Your ideas are transform into clearly
        structured text. If you’re on the go, you can listen to it so it’s
        easier to consume. Sharing your thoughts is only two clicks away.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_5.mp4",
  },
  {
    title: "6. Edit with AI",
    description: (
      <p>
        Capturing ideas is a major part of owning your future, but having your
        ideas in a place where they can evolve is even more powerful. If what
        you see isn’t perfect, just hit the record button an describe what you
        want to see. It will instantly take that form. We also offer questions
        and suggestions to help you get clear.
      </p>
    ),
    media: "https://storage.googleapis.com/why_talk_videos/step_5.mp4",
  },
];

export const WhyTalk = () => {
  const footerRef = useRef(null);
  const [footerHeight, setFooterHeight] = useState(0);
  const navigate = useNavigate();
  const { isMobile, isSmallMobile, isPWA } = useGlobal();
  useEffect(() => {
    if (footerRef.current) {
      const height = footerRef.current.offsetHeight;
      setFooterHeight(height);
    }
  }, [footerRef]);
  return (
    <>
      <HeroBlock>
        <HeroBlockInfoWrapper>
          <h1>Unlock your potential!</h1>
          <p>
            Own your trajectory. Talking out your ideas and getting feedback
            from AI is often one of the fastest ways to clarity. Add it to your
            toolkit today.
          </p>
          <a href="#explore">
            Scroll down to explore
            <ArrowLongIcon />
          </a>
        </HeroBlockInfoWrapper>
        <img src={PhonesFrame} />
      </HeroBlock>
      <HowToUseTitleWrapper id="explore">
        <IdeaIcon width={isMobile ? 32 : 56} height={isMobile ? 32 : 56} />
        How to use our tool
      </HowToUseTitleWrapper>
      <HowToUseBlock>
        {howToUseData.map(({ title, description, media }) => (
          <HowToUseSection key={title}>
            <TextWrapper>
              <h3>{title}</h3>
              {description}
            </TextWrapper>
            <VideoWrapper className="video-wrapper">
              <video loop muted autoPlay playsInline src={media} />
            </VideoWrapper>
          </HowToUseSection>
        ))}
      </HowToUseBlock>
      <div
        style={{
          height: isMobile
            ? isPWA
              ? footerHeight - (isSmallMobile ? 80 : 88)
              : footerHeight - (isSmallMobile ? 56 : 64)
            : footerHeight,
        }}
      />
      <Footer ref={footerRef}>
        <FooterContentWrapper>
          <CtaBlock>
            <h2>Are you ready to try or still need to know more?</h2>
            <ButtonsWrapper>
              <ButtonCTA onClick={() => navigate("/")}>
                Try the app now
              </ButtonCTA>
              <ButtonCTA
                onClick={() => window.open("https://vnote.ai", "_blank")}
              >
                Visit our website
                <ArrowUpperRightIcon style={{ marginBottom: 2 }} />
              </ButtonCTA>
            </ButtonsWrapper>
          </CtaBlock>
          <TextBlock>
            <p>
              Submit feedback using the red tab or email us at{" "}
              <a href="mailto:support@vnote.ai">
                support@vnote.ai
              </a>
              <br />
              <br />
              The best way to learn is to try it out. Take a plunge and create
              radical clarity in your communications today.
              <br />
              <br />
              If you want to learn more, we have case studies, use cases, blog
              posts, and community signup pages on our website.
            </p>
          </TextBlock>
        </FooterContentWrapper>
      </Footer>
    </>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const ChatIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7409_31201)">
      <path d="M7.03125 3.125C5.73891 3.125 4.6875 4.17641 4.6875 5.46875C4.6875 5.90023 5.03727 6.25 5.46875 6.25C5.90023 6.25 6.25 5.90023 6.25 5.46875C6.25 5.03797 6.60047 4.6875 7.03125 4.6875C7.46203 4.6875 7.8125 5.03797 7.8125 5.46875C7.8125 5.78539 7.62363 6.06871 7.33133 6.19051C6.67445 6.46422 6.25 7.1009 6.25 7.8125C6.25 8.24398 6.59977 8.59375 7.03125 8.59375C7.46273 8.59375 7.8125 8.24398 7.8125 7.8125C7.8125 7.73367 7.85953 7.66313 7.9323 7.63281C8.80871 7.26762 9.375 6.4182 9.375 5.46875C9.375 4.17641 8.32359 3.125 7.03125 3.125Z" fill="#FF6D34"/>
      <path d="M7.03125 10.9375C7.46272 10.9375 7.8125 10.5877 7.8125 10.1562C7.8125 9.72478 7.46272 9.375 7.03125 9.375C6.59978 9.375 6.25 9.72478 6.25 10.1562C6.25 10.5877 6.59978 10.9375 7.03125 10.9375Z" fill="#FF6D34"/>
      <path d="M14.0625 7.03125C14.0625 3.15422 10.9083 0 7.03126 0C3.15423 0 1.12548e-05 3.15422 1.12548e-05 7.03125C1.12548e-05 8.64652 0.540988 10.1786 1.53712 11.4205L0.228839 12.7288C0.00540189 12.9522 -0.0614341 13.2882 0.0595034 13.5802C0.180441 13.8721 0.465285 14.0625 0.7813 14.0625H7.0313C10.9083 14.0625 14.0625 10.9083 14.0625 7.03125ZM7.03126 12.5H2.66736L3.16427 12.0031C3.31079 11.8566 3.3931 11.6579 3.3931 11.4506C3.3931 11.2434 3.31079 11.0447 3.16427 10.8982C2.13138 9.86539 1.56255 8.49207 1.56255 7.03121C1.56255 4.01574 4.01583 1.56246 7.0313 1.56246C10.0468 1.56246 12.5001 4.01574 12.5001 7.03121C12.5001 10.0467 10.0467 12.5 7.03126 12.5Z" fill="#FF6D34"/>
      <path d="M19.8067 18.7037L18.7438 17.4889C19.5582 16.4085 20 15.1045 20 13.7494C20 11.233 18.5026 8.97299 16.1851 7.99174C15.7878 7.82346 15.3293 8.00921 15.1611 8.40655C14.9928 8.80385 15.1785 9.26233 15.5759 9.43057C17.3142 10.1666 18.4374 11.8618 18.4374 13.7494C18.4374 14.9408 17.979 16.0817 17.1465 16.9617C16.8696 17.2544 16.8608 17.7097 17.1261 18.013L17.497 18.4369H13.75C11.8624 18.4369 10.1672 17.3137 9.43117 15.5753C9.26292 15.178 8.80437 14.9923 8.40714 15.1605C8.0098 15.3288 7.8241 15.7872 7.99234 16.1846C8.97355 18.502 11.2336 19.9994 13.75 19.9994H19.2187C19.5253 19.9994 19.8035 19.8201 19.9302 19.541C20.0568 19.2618 20.0086 18.9343 19.8067 18.7037Z" fill="#FF6D34"/>
    </g>
    <defs>
      <clipPath id="clip0_7409_31201">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
);

  
import React from 'react'
import { SVGProps, Svg } from '..'

export const SwedenFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_238)">
    <g clipPath="url(#clip1_104_238)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#3A99FF"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.28571 13.6H1V10.4H7.28571V4H10.4286V10.4H23V13.6H10.4286V20H7.28571V13.6Z" fill="#FFDA2C"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_104_238">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_104_238">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
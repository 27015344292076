import { datadogLogs } from "@datadog/browser-logs";
import { DefaultPrivacyLevel, datadogRum } from "@datadog/browser-rum";
import { AxiosError } from "axios";
import { useEffect, useRef } from "react";
import * as uncaught from "uncaught";
import { useAnonymousId } from "../hooks";
import { useAccount } from "../hooks/use-account";
import { useGlobal } from "../hooks/use-global";

export const trackError = (
  label: string,
  error: any = {},
  payload: any = {}
) => {
  if (!!error) {
    if (error instanceof AxiosError) {
      error = error?.response?.data || error?.response || error;

      if (error instanceof XMLHttpRequest) {
        error = JSON.stringify(error);
      }
    } else {
      error = {
        name: error.name,
        message: error.message,
        stack: error.stack,
      };
    }
  }

  if (process.env.NODE_ENV !== "production") {
    console.error(label, {
      payload: { error, ...payload },
    });
    return;
  }

  datadogLogs.logger.error(label, {
    payload: { error, ...payload },
  });
};

export const trackEvent = (eventName: string, eventData: any = {}) => {
  if (process.env.NODE_ENV !== "production") {
    console.info(eventName, { payload: { ...eventData } });
    return;
  }

  datadogLogs.logger.info(eventName, { payload: eventData });
};

export const DatadogLogging = () => {
  const { pingData, isPWA } = useGlobal();
  const { account } = useAccount();
  const shouldTrack = process.env.NODE_ENV === "production";
  const isInitializedRef = useRef<boolean>(false);
  const [anonymousId] = useAnonymousId();

  useEffect(() => {
    if (!shouldTrack || isInitializedRef.current) return () => null;

    datadogLogs.init({
      clientToken: "pubd6c0268c42fc019cef18519ecdd79993",
      site: "us5.datadoghq.com",
      forwardErrorsToLogs: true,
      sessionSampleRate: 100,
      service: `storied-app`,
      version: pingData.version,
      env: process.env.REACT_APP_ENV,
    });

    datadogRum.init({
      applicationId: "a5d929c0-a98a-473d-bc30-f4bf68453267",
      clientToken: "pubaf06e9e0d5d433808ddcfd91cb973ee2",
      site: "us5.datadoghq.com",
      service: "storied-app",
      sessionSampleRate: 100,
      premiumSampleRate: 100,
      trackUserInteractions: true,
      version: pingData.version,
      defaultPrivacyLevel: DefaultPrivacyLevel.MASK_USER_INPUT,
      env: process.env.REACT_APP_ENV,
      allowedTracingUrls: [
        (url) => url.startsWith(process.env.REACT_APP_SERVER),
      ],
    });

    datadogRum.startSessionReplayRecording();

    uncaught.start();
    uncaught.addListener(function (error) {
      if (!!error.includes && error.includes("Object Not Found Matching")) {
        return;
      }

      trackError("unhandled_error", error);
    });

    isInitializedRef.current = true;

    return () => {
      uncaught.flush();
    };
  }, [shouldTrack]);

  useEffect(() => {
    if (!!account) {
      datadogLogs.setGlobalContextProperty("extraPayload", {
        isPWA,
        anonId: anonymousId,
      });

      datadogLogs.setUser({
        id: account.id,
        email: account.mainEmail,
        name:
          account.loggedInHistory?.length > 0
            ? `${account.loggedInHistory[0]} (anon)`
            : account.profile?.name,
        loggedInHistory: account.loggedInHistory,
      });

      datadogRum.setGlobalContextProperty("extraPayload", {
        isPWA,
        anonId: anonymousId,
      });

      datadogRum.setUser({
        id: account.id,
        email: account.mainEmail,
        name:
          account.loggedInHistory?.length > 0
            ? `${account.loggedInHistory[0]} (anon)`
            : account.profile?.name,
        loggedInHistory: account.loggedInHistory,
      });
    }
  }, [account, anonymousId]);

  return <></>;
};

import styled from "styled-components";

export const MAX_CONTENT_HEIGHT = "40vh";

export const CollapsableMenuWrapper = styled.div<{ $expanded: boolean }>`
  margin-bottom: -16px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 7;
  width: 100%;
  background: white;
  padding: 20px 20px 16px 20px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -4px 21px 0px rgba(0, 0, 0, 0.08);
  transition: max-height 0.2s ease-out;
  max-height: ${(props) => (props.$expanded ? MAX_CONTENT_HEIGHT : "66px")};
  overflow-y: ${(props) => (props.$expanded ? "auto" : "hidden")};
  overflow: hidden;
  touch-action: none;
  & > div:nth-child(2) {
    margin-top: 4px;
    margin-bottom: 12px;
  }
`;

export const CollapsableMenuWrapperPWA = styled.div<{ $expanded: boolean }>`
  margin-bottom: -16px;
  position: fixed;
  bottom: 20px;
  left: 0;
  right: 0;
  z-index: 7;
  width: 100%;
  background: white;
  padding: 20px 20px 40px 20px;
  padding-top: 32px;
  border-radius: 20px 20px 0px 0px;
  box-shadow: 0px -4px 21px 0px rgba(0, 0, 0, 0.08);
  transition: max-height 0.2s ease-out;
  max-height: ${(props) => (props.$expanded ? MAX_CONTENT_HEIGHT : "86px")};
  overflow-y: ${(props) => (props.$expanded ? "auto" : "hidden")};
  overflow: hidden;
  touch-action: none;
  & > div:nth-child(2) {
    margin-top: 4px;
    margin-bottom: ${(props) => (props.$expanded ? "10px" : "40px")};
  }
`;

// Add a content wrapper to manage scrolling
export const ContentWrapper = styled.div`
  overflow-y: auto;
  max-height: calc(
    ${MAX_CONTENT_HEIGHT} - 66px
  ); // Adjust based on your header height
`;

export const TriangleWrapper = styled.div<{ $expanded: boolean }>`
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  display: flex;
  transition: 0.3s all;
  transform: ${(props) => (props.$expanded ? "" : "scaleY(-1)")};
`;

export const TouchZone = styled.div<{ $isPWA: boolean; $expanded: boolean }>`
  width: calc(100% - 124px);
  position: absolute;
  cursor: pointer;
  top: ${(props) => (props.$expanded ? 0 : props.$isPWA ? "-62px" : "-42px")};
  left: 0;
  height: ${(props) => (props.$isPWA && !props.$expanded ? "86px" : "66px")};
`;

export const Triangle = styled.div`
  margin: auto;
`;

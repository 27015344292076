import { createContext, useContext, useEffect, useState } from "react";
import { useAccount, useGlobal } from "@considr-it/storied-shared";
import { Transport } from "@considr-it/storied-shared";
import { Idea, Topic } from "@considr-it/storied-entities";
import { usePayment } from "./use-payment";

export const useDocumentProvider = () => {
  const { transport } = useGlobal();
  const { account } = useAccount();
  const { revalidatePaymentInfo } = usePayment();
  const [currentTopicId, setCurrentTopicId] = useState<string>("");
  const [currentIdea, setCurrentIdea] = useState<Idea>(null);

  const [basicTopicsCount, setBasicTopicsCount] = useState<number>(
    Number(localStorage.getItem("basic_topics_count") || 0)
  );

  const [topicsCount, setTopicsCount] = useState<number>(
    Number(localStorage.getItem("topics_count") || 0)
  );

  const [freeTopicsCurrentMonthCount, setFreeTopicsCurrentMonthCount] =
    useState<number>(
      Number(localStorage.getItem("free_topics_current_month_count") || 0)
    );

  const [trialTopicsCount, setTrialTopicsCount] = useState<number>(
    Number(localStorage.getItem("trial_topics_count") || 0)
  );

  const [totalTopicsCount, setTotalTopicsCount] = useState<number>(
    Number(localStorage.getItem("total_topics_count") || 0)
  );

  const {
    data: currentTopic,
    revalidate: revalidateTopic,
    isLoading: isLoadingTopic,
    isValidating: isValidatingTopic,
  } = Transport.useTransportSWR<Topic>(
    transport,
    currentTopicId ? `/topic/${currentTopicId}` : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    }
  );

  const refreshTopicsCount = async () => {
    const resp = await transport.get<{
      topicsCount: number;
      freeTopicsCurrentMonthCount: number;
      trialTopicsCount: number;
      totalTopicsCount: number;
      basicTopicsCount: number;
    }>("/topicsCount");

    if (resp?.data) {
      setTopicsCount(resp.data.topicsCount);
      setFreeTopicsCurrentMonthCount(resp.data.freeTopicsCurrentMonthCount);
      setTrialTopicsCount(resp.data.trialTopicsCount);
      setTotalTopicsCount(resp.data.totalTopicsCount);
      setBasicTopicsCount(resp.data.basicTopicsCount);

      await revalidatePaymentInfo();
    }
  };

  useEffect(() => {
    refreshTopicsCount();
  }, [account]);

  useEffect(() => {
    if (topicsCount !== undefined && topicsCount !== null) {
      localStorage.setItem("topics_count", topicsCount.toString());
    }
  }, [topicsCount]);

  useEffect(() => {
    if (
      freeTopicsCurrentMonthCount !== undefined &&
      freeTopicsCurrentMonthCount !== null
    ) {
      localStorage.setItem(
        "free_topics_current_month_count",
        freeTopicsCurrentMonthCount.toString()
      );
    }
  }, [freeTopicsCurrentMonthCount]);

  useEffect(() => {
    if (trialTopicsCount !== undefined && trialTopicsCount !== null) {
      localStorage.setItem("trial_topics_count", trialTopicsCount.toString());
    }
  }, [trialTopicsCount]);

  useEffect(() => {
    if (totalTopicsCount !== undefined && totalTopicsCount !== null) {
      localStorage.setItem("total_topics_count", totalTopicsCount.toString());
    }
  }, [totalTopicsCount]);

  useEffect(() => {
    if (basicTopicsCount !== undefined && basicTopicsCount !== null) {
      localStorage.setItem("basic_topics_count", basicTopicsCount.toString());
    }
  }, [basicTopicsCount]);

  return {
    currentTopic,
    revalidateTopic,

    refreshTopicsCount,

    topicsCount,
    setTopicsCount,

    basicTopicsCount,
    setBasicTopicsCount,

    freeTopicsCurrentMonthCount,
    trialTopicsCount,

    totalTopicsCount,
    setTotalTopicsCount,

    currentTopicId,
    setCurrentTopicId,
    isLoadingTopic,
    isValidatingTopic,

    currentIdea,
    setCurrentIdea,
  };
};

export const DocumentProvider = ({ children }) => {
  const documentProvider = useDocumentProvider();

  return (
    <DocumentContext.Provider value={documentProvider}>
      {children}
    </DocumentContext.Provider>
  );
};

export type DocumentProps = ReturnType<typeof useDocumentProvider>;
export const DocumentContext = createContext<DocumentProps>(null);
export const useDocument = () => useContext(DocumentContext);

import { Image, Spinner } from "react-bootstrap";
import styled from "styled-components";
import { storiedOrange } from "../../constants";

export const StyledSpinner = styled(Spinner)`
  border-color: ${props => props.$disabledColor ? '#C1C1C1' : storiedOrange};
  ${(props) => props.$borderColor ? `border-color: ${props.$borderColor}` : ""};
  border-right-color: transparent;
  width: ${props => props.$borderSize ?? "32px"};
  height: ${props => props.$borderSize ?? "32px"};
`;

export const StyledSpinnerAdaptive = styled(Spinner)`
  border-color: ${storiedOrange};
  border-right-color: transparent;
  width: 100%;
  max-width: 270px;
`;

export const StyledImageSpinner = styled.div`
  margin: auto;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    img {
      height: 151px;
    }
  }
  @media screen and (max-width: 360px) {
    img {
      height: 121px;
    }
  }
`;

export const Text = styled.h2`
  color: #101212;
  text-align: center;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -0.704px;
  margin-bottom: 16px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 31.2px;
  }
  @media screen and (max-width: 360px) {
    font-size: 18px;
    font-weight: 600;
    line-height: 23.4px;
    letter-spacing: -0.396px;
  }
`;

export const Subtext = styled.p`
  color: #6F6F6F;
  text-align: center;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: -0.396px;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.352px;
  }
  @media screen and (max-width: 360px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: -0.308px;
  }
`;

import React from "react";
import { SVGProps, Svg } from "..";

export const ExportIcon: React.FC<SVGProps> = (props) => {
  return (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path d="M7.99825 7.998L5.99741 7.998C4.89195 7.998 3.99658 8.89337 3.99658 9.99883L3.99658 19.0026C3.99658 20.108 4.89195 21.0034 5.99742 21.0034L18.0024 21.0034C19.1079 21.0034 20.0032 20.108 20.0032 19.0026L20.0032 9.99883C20.0032 8.89337 19.1079 7.998 18.0024 7.998L16.0016 7.998" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M11.9997 1.99542L11.9997 13" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path d="M8.99951 4.99658L11.9998 1.99533L15.001 4.99658" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
};

import styled from 'styled-components';

export const HeroBlock = styled.div`
  width: 100%;
  min-height: 420px;
  display: flex;
  gap: 68px;
  margin-bottom: 90px;
  margin-top: 24px;
  img {
    height: 420px;
    width: 420px;
    max-width: 420px;
    max-height: 420px;
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 40px;
    flex-direction: column;
    gap: 24px;
    img {
      height: 280px;
      width: 280px;
      max-width: 280px;
      max-height: 280px;
      margin-left: auto;
      margin-right: auto;
    }
  }
`;
export const HeroBlockInfoWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 32px;
  h1 {
    color: #101212;
    text-align: start;
    font-size: 64px;
    font-weight: 700;
    margin-bottom: 0;
    letter-spacing: -1.408px;
  }
  p {
    color: #6F728F;
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    margin-bottom: 0;
  }
  a {
    cursor: pointer;
    display: flex;
    gap: 8px;
    color: #FF6D34;
    font-size: 18px;
    font-weight: 600;
    text-decoration: none;
  }
  @media screen and (max-width: 767px) {
    gap: 24px;
    h1 {
      font-size: 40px;
      line-height: 52px;
      letter-spacing: -0.88px;
    }
    p {
      font-size: 16px;
      line-height: 20.8px;
    }
    a {
      font-size: 16px;
    }
    img {
      height: 280px;
      max-height: 280px;
    }
  }
`;
export const HowToUseTitleWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  color: #101212;
  font-size: 40px;
  font-weight: 700;
  letter-spacing: -0.88px;
  line-height: normal;
  margin-bottom: 72px;
  @media screen and (max-width: 767px) {
    gap: 8px;
    flex-direction: column;
    font-size: 24px;
    line-height: 31.2px;
    letter-spacing: -0.528px;
    margin-bottom: 32px;
  }
`;
export const HowToUseBlock = styled.div`
  display: flex;
  gap: 90px;
  flex-direction: column;
  margin-bottom: 110px;
  @media screen and (max-width: 767px) {
    gap: 56px;
    margin-bottom: 40px;
  }
`;
export const HowToUseSection = styled.div`
  display: grid;
  grid-template-columns: auto 460px;
  grid-template-areas: 'a b';
  gap: 40px;
  align-items: center;
  &:nth-child(even) {
    grid-template-areas: 'b a';
    grid-template-columns: 460px auto;
    .video-wrapper {
      box-shadow: 3px 4px 20px 0px rgba(54, 54, 54, 0.10);
    }
  }
  & div:first-child {
    grid-area: a;
  }
  & div:last-child {
    grid-area: b;
  }
  @media screen and (max-width: 767px) {
    gap: 24px;
    display: flex;
    flex-direction: column;
  }
`;
export const TextWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  gap: 24px;
  h3 {
    color: #101212;
    font-size: 32px;
    font-weight: 700;
    line-height: 41.6px;
    letter-spacing: -0.704px;
    margin-bottom: 0;
  }
  p {
    color: #6F728F;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    h3 {
      font-size: 20px;
      line-height: 26px;
      letter-spacing: -0.44px;
    }
    p {
      font-size: 16px;
      line-height: 20.8px;
    }
  }
`;
export const VideoWrapper = styled.div`
  max-width: 460px;
  max-height: 250px;
  width: 100%;
  height: 250px;
  border-radius: 16px;
  display: flex;
  justify-content: center;
  align-items: center;
  box-shadow: -3px 4px 20px 0px rgba(54, 54, 54, 0.10);
  video {
    max-width: 95%;
    max-height: 95%;
    margin-left: auto;
    margin-right: auto;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 100%;
    border-radius: 16px;
    video {
      max-width: 95%;
      max-height: 95%;;
    }
  }
`;
export const Footer = styled.div`
  background: #FA8152;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
`;
export const FooterContentWrapper = styled.div`
  max-width: 1184px;
  padding: 56px 0;
  margin-left: auto;
  margin-right: auto;
  padding-left: calc(1.5rem*.5);
  padding-right: calc(1.5rem*.5);
  width: 100%;
  overflow-x: hidden;
  display: flex;
  gap: 200px;
  h2 {
    color: #FFF;
    font-size: 48px;
    font-weight: 700;
    line-height: 62.4px;
    letter-spacing: -1.056px;
    margin-bottom: 0;
  }
  p {
    color: white;
    margin-bottom: 0;
  }
  @media screen and (max-width: 767px) {
    gap: 32px;
    flex-direction: column;
    padding: 32px 20px;
    h2 {
      color: var(--White-000, #FFF);
      font-size: 28px;
      line-height: 36.4px;
      letter-spacing: -0.616px;
    }
  }
`;
export const CtaBlock = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
`;
export const TextBlock = styled.div`
  display: flex;
  align-items: center;
  p {
    color: white;
    font-size: 20px;
    font-weight: 500;
    line-height: 130%;
    margin-bottom: 0;
  }
  a {
    text-decoration: none;
    color: white;
    display: inline;
    font-weight: 700;
  }
  @media screen and (max-width: 767px) {
    p {
      font-size: 16px;
      line-height: 20.8px;
      margin-bottom: 80px;
    }
  }
`;
export const ButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
  width: 100%;
  @media screen and (max-width: 992px) {
    flex-direction: column;
  }
`;
export const ButtonCTA = styled.div`
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  border: 1.5px solid #FFF;
  display: flex;
  height: 56px;
  padding: 12px 24px;
  justify-content: center;
  align-items: center;
  gap: 10px;
  color: #FFF;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  transition: all .15s;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
  }
  &:hover {
    background: white;
    color: #FF6D34;
    svg {
      path {
        stroke: #FF6D34;
      }
    }
  }
  &:first-child {
    background: white;
    color: #FF6D34;
    &:hover {
      background: #FFF7F4;
    }
  }
`;
import React from 'react'
import { SVGProps, Svg } from '..'

export const TickIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M9.23074 17.6472C9.61054 17.6319 9.96343 17.4703 10.2226 17.1931L10.2354 17.1794L10.2472 17.1648L22.7215 1.63995L22.3323 1.32724L22.7215 1.63994C22.8728 1.45159 23.1482 1.42158 23.3365 1.57293L23.6497 1.18319L23.3365 1.57294C23.5249 1.72429 23.5549 1.99966 23.4035 2.18807L10.9119 17.7345C10.9118 17.7346 10.9117 17.7347 10.9116 17.7349C10.9059 17.7419 10.9007 17.7481 10.8959 17.7535C10.4743 18.2243 9.89742 18.4961 9.26602 18.5215L9.2656 18.5215C9.23357 18.5228 9.2015 18.5234 9.16966 18.5234C8.57482 18.5234 8.0154 18.3007 7.58044 17.8876L7.58121 17.8883L7.23522 18.2493C7.76303 18.751 8.446 19.0234 9.16966 19.0234C9.20833 19.0234 9.24724 19.0226 9.2861 19.021L7.5797 17.8869L0.634774 11.2299C0.63477 11.2299 0.634765 11.2298 0.634761 11.2298C0.460315 11.0626 0.454477 10.7857 0.621656 10.6113C0.788908 10.4368 1.06578 10.431 1.24023 10.5981C1.24024 10.5982 1.24025 10.5982 1.24026 10.5982L8.18391 17.254L8.18545 17.2554C8.46662 17.5227 8.84071 17.6629 9.23074 17.6472ZM9.23074 17.6472L9.21067 17.1476L9.23081 17.6472C9.23078 17.6472 9.23076 17.6472 9.23074 17.6472Z" stroke="#FF6D34"/>
  </Svg>
);

  
import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useTransport } from "./use-transport";
import { PingResponse } from "@considr-it/storied-entities";
import { useAuth0 } from "@auth0/auth0-react";

export const useGlobalProvider = (pingData: PingResponse, isAdmin: boolean) => {
  const { user } = useAuth0();
  const [isOnline, setIsOnline] = useState(navigator.onLine);
  const [appVisibility, setAppVisibility] = useState<boolean>(true);

  const { transport } = useTransport(isAdmin);

  const [width, setWidth] = useState<number>(window.innerWidth);
  const isPrerender = navigator.userAgent.toLowerCase().includes("prerender");
  const isPWA =
    window.matchMedia("(display-mode: standalone)").matches ||
    window.navigator["standalone"] ||
    document.referrer.includes("android-app://");

  const handleWindowSizeChange = () => {
    setWidth(window.innerWidth);
  };

  useEffect(() => {
    const handleVisibilityChange = () => {
      if (document.hidden) {
        setAppVisibility(false);
      } else {
        setAppVisibility(true);
      }
    };
    document.addEventListener("visibilitychange", handleVisibilityChange);
    return () => {
      document.removeEventListener("visibilitychange", handleVisibilityChange);
    };
  }, []);

  useEffect(() => {
    window.addEventListener("resize", handleWindowSizeChange);
    return () => {
      window.removeEventListener("resize", handleWindowSizeChange);
    };
  }, []);

  useEffect(() => {
    const onlineHandler = () => {
      setIsOnline(true);
    };

    const offlineHandler = () => {
      setIsOnline(false);
    };

    window.addEventListener("online", onlineHandler);
    window.addEventListener("offline", offlineHandler);

    return () => {
      window.removeEventListener("online", onlineHandler);
      window.removeEventListener("offline", offlineHandler);
    };
  }, []);

  const isMobile = width <= 768;
  const isSmallMobile = width <= 360;

  const triggerLinkedinOauth = useCallback(
    async (state: string) => {
      let redirect_uri = encodeURIComponent(
        window.location.origin + "/linkedin-callback"
      );

      let scopes = encodeURIComponent("w_member_social r_basicprofile");

      const url = `https://www.linkedin.com/oauth/v2/authorization?response_type=code&client_id=${pingData.linkedInOauth.clientId}&redirect_uri=${redirect_uri}&scope=${scopes}&state=${state}`;
      window.location.href = url;
    },
    [user]
  );

  const triggerGoogleOauth = useCallback(
    async (state: string) => {
      localStorage.removeItem("google_oauth");

      //Google's OAuth 2.0 endpoint for requesting an access token
      let oauth2Endpoint =
        "https://accounts.google.com/o/oauth2/v2/auth?access_type=offline";

      // Create <form> element to submit parameters to OAuth 2.0 endpoint.
      let form = document.createElement("form");
      form.setAttribute("method", "GET"); // Send as a GET request.
      form.setAttribute("action", oauth2Endpoint);

      // Parameters to pass to OAuth 2.0 endpoint.
      let params = {
        client_id: process.env.REACT_APP_CLIENT_ID,
        redirect_uri: `${window.location.origin}/google-callback`,
        response_type: "token",
        scope: "https://www.googleapis.com/auth/drive.file",
        prompt: "consent",
        include_granted_scopes: "false",
        state,
      };

      if (!!user) {
        params["login_hint"] = user.email;
      }

      // Add form parameters as hidden input values.
      for (let p in params) {
        let input = document.createElement("input");
        input.setAttribute("type", "hidden");
        input.setAttribute("name", p);
        input.setAttribute("value", params[p]);
        form.appendChild(input);
      }

      // Add form to page and submit it to open the OAuth 2.0 endpoint.
      document.body.appendChild(form);
      form.submit();
    },
    [user]
  );

  return {
    isMobile,
    isSmallMobile,
    pingData,
    appVisibility,

    transport,

    triggerGoogleOauth,
    triggerLinkedinOauth,
    isPrerender,
    isOnline,
    isPWA,
  };
};

export const GlobalProvider = ({ pingData, children, isAdmin }) => {
  const globalProvider = useGlobalProvider(pingData, isAdmin);

  return (
    <GlobalContext.Provider value={globalProvider}>
      {children}
    </GlobalContext.Provider>
  );
};

export type GlobalContextProps = ReturnType<typeof useGlobalProvider>;
export const GlobalContext = createContext<GlobalContextProps>(null);
export const useGlobal = () => useContext(GlobalContext);

import React from "react";
import { SVGProps, Svg } from "..";

export const LampIcon: React.FC<SVGProps> = (props) => {
  return (
    <Svg viewBox="0 0 12 13" {...props}>
      <g clipPath="url(#clip0_2766_19475)">
        <path
          d="M3.26541 3.2684L2.27114 2.27413C2.13382 2.13681 1.91133 2.13681 1.77401 2.27413C1.63669 2.41145 1.63669 2.63395 1.77401 2.77127L2.76828 3.76553C2.9056 3.90285 3.12809 3.90285 3.26541 3.76553C3.40273 3.62821 3.40273 3.40572 3.26541 3.2684Z"
          fill="#FFBD59"
        />
        <path
          d="M1.78125 6.14844H0.351562C0.157242 6.14844 0 6.30568 0 6.5C0 6.69432 0.157242 6.85156 0.351562 6.85156H1.78125C1.97557 6.85156 2.13281 6.69432 2.13281 6.5C2.13281 6.30568 1.97557 6.14844 1.78125 6.14844Z"
          fill="#FFBD59"
        />
        <path
          d="M11.6484 6.14844H10.2188C10.0244 6.14844 9.86719 6.30568 9.86719 6.5C9.86719 6.69432 10.0244 6.85156 10.2188 6.85156H11.6484C11.8428 6.85156 12 6.69432 12 6.5C12 6.30568 11.8428 6.14844 11.6484 6.14844Z"
          fill="#FFBD59"
        />
        <path
          d="M10.226 2.27413C10.0887 2.13681 9.86617 2.13681 9.72885 2.27413L8.73458 3.2684C8.59726 3.40572 8.59726 3.62821 8.73458 3.76553C8.8719 3.90285 9.09439 3.90285 9.23171 3.76553L10.226 2.77127C10.3633 2.63395 10.3633 2.41148 10.226 2.27413Z"
          fill="#FFBD59"
        />
        <path
          d="M6 0.5C5.80568 0.5 5.64844 0.657242 5.64844 0.851562V2.28125C5.64844 2.47557 5.80568 2.63281 6 2.63281C6.19432 2.63281 6.35156 2.47557 6.35156 2.28125V0.851562C6.35156 0.657242 6.19432 0.5 6 0.5Z"
          fill="#FFBD59"
        />
        <path
          d="M7.96878 4.01788C7.20239 3.41319 6.21097 3.19524 5.24065 3.42728C4.11565 3.68743 3.20864 4.58038 2.9344 5.69837C2.66018 6.82335 2.97659 7.96241 3.78516 8.75694C4.07346 9.04524 4.24222 9.49756 4.24222 9.96165V10.039C4.24222 10.2358 4.39688 10.3906 4.59378 10.3906H7.40628C7.60318 10.3906 7.75784 10.2358 7.75784 10.039V9.96165C7.75784 9.5046 7.93362 9.03819 8.243 8.73585C8.83365 8.13819 9.16409 7.34368 9.16409 6.49993C9.16409 5.52962 8.72815 4.62256 7.96878 4.01788ZM6.00003 5.44524C5.46964 5.44524 5.08353 5.79706 4.98242 6.20434C4.93641 6.38956 4.75004 6.50778 4.55635 6.46114C4.36822 6.41446 4.25318 6.22356 4.29989 6.03542C4.47408 5.3323 5.1345 4.74212 6.00003 4.74212C6.19435 4.74212 6.35159 4.89936 6.35159 5.09368C6.35159 5.288 6.19435 5.44524 6.00003 5.44524Z"
          fill="#FFBD59"
        />
        <path
          d="M4.59375 11.0938V11.4453C4.59375 12.0269 5.06686 12.5 5.64844 12.5H6.35156C6.93314 12.5 7.40625 12.0269 7.40625 11.4453V11.0938H4.59375Z"
          fill="#FFBD59"
        />
      </g>
      <defs>
        <clipPath id="clip0_2766_19475">
          <rect
            width="12"
            height="12"
            fill="white"
            transform="translate(0 0.5)"
          />
        </clipPath>
      </defs>
    </Svg>
  );
};

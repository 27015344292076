import React from "react";
import styled from "styled-components";
// Components
import { BasicModal } from "../BasicModal";
// Styles
const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  margin-bottom: 24px;
  margin-left: -14px;
  margin-right: -14px;
  padding-left: 4.5px;
  padding-right: 4.5px;
`;
const Header = styled.p`
  color: #101212;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  margin-left: -14px;
  margin-right: -14px;
  padding-left: 4.5px;
  padding-right: 4.5px;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
  @media screen and (max-width: 360px) {
    margin-left: 0;
    margin-right: 0;
  }
`;
const Description = styled.div`
  margin-left: -14px;
  margin-right: -14px;
  padding-left: 4.5px;
  padding-right: 4.5px;
  color: #6F6F6F;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 360px) {
    font-size: 14px;
    line-height: 18.2px;
    letter-spacing: -0.308px;
    margin-left: 0;
    margin-right: 0;
  }
`;
const Content = styled.div`
  margin-bottom: 16px;
`;
const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  width: 100%;
`;

interface ConfirmationProps {
  onDismiss?: () => void;
  params?: any;
}

export const ConfirmationModal: React.FC<ConfirmationProps> = (props) => {
  const { onDismiss, params = {} } = props;
  const {
    confirmationMessage,
    description,
    actions,
    bodyContent,
    withCloseBtn,
    disableOnHide,
  } = params;

  return (
    <BasicModal
      show={true}
      withCloseBtn={withCloseBtn}
      withLogo
      closeModal={onDismiss}
      disableOnHide={disableOnHide}
    >
      <Wrapper>
        <Header>{confirmationMessage}</Header>
        {description && <Description>{description}</Description>}
      </Wrapper>
      {bodyContent && <Content>{bodyContent}</Content>}
      <ButtonGroupWrapper>
        {actions.map((el, i) => <React.Fragment key={i}>{el}</React.Fragment>)}
      </ButtonGroupWrapper>
    </BasicModal>
  );
};

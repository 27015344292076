import styled from "styled-components";

export const ProfileDesktopWrapper = styled.div`
  display: grid;
  grid-template-columns: minmax(240px, 1fr) 3fr;
  grid-gap: 24px;
  width: 100%;
  margin-top: 48px;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const Section = styled.div`
  min-height: 440px;
  width: 100%;
  padding: 24px;
  display: flex;
  flex-direction: column;
  gap: 32px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
`;

export const Title = styled.h2`
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  margin-bottom: 0;
`;

export const MenuItem = styled.div<{ $isActive?: boolean }>`
  color: ${(props) => props.$isActive ? "#FF6D34" : "#101212"};
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  display: flex;
  align-items: center;
  gap: 10px;
  cursor: pointer;
`;

export const Menu = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  height: 100%;
`;

export const MenuItemsWrapper = styled.div`
  display: flex;
  gap: 32px;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;

export const CategoryTitle = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.44px;
`;

export const ProfileMobileWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
`;

export const TitleMobile = styled.h1`
  font-family: Metropolis;
  font-size: 24px;
  font-weight: 600;
  line-height: 120%;
  display: flex;
  align-items: center;
  margin-bottom: 24px;
`;

export const MenuMobile = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: space-between;
`;

export const MobileMenuItem = styled.div`
  border-radius: 10px;
  background: #FFF;
  padding: 16px;
  display: flex;
  gap: 10px;
  width: 100%;
  align-items: center;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  .icon {
    transform: scaleX(-1);
    margin-left: auto;
  }
`;

export const BackToSettingsButton = styled.div`
  display: flex;
  height: 36px;
  align-items: center;
  gap: 8px;
  color: #FF6D34;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  margin-bottom: 10px;
`;
import { PaymentTier } from "@considr-it/storied-enums";
import { Helmet } from "react-helmet";
import { usePayment } from "../../hooks/use-payment";
import BasicPlanWhite from "./basicPlanWhite.svg";
import ProPlanWhite from "./proPlanWhite.svg";
import { Link } from "react-router-dom";
import styled from "styled-components";
import { useDocument } from "../../hooks/use-document";
import { useAccount } from "@considr-it/storied-shared";

export const LinkWrapper = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #ffff;
  cursor: pointer;
`;

const BannerBackground = ({ color, children }) => {
  return (
    <>
      <Helmet>
        <meta name="theme-color" content={color} />
      </Helmet>
      <div
        style={{
          position: "relative",
          width: "100%",
          backgroundColor: color,
          textAlign: "center",
          color: "#ffff",
        }}
      >
        {children}
      </div>
    </>
  );
};

export const TrialBanner = () => {
  const { paymentInfo } = usePayment();
  const { basicTopicsCount } = useDocument();
  const { account } = useAccount();

  let color = "#fa8152";
  let displayStr = "";
  let iconImg = null;
  let linkText = "Upgrade Now";

  if (!account.isAnonymous) {
    if (paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_PRO_TRIAL) {
      if (paymentInfo.trialStartedAt) {
        const expireDate = new Date(
          new Date(paymentInfo.trialStartedAt).getTime() +
            paymentInfo.trialDurationDays * 24 * 3600 * 1000
        );

        const todayDate = new Date();
        const numDaysTillExpire = Math.round(
          (expireDate.getTime() - todayDate.getTime()) / 1000 / 3600 / 24
        );

        if (numDaysTillExpire > 1) {
          displayStr = `${numDaysTillExpire} days left in your trial`;
        } else if (numDaysTillExpire === 1) {
          displayStr = "1 day left in your trial";
        } else {
          displayStr = "Trial expires today";
        }
      } else {
        displayStr = `Create a document to start your ${paymentInfo.trialDurationDays}-day trial!`;
        linkText = "";
      }

      iconImg = ProPlanWhite;
    } else if (paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_BASIC) {
      iconImg = BasicPlanWhite;

      const documentsRemaining =
        paymentInfo.basicTopicsThreshold - basicTopicsCount;

      if (documentsRemaining > 0) {
        displayStr = `You have ${documentsRemaining} document${
          documentsRemaining > 1 ? "s" : ""
        } remaining this month`;

        linkText = "";
      } else {
        displayStr = "You've used all of your documents";
      }
    } else if (paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_EXPIRED) {
      if (!paymentInfo.previousPaymentTier) {
        displayStr = "Trial expired";
      } else {
        displayStr = "Subscription expired";
      }

      color = "#ce3b01";
    }

    if (displayStr) {
      if (linkText) {
        displayStr += " - ";
      }

      return (
        <BannerBackground color={color}>
          <div>
            <img src={iconImg} style={{ paddingRight: "5px" }} />
            {displayStr}
            {linkText && (
              <Link
                style={{ color: "#ffff" }}
                to={`${window.location.origin}/profile/plans`}
              >
                {linkText}
              </Link>
            )}
          </div>
        </BannerBackground>
      );
    }
  }

  return (
    <Helmet>
      <meta name="theme-color" content={"#fffcf3"} />
    </Helmet>
  );
};

import styled from "styled-components";
import { ProcessingCircle } from "../ProcessingCircle";

const FilledButton = styled.button<{
  fullWidth: boolean;
  noIconFill: boolean;
  extraSmall: boolean;
}>`
  display: flex;
  height: 46px;
  padding: 12px 16px;
  @media screen and (max-width: 360px) {
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    height: unset;
  }
  justify-content: center;
  align-items: center;
  gap: 10px;
  border-radius: 10px;
  color: #fff;
  border: 2px solid #ff6d34;
  background: #ff6d34;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  transition: all 0.2s ease-in-out;
  ${(props) =>
    props.extraSmall
      ? `
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 4px;
    border: 1px solid #ff6d34;
  `
      : ""}
  svg {
    ${(props) => (props.noIconFill ? "" : "fill: #FFF")};
    transition: fill 0.2s ease-in-out;
  }

  &:disabled {
    border: ${(props) => (props.extraSmall ? "1px" : "2px")} solid #ababab;
    background: #ababab;
    color: rgba(255, 255, 255, 0.67);
    ${(props) =>
      props.noIconFill ? "" : "svg { fill: rgba(255, 255, 255, 0.67); }"}
    &:hover {
      border: ${(props) => (props.extraSmall ? "1px" : "2px")} solid #ababab;
      background: #ababab;
      color: rgba(255, 255, 255, 0.67);
    }
  }

  &:hover {
    border: ${(props) => (props.extraSmall ? "1px" : "2px")} solid #ff510e;
    background: #ff510e;
  }
`;

interface BtnProps {
  children: React.ReactNode;
  fullWidth?: boolean;
  tooltip?: string;
  noIconFill?: boolean;
  extraSmall?: boolean;
  loading?: boolean;
  [key: string]: any;
}

export const PrimaryButton: React.FC<BtnProps> = ({
  children,
  fullWidth = false,
  tooltip = "",
  noIconFill = false,
  extraSmall = false,
  loading = false,
  ...nativeButtonProps
}) => (
  <FilledButton
    fullWidth={fullWidth}
    title={tooltip}
    type={nativeButtonProps?.type || "button"}
    noIconFill={noIconFill}
    extraSmall={extraSmall}
    {...nativeButtonProps}
  >
    {!loading && children}
    {loading && <ProcessingCircle circleSize="20px" borderColor="white" />}
  </FilledButton>
);

import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useState, useRef } from "react";
import {
  CollapsableMenuWrapper,
  CollapsableMenuWrapperPWA,
  ContentWrapper,
  TouchZone,
  Triangle,
  TriangleWrapper,
  MAX_CONTENT_HEIGHT,
} from "./collapsable-menu.styles";
import TriangleIcon from "./triangle.svg";

interface ICollapsableMenuProps {
  children: any;
  onTouchZone?: () => void;
  defaultExpanded: boolean;
  dataToRefresh?: any;
}

export const CollapsableMenu = ({
  children,
  onTouchZone,
  defaultExpanded,
  dataToRefresh,
}: ICollapsableMenuProps) => {
  const { isPWA } = useGlobal();
  const [expanded, setExpanded] = useState(defaultExpanded);
  const contentRef = useRef<HTMLDivElement>(null);

  const toggleSetting = () => {
    setExpanded((s) => !s);
  };

  const FeedbackWrapper = isPWA
    ? CollapsableMenuWrapperPWA
    : CollapsableMenuWrapper;

  useEffect(() => {
    if (expanded && contentRef.current) {
      contentRef.current.style.maxHeight = `calc(${MAX_CONTENT_HEIGHT} - 66px)`;
    }
  }, [expanded]);

  useEffect(() => {
    setExpanded(!!dataToRefresh);
  }, [dataToRefresh]);

  return (
    <FeedbackWrapper $expanded={expanded}>
      <TriangleWrapper $expanded={expanded}>
        <Triangle>
          <img src={TriangleIcon} />
        </Triangle>
        <TouchZone
          $isPWA={isPWA}
          $expanded={expanded}
          onClick={onTouchZone || toggleSetting}
        />
      </TriangleWrapper>
      <ContentWrapper ref={contentRef}>{children}</ContentWrapper>
    </FeedbackWrapper>
  );
};

import { useState, useRef, useLayoutEffect } from "react";
import { Image, Stack } from "react-bootstrap";
import {
  StyledImageSpinner,
  StyledSpinner,
  StyledSpinnerAdaptive,
  Text,
  Subtext,
} from "./processing-circle.style";
import OrangeLines from "./OrangeLines.svg";

export const ProcessingCircle = ({
  text = null,
  subtext = null,
  circleSize = null,
  customAnimation = false,
  cleanAdaptiveSpinner = false,
  disabledColor = false,
  borderColor = "",
}) => {
  const [currentWidth, setCurrentWidth] = useState(null);
  const styledSpinnerAdaptiveRef = useRef(null);
  useLayoutEffect(() => {
    if (styledSpinnerAdaptiveRef?.current) {
      const spinnerElement = styledSpinnerAdaptiveRef.current;
      const computedWidth = spinnerElement.getBoundingClientRect().width;
      setCurrentWidth(computedWidth);
    }
  }, [styledSpinnerAdaptiveRef]);
  if (cleanAdaptiveSpinner) {
    return (
      <StyledSpinnerAdaptive
        ref={styledSpinnerAdaptiveRef}
        animation="border"
        role="status"
        className="m-auto"
        style={{ height: currentWidth }}
      />
    );
  }
  return (
    <Stack className="d-flex flex-col m-auto text-center">
      {customAnimation ? (
        <StyledImageSpinner>
          <Image src={OrangeLines} />
        </StyledImageSpinner>
      ) : (
        <StyledSpinner
          animation="border"
          role="status"
          className="m-auto"
          $borderSize={circleSize}
          $disabledColor={disabledColor}
          $borderColor={borderColor}
        />
      )}
      {text && <Text>{text}</Text>}
      {subtext && <Subtext>{subtext}</Subtext>}
    </Stack>
  );
};

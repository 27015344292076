import styled from "styled-components";

const Label = styled.label<{
  fullWidth: boolean;
  noIconFill: boolean;
  extraSmall: boolean;
}>`
  display: flex;
  height: 46px;
  justify-content: center;
  align-items: center;
  border-radius: 10px;
  @media screen and (max-width: 360px) {
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    height: unset;
  }
  color: #fff;
  border: 2px solid #ff6d34;
  background: #ff6d34;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  cursor: pointer;
  width: ${(props) => (props.fullWidth ? "100%" : "auto")};
  transition: all 0.2s ease-in-out;
  ${(props) => props.extraSmall ? `
    width: 28px;
    height: 28px;
    padding: 0;
    border-radius: 4px;
    border: 1px solid #ff6d34;
  ` : ''}
  svg {
    ${(props) => (props.noIconFill ? "" : "fill: #FFF")};
    transition: fill 0.2s ease-in-out;
  }

  &:disabled {
    border: ${(props) => props.extraSmall ? '1px' : '2px'} solid #ababab;
    background: #ababab;
    color: rgba(255, 255, 255, 0.67);
    ${(props) =>
      props.noIconFill ? "" : "svg { fill: rgba(255, 255, 255, 0.67); }"}
    &:hover {
      border: ${(props) => props.extraSmall ? '1px' : '2px'} solid #ababab;
      background: #ababab;
      color: rgba(255, 255, 255, 0.67);
    }
  }

  &:hover {
    border: ${(props) => props.extraSmall ? '1px' : '2px'} solid #ff510e;
    background: #ff510e;
  }
`;

interface ComponentProps {
  children: string;
  fullWidth?: boolean;
  tooltip?: string;
  noIconFill?: boolean;
  extraSmall?: boolean;
  type: string;
  accept?: string;
  onChange?: any;
  [key: string]: any;
}

export const InputButton: React.FC<ComponentProps> = ({
  children="",
  fullWidth = false,
  tooltip = "",
  noIconFill = false,
  extraSmall = false,
  type, 
  accept = "",
  onChange = () => {},
  ...extraProps
}) => (
  <Label
    noIconFill={noIconFill}
    extraSmall={extraSmall}
    title={tooltip}
    fullWidth={fullWidth}
    {...extraProps}
  >
    <input
      type={type}
      accept={accept}
      onChange={onChange}
      style={{ display: "none" }}
    />
    {children}
  </Label>
);

import { BasicModal } from "../BasicModal";
// Components
import { PrimaryButton } from "../../Buttons";
// Styles
import {
  LockerImg,
  Title,
  ContentWrapper,
  Description,
} from "./upgrademodal.style";
// Constants
interface UpgradeToProModalProps {
  onDismiss?: () => void;
}

export const UpgradeToProModal: React.FC<UpgradeToProModalProps> = ({
  onDismiss,
}) => {
  return (
    <BasicModal
      show
      withCloseBtn={false}
      contentWrapper={ContentWrapper}
      closeModal={onDismiss}
      disableOnHide={true}
    >
      <LockerImg />
      <Title>Upgrade to use this feature</Title>
      <Description>
        With Pro plan, you can edit your docs fast and easy and boost them to
        the perfect results. You can unlock your ideas and get a real co-pilot
        for them.
      </Description>
      <PrimaryButton
        style={{ width: "80%", marginLeft: "auto", marginRight: "auto" }}
        onClick={() => window.open("/profile/plans")}
      >
        Become Pro
      </PrimaryButton>
    </BasicModal>
  );
};

// Components
import { Description, ButtonCTA } from "./onboarding-carousel.style";
// Icons
import { ArrowUpperRightIcon } from "../IconsSvg";

export const Step1 = () => {
  return (
    <div>
      <Description>
        Vnote doesn't give you answers like ChatGPT. It helps you transform{" "}
        <strong>your ideas. </strong>
        It's a dictation app on steroids.
      </Description>
      <ButtonCTA
        onClick={() => window.open("https://vnote.ai", "_blank")}
      >
        Visit our website
        <ArrowUpperRightIcon style={{ marginBottom: 2 }} />
      </ButtonCTA>
    </div>
  );
};

import Sticky from 'react-stickynode';
import styled from 'styled-components';

const ContentContainer = styled.div`
  max-height: 100vh;
  overflow-y: auto;
  scrollbar-width: none;
`;
export const StickyWrapper = ({ children }) => {
  return (
    <Sticky>
      <ContentContainer>{children}</ContentContainer>
    </Sticky>
  )
};

import { useEffect, useLayoutEffect, useRef } from "react";
import { useLocation } from "react-router-dom";
// Styles
import { FancyBackgroundWrapper, FancyBackgroundImg } from "./fancy-background.style";
// Pictures
import BackgroundImg from "./background.svg";
// Helpers
const setBackgroundPos = (
  wrapperRef: React.RefObject<HTMLDivElement>,
  imageRef: React.RefObject<HTMLImageElement>,
  isIdeaFlowPage: boolean = false,
  initialExtraNum: number = 0,
) => {
  const windowHeight = window.innerHeight;
  const wrapper = wrapperRef.current;
  const canvas = document.querySelector('canvas');
  const image = imageRef.current;
  if (!wrapper) return null;
  if (canvas && isIdeaFlowPage) {
    const topPosition = (image.offsetHeight - canvas.offsetHeight) / 2
    const leftPosition = (image.offsetWidth - canvas.offsetWidth) / 2
    image.style.top = canvas.getBoundingClientRect().top - topPosition + initialExtraNum + 'px';
    image.style.left = canvas.getBoundingClientRect().left - leftPosition + 'px';
    image.style.transform = '';
  } else {
    image.style.top = (windowHeight - image.offsetHeight) / 2 + 'px';
    image.style.left = '50%';
    image.style.transform = 'translate(-50%, 0)';
  }
};

export const FancyBackground: React.FC = () => {
  const wrapperRef = useRef<HTMLDivElement | null>(null);
  const imageRef = useRef<HTMLImageElement | null>(null);
  const { pathname } = useLocation();
  const isIdeaFlowPage = pathname === '/';
  useEffect(() => {
    const handleLayout = () => setBackgroundPos(wrapperRef, imageRef, isIdeaFlowPage);

    setBackgroundPos(wrapperRef, imageRef, isIdeaFlowPage, 50);
  
    window.addEventListener('resize', handleLayout);
    return () => {
      window.removeEventListener('resize', handleLayout);
    };
  }, [pathname]);
  return (
    <FancyBackgroundWrapper ref={wrapperRef}>
      <FancyBackgroundImg src={BackgroundImg} ref={imageRef} />
    </FancyBackgroundWrapper>
  );
};

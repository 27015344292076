import styled from 'styled-components';
import { Spinner } from "react-bootstrap";

export const StyledSpinner = styled(Spinner)`
  border-color: #fa8152;
  border-right-color: #fff;
  width: 64px;
  height: 64px;
  border-width: 8px;
  @media screen and (max-width: 767px) {
    width: 40px;
    height: 40px;
    border-width: 5px;
  }
`;

export const CircleWrapper = styled.div`
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  z-index: -1;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const LoadingAnimationWrapper = styled.div`
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  margin-top: 92px;
  @media screen and (max-width: 767px) {
    margin-top: 186px;
  }
  img {
    height: 95px;
    width: 290px;
    @media screen and (max-width: 767px) {
      height: 58.8px;
      width: 179.5px;
    }
  }
`;

export const StripesWrapper = styled.div`
  width: 398px;
  position: relative;
  @media screen and (max-width: 767px) {
    max-width: 80vw;
    width: 242px;
  }
`;
export const BackgroundStripe = styled.div`
  width: 398px;
  height: 8px;
  background: #FFF8F5;
  position: absolute;
  border-radius: 18.29px;
  @media screen and (max-width: 767px) {
    width: 100%;
    height: 4.88px;
  }
`;
export const LoadingStripe = styled.div`
  background: #FF6D34;
  height: 8px;
  @media screen and (max-width: 767px) {
    height: 4.88px;
  }
  position: absolute;
  border-radius: 18.29px;
  transition: width 1s;
  animation: loading 1s linear;
  animation-fill-mode: forwards;
  @keyframes loading {
    0% {
      width: 0;
    }
    30% {
      width: 50%;
    }
    80% {
      width: 70%;
    }
    100% {
      width: 100%;
    }
  }
`;
import React from "react";
import { SVGProps, Svg } from "..";

export const Pricing: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path
      d="M13.5005 8.9924H17.5022"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49805 8.72116L8.41743 9.54951L10.2682 7.88281"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M10.4995 19.9997H8.49867C5.73609 19.9997 3.49658 17.7602 3.49658 14.9976V6.99427C3.49658 4.2317 5.73609 1.99219 8.49867 1.99219H16.502C17.8286 1.99219 19.1009 2.51919 20.039 3.45726C20.9771 4.39534 21.5041 5.66764 21.5041 6.99427V9.99552"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M7.49805 13.7212L8.41743 14.5495L10.2682 12.8828"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.501 13.8984V19.6012C14.502 20.3744 16.0684 21.0016 18.0019 21.0016C19.9355 21.0016 21.5019 20.3744 21.5029 19.6012V13.8984"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.502 16.75C14.502 17.5232 16.0694 18.1504 18.0029 18.1504C19.9364 18.1504 21.5039 17.5232 21.5039 16.75"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
    <path
      d="M14.502 13.9004C14.502 14.6736 16.0694 15.3008 18.0029 15.3008C19.9365 15.3008 21.5029 14.6736 21.5029 13.9004C21.5029 13.1262 19.9345 12.5 18.0019 12.5C16.0694 12.5 14.502 13.1272 14.501 13.9004"
      stroke="#919191"
      stroke-width="1.5"
      stroke-linecap="round"
      stroke-linejoin="round"
    />
  </Svg>
);

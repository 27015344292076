import { useGlobal, usePlayer } from "@considr-it/storied-shared";
import { useMemo, useState } from "react";
import { HeaderIconWrapper } from "../HeaderIconWrapper";
import { PauseIcon, PlayIcon } from "../IconsSvg";
import { ProcessingCircle } from "../ProcessingCircle";

interface IAudioPlayerProps {
  itemId: string;
  itemModel: string;
}

export const AudioPlayer = ({ itemId, itemModel }: IAudioPlayerProps) => {
  const { isSmallMobile } = useGlobal();
  const [triggeredPlay, setTriggeredPlay] = useState<boolean>(false);

  const {
    controls: { togglePlay },
    state: { canPlay, paused },
  } = usePlayer();

  const IconSize = isSmallMobile
    ? { width: 20, height: 20 }
    : { width: 28, height: 28 };

  const Icon = useMemo(
    () => (paused ? <PlayIcon {...IconSize} /> : <PauseIcon {...IconSize} />),
    [paused]
  );

  const isLoading = useMemo(() => {
    return !canPlay && triggeredPlay;
  }, [canPlay, triggeredPlay]);

  if (isLoading) {
    return <ProcessingCircle circleSize={"24px"} />;
  }

  return (
    <HeaderIconWrapper
      onClick={() => {
        if (!isLoading) {
          setTriggeredPlay(true);
          togglePlay({ itemId, itemModel });
        }
      }}
      Icon={Icon}
    />
  );
};

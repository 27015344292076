import { Toolbar, useCommands, useActive, useHelpers } from "@remirror/react";
import { EditorButton } from "../Buttons";
import {
  EditBIcon,
  EditIIcon,
  EditSIcon,
  EditH1Icon,
  EditH2Icon,
  EditULIcon,
  EditOLIcon,
  EditUndoIcon,
  EditRedoIcon,
} from "../IconsSvg";

const buttons = [
  { activeName: "bold", command: "toggleBold", icon: EditBIcon, title: "Bold" },
  {
    activeName: "italic",
    command: "toggleItalic",
    icon: EditIIcon,
    title: "Italic",
  },
  {
    activeName: "strike",
    command: "toggleStrike",
    icon: EditSIcon,
    title: "Strikethrough",
  },
  {
    activeName: "heading",
    command: "toggleHeading",
    icon: EditH1Icon,
    title: "Heading 1",
    args: { level: 1 },
  },
  {
    activeName: "heading",
    command: "toggleHeading",
    icon: EditH2Icon,
    title: "Heading 2",
    args: { level: 2 },
  },
  {
    activeName: "bulletList",
    command: "toggleBulletList",
    icon: EditULIcon,
    title: "Bullet List",
  },
  {
    activeName: "orderedList",
    command: "toggleOrderedList",
    icon: EditOLIcon,
    title: "Numbered List",
  },
  { command: "undo", icon: EditUndoIcon, title: "Undo" },
  { command: "redo", icon: EditRedoIcon, title: "Redo" },
];

export const Menu = () => {
  const allCommands = useCommands();
  const active = useActive();

  return (
    <Toolbar onMouseDown={(e) => e.preventDefault()}>
      {buttons.map(({ activeName, command, icon: Icon, title, args }) => {
        const isAvailable = allCommands[command].enabled(args);
        const isActive = activeName && active?.[activeName](args);
        return (
          <EditorButton
            key={title}
            isActive={isActive}
            disabled={!isAvailable}
            onMouseDown={(e) => e.preventDefault()}
            onClick={() => {
              if (args) {
                allCommands[command](args);
              } else {
                allCommands[command]();
              }
            }}
            title={title}
          >
            <Icon width={16} height={16} />
          </EditorButton>
        );
      })}
    </Toolbar>
  );
};

export default Menu;

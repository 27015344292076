import React from 'react'
import { SVGProps, Svg } from '..'

export const NetherlandsFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_335)">
    <g clipPath="url(#clip1_104_335)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 15H23V20H1V15Z" fill="#1E448D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H23V9H1V4Z" fill="#B01923"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_104_335">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_104_335">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
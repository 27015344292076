import { ChangeEvent, useEffect, useState } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Icons
import loopaIcon from "./loopaIcon.svg";
import { FileIcon } from "../../components/IconsSvg";
// Styles
import {
  SearchBoxInput,
  SearchBoxInputWrap,
  SearchBoxInputButton,
  TitleSearchWrapper,
  TitleWrap,
} from "./my-documents.style";

export const Search = () => {
  const [searchOpen, setSearchOpen] = useState(false);
  const [searchTerm, setSearchTerm] = useState("");
  const { filter, searchTerm: searchTermFromParams } = useParams();
  const navigate = useNavigate();
  const { isMobile } = useGlobal();
  const handleSearch = (event: ChangeEvent<HTMLInputElement>) => {
    const newSearchTerm = event.target.value;
    setSearchTerm(newSearchTerm);
    navigate(`/documents/${filter}/${newSearchTerm || ""}`);
  };
  useEffect(() => {
    if (searchTermFromParams) {
      setSearchTerm(searchTermFromParams);
    }
  }, []);

  return (
    <TitleSearchWrapper>
      {(!isMobile || !searchOpen) && (
        <TitleWrap>
          <FileIcon width={28} height={28} $color="#6F6F6F" />
          Docs
        </TitleWrap>
      )}
      {isMobile && !searchOpen && (
        <SearchBoxInputButton onClick={() => setSearchOpen(true)}>
          <img src={loopaIcon} />
        </SearchBoxInputButton>
      )}
      {(!isMobile || searchOpen) && (
        <SearchBoxInputWrap>
          <img src={loopaIcon} />
          <SearchBoxInput
            placeholder="Search by name, keywords etc"
            onChange={handleSearch}
            value={searchTerm}
          />
        </SearchBoxInputWrap>
      )}
    </TitleSearchWrapper>
  );
};

import { PrimaryButton, SecondaryButton } from "../Buttons";
import { useAccount } from "@considr-it/storied-shared";
import { useConfirmModal } from "../../hooks/use-confirm-modal";

const LoginButton = () => {
  const { login } = useAccount();
  const { onPresentConfirmModal } = useConfirmModal();

  return (
    <SecondaryButton
      onClick={() => {
        onPresentConfirmModal({
          confirmationMessage: "Sign Up or Login",
          description: "Create a new account or log back in",
          actions: [
            <div>
              <PrimaryButton fullWidth onClick={() => login("login")}>
                Login
              </PrimaryButton>
              <br />
              <SecondaryButton fullWidth onClick={() => login("signup")}>
                Sign Up
              </SecondaryButton>
            </div>,
          ],
          withCloseBtn: true,
        });
      }}
    >
      Sign Up / Login
    </SecondaryButton>
  );
};

export default LoginButton;

import { useEffect, useState } from "react";
// Custom hooks
import { useBasicModal } from "../../hooks/use-basic-modal";
import { trackEvent, useGlobal } from "@considr-it/storied-shared";
// Components
import { PrimaryButton } from "../Buttons";
import { Step1 } from "./Step1";
import { Step2 } from "./Step2";
import { Step4 } from "./Step4";
import { Step5 } from "./Step5";
// Media
import MediaStep1 from "./mediaStep1.jpg";
import MediaStep2 from "./mediaStep2.mp4";
import MediaStep4 from "./mediaStep4.mp4";
import MediaMobileStep2 from "./mediaMobileStep2.mp4";
import MediaMobileStep4 from "./mediaMobileStep4.mp4";
// Styles
import {
  CarouselWrapper,
  TopSection,
  MiddleSection,
  BottomSection,
  DotsWrapper,
  Dot,
  VideoWrapper,
  ImgWrapper,
  Title,
  ModalWrapper,
} from "./onboarding-carousel.style";
import { useOnboarding } from "../../hooks/use-onboarding";
import { useNavigate } from "react-router-dom";

const steps = [
  {
    photo: MediaStep1,
    title: "Welcome to Vnote!",
    component: Step1,
  },
  {
    video: MediaStep2,
    videoMobile: MediaMobileStep2,
    title: "Create any doc in just 2 steps:",
    component: Step2,
  },
  // {
  //   video: MediaStep3,
  //   videoMobile: MediaMobileStep3,
  //   title: "Do you want some examples?",
  //   component: Step3,
  // },
  {
    video: MediaStep4,
    videoMobile: MediaMobileStep4,
    title: "Think in another language?",
    component: Step4,
  },
  {
    title: "How do you want to start?",
    component: Step5,
  },
];

export const OnboardingCarousel = ({ flowName }) => {
  const { onboardingState, completeOnboardingFlow, finalRedirect } =
    useOnboarding();
  const [step, setStep] = useState<number>(0);
  const [stepDisabled, setStepDisabled] = useState<boolean>(false);
  const navigate = useNavigate();

  const { isMobile } = useGlobal();
  const { BasicModal, toggleModal } = useBasicModal({
    withCloseBtn: false,
    contentWrapper: ModalWrapper,
    disableOnHide: true,
  });

  const isLastStep = step === steps.length - 1;
  const CurrentStep = steps[step];

  useEffect(() => {
    toggleModal();
  }, []);

  if (onboardingState?.completed) return <></>;

  return (
    <BasicModal>
      <CarouselWrapper>
        {(CurrentStep?.videoMobile || CurrentStep?.video) && (
          <TopSection>
            <VideoWrapper>
              <video
                loop
                muted
                autoPlay
                playsInline
                src={isMobile ? CurrentStep.videoMobile : CurrentStep.video}
              />
            </VideoWrapper>
          </TopSection>
        )}
        {CurrentStep?.photo && (
          <TopSection>
            <ImgWrapper>
              <img src={CurrentStep.photo} />
            </ImgWrapper>
          </TopSection>
        )}
        <MiddleSection>
          <Title>{CurrentStep.title}</Title>
          <CurrentStep.component {...{ stepDisabled, setStepDisabled }} />
        </MiddleSection>
        <BottomSection>
          <DotsWrapper>
            {steps.map((el, i) => (
              <Dot
                key={el.title}
                $active={i === step}
                onClick={() => setStep(i)}
              />
            ))}
          </DotsWrapper>
          <PrimaryButton
            disabled={isLastStep ? stepDisabled : false}
            onClick={
              isLastStep
                ? () => {
                    completeOnboardingFlow(flowName);
                    trackEvent("completed_onboarding", { flowName });

                    navigate(finalRedirect || "/");
                  }
                : () => setStep((val) => val + 1)
            }
          >
            {isLastStep ? "Try it for free!" : "Next"}
          </PrimaryButton>
        </BottomSection>
      </CarouselWrapper>
    </BasicModal>
  );
};

import { useAccount } from "@considr-it/storied-shared";
import { useNavigate } from "react-router-dom";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { ConfirmationModal } from "../../components/Modals/ConfirmModal";

export const Login = () => {
  const { account, login } = useAccount();
  const navigate = useNavigate();

  if (account.isAnonymous) {
    return (
      <ConfirmationModal
        params={{
          confirmationMessage: "Sign Up or Log In",
          description: "Create a new account or log back in",
          actions: [
            <div>
              <PrimaryButton fullWidth onClick={() => login("login")}>
                Login
              </PrimaryButton>
              <br />
              <SecondaryButton fullWidth onClick={() => login("signup")}>
                Sign Up
              </SecondaryButton>
            </div>,
          ],
          withCloseBtn: false,
          disableOnHide: true,
        }}
      />
    );
  } else {
    navigate("/");
  }

  return <></>;
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const FilesIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M19.0029 10.9995V7.82623C19.0029 7.29601 18.7918 6.78679 18.4167 6.41164L15.5875 3.58246C15.2123 3.2073 14.7031 2.99622 14.1729 2.99622H6.9979C5.89244 2.99622 4.99707 3.89159 4.99707 4.99705V19.0029C4.99707 20.1083 5.89244 21.0037 6.9979 21.0037H12" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.0031 7.9983H15.0014C14.4492 7.9983 14.001 7.55011 14.001 6.99788V2.99622" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.3454 20.0263L20.1012 20.9486C20.4433 21.1287 20.8425 20.8376 20.7774 20.4574L20.4423 18.5026L21.8629 17.119C22.14 16.8499 21.9869 16.3797 21.6048 16.3247L19.642 16.0396L18.7646 14.2599C18.5935 13.9137 18.0993 13.9137 17.9292 14.2599L17.0519 16.0396L15.0891 16.3247C14.7069 16.3807 14.5538 16.8499 14.831 17.119L16.2515 18.5026L15.9154 20.4584C15.8504 20.8386 16.2495 21.1287 16.5917 20.9496L18.3474 20.0273" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
import { useMicRecorder } from "../../hooks/use-mic-recorder";
import { useToast } from "../../hooks/use-toast";
import { MuteIcon } from "../../components/IconsSvg";
import { useEffect } from "react";

export const NoSoundWarningBanner = () => {
  const { soundDetected, isRecording, defaultAudioDevice } = useMicRecorder();

  const { failureToast, dismissToasts } = useToast();

  useEffect(() => {
    if (isRecording && !soundDetected) {
      failureToast(
        `No audio detected. Make sure your microphone "${defaultAudioDevice.label}" is not muted.`,
        {
          position: "top-center",
          icon: <MuteIcon />,
        }
      );
    } else {
      dismissToasts();
    }
  }, [isRecording, soundDetected]);

  return <></>;
};

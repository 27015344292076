import React from 'react'
import { SVGProps, Svg } from '..'

export const HeadIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M13.0001 6L12.9999 6.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.0001 11.5L12.9999 12" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.5984 7.50004L15.1653 7.74996" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.8347 10.25L10.4016 10.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.4016 7.50004L10.8347 7.74996" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M15.1653 10.25L15.5984 10.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6 9C6 5.41015 8.91015 2.5 12.5 2.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12 15V15.8318C12 16.6927 11.4491 17.457 10.6325 17.7292L8.63246 18.3959C8.02256 18.5992 7.35212 18.4969 6.83058 18.121C6.30904 17.7451 6 17.1414 6 16.4985V14.7295" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M10.2117 17.8659L9.43902 20.1838C9.33737 20.4887 9.38851 20.8239 9.57646 21.0847C9.76441 21.3455 10.0663 21.5 10.3877 21.5H17.6129C17.9343 21.5 18.2361 21.3455 18.4241 21.0847C18.6121 20.8239 18.6632 20.4887 18.5615 20.1838L18.2955 19.3855C17.4734 16.9193 17.77 14.2171 19.1075 11.988V11.988C19.6917 11.0143 20.0003 9.90021 20.0003 8.76476V8.76476C20.0003 7.10324 19.3402 5.50978 18.1654 4.33491C16.9905 3.16004 15.397 2.5 13.7355 2.5H12.5003" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5.99954 14.7294L4.55374 14.0066C4.29715 13.879 4.10777 13.6473 4.03382 13.3705C3.95986 13.0937 4.00847 12.7984 4.16726 12.5599L5.99954 9.81152V9" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
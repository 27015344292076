import {
  ButtonContainer,
  Icon,
  IconContainer,
  Label,
} from "./output-viewer.style";

export const ShareButton = ({ icon, label, onClick }) => (
  <ButtonContainer>
    <IconContainer>
      <Icon src={icon} alt={label} onClick={onClick} />
    </IconContainer>
    <Label>{label}</Label>
  </ButtonContainer>
);

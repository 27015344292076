import React, { useMemo } from "react";
import { Stack } from "react-bootstrap";
import styled from "styled-components";
import { FiChevronLeft, FiChevronRight } from "react-icons/fi";
// Constants
import { storiedDisabled, storiedOrange } from "../../constants";
// Styles
const OutputPaginationWrapper = styled(Stack)`
  font-size: 16px;
  white-space: nowrap;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
  }
`;


interface OutputPaginationProps {
  totalPageCount: number;
  currentPageNumber: number;
  disabled: boolean;
  onPrev: () => void;
  onNext: () => void;
}

export const OutputPagination: React.FC<OutputPaginationProps> = ({
  totalPageCount,
  currentPageNumber,
  disabled,
  onPrev,
  onNext,
}) => {
  const disabledLeft = useMemo(() => {
    return disabled || currentPageNumber === 1;
  }, [disabled, currentPageNumber]);

  const disabledRight = useMemo(() => {
    return disabled || currentPageNumber === totalPageCount;
  }, [disabled, currentPageNumber]);

  return (
    <OutputPaginationWrapper direction="horizontal">
      <FiChevronLeft
        className="output-pagination-left"
        size="1.3rem"
        color={disabledLeft ? storiedDisabled : storiedOrange}
        onClick={() => {
          if (!disabledLeft) {
            onPrev();
          }
        }}
      />
      v{currentPageNumber} / {totalPageCount}{" "}
      <FiChevronRight
        className="output-pagination-right"
        size="1.3rem"
        color={disabledRight ? storiedDisabled : storiedOrange}
        onClick={() => {
          if (!disabledRight) {
            onNext();
          }
        }}
      />
    </OutputPaginationWrapper>
  );
};

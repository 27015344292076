export const timeAgo = (date: Date): string => {
  const now = new Date();
  let seconds = Math.floor((now.getTime() - date.getTime()) / 1000);
  
  // Condition 5: More than 1 month, show exact date
  if (seconds > 2592000) {
    return date.toLocaleString();
  }
  
  // Condition 4a: More than 1 week, show only weeks and days
  if (seconds > 604800) {
    const weeks = Math.floor(seconds / 604800);
    const remainingDays = Math.floor((seconds % 604800) / 86400);
    return `${weeks} week${weeks > 1 ? 's' : ''}${remainingDays > 0 ? ` and ${remainingDays} day${remainingDays > 1 ? 's' : ''}` : ''} ago`;
  }
  
  // Condition 4b: More than 1 day but less than 1 week, show only days
  if (seconds > 86400) {
    const days = Math.floor(seconds / 86400);
    return `${days} day${days === 7 ? '' : days > 1 ? 's' : ''} ago`;
  }
  
  // Condition 3: More than 1 hour, show hours and minutes
  if (seconds > 3600) {
    const hours = Math.floor(seconds / 3600);
    const minutes = Math.floor((seconds % 3600) / 60);
    return `${hours} hour${hours > 1 ? 's' : ''} ago`;
  }
  
  // Condition 2: Less than one hour, show just minutes
  if (seconds > 60) {
    const minutes = Math.floor(seconds / 60);
    return `${minutes} minute${minutes > 1 ? 's' : ''} ago`;
  }
  
  // Condition 1: Less than 1 minute, show "Just now"
  return 'Just now';
}
  
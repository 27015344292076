import React from 'react'
import { SVGProps, Svg } from '..'

export const CheckmarkRoundedIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="18"
    height="19"
    viewBox="0 0 18 19"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7124_26587)">
      <path d="M8.99995 0.5C4.03741 0.5 0 4.53731 0 9.49995C0 14.4626 4.03741 18.5 8.99995 18.5C13.9625 18.5 17.9999 14.4626 17.9999 9.49995C17.9999 4.53731 13.9626 0.5 8.99995 0.5ZM14.1679 7.97979L8.51367 13.634C8.27325 13.8744 7.95367 14.0067 7.6137 14.0067C7.27374 14.0067 6.95415 13.8744 6.71374 13.634L3.83202 10.7523C3.5916 10.5119 3.45919 10.1923 3.45919 9.85231C3.45919 9.51226 3.5916 9.19267 3.83202 8.95225C4.07234 8.71184 4.39193 8.57942 4.73199 8.57942C5.07195 8.57942 5.39163 8.71184 5.63196 8.95235L7.61361 10.9339L12.3678 6.17976C12.6082 5.93934 12.9278 5.80702 13.2677 5.80702C13.6077 5.80702 13.9273 5.93934 14.1677 6.17976C14.6641 6.67618 14.6641 7.48355 14.1679 7.97979Z" fill="#FF6D34"/>
    </g>
    <defs>
      <clipPath id="clip0_7124_26587">
        <rect width="18" height="18" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </Svg>
);

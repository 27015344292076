import { useCallback, useEffect, useMemo, useRef, useState } from "react";

import { useIsMounted } from "./use-is-mounted";
import { useGlobal } from "./use-global";
import { trackError, trackEvent } from "../components/datadog-logs";

const PLAY_RATE_MAP: Record<string, number> = {
  "1": 1.25,
  "1.25": 1.5,
  "1.5": 2,
  "2": 0.5,
  "0.5": 1,
};

export const getNextPlaybackRate = (rate = 0.5) =>
  PLAY_RATE_MAP[rate.toString()];

export type AudioSource = {
  itemId: string;
  itemModel: string;
  isTemporary?: boolean;
};

export type PlayerHookProps = {
  defaultSrc?: AudioSource;
  onEnded?: () => void;
};

export enum PlaybackState {
  Ready = "Ready to play",
  Loading = "Loading...",
  Processing = "Processing...",
}

export interface IAudio {
  src: string;
  paused: boolean;
  currentTime: number;
  playbackRate: number;
  duration: number;
  muted: boolean;
  play: () => Promise<void>;
  pause: () => Promise<void> | void;
  addEventListener: (eventName: string, fn: () => void) => void;
  removeEventListener: (eventName: string, fn: () => void) => void;
  fastSeek: (value: number) => void;
}

export const usePlaybackState = ({ src, canPlay }) =>
  useMemo(
    () =>
      !src
        ? PlaybackState.Processing
        : canPlay
        ? PlaybackState.Ready
        : PlaybackState.Loading,
    [src, canPlay]
  );

export const usePlayer = ({ onEnded = () => null }: PlayerHookProps = {}) => {
  let audioFactory = useRef(new Audio()).current;
  const audioRef = useRef<IAudio | null>(null);
  const [src, setSrc] = useState<AudioSource>(null);
  const { transport } = useGlobal();

  const [paused, setPaused] = useState(true);
  const [duration, setDuration] = useState(0);
  const [time, setTime] = useState(0);
  const [playbackRate, setPlaybackRate] = useState(1);
  const [canPlay, setCanPlay] = useState(false);

  const lockPlayRef = useRef(false);

  const { safeEventCallback } = useIsMounted();

  //   // https://stackoverflow.com/questions/58017215/what-typescript-type-do-i-use-with-useref-hook-when-setting-current-manually
  //   const audioRef = useRef<IAudio | null>(null);

  const playbackState = useMemo(
    () =>
      !src
        ? PlaybackState.Ready
        : canPlay
        ? PlaybackState.Ready
        : PlaybackState.Loading,
    [src, canPlay]
  );

  const invalidateCache = (data: string) => URL.revokeObjectURL(data);

  const isAudioActive = useCallback(
    (newSrc: AudioSource) =>
      newSrc.itemId === src?.itemId && newSrc.itemModel === src?.itemModel,
    [src?.itemId, src?.itemModel]
  );

  const pause = useCallback(async () => {
    trackEvent("pause_audio", src);
    const audio = audioRef.current;

    if (!audio || lockPlayRef.current) return;

    await audio.pause();
  }, [src]);

  const stop = useCallback(async () => {
    trackEvent("stop_audio", src);

    const audio = audioRef.current;
    if (!audio) return;

    await audio.pause();

    audio.currentTime = 0;
  }, [src]);

  const clearAudioCache = useCallback(async () => {
    const audio = audioRef.current;

    if (!audio) return;
    if (!audio.paused) {
      await stop();
    }

    const currentSrc = audio?.src;

    if (!currentSrc) return;

    await invalidateCache?.(currentSrc);
  }, [invalidateCache, stop, audioFactory, audioRef]);

  const getAudioSrc = useCallback(
    async (newSrc: AudioSource) => {
      if (!newSrc) return null;
      if (newSrc.itemModel === "local") return newSrc.itemId;

      try {
        const { data: signedURL } = await transport.get(
          `/audioSignedUrl/${newSrc.itemModel}/${newSrc.itemId}`
        );

        const audioResp = await fetch(signedURL);
        const arrayBuffer = await audioResp.arrayBuffer();

        return URL.createObjectURL(
          new Blob([arrayBuffer], { type: "audio/mp3" })
        );
      } catch (err) {
        trackError("failed_download_audio", err, {
          itemId: newSrc.itemId,
          itemModel: newSrc.itemModel,
        });

        return null;
      }
    },
    [transport]
  );

  const setAudioSrc = useCallback(
    (audioSrc: string) => {
      if (audioFactory) {
        audioFactory.src = audioSrc;
      }
    },
    [audioFactory]
  );

  const reset = useCallback(
    async (clearCache = true, setDefault = true, clearSrc = true) => {
      if (clearCache) await clearAudioCache();

      if (setDefault) {
        setPaused(true);
        setCanPlay(false);
        setDuration(0);
        setTime(0);
      }

      if (clearSrc) {
        setSrc(null);
      }
    },
    [clearAudioCache]
  );

  const getAudio = useCallback(
    async (newSrc: AudioSource) => {
      setCanPlay(false);

      const audioSrc = await getAudioSrc(newSrc);
      const oldSrc = audioRef.current?.src;

      if (oldSrc === audioSrc) return;
      if (!audioSrc) return;

      await reset(!!oldSrc, false, false);

      if (!audioRef.current) {
        const audio = audioFactory;

        audio.addEventListener(
          "play",
          safeEventCallback(() => {
            setPaused(false);
          })
        );
        audio.addEventListener(
          "pause",
          safeEventCallback(() => {
            setPaused(true);
          })
        );
        audio.addEventListener(
          "ended",
          safeEventCallback(() => {
            setPaused(true);
            onEnded();
          })
        );

        audio.addEventListener(
          "durationchange",
          safeEventCallback(() => setDuration(audio.duration))
        );
        audio.addEventListener(
          "loadstart",
          safeEventCallback(() => setCanPlay(false))
        );

        audio.addEventListener(
          "canplay",
          safeEventCallback(() => setCanPlay(true))
        );
        audio.addEventListener(
          "timeupdate",
          safeEventCallback(() => setTime(audio.currentTime))
        );
        audio.addEventListener(
          "ratechange",
          safeEventCallback(() => {
            setPlaybackRate(audio.playbackRate);
          })
        );

        audioRef.current = audio;

        audio.load();
      }

      setAudioSrc(audioSrc);
    },
    [getAudioSrc, reset, setAudioSrc, audioFactory, onEnded, safeEventCallback]
  );

  const setup = useCallback(
    async (newSrc?: AudioSource) => {
      trackEvent("setup_audio", newSrc);

      if (!newSrc) return;
      if (
        newSrc.itemModel === "local" &&
        newSrc.itemId === audioRef.current?.src
      )
        return;

      if (isAudioActive(newSrc)) return;

      setSrc(newSrc);

      return getAudio(newSrc);
    },
    [getAudio, isAudioActive]
  );

  const play = async (newSrc?: AudioSource) => {
    trackEvent("play_audio", newSrc);

    if (lockPlayRef.current) return;

    await pause();

    await setup(newSrc);

    const audio = audioRef.current;

    if (!audio) return;
    if (audio.currentTime === duration) {
      await seek(0);
    }

    lockPlayRef.current = true;

    await audio.play();

    lockPlayRef.current = false;
  };

  const incrementPlaybackRate = async (persistedRate: number = null) => {
    const audio = audioRef.current;
    if (!audio || duration === undefined) return;
    audio.playbackRate = getNextPlaybackRate(
      persistedRate ? persistedRate : playbackRate
    );
  };

  const seek = async (time: number) => {
    const audio = audioRef.current;
    if (!audio || duration === undefined) return;
    time = Math.min(duration, Math.max(0, time));

    audio.currentTime = time || 0;
  };

  const restart = async () => {
    const audio = audioRef.current;
    if (!audio) return;

    audio.currentTime = 0;

    if (audio.paused) {
      await audio.play();
    }
  };

  const togglePlay = async (newSrc: AudioSource) => {
    if ((!!newSrc && !isAudioActive(newSrc)) || paused) {
      await play(newSrc);
    } else {
      await pause();
    }
  };

  useEffect(() => {
    return () => {
      reset();
    };
  }, []);

  useEffect(() => {
    if (canPlay && src?.isTemporary) {
      trackEvent("deleting_audio", src);
      transport.delete(`/tts/${src.itemModel}/${src.itemId}`);
    }
  }, [canPlay, src]);

  return {
    src,
    setup,
    reset,
    isAudioActive,
    controls: {
      play,
      stop,
      pause,
      togglePlay,
      restart,
      incrementPlaybackRate,
      seek,
    },
    state: { paused, duration, time, playbackRate, playbackState, canPlay },
  };
};

export type PlayerHook = ReturnType<typeof usePlayer>;

import React from 'react'
import { SVGProps, Svg } from '..'

export const ExitIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="#fa8152"
    {...props}
  >
    <g clipPath="url(#clip0_6857_24732)">
      <path d="M13.2812 14.875C12.9361 14.875 12.6562 15.1548 12.6562 15.5V17.25H3.71875V3.75H12.6562V5.5C12.6562 5.84519 12.9361 6.125 13.2812 6.125C13.6264 6.125 13.9062 5.84519 13.9062 5.5V3.125C13.9062 2.77981 13.6264 2.5 13.2812 2.5H3.09375C2.74856 2.5 2.46875 2.77981 2.46875 3.125V17.875C2.46875 18.2202 2.74856 18.5 3.09375 18.5H13.2812C13.6264 18.5 13.9062 18.2202 13.9062 17.875V15.5C13.9062 15.1548 13.6264 14.875 13.2812 14.875Z" fill="#FF6D34"/>
      <path d="M17.348 10.0581L15.6686 8.37874C15.4245 8.13464 15.0288 8.13467 14.7847 8.37874C14.5406 8.6228 14.5406 9.01855 14.7847 9.26264L15.3972 9.87505L10.2197 9.87502C9.87457 9.87502 9.59473 10.1548 9.59473 10.5C9.59473 10.8452 9.87454 11.125 10.2197 11.125L15.3972 11.1251L14.7848 11.7375C14.5407 11.9816 14.5407 12.3773 14.7848 12.6214C15.0288 12.8655 15.4246 12.8654 15.6686 12.6214L17.348 10.942C17.348 10.9419 17.3481 10.9418 17.3481 10.9418C17.4739 10.816 17.5265 10.6705 17.5311 10.5001C17.5311 10.3316 17.4595 10.1696 17.348 10.0581Z" fill="#FF6D34"/>
    </g>
    <defs>
      <clipPath id="clip0_6857_24732">
        <rect width="16" height="16" fill="white" transform="translate(2 2.5)"/>
      </clipPath>
    </defs>
  </Svg>
);

import styled from "styled-components";

export const ContentWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 8px;
  padding: 24px 20px 16px;
  border-radius: 16px;
  background: white;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 10px;
  color: #101212;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  height: 18px;
`;

export const DescriptionWrapper = styled.div`
  margin-top: 10px;
  color: #101212;
  font-family: Metropolis;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
`;

export const CardsWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const Card = styled.div`
  display: flex;
  gap: 10px;
  padding: 12px 16px;
  border-radius: 10px;
  border: 1px solid #ebebeb;
  background: #fff;
  p {
    margin-bottom: 0;
    color: #101212;
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 500;
  }
`;

export const RestoreThisVersionPanelWrapper = styled.div`
  margin-top: 0 !important;
  margin-bottom: 0 !important;
  padding: 20px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 16px;
  color: #101212;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
`;

import React from 'react'
import { SVGProps, Svg } from '..'

export const SouthKoreaFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_261)">
    <g clipPath="url(#clip1_104_261)">
    <path d="M24.5696 19.6897H0.431786C0.19375 19.6897 0.000732422 19.4967 0.000732422 19.2587V3.74148C0.000732422 3.50344 0.19375 3.31042 0.431786 3.31042H24.5697C24.8077 3.31042 25.0007 3.50344 25.0007 3.74148V19.2587C25.0007 19.4968 24.8077 19.6897 24.5696 19.6897Z" fill="white"/>
    <path d="M14.8936 7.91275C12.9129 6.59239 10.2378 7.13218 8.91827 9.10933C8.2564 10.0962 8.52545 11.4372 9.51568 12.0974C10.5052 12.7576 11.8418 12.4894 12.5021 11.4991C13.1623 10.5088 14.4998 10.244 15.4893 10.9008C16.4804 11.561 16.7469 12.9019 16.0858 13.8888C17.4062 11.9083 16.8725 9.23316 14.8936 7.91275Z" fill="#FF4B55"/>
    <path d="M8.91838 9.10938C8.25651 10.0962 8.52556 11.4372 9.51579 12.0974C10.5053 12.7576 11.842 12.4895 12.5022 11.4992C13.1624 10.5089 14.4999 10.2441 15.4894 10.9008C16.4805 11.561 16.747 12.902 16.0859 13.8889C14.7672 15.8728 12.093 16.4059 10.1124 15.0855C8.13356 13.7652 7.59802 11.09 8.91838 9.10938Z" fill="#41479B"/>
    <path d="M17.0872 6.28488L17.8702 7.46174C17.9359 7.56061 17.9093 7.69411 17.8107 7.76017L17.6121 7.89313C17.513 7.95944 17.379 7.93278 17.3129 7.83356L16.5289 6.657C16.463 6.55803 16.4897 6.42429 16.5886 6.35822L16.7881 6.22502C16.8873 6.15886 17.0212 6.18566 17.0872 6.28488Z" fill="#464655"/>
    <path d="M18.2955 8.09926L19.081 9.27538C19.1473 9.3747 19.1202 9.50907 19.0206 9.57489L18.8212 9.70668C18.7223 9.77211 18.5891 9.7452 18.5232 9.64662L17.7381 8.47172C17.672 8.37279 17.6986 8.23896 17.7976 8.1728L17.9965 8.03979C18.0955 7.97363 18.2293 8.00024 18.2955 8.09926Z" fill="#464655"/>
    <path d="M17.9257 5.72529L19.9192 8.71713C19.9853 8.81615 19.9585 8.94994 19.8594 9.01596L19.6591 9.14945C19.5601 9.21552 19.4263 9.18871 19.3603 9.08969L17.366 6.09804C17.2999 5.99897 17.3268 5.86508 17.4259 5.79912L17.6268 5.66538C17.7259 5.59951 17.8596 5.62627 17.9257 5.72529Z" fill="#464655"/>
    <path d="M18.7618 5.16674L19.5464 6.34589C19.6126 6.44521 19.5854 6.57929 19.4858 6.64506L19.2863 6.7769C19.1873 6.84233 19.0541 6.81542 18.9882 6.71664L18.203 5.53906C18.1369 5.44003 18.1637 5.3062 18.2627 5.24018L18.4629 5.10678C18.5619 5.04072 18.6958 5.06752 18.7618 5.16674Z" fill="#464655"/>
    <path d="M19.9706 6.98503L20.7541 8.16174C20.82 8.26066 20.7933 8.39426 20.6945 8.46032L20.4955 8.59333C20.3965 8.65959 20.2624 8.63283 20.1964 8.53361L19.4129 7.35691C19.347 7.25798 19.3737 7.12439 19.4725 7.05832L19.6715 6.92532C19.7705 6.85906 19.9046 6.88577 19.9706 6.98503Z" fill="#464655"/>
    <path d="M6.48189 13.3547L8.47524 16.3434C8.54126 16.4423 8.5146 16.5761 8.41557 16.6422L8.21597 16.7755C8.11694 16.8416 7.9831 16.8149 7.91699 16.7158L5.92363 13.7272C5.85762 13.6282 5.88428 13.4944 5.9833 13.4284L6.18291 13.2951C6.28193 13.229 6.41582 13.2557 6.48189 13.3547Z" fill="#464655"/>
    <path d="M5.64523 13.9137L6.42784 15.0876C6.49381 15.1865 6.4672 15.3201 6.36842 15.3862L6.16989 15.5191C6.07091 15.5854 5.93693 15.5588 5.87076 15.4597L5.08722 14.2861C5.02111 14.1872 5.04777 14.0533 5.14679 13.9872L5.3463 13.854C5.44528 13.7879 5.57916 13.8147 5.64523 13.9137Z" fill="#464655"/>
    <path d="M6.85453 15.7289L7.63803 16.9058C7.70394 17.0047 7.67723 17.1383 7.57836 17.2044L7.37924 17.3375C7.28016 17.4037 7.14623 17.377 7.08012 17.2778L6.29613 16.1012C6.23017 16.0023 6.25692 15.8685 6.3558 15.8025L6.55541 15.6692C6.65453 15.6029 6.78847 15.6297 6.85453 15.7289Z" fill="#464655"/>
    <path d="M4.80827 14.4726L6.80036 17.4613C6.86632 17.5603 6.83961 17.6939 6.74083 17.7601L6.54176 17.8932C6.44274 17.9594 6.3088 17.9327 6.24269 17.8336L4.24943 14.8452C4.18337 14.7462 4.21017 14.6124 4.3092 14.5463L4.50949 14.4128C4.60842 14.3468 4.74225 14.3735 4.80827 14.4726Z" fill="#464655"/>
    <path d="M5.92411 9.27558L7.91717 6.28452C7.98324 6.1854 8.11712 6.15865 8.2162 6.22476L8.41576 6.35801C8.51473 6.42407 8.54139 6.55786 8.47543 6.65684L6.48158 9.64624C6.41566 9.74502 6.28231 9.77187 6.18334 9.70634L5.98456 9.57475C5.88505 9.50893 5.858 9.3748 5.92411 9.27558Z" fill="#464655"/>
    <path d="M5.08686 8.71731L7.08026 5.72566C7.14633 5.62654 7.28031 5.59978 7.37938 5.66599L7.57845 5.7991C7.67728 5.86516 7.70394 5.99876 7.63802 6.09773L5.64516 9.08952C5.57909 9.18864 5.44521 9.21545 5.34613 9.14929L5.14652 9.01604C5.0476 8.95002 5.02089 8.81628 5.08686 8.71731Z" fill="#464655"/>
    <path d="M4.24906 8.16153L6.24266 5.1669C6.30872 5.06773 6.44276 5.04093 6.54183 5.10714L6.7409 5.24024C6.83973 5.30631 6.86639 5.4398 6.80057 5.53878L4.80819 8.53384C4.74222 8.63301 4.60829 8.65986 4.50921 8.5938L4.30892 8.4603C4.20995 8.39429 4.18319 8.26055 4.24906 8.16153Z" fill="#464655"/>
    <path d="M16.5293 16.3433L17.3128 15.1697C17.379 15.0706 17.513 15.044 17.612 15.1103L17.8105 15.2433C17.9093 15.3094 17.9358 15.443 17.8699 15.5419L17.0873 16.7157C17.0212 16.8148 16.8874 16.8415 16.7883 16.7754L16.5888 16.6422C16.4898 16.5761 16.4632 16.4423 16.5293 16.3433Z" fill="#464655"/>
    <path d="M17.7379 14.5343L18.5227 13.3549C18.5887 13.2558 18.7226 13.2289 18.8217 13.295L19.0217 13.4283C19.1207 13.4944 19.1475 13.6283 19.0814 13.7273L18.2949 14.9049C18.229 15.0036 18.0956 15.0304 17.9966 14.9648L17.7983 14.8333C17.6989 14.7675 17.6719 14.6335 17.7379 14.5343Z" fill="#464655"/>
    <path d="M17.366 16.9053L18.15 15.7286C18.216 15.6295 18.3498 15.6027 18.4489 15.6688L18.6493 15.8024C18.7483 15.8685 18.775 16.0021 18.7091 16.1012L17.9256 17.278C17.8596 17.377 17.7259 17.4039 17.6267 17.3379L17.4258 17.2042C17.3268 17.1382 17.2999 17.0043 17.366 16.9053Z" fill="#464655"/>
    <path d="M18.5759 15.0872L19.3604 13.9133C19.4265 13.8144 19.5602 13.7878 19.6592 13.8537L19.8595 13.9872C19.9585 14.0533 19.9853 14.1872 19.9191 14.2862L19.1347 15.4602C19.0686 15.559 18.9349 15.5857 18.8359 15.5197L18.6356 15.3862C18.5364 15.3203 18.5097 15.1863 18.5759 15.0872Z" fill="#464655"/>
    <path d="M18.2031 17.4611L18.988 16.2865C19.054 16.1879 19.1871 16.161 19.286 16.2264L19.4855 16.3582C19.5851 16.4241 19.6123 16.5583 19.546 16.6576L18.7617 17.8338C18.6957 17.9328 18.5619 17.9595 18.4628 17.8935L18.2627 17.7601C18.1636 17.694 18.1369 17.5601 18.2031 17.4611Z" fill="#464655"/>
    <path d="M19.4132 15.6491L20.1961 14.473C20.2622 14.3739 20.3962 14.3471 20.4953 14.4133L20.6941 14.5462C20.793 14.6124 20.8196 14.7461 20.7536 14.845L19.97 16.0195C19.9041 16.1183 19.7706 16.1451 19.6716 16.0795L19.4734 15.9481C19.3741 15.8823 19.3471 15.7483 19.4132 15.6491Z" fill="#464655"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_104_261">
    <rect x="1" y="4" width="22" height="16" rx="1.76923" fill="white"/>
    </clipPath>
    <clipPath id="clip1_104_261">
    <rect width="25" height="25" fill="white" transform="translate(0 -1)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
import { useState } from "react";
import styled from "styled-components";
import CopyToClipboardIcon from "./copyToClipboard.svg";
import CheckMarkIcon from "./checkMark.svg";

const ShareLinkBoxWrapper = styled.div`
  font-size: 16px;
  width: 100%;
`;

const LinkInputContainer = styled.div`
  display: flex;
`;

const LinkInput = styled.input`
  flex-grow: 1;
  padding: 0.5rem;
  border: 1px solid #ccc;
  border-right: none;
  border-radius: 4px 0 0 4px;
  background-color: #f8f8f8;
`;

const CopyButton = styled.button`
  padding: 0.5rem;c;
  border: none;
  cursor: pointer;
  transition: background-color 0.2s;
  .hover {
    background-color: #0052a3;
  }
`;

export const ShareLinkBox = ({ url, onDismiss }) => {
  const [copied, setCopied] = useState(false);

  const handleCopy = async () => {
    try {
      await navigator.clipboard.writeText(url);
      setCopied(true);
      setTimeout(() => {
        setCopied(false);
        onDismiss();
      }, 500);
    } catch (err) {
      console.error("Failed to copy text: ", err);
    }
  };

  return (
    <ShareLinkBoxWrapper>
      <LinkInputContainer>
        <LinkInput type="text" readOnly value={url} />
        <CopyButton onClick={handleCopy} aria-label="Copy to clipboard">
          <img
            src={copied ? CheckMarkIcon : CopyToClipboardIcon}
            width={32}
            height={32}
          />
        </CopyButton>
      </LinkInputContainer>
    </ShareLinkBoxWrapper>
  );
};

// Styles
import { LampHintWrapper } from "./lamp-hint.style";
// Icons
import { LampIcon } from "../IconsSvg";

export const LampHint = ({ text }) => {
  return (
    <LampHintWrapper>
      <LampIcon width="18px" height="18px" />
      <p>{text}</p>
    </LampHintWrapper>
  );
};

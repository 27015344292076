import React from 'react';
import styled from 'styled-components';
import { CheckmarkIcon } from '../IconsSvg';
// Styles
const CustomCheckboxContainer = styled.div`
  display: inline-block;
`;
const HiddenCheckbox = styled.input.attrs({ type: 'checkbox' })`
  position: absolute;
  opacity: 0;
  cursor: pointer;
  height: 0;
  width: 0;
`;
const StyledCheckbox = styled.div<{ $checked: boolean }>`
  display: inline-block;
  width: 20px;
  height: 20px;
  border: 1px solid #FF6D34;
  border-radius: 2px;
  background-color: transparent;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    width: 18px;
    height: 18px;
  }
`;
const Checkmark = styled.span<{ $checked: boolean }>`
  position: relative;
  left: 5px;
  bottom: 3px;
  @media screen and (max-width: 767px) {
    left: 4px;
    bottom: 5px;
  }
  visibility: ${({ $checked }) => ($checked ? 'visible' : 'hidden')};
`;

interface CheckboxProps {
  checked: boolean;
  id?: string;
  onChange: (event: React.MouseEvent<HTMLDivElement> | React.ChangeEvent<HTMLInputElement> ) => void;
}
  
const Checkbox: React.FC<CheckboxProps> = ({ onChange, checked, id, ...props }) => (
  <CustomCheckboxContainer>
    <HiddenCheckbox {...props} onChange={onChange} id={id} />
    <StyledCheckbox onClick={onChange} $checked={checked}>
      <Checkmark $checked={checked}>
        <CheckmarkIcon />
      </Checkmark>
    </StyledCheckbox>
  </CustomCheckboxContainer>
);

export default Checkbox;
import React from 'react'
import { SVGProps, Svg } from '..'

export const DialogIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M7.083 21.813C6.345 21.813 5.646 21.65 5.018 21.359L2 22L2.631 18.976C2.338 18.346 2.174 17.644 2.174 16.904C2.174 14.196 4.369 12 7.078 12C9.787 12 11.982 14.196 11.982 16.904C11.982 19.612 9.786 21.808 7.078 21.808" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.612 12.019C6.219 11.164 6 10.227 6 9.24C6 5.223 9.598 2 14 2C18.402 2 22 5.223 22 9.24C22 11.614 20.738 13.704 18.801 15.022C18.802 15.778 18.8 16.797 18.8 17.867L15.661 16.321C15.124 16.424 14.569 16.48 14 16.48C13.289 16.48 12.602 16.388 11.944 16.23" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.79512 16.9646C4.81465 16.9842 4.81465 17.0158 4.79512 17.0354C4.77559 17.0549 4.74394 17.0549 4.72441 17.0354C4.70488 17.0158 4.70488 16.9842 4.72441 16.9646C4.74394 16.9451 4.77559 16.9451 4.79512 16.9646" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7.04512 16.9646C7.06465 16.9842 7.06465 17.0158 7.04512 17.0354C7.02559 17.0549 6.99394 17.0549 6.97441 17.0354C6.95488 17.0158 6.95488 16.9842 6.97441 16.9646C6.99394 16.9451 7.02559 16.9451 7.04512 16.9646" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.29512 16.9646C9.31465 16.9842 9.31465 17.0158 9.29512 17.0354C9.27559 17.0549 9.24394 17.0549 9.22441 17.0354C9.20488 17.0158 9.20488 16.9842 9.22441 16.9646C9.24394 16.9451 9.27559 16.9451 9.29512 16.9646" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M13.9996 10.0439C13.9996 9.38694 14.4056 9.03094 14.8126 8.75694C15.2106 8.48894 15.6086 8.13994 15.6086 7.49694C15.6086 6.60794 14.8886 5.88794 13.9996 5.88794C13.1106 5.88794 12.3906 6.60794 12.3906 7.49694" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14 12.458C13.945 12.458 13.9 12.503 13.9 12.558C13.9 12.613 13.945 12.658 14 12.658C14.055 12.658 14.1 12.613 14.1 12.558C14.1 12.503 14.055 12.458 14 12.458" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
import React from 'react'
import { SVGProps, Svg } from '..'

export const ImageIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M3 16L7.58579 11.4142C8.36684 10.6332 9.63316 10.6332 10.4142 11.4142L12.5858 13.5858C13.3668 14.3668 14.6332 14.3668 15.4142 13.5858L21 8" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M14.05 8.00007C14.05 8.02767 14.0276 8.05002 14 8.05C13.9724 8.04998 13.95 8.0276 13.95 8C13.95 7.9724 13.9724 7.95002 14 7.95C14.0276 7.94998 14.05 7.97233 14.05 7.99994V8.00007" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <rect x="3" y="3" width="18" height="18" rx="5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
import React, { useState } from "react";
// Components
import LetMeExplain from "../../pages/WritingFeedback/WritingFeedbackSettings/let-me-explain";
import SharpenWithQuestions from "../../pages/WritingFeedback/WritingFeedbackSettings/sharpen-with-questions";
import ClarifyMainIdea from "../../pages/WritingFeedback/WritingFeedbackSettings/clarify-main-idea";
import ChooseWritingStyle from "../../pages/WritingFeedback/WritingFeedbackSettings/choose-writing-style";
import GenerateCoverImage from "../../pages/WritingFeedback/WritingFeedbackSettings/generate-cover-image";
import { ProcessingCircle } from "../ProcessingCircle";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { SecondaryButton } from "../Buttons";
// Custom hooks
import { useUpgradeToProModal } from "../../hooks/use-upgrade-to-pro-modal";
import { useBasicModal } from "../../hooks/use-basic-modal";
import { useGlobal } from "@considr-it/storied-shared";
import { useFeaturesLocking } from "../../hooks/use-features-locking";
// Styles
import {
  ContentWrapper,
  TitleWrapper,
  DescriptionWrapper,
  Card,
  CardsWrapper,
  RestoreThisVersionPanelWrapper,
} from "./mobile-feedback.styles";
// Icons
import {
  IdeaIcon,
  EditAIIcon,
  ImageIcon,
  DocIcon,
  RocketIcon,
  DialogIcon,
  HeadIcon,
} from "../../components/IconsSvg";
import { CollapsableMenu } from "../CollapsableMenu";

interface MobileFeedbackProps {
  currentTopic: any;
  processingOutputType: any;
  streaming: any;
  generateOutput: any;
  isLastOutput: boolean;
  restoreVersionModal: () => void;
}

interface Setting {
  getComponent: () => JSX.Element;
  num: number;
  Icon: React.FC<{ $inactive?: boolean }>;
  name: string;
}

export const MobileFeedback: React.FC<MobileFeedbackProps> = ({
  currentTopic,
  processingOutputType,
  streaming,
  generateOutput,
  isLastOutput,
  restoreVersionModal,
}) => {
  const [activeSetting, setActiveSetting] = useState<number>(4);
  const { BasicModal, toggleModal } = useBasicModal({
    contentWrapper: ContentWrapper,
  });
  const { isLocked } = useFeaturesLocking();
  const { onPresent: showUpgradeToProModal } = useUpgradeToProModal();

  const settingsToRender: Setting[] = [
    {
      getComponent: () => renderSetting(SharpenWithQuestions),
      num: 0,
      Icon: DialogIcon,
      name: "Identify the gaps",
    },
    {
      getComponent: () => renderSetting(ClarifyMainIdea),
      num: 1,
      Icon: RocketIcon,
      name: "Choose the main idea",
    },
    {
      getComponent: () => renderSetting(ChooseWritingStyle),
      num: 2,
      Icon: DocIcon,
      name: "Choose the doc type",
    },
    {
      getComponent: () => renderSetting(GenerateCoverImage),
      num: 3,
      Icon: ImageIcon,
      name: "Generate cover image",
    },
    {
      getComponent: () => renderSetting(LetMeExplain),
      num: 4,
      Icon: HeadIcon,
      name: "Describe changes",
    },
  ];

  const renderSetting = (Component: React.ComponentType<any>) => (
    <Component
      disabled={processingOutputType || streaming}
      generateOutput={generateOutput}
      forMobile
      openDefaultSetting={() => setActiveSetting(4)}
    >
      <SecondaryButton
        noIconFill
        extraSmall
        onClick={toggleModal}
        style={{
          marginLeft: "auto",
          height: 34,
          fontSize: 12,
          padding: 8,
          borderRadius: 10,
          width: "auto",
          gap: 8,
        }}
      >
        <IdeaIcon width={16} height={16} style={{ marginBottom: 2 }} />
        Options
      </SecondaryButton>
    </Component>
  );

  const onCardClick = (num: number) => {
    setActiveSetting(num);
    toggleModal();
  };

  const RestoreThisVersionComponent = (
    <RestoreThisVersionPanelWrapper>
      <div>
        Sorry, but only the last version can be edited. Would you like to
        restore to this version?
      </div>
      <SecondaryButton onClick={restoreVersionModal} style={{ maxWidth: 200 }}>
        Restore to this version
      </SecondaryButton>
    </RestoreThisVersionPanelWrapper>
  );

  return (
    <>
      <CollapsableMenu
        onTouchZone={isLocked ? () => showUpgradeToProModal : null}
        defaultExpanded={!isLocked}
      >
        <ReturnJSX
          if={!currentTopic?.topicParametersGenerating}
          else={<ProcessingCircle />}
        >
          <ReturnJSX if={isLastOutput} else={RestoreThisVersionComponent}>
            {settingsToRender[activeSetting].getComponent()}
          </ReturnJSX>
        </ReturnJSX>
      </CollapsableMenu>
      <BasicModal>
        <TitleWrapper>
          <EditAIIcon width={18} height={18} />
          Edit with AI
        </TitleWrapper>
        <DescriptionWrapper>
          Improve the writing with the tools below.
        </DescriptionWrapper>
        <CardsWrapper>
          {settingsToRender.map(({ Icon, num, name }) => (
            <Card key={num} onClick={() => onCardClick(num)}>
              <Icon $inactive />
              <p>{name}</p>
            </Card>
          ))}
        </CardsWrapper>
      </BasicModal>
    </>
  );
};

export const checkSupportFlagEmoji = (): boolean => {
  const flagEmoji = '🇺🇸';
  const canvas = document.createElement('canvas');
  if (!canvas.getContext) return false;
  const ctx = canvas.getContext('2d');
  if (!ctx) return false;
  ctx.textBaseline = 'top';
  ctx.font = '32px Arial';
  ctx.fillText(flagEmoji, 0, 0);
  return ctx.getImageData(16, 16, 1, 1).data[0] !== 0;
};

import { SharedOutput, SharedOutputReview } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useState } from "react";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";
import useConfirmModal from "../../../hooks/use-confirm-modal";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import { ReviewItem } from "../ReviewItem";

interface IReviewsListProps {
  sharedOutputReviewsIds: string[];
}

export const ReviewsList = ({ sharedOutputReviewsIds }: IReviewsListProps) => {
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const { transport } = useGlobal();
  const {
    setAnnotationsList,
    currentSelectedAnnotation,
    setCurrentSelectedAnnotation,
  } = useIdeaFlow();
  const [reviewsList, setReviewsList] = useState<string[]>(null);

  useEffect(() => {
    setReviewsList(sharedOutputReviewsIds);
  }, [sharedOutputReviewsIds]);

  const handleDelete = (reviewId: string) => {
    onPresentConfirmModal({
      confirmationMessage: "Are you sure you want to delete this comment?",
      actions: [
        <PrimaryButton
          onClick={async () => {
            onDismiss();
            transport.delete(`/sharedOutputReview/${reviewId}`);

            if (currentSelectedAnnotation && reviewsList.length === 1) {
              setAnnotationsList((prev) => {
                const newArray = prev.annotations.filter(
                  (a) => a.id !== currentSelectedAnnotation.id
                );

                return { annotations: newArray, reason: "removeAnnotation" };
              });

              setCurrentSelectedAnnotation(null);
            }

            setReviewsList((prev) => {
              return prev.filter((x: string) => x !== reviewId);
            });
          }}
        >
          Confirm
        </PrimaryButton>,
        <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>,
      ],
    });
  };

  if (!reviewsList) return <></>;

  return (
    <div>
      {reviewsList.map((sor: string) => {
        return (
          <ReviewItem
            key={sor}
            sharedOutputReviewId={sor}
            handleDelete={handleDelete}
          />
        );
      })}
    </div>
  );
};

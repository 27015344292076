// Styles
import { BillingCycle, PaymentTier } from "@considr-it/storied-enums";
import { useAccount } from "@considr-it/storied-shared";
import { useEffect, useMemo, useState } from "react";
import { useNavigate } from "react-router-dom";
import { PrimaryButton } from "../../components/Buttons";
import {
  ArrowUpperRightIcon,
  CheckmarkRoundedIcon,
  StarIcon,
} from "../../components/IconsSvg";
import { usePayment } from "../../hooks/use-payment";
import {
  PricingWrapper,
  Title,
  Description,
  TabsWrapper,
  PricingTabs,
  TabItem,
  CardsWrapper,
  Card,
  CardTopBlock,
  CardBottomBlock,
  PlanInfoWrapper,
  PlanName,
  PlanPrice,
  PlanDescription,
  FeaturesWrapper,
  FeaturesItem,
  SiteLink,
  IconWrapper,
} from "./pricing.style";

export const Pricing = () => {
  const { account, login } = useAccount();
  const navigate = useNavigate();

  const [billingCycleType, setBillingCycleType] = useState<BillingCycle>(
    BillingCycle.BILLING_CICLE_MONTHLY
  );

  const { paymentInfo, paidPlans, startCheckoutProcess } = usePayment();

  useEffect(() => {
    if (
      ![
        PaymentTier.PAYMENT_TIER_EXPIRED,
        PaymentTier.PAYMENT_TIER_PRO_TRIAL,
      ].includes(paymentInfo.paymentTier)
    ) {
      navigate("/");
    }
  }, [paymentInfo]);

  if (!paidPlans) {
    return <></>;
  }

  return (
    <PricingWrapper>
      <Title>Plans & Pricing</Title>
      <Description>
        From aspiring writers seeking efficiency on a shoestring budget to
        seasoned professionals managing prestigious accounts, we have the
        perfect plan for every stage of your journey.
      </Description>
      <TabsWrapper>
        <PricingTabs>
          <TabItem
            onClick={() =>
              setBillingCycleType(BillingCycle.BILLING_CICLE_MONTHLY)
            }
            $isActive={billingCycleType === BillingCycle.BILLING_CICLE_MONTHLY}
          >
            Monthly billing
          </TabItem>
          <TabItem
            onClick={() =>
              setBillingCycleType(BillingCycle.BILLING_CICLE_ANUALLY)
            }
            $isActive={billingCycleType === BillingCycle.BILLING_CICLE_ANUALLY}
          >
            Annually billing
          </TabItem>
        </PricingTabs>
      </TabsWrapper>
      <CardsWrapper>
        {paidPlans.map((plan) => {
          const isAnnualBillingPlan =
            billingCycleType === BillingCycle.BILLING_CICLE_ANUALLY;
          const planPrice = isAnnualBillingPlan
            ? plan.priceAnnually
            : plan.priceMonthly;
          const planDescription = isAnnualBillingPlan
            ? plan.descriptionAnnually
            : plan.descriptionMonthly;
          const isHighlighted =
            plan.paymentTier === PaymentTier.PAYMENT_TIER_PRO;
          const ctaText =
            plan.paymentTier === PaymentTier.PAYMENT_TIER_TEAM
              ? "Contact Us"
              : "Buy now";
          return (
            <Card key={plan.name} $isHighlighted={isHighlighted}>
              {isHighlighted && (
                <IconWrapper>
                  <StarIcon />
                </IconWrapper>
              )}
              <CardTopBlock className="card-top-block">
                <PlanInfoWrapper>
                  <PlanName>{plan.name}</PlanName>
                  <PlanPrice className="plan-price">{planPrice}</PlanPrice>
                  <PlanDescription>{planDescription}</PlanDescription>
                </PlanInfoWrapper>
                <PrimaryButton
                  disabled={plan.id === paymentInfo.paymentTier}
                  onClick={() => {
                    if (plan.paymentTier === PaymentTier.PAYMENT_TIER_TEAM) {
                      window.open(
                        "https://forms.gle/71tofhhsWt9vFowR6",
                        "_blank"
                      );
                    } else if (account.isAnonymous) {
                      login("signup");
                    } else {
                      startCheckoutProcess(plan.paymentTier, billingCycleType);
                    }
                  }}
                  fullWidth
                >
                  {ctaText}
                </PrimaryButton>
              </CardTopBlock>
              <CardBottomBlock className="card-bottom-block">
                <FeaturesWrapper>
                  {plan.features.map((feature) => (
                    <FeaturesItem key={feature}>
                      <CheckmarkRoundedIcon />
                      {feature}
                    </FeaturesItem>
                  ))}
                </FeaturesWrapper>
                <SiteLink
                  onClick={() => window.open(plan.webflowLink, "_blank")}
                >
                  Find more details on plan features
                  <ArrowUpperRightIcon style={{ marginBottom: 2 }} />
                </SiteLink>
              </CardBottomBlock>
            </Card>
          );
        })}
      </CardsWrapper>
    </PricingWrapper>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const InfoIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="17"
    viewBox="0 0 16 17"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_6273_23298)">
      <path d="M8 0.5C3.57861 0.5 0 4.078 0 8.5C0 12.9214 3.578 16.5 8 16.5C12.4214 16.5 16 12.922 16 8.5C16 4.07861 12.422 0.5 8 0.5ZM8 15.25C4.26941 15.25 1.25 12.231 1.25 8.5C1.25 4.76941 4.26904 1.75 8 1.75C11.7306 1.75 14.75 4.76904 14.75 8.5C14.75 12.2306 11.731 15.25 8 15.25ZM9.85413 12.1875C9.85413 12.5327 9.57434 12.8125 9.22913 12.8125H6.77087C6.42566 12.8125 6.14587 12.5327 6.14587 12.1875C6.14587 11.8423 6.42566 11.5625 6.77087 11.5625H7.375V7.89587H6.77087C6.42566 7.89587 6.14587 7.61609 6.14587 7.27087C6.14587 6.92566 6.42566 6.64587 6.77087 6.64587H8C8.34521 6.64587 8.625 6.92566 8.625 7.27087V11.5625H9.22913C9.57434 11.5625 9.85413 11.8423 9.85413 12.1875ZM7.21875 4.36462C7.21875 3.93311 7.56848 3.58337 8 3.58337C8.43152 3.58337 8.78125 3.93311 8.78125 4.36462C8.78125 4.79602 8.43152 5.14587 8 5.14587C7.56848 5.14587 7.21875 4.79602 7.21875 4.36462Z" fill="#D1D1D1"/>
    </g>
    <defs>
      <clipPath id="clip0_6273_23298">
        <rect width="16" height="16" fill="white" transform="translate(0 0.5)"/>
      </clipPath>
    </defs>
  </Svg>
);

  
import styled from "styled-components";

export const CarouselWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 518px;
  min-height: 460px;
  @media screen and (max-width: 767px) {
    max-width: 518px;
    width: calc(100vw - 40px);
  }
`;
export const ModalWrapper = styled.div`
  max-width: 518px;
  margin: auto;
  background: white;
  @media screen and (max-width: 767px) {
    border-radius: 16px;
  }
  border-radius: 24px;
`;
export const TopSection = styled.div`
  height: 300px;
  width: 100%;
  @media screen and (max-width: 767px) {
    height: 180px;
  }
`;
export const Title = styled.h2`
  color: #101212;
  font-family: Metropolis;
  font-size: 32px;
  font-weight: 600;
  line-height: 41.6px;
  letter-spacing: -0.704px;
  margin-bottom: 0;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    font-weight: 600;
    line-height: 28.8px;
  }
`;
export const Description = styled.p`
  margin-top: 16px;
  margin-bottom: 0;
  color: #101212;
  font-size: 18px;
  line-height: 23.4px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.352px;
  }
`;
export const ButtonCTA = styled.div`
  margin-top: 24px;
  margin-right: auto;
  width: 100%;
  cursor: pointer;
  border-radius: 10px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FF6D34;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  transition: all .15s;
  padding: 0;
  svg {
    path {
      stroke: #FF6D34;
    }
  }
  &:hover {
    color: #fc500d;
    svg {
      path {
        stroke: #fc500d;
      }
    }
  }
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
  }
`;
export const MiddleSection = styled.div`
  padding: 40px 40px 0 40px;
  margin-bottom: 48px;
  @media screen and (max-width: 767px) {
    padding: 24px 24px 0 24px;
  }
`;
export const BottomSection = styled.div`
  margin-top: auto;
  display: flex;
  justify-content: space-between;
  padding: 40px;
  padding-top: 0;
  @media screen and (max-width: 767px) {
    padding: 24px;
    padding-top: 0;
  }
`;
export const DotsWrapper = styled.div`
  display: flex;
  align-items: center;
  height: 10px;
  gap: 4px;
`;
export const Dot = styled.div<{ $active: boolean }>`
  width: ${(props) => props.$active ? "10px" : "6px"};
  height: ${(props) => props.$active ? "10px" : "6px"};
  background: ${(props) => props.$active ? "#FF6D34" : "#FFEFE8"};
  border-radius: 50%;
  transition: background .3s;
  cursor: pointer;
`;
export const ImgWrapper = styled.div`
  height: 100%;
  width: 100%;
  img {
    @media screen and (max-width: 767px) {
      border-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
    }
    border-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    height: 100%;
    width: 100%;
    object-fit: cover;
  }
`;
export const VideoWrapper = styled.div`
  height: 100%;
  width: 100%;
  display: flex;
  border-radius: 24px;
  border-bottom-left-radius: 0;
  border-bottom-right-radius: 0;
  video {
    object-fit: cover;
    height: 100%;
    width: 100%;
    border-radius: 24px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
  }
  @media screen and (max-width: 767px) {
    max-height: 100%;
    max-width: 100%;
    overflow: hidden;
    position: relative;
    background: #fff7f1;
    border-radius: 16px;
    border-bottom-left-radius: 0;
    border-bottom-right-radius: 0;
    video {
      border-radius: 16px;
      border-bottom-left-radius: 0;
      border-bottom-right-radius: 0;
      position: absolute;
      height: 160%;
      bottom: 50%;
      transform: translate(3%, 50%);
      height: 150%;
    }
  }
`;
import React from 'react'
import { SVGProps, Svg } from '..'

export const PenCircleIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="27"
    height="27"
    viewBox="0 0 27 27"
    fill="none"
    {...props}
  >
		<path d="M14.345 25.2208C2.91264 25.7403 4.56095 5.3101 12.4373 4.61823C12.7066 4.3864 12.2716 4.35694 11.4187 4.27003C3.92589 4.91286 1.50556 25.3976 13.7753 25.944C29.1456 26.6285 33.3186 0.681759 14.345 0.00595037C4.2197 -0.354695 -2.58279 15.5356 1.24991 25.9119C1.40576 26.285 1.69016 26.5493 2.00955 26.8333C-1.04625 15.782 5.00044 0.291701 14.9838 0.943407C32.1939 2.06687 26.8293 24.6535 14.345 25.2208Z" fill="#101212"/>
  </Svg>
);

  
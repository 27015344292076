export const getNextSubscriptionRefreshDate = (
  subscriptionStartDate: Date | string
) => {
  const todayDate = new Date();

  let startDate = new Date(subscriptionStartDate);
  const endDate = startDate;

  while (endDate.getTime() < todayDate.getTime()) {
    startDate = new Date(endDate);
    endDate.setMonth(endDate.getMonth() + 1);
  }

  return { startDate, endDate };
};

import { useNavigate, useParams } from "react-router-dom";
import { useEffect } from "react";
// Custom hooks
import { useDocs } from "../../hooks/use-docs";
// Components
import { Cards } from "./Cards";
import { Search } from "./Search";
import { LoadingAnimation } from "../../components/LoadingAnimation";
// Styles
import {
  CategoriesWrapper,
  DocsSwitchButton,
  NoSearchResultsWrapper,
  CategoriesWrapperStand,
  DocsPageWrapper,
} from "./my-documents.style";
// Pictures
import NoResultsPic from "./noResultsPic.svg";

export const MyDocuments = () => {
  const { filter, searchTerm } = useParams();
  const navigate = useNavigate();
  const {
    isLoading,

    editedWithAiList,
    inProgressList,
    sharedList,
    draftsList,

    currentDocsList,

    getDocType,
    removeDoc,
    convertLiveStory,
  } = useDocs();
  useEffect(() => {
    if (!filter) {
      navigate(`/documents/all-docs/${searchTerm || ""}`);
    }
  }, []);

  const renderButton = (to: string, label: string, count: number) => (
    <DocsSwitchButton to={to} $isActive={filter === to.split("/")[2]}>
      {`${label}${count ? ` (${count})` : ""}`}
    </DocsSwitchButton>
  );

  if (isLoading) {
    return <LoadingAnimation circle />;
  }

  return (
    <DocsPageWrapper>
      <Search />
      <CategoriesWrapper>
        {renderButton(
          `/documents/all-docs/${searchTerm || ""}`,
          "All Documents",
          editedWithAiList?.length +
            inProgressList?.length +
            sharedList?.length +
            draftsList?.length
        )}
        {renderButton(
          `/documents/edited-with-ai/${searchTerm || ""}`,
          "Edited with AI",
          editedWithAiList?.length
        )}
        {renderButton(
          `/documents/in-progress/${searchTerm || ""}`,
          "In Progress",
          inProgressList?.length
        )}
        {renderButton(
          `/documents/drafts/${searchTerm || ""}`,
          "Drafts",
          draftsList?.length
        )}
        {renderButton(
          `/documents/shared-docs/${searchTerm || ""}`,
          "Shared with me",
          sharedList?.length
        )}
      </CategoriesWrapper>
      <CategoriesWrapperStand />
      {!currentDocsList?.length ? (
        <NoSearchResultsWrapper>
          <img src={NoResultsPic} />
          Oops.. Nothing found
        </NoSearchResultsWrapper>
      ) : null}
      {currentDocsList?.length ? (
        <Cards
          docs={currentDocsList}
          removeDoc={removeDoc}
          getDocType={getDocType}
          convertLiveStory={convertLiveStory}
        />
      ) : null}
    </DocsPageWrapper>
  );
};

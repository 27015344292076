import React from 'react'
import { SVGProps, Svg } from '..'

export const PortugalFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_315)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H8V20H1V4Z" fill="#249F58"/>
    <path d="M8.33335 14.6667C10.0691 14.6667 11.4762 13.234 11.4762 11.4667C11.4762 9.69941 10.0691 8.26672 8.33335 8.26672C6.5976 8.26672 5.19049 9.69941 5.19049 11.4667C5.19049 13.234 6.5976 14.6667 8.33335 14.6667Z" fill="#FFDA2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 12.5334V9.33337H6.2381V12.5334C6.2381 13.1222 7.17572 13.6 8.33334 13.6C9.49096 13.6 10.4286 13.1222 10.4286 12.5334Z" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.28571 10.4H9.38094V12.5334H7.28571V10.4Z" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.28571 10.4H8.33332V11.4667H7.28571V10.4ZM8.33332 11.4667H9.38094V12.5334H8.33332V11.4667Z" fill="#1A47B8"/>
    </g>
    <defs>
    <clipPath id="clip0_104_315">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
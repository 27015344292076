import React, { useMemo, useState, useEffect } from "react";
import { useDocument } from "../../hooks/use-document";
import { EditAIIcon, ExportIcon, MicOutlineIcon } from "../IconsSvg";
import {
  BarWrapper,
  DividerWrapper,
  ItemWrapper,
  WritingProgressBarWrapper,
  CornerNumWrapper,
  IconCircleBackground,
} from "./styles";
import { OutputGenerateReason } from "@considr-it/storied-enums";
import { Output } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
// Constants
const activeColor = "#4CBF05";
const deactiveColor = "#ABABAB";

function ProgressBarItem({ item, isActive }) {
  return (
    <BarWrapper $isActive={isActive}>
      <IconCircleBackground $isActive={isActive}>
        {item.icon}
      </IconCircleBackground>
      <span>{item.label}</span>
      {item.cornerNum ? (
        <CornerNumWrapper>{item.cornerNum}</CornerNumWrapper>
      ) : null}
    </BarWrapper>
  );
}

export default function WritingProgressBar() {
  const { currentTopic } = useDocument();
  const { transport } = useGlobal();

  const [latestOutput, setLatestOutput] = useState<Output>(null);

  useEffect(() => {
    if (currentTopic && currentTopic.outputs.length > 0) {
      transport
        .get<Output>(
          `/output/${currentTopic.outputs[currentTopic.outputs.length - 1]}`
        )
        .then((resp) => setLatestOutput(resp.data));
    }
  }, [currentTopic]);

  const activeStep = useMemo(() => {
    if (
      latestOutput &&
      latestOutput.reason !== OutputGenerateReason.CLEANUP_TRANSCRIPT &&
      latestOutput.reason !== OutputGenerateReason.DEFAULT &&
      latestOutput.reason !== OutputGenerateReason.CUSTOM_START
    ) {
      if (currentTopic.exported) {
        return 3;
      } else {
        return 2;
      }
    }

    return 1;
  }, [currentTopic, latestOutput]);

  const items = [
    {
      icon: <MicOutlineIcon $color={activeColor} />,
      label: "Voice",
    },
    {
      icon: (
        <EditAIIcon $color={activeStep >= 2 ? activeColor : deactiveColor} />
      ),
      label: "Edit with AI",
      cornerNum:
        currentTopic.outputs.length >= 2 && activeStep >= 2
          ? currentTopic.outputs.length - 1
          : null,
    },
    {
      icon: (
        <ExportIcon $color={activeStep >= 3 ? activeColor : deactiveColor} />
      ),
      label: "Export",
    },
  ];

  return (
    <WritingProgressBarWrapper>
      {items.map((item, idx) => (
        <React.Fragment key={`progress_${idx}`}>
          <ItemWrapper>
            <ProgressBarItem item={item} isActive={idx + 1 <= activeStep} />
          </ItemWrapper>
          {idx + 1 < items.length && (
            <DividerWrapper
              fill={idx + 1 < activeStep ? activeColor : deactiveColor}
            />
          )}
        </React.Fragment>
      ))}
    </WritingProgressBarWrapper>
  );
}

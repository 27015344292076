import styled from "styled-components";

export const ButtonsWrapper = styled.div`
  display: grid;
  width: 100%;
  grid-template-columns: 1fr 1fr;
  gap: 8px;
`;

export const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  color: #101212;
  font-size: 18px;
  font-style: normal;
  font-weight: 600;
  line-height: 24px;
  margin-bottom: 10px;
  p {
    margin-bottom: 0;
  }
  @media screen and (max-width: 360px) {
    font-size: 14px;
    font-weight: 500;
    line-height: 18.2px;
    letter-spacing: -0.308px;
  }
`;

export const FormCheckWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;

export const SummaryBanner = styled.div`
  background: white;
  box-shadow: 0px 4px 21px 0 rgba(0, 0, 0, 0.05);
  border-radius: 10px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 12px 16px;
  min-height: 70px;
  margin-bottom: 24px;
`;

export const WritingFeedbackWrapper = styled.div`
  display: grid;
  grid-template-columns: 60% 1fr;
  grid-gap: 24px;
  align-items: flex-start;

  @media screen and (max-width: 767px) {
    display: block;
    .generateImageWrapper {
      position: relative;
      &::before {
        content: " ";
        position: absolute;
        left: 0;
        top: 0;
        width: 100%;
        height: 100%;
        background: rgb(0 0 0 / 50%);
        opacity: 1;
      }
    }
    .coverImageBtn {
      position: absolute;
      left: 50%;
      top: 50%;
      transform: translate(-50%, -50%);
      display: flex;
      align-items: center;
      gap: 10px;
      transition: all 0.15s ease-in-out;
      background: transparent;
      border: 1px solid white !important;
      border-radius: 13px;
      padding: 10px 16px;
      cursor: pointer;
      color: white;
      font-family: Metropolis;
      font-size: 16px;
      font-weight: 500;
      text-align: center;
      letter-spacing: -0.352px;
      .magicWandIcon {
        fill: white;
        width: 24px;
      }
    }
  }
  .form-check {
    display: flex;
    gap: 10px;
    padding: 0;
    min-height: unset;
    margin-bottom: 0;
    @media screen and (max-width: 360px) {
     align-items: center;
     font-size: 12px;
     line-height: 15.6px; 
    }
  }
  .form-check .form-check-input {
    width: 20px;
    height: 20px;
    margin: 0;
    margin-top: 2.5px;
    @media screen and (max-width: 360px) {
      width: 16px;
      height: 16px;
      margin-top: 0;
    }
  }
`;

export const SpanQA = styled.span`
  font-weight: 500;
  font-size: 16px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
  }
`;

export const PanelLabel = styled.h4`
  font-size: 24px;
  line-height: 31px;
  height: 31px;
  font-weight: 600;
  margin: 0 8px 16px;
  display: flex;
  gap: 12px;
  align-items: center;
`;

export const StartNewBtnWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-bottom: 16px;
`;

export const ToolDescription = styled.p`
  font-size: 16px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 400;
    line-height: 15.6px;
  }
`;

export const OrElementWrapper = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 16px;
  margin-top: 12px;
  margin-bottom: 12px;
  margin-left: 32px;
  margin-right: 32px;
`;

export const OrElement = styled.div`
  color: #ababab;
  font-family: "Metropolis";
  font-size: 14px;
  font-weight: 400;
  line-height: 130%;
`;

export const HRLine = styled.div`
  background: #ababab;
  height: 1px;
  width: 100%;
`;

export const RestoreThisVersionPanelWrapper = styled.div`
  margin-top: 130px;
  margin-bottom: 200px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 24px;
  color: #101212;
  text-align: center;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
`;

export const RestoreVerIconWrapper = styled.div`
  border-radius: 145.455px;
  background: #FFEFE8;
  width: 64px;
  height: 64px;
  display: flex;
  justify-content: center;
  align-items: center;
`;

export const RestoreVerButtonWrapper = styled.div`
  display: flex;
  gap: 16px;
  align-items: center;
  justify-content: center;
  flex-direction: column;
`;
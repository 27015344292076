import React from 'react'
import { SVGProps, Svg } from '..'

export const UkraineFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_102_55770)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#FFDA2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H23V12H1V4Z" fill="#3A99FF"/>
    </g>
    <defs>
    <clipPath id="clip0_102_55770">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
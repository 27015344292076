import React from 'react'
import { SVGProps, Svg } from '..'

export const ArrowLongIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M12 19L12 5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17.001 14.001L12 19.002L6.99898 14.001" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);


import React from 'react'
import { SVGProps, Svg } from '..'

export const IndiaFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_266)">
    <g clipPath="url(#clip1_104_266)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M12 14.6667C13.4457 14.6667 14.6191 13.472 14.6191 12C14.6191 10.528 13.4457 9.33337 12 9.33337C10.5543 9.33337 9.38098 10.528 9.38098 12C9.38098 13.472 10.5543 14.6667 12 14.6667ZM12 13.6C12.8675 13.6 13.5715 12.8832 13.5715 12C13.5715 11.1168 12.8675 10.4 12 10.4C11.1326 10.4 10.4286 11.1168 10.4286 12C10.4286 12.8832 11.1326 13.6 12 13.6Z" fill="#1A47B8"/>
    <path d="M12 12.5333C12.2893 12.5333 12.5238 12.2946 12.5238 12C12.5238 11.7055 12.2893 11.4667 12 11.4667C11.7107 11.4667 11.4762 11.7055 11.4762 12C11.4762 12.2946 11.7107 12.5333 12 12.5333Z" fill="#1A47B8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 15H23V20H1V15Z" fill="#249F58"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H23V9H1V4Z" fill="#FF6C2D"/>
    </g>
    </g>
  </Svg>
);

  
import { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import TypeOrRecordForm from "../../../components/TypeOrRecordForm";
import {
  IdeaType,
  OutputGenerateReason,
  RecordingObjectType,
} from "@considr-it/storied-enums";
import {
  FormCheckWrapper,
  TitleWrapper,
  ToolDescription,
} from "../writing-feedback.style";
import { useDocument } from "../../../hooks/use-document";
import { Idea } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import { RocketIcon } from "../../../components/IconsSvg";

const ClarifyMainIdea = ({
  eventKey,
  activeKey,
  generateOutput,
  disabled,
  forMobile = false,
  children = undefined,
}) => {
  const [isCustomMainIdea, setIsCustomMainIdea] = useState<boolean>(false);
  const { currentTopic, revalidateTopic } = useDocument();
  const [mainIdea, setMainIdea] = useState<Idea>(null);
  const { transport } = useGlobal();
  const { setProcessingOutputType } = useIdeaFlow();

  useEffect(() => {
    if (!!currentTopic.mainIdea && !mainIdea) {
      setMainIdea(currentTopic.mainIdea as Idea);
    }
  }, [currentTopic]);

  const cardBody = (
    <>
      <ToolDescription>
        Choose the main idea and we’ll focus the writing on it.
      </ToolDescription>
      <div data-dd-privacy="mask">
        {currentTopic.mainIdea && (
          <FormCheckWrapper>
            <Form.Check
              disabled={disabled}
              name="main-message-option"
              type="radio"
              id={`main-message-proposed-locked`}
              key={`main-message-proposed-locked`}
              label={(currentTopic.mainIdea as Idea).summary}
              checked={true}
              readOnly={true}
            />
          </FormCheckWrapper>
        )}
      </div>
      {!currentTopic.mainIdea && (
        <>
          <FormCheckWrapper>
            <div data-dd-privacy="mask">
              <FormCheckWrapper>
                {currentTopic.ideas.map((i, index) => {
                  return (
                    <Form.Check
                      disabled={disabled}
                      name="main-message-option"
                      type="radio"
                      id={`main-message-proposed-${index}`}
                      key={`main-message-proposed-${index}`}
                      label={i.summary}
                      onClick={async () => {
                        if (mainIdea !== i.id) {
                          setProcessingOutputType(OutputGenerateReason.PURPOSE);

                          setIsCustomMainIdea(false);
                          setMainIdea(i);

                          await generateOutput(OutputGenerateReason.PURPOSE, {
                            mainIdeaId: i.id,
                          });

                          await revalidateTopic();
                        }
                      }}
                      checked={!isCustomMainIdea && mainIdea?.id === i?.id}
                      onChange={() => {}}
                    />
                  );
                })}
              </FormCheckWrapper>
            </div>

            <Form.Check
              name="main-message-option"
              disabled={disabled}
              type="radio"
              key={`main-message-custom`}
              id={`main-message-custom`}
              label="Something else, let me explain"
              onClick={() => {
                setMainIdea(null);
                setIsCustomMainIdea(true);
              }}
              checked={isCustomMainIdea}
              onChange={() => {}}
            />
          </FormCheckWrapper>
          {isCustomMainIdea && (
            <TypeOrRecordForm
              disabled={disabled}
              preProcess={() => {
                setProcessingOutputType(OutputGenerateReason.PURPOSE);
              }}
              onEntry={async (objectId: string, transcript: string) => {
                const { data: newMainIdea } = await transport.post<Idea>(
                  "/createIdea",
                  {
                    ideaId: objectId,
                    topicId: currentTopic.id,
                    transcript,
                    type: IdeaType.MAIN_MANUAL,
                  }
                );

                setMainIdea(newMainIdea);
                setIsCustomMainIdea(false);

                await generateOutput(OutputGenerateReason.PURPOSE, {
                  mainIdeaId: newMainIdea.id,
                });

                await revalidateTopic();
              }}
              placeHolderText="Type here or record => "
              objectType={RecordingObjectType.MAIN_MESSAGE}
            />
          )}
        </>
      )}
    </>
  );

  if (forMobile) {
    return (
      <>
        <TitleWrapper>
          <RocketIcon style={{ marginRight: 8 }} />
          <p>Choose the main idea</p>
          {children}
        </TitleWrapper>
        {cardBody}
      </>
    );
  }

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={activeKey === eventKey ? "accordion-active" : ""}
    >
      <Accordion.Header>
        <RocketIcon
          style={{ marginRight: 10 }}
          $inactive={activeKey !== eventKey}
        />
        Choose the main idea
      </Accordion.Header>

      <Accordion.Body>{cardBody}</Accordion.Body>
    </Accordion.Item>
  );
};

export default ClarifyMainIdea;

import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useState,
} from "react";
import { useGlobal, useAccount } from "@considr-it/storied-shared";
import { OnboardingFlow } from "@considr-it/storied-entities";
import { OnboardingFlowType } from "@considr-it/storied-enums";

export const useOnboardingProvider = () => {
  const [onboardingState, setOnboardingState] = useState<
    Partial<OnboardingFlow>
  >(JSON.parse(localStorage.getItem(`onboarding_flow_state`)));

  const { transport } = useGlobal();
  const { account } = useAccount();

  const [finalRedirect, setFinalRedirect] = useState<string>("/");

  const completeOnboardingFlow = useCallback(
    (flowName: string) => {
      setOnboardingState({
        flowName,
        completed: true,
      });

      transport.post("completeOnboardingFlow", {
        flowName,
      });
    },
    [transport]
  );

  const fetchOnboardingState = useCallback(async () => {
    const resp = await transport.get<OnboardingFlow>(
      `onboardingFlow/${OnboardingFlowType.ONBOARDING_FLOW_RECORD}`
    );

    if (!!resp?.data) {
      if (resp.data.completed && !onboardingState?.completed) {
        setOnboardingState({
          flowName: resp.data.flowName,
          completed: resp.data.completed,
        });
      } else if (!resp.data.completed && onboardingState?.completed) {
        await completeOnboardingFlow(onboardingState.flowName);
      }
    }
  }, [transport, onboardingState]);

  useEffect(() => {
    fetchOnboardingState();
  }, [account]);

  useEffect(() => {
    if (!!onboardingState) {
      localStorage.setItem(
        "onboarding_flow_state",
        JSON.stringify(onboardingState)
      );
    }
  }, [onboardingState]);

  return {
    onboardingState,
    completeOnboardingFlow,

    finalRedirect,
    setFinalRedirect,
  };
};

export const OnboardingProvider = ({ children }) => {
  const onboardingProvider = useOnboardingProvider();

  return (
    <OnboardingContext.Provider value={onboardingProvider}>
      {children}
    </OnboardingContext.Provider>
  );
};

export type OnboardingProps = ReturnType<typeof useOnboardingProvider>;
export const OnboardingContext = createContext<OnboardingProps>(null);
export const useOnboarding = () => useContext(OnboardingContext);

import { useGlobal } from "@considr-it/storied-shared";
import { useEffect, useState } from "react";
import { useNavigate, useSearchParams } from "react-router-dom";
import { quickTimer } from "@considr-it/storied-utils";

export const SuccessCheckout = () => {
  const [searchParams, setSearchParams] = useSearchParams();
  const [affiliateId, setAffiliateId] = useState(null);
  const navigate = useNavigate();
  const { transport } = useGlobal();

  useEffect(() => {
    const affiliateIdParam = searchParams.get("affiliate_id");

    if (affiliateIdParam) {
      setSearchParams((params) => {
        params.delete("affiliate_id");
        return params;
      });

      transport
        .post("/verifyAffiliate", { affiliateId: affiliateIdParam })
        .then(() => {
          setAffiliateId(affiliateIdParam);
        })
        .catch(() => {
          setAffiliateId(null);
        })
        .finally(async () => {
          await quickTimer(1500);
          navigate("/");
        });
    }
  }, []);

  if (affiliateId) {
    return <></>;
  } else {
    return (
      <img
        src="https://gdaagency10797820.o18.link/p?m=21997&t=i&gb=1"
        width="0px"
        height="0px"
      ></img>
    );
  }
};

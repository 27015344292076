import React from 'react';
import { SVGProps, Svg } from '..';

export const IdeaIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="16"
    height="16"
    viewBox="0 0 56 56"
    fill="none"
    {...props}
  >
    <path d="M35.5 35.444H21.5" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M20.4289 34.7573C16.2849 31.829 13.7999 26.7166 14.6749 21.0886C15.6222 15.0056 20.6925 10.15 26.8082 9.43362C35.2969 8.43729 42.4999 15.0453 42.4999 23.3333C42.4999 28.0583 40.1525 32.228 36.5662 34.762C35.9222 35.217 35.4999 35.9216 35.4999 36.7103V43.1666C35.4999 46.389 32.8889 49 29.6665 49H27.3332C24.1109 49 21.4999 46.389 21.4999 43.1666V36.7196C21.4999 35.924 21.0752 35.2146 20.4289 34.7573Z" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M47.1665 11.6673L49.9432 8.89062" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.83331 35L7.05664 37.7767" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.1668 9.33331L9.39014 6.55664" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M47.1665 35L49.9432 37.7767" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M49.5 23.3327H53.4433" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.55664 23.3327H7.49997" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21.5 42.0007H35.1967" stroke="#FF6D34" strokeWidth="2.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
import React, { useCallback, useEffect, useRef, useState } from "react";
import { useSearchParams, useNavigate, Link } from "react-router-dom";
import axios from "axios";
// Components
import MyCard from "../MyCard";
import { HeaderIconWrapper } from "../HeaderIconWrapper";
import { ThreeDotsMenu } from "../ThreeDotsMenu";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { SharedOutputTextArea } from "../SharedOutputTextArea";
import { TextToSpeech } from "../TextToSpeech";
import { OutputPagination } from "../OutputPagination";
import { ProcessingCircle } from "../ProcessingCircle";
import { PrimaryButton, SecondaryButton } from "../Buttons";
// Helpers
import { trackError, trackEvent, useAccount } from "@considr-it/storied-shared";
// Custom hooks
import { useDocument } from "../../hooks/use-document";
import { useProcessingModal } from "../../hooks/use-processing-modal";
import { useEditor } from "../../hooks/use-editor";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
import { useIdeaFlow, WRITING_TAB_TYPE } from "../../hooks/use-idea-flow";
import { useGlobal } from "@considr-it/storied-shared";
import removeMd from "remove-markdown";
// Types
import {
  ImageType,
  LANGUAGE_CODE_TO_LANGUAGE_MAP,
  OutputGenerateReason,
  TTSType,
} from "@considr-it/storied-enums";
import { SharedOutput } from "@considr-it/storied-entities";
// Styles
import {
  HeaderContainer,
  HeaderIconsWrapper,
  SideUIWrapper,
  SideUIHint,
  SideUIOrElementWrapper,
  SideUIOrElement,
  SideUILine,
  SharableLinkWrapper,
  TabsWrapper,
  Tab,
  Grid,
} from "./output-viewer.style";
// Icons
import GoogleDocsIcon from "./icons/googleDocs.svg";
import CopyToClipboardIcon from "./icons/copyToClipboard.svg";
import LinkedInIcon from "./icons/linkedIn.svg";
import SlackIcon from "./icons/slack.svg";
import TextMessagingIcon from "./icons/textMessaging.svg";
import WhatsAppIcon from "./icons/whatsApp.svg";
import CheckMarkIcon from "./icons/checkMark.svg";
import LinkIcon from "./icons/linkIcon.svg";
import LinkGreyIcon from "./icons/linkGreyIcon.svg";
import { FaAngleRight } from "react-icons/fa";
import { ExportIcon, CopyIcon, CrossIcon, TickIcon } from "../IconsSvg";
import { CloseButtonWrapper } from "../IdeaFlow/idea-flow.style";
import Skeleton from "react-loading-skeleton";
import "react-loading-skeleton/dist/skeleton.css";
import axiosRetry from "axios-retry";
import {
  convertMarkdownToHTML,
  getLvDistance,
  getPercentageOfWordChanged,
  isCharLowerCase,
  normalizeDimensions,
  removeNonAlphanumeric,
} from "@considr-it/storied-utils";
import { CustomWordsAddingComponent } from "../Editor/CustomWordsAddingComponent";
import * as Phonetics from "phonetics";
import { replaceNumbersWithWords } from "../../utils/utils";
import { ShareButton } from "./share-button";

axiosRetry(axios, { retries: 3 });

// Constants
const TLDR_PLACEHOLDER = `I've recorded some thoughts. Click the link to take a look`;
interface OutputViewerProps {
  text: string;
  disabled: boolean;
  deleteOutput: () => void;
  deleteTopic: () => void;
  restoreVersion: () => void;
  onNext: () => void;
  onPrev: () => void;
  generateOutput: (reason: OutputGenerateReason) => void;
  totalPageCount: number;
  currentPageNumber: number;
  width?: string;
}

export const OutputViewer: React.FC<OutputViewerProps> = ({
  text,
  disabled,
  onNext,
  onPrev,
  deleteOutput,
  deleteTopic,
  restoreVersion,
  generateOutput,
  totalPageCount,
  currentPageNumber,
}) => {
  const { currentTopic } = useDocument();
  const navigate = useNavigate();

  const [customWord, setCustomWord] = useState<{
    oldWord: string;
    newWord: string;
  }>(null);
  const { accountMetaData, revalidateAccountMetaData, account } = useAccount();

  const {
    onPresent: onPresentExportingToGoogleDocs,
    onDismiss: onDismissExportingToGoogleDocs,
  } = useProcessingModal({
    text: "Exporting to Google Docs",
  });

  const {
    onPresent: onPresentExportingToLinkedIn,
    onDismiss: onDismissExportingToLinkedIn,
  } = useProcessingModal({
    text: "Exporting to LinkedIn",
  });

  const {
    onPresent: onPresentCreatingStoriedLink,
    onDismiss: onDismissCreatingStoriedLink,
  } = useProcessingModal({
    text: "Creating Vnote link",
  });

  const [searchParams, setSearchParams] = useSearchParams();
  const {
    triggerGoogleOauth,
    triggerLinkedinOauth,
    isMobile,
    isSmallMobile,
    transport,
  } = useGlobal();

  const {
    currentOutput,
    updatingOutput,
    deletingOutput,
    writingTab,
    setWritingTab,
    setUpdatingOutput,
  } = useIdeaFlow();
  const [copied, setCopied] = useState(false);
  const { coverImage, revalidateCoverImage, isLoadingCoverImage } =
    useIdeaFlow();
  const imageRef = useRef<HTMLDivElement | null>(null);

  const { onPresentConfirmModal, onDismiss } = useConfirmModal();

  //For the English language there are several algorithms to check if 2 words sound similar
  const isPhoneticMatch = (text1: string, text2: string) => {
    if (LANGUAGE_CODE_TO_LANGUAGE_MAP[account.language] !== "English") {
      return false;
    }

    //We need to replace the nubmers with their spelled version
    text1 = replaceNumbersWithWords(text1);
    text2 = replaceNumbersWithWords(text2);

    if (
      Phonetics.soundexMatch(text1, text2) ||
      Phonetics.metaphoneMatch(text1, text2) ||
      Phonetics.doubleMetaphoneMatch(text1, text2)
    ) {
      return true;
    }

    const s1 = Phonetics.soundex(text1);
    const s2 = Phonetics.soundex(text2);

    const m1 = Phonetics.metaphone(text1);
    const m2 = Phonetics.metaphone(text2);

    const dm1 = Phonetics.doubleMetaphone(text1);
    const dm2 = Phonetics.doubleMetaphone(text2);

    //We also want to accomodate for cases where the phonetics representations ALMOST match, but to be sure we need all 3 algorithms to ALMOST match, not just one
    if (
      getLvDistance(s1, s2) === 1 &&
      getLvDistance(m1, m2) === 1 &&
      (getLvDistance(dm1[0], dm2[0]) === 1 ||
        getLvDistance(dm1[0], dm2[1]) === 1 ||
        getLvDistance(dm1[1], dm2[0]) === 1 ||
        getLvDistance(dm1[1], dm2[1]) === 1)
    ) {
      return true;
    }

    return false;
  };

  const detectPossibleCustomWord = (s1: string, s2: string) => {
    //Here we will store the different word in the text
    let diff = null as { oldWord: string; newWord: string };

    //Strip down markdown since we don't need that in detecting replaced words
    const n1 = removeMd(s1)
      //removeMd doesn't remove "*" character
      .replaceAll("*", "")
      //removing "'s" from words so we can correctly detect possesive forms of words like "Mircha's"
      .replaceAll("'s", "")
      //we're going to check each line separately
      .split("\n\n") as string[];

    //We do the same thing for the 2nd piece of text
    const n2 = removeMd(s2)
      .replaceAll("*", "")
      .replaceAll("'s", "")
      .split("\n\n") as string[];

    //We need to only do the check if both texts have the same number of lines otherwise it means that the user removed or deleted a row so he definitely didn't want to fix a misspelled word
    if (n1.length > 0 && n1.length === n2.length) {
      //We go line by line
      for (let t = 0; t < n1.length; t++) {
        //We split each line by "comas, perios and space" so we'll get 2 list of words
        const a1 = n1[t]
          .split(/[\s,.]+/)
          .map((w) => w.trim())
          .map((w) => removeNonAlphanumeric(w))
          .filter((w) => w.length > 0);
        const a2 = n2[t]
          .split(/[\s,.]+/)
          .map((w) => w.trim())
          .map((w) => removeNonAlphanumeric(w))
          .filter((w) => w.length > 0);

        let i = 0;

        //We compare the texts word by word to see where they start to differ (meaning that's where the user did the edit)
        while (
          i < a1.length &&
          i < a2.length &&
          a1[i].toLowerCase() === a2[i].toLowerCase()
        ) {
          i++;
        }

        //This means there was no difference so we continue to the next line
        if (i >= a1.length || i >= a2.length) {
          continue;
        }

        let oldWord = a1[i];
        let newWord = a2[i];

        if (!oldWord || !newWord) {
          continue;
        }

        //If it was a word that was edited it's likely not a mispelled word
        if (!!Number(oldWord) || !!Number(newWord)) {
          continue;
        }

        /* We only look for custom words that originate from the original transcript
         We preprocess the transcript by:
         1. Making it lowercase so we identify the scenario where the custom word was capitalized due to being the first word in a sentence for example
         2. Remove all "'s" for the same reason as above.
         3. Split it into words so we cover cases where the transcript has a period or a comma after the word we're looking for.
        */
        if (
          !currentTopic.transcript
            .toLowerCase()
            .replaceAll("'s", "")
            .split(/[\s,.]+/)
            .includes(oldWord.toLowerCase())
        ) {
          continue;
        }

        /* We might get cases where the speech-to-text interprets wrongly a word that sounds similar to a collection of 2 or 3 words, for example "Mir Cha"
           What the algorithm is doing here is grabbing every word until' the old text and new text resume matching.
        */

        // let j = ++i;

        // if (i < a1.length && j < a2.length) {
        //   if (a1.length < a2.length) {
        //     while (
        //       j < a2.length &&
        //       a1[i].toLowerCase() !== a2[j].toLowerCase()
        //     ) {
        //       const possibleNewWord = newWord + " " + a2[j];

        //       if (
        //         getPercentageOfWordChanged(oldWord, possibleNewWord) > 50 &&
        //         !isPhoneticMatch(oldWord, possibleNewWord)
        //       ) {
        //         break;
        //       }

        //       newWord = possibleNewWord;
        //       j++;
        //     }
        //   } else if (a1.length > a2.length) {
        //     while (
        //       i < a1.length &&
        //       a1[i].toLowerCase() !== a2[j].toLowerCase()
        //     ) {
        //       const possibleOldWord = oldWord + " " + a1[i];

        //       if (
        //         getPercentageOfWordChanged(possibleOldWord, newWord) > 50 &&
        //         !isPhoneticMatch(possibleOldWord, newWord)
        //       ) {
        //         break;
        //       }

        //       oldWord = possibleOldWord;
        //       i++;
        //     }
        //   }
        // }

        if (isCharLowerCase(oldWord[0]) || isCharLowerCase(newWord[0])) {
          continue;
        }

        if (
          getPercentageOfWordChanged(oldWord, newWord) > 50 &&
          !isPhoneticMatch(oldWord, newWord)
        ) {
          continue;
        }

        //Ignore words that have 2 or less characters
        if (newWord.length <= 2) {
          continue;
        }

        diff = { oldWord, newWord };

        trackEvent("valid_custom_word", {
          oldWord,
          newWord,
          lvDistance: getLvDistance(oldWord, newWord),
          percentageChanged: getPercentageOfWordChanged(oldWord, newWord),
          isPhoneticMatch: isPhoneticMatch(oldWord, newWord),
          topicId: currentTopic.id,
          outputId: currentOutput.id,
        });

        if (diff) {
          break;
        }
      }
    }

    return diff;
  };

  const updateOutputText = async (oldText: string, newText: string) => {
    setUpdatingOutput(true);
    await transport.patch(`/output/${currentOutput.id}`, {
      payload: {
        text: newText,
      },
    });
    setUpdatingOutput(false);

    if (oldText?.length > 0 && newText?.length > 0) {
      const diff = detectPossibleCustomWord(oldText, newText);

      if (
        !!diff &&
        (!accountMetaData.customWords ||
          !accountMetaData.customWords.includes(diff.newWord)) &&
        (!accountMetaData.rejectedCustomWords ||
          !accountMetaData.rejectedCustomWords.includes(diff.newWord))
      ) {
        setCustomWord(diff);

        setTimeout(() => {
          trackEvent("custom_word_timeout", {
            customWord,
            outputId: currentOutput.id,
            topicId: currentTopic.id,
          });

          setCustomWord(null);
        }, 5000);
      } else {
        setCustomWord(null);
      }
    }
  };

  const CopyTextButton = () => {
    const [textCopied, setTextCopied] = useState<boolean>(false);
    const onCopyToClipboard = useCallback(async () => {
      trackEvent("copied_to_clipboard", {
        topicId: currentTopic.id,
        outputId: currentOutput.id,
      });

      if (!!markdownContent?.current) {
        const htmlBlobArray = [];

        if (!!imageRef?.current) {
          htmlBlobArray.push(imageRef.current.innerHTML);
        }

        htmlBlobArray.push(convertMarkdownToHTML(currentText));

        const data = [
          new ClipboardItem({
            ["text/html"]: new Blob(htmlBlobArray, {
              type: "text/html",
            }),

            ["text/plain"]: new Blob([currentText], {
              type: "text/plain",
            }),
          }),
        ];

        try {
          setTextCopied(true);
          await navigator.clipboard.write(data);
          setTimeout(() => setTextCopied(false), 1500);
        } catch (err) {
          trackError("clipboard_output_error", err);
        }
      }
    }, [currentText, currentTopic, currentOutput]);

    return (
      <SecondaryButton
        onClick={async () => {
          if (!currentTopic.exported) {
            transport.patch(`/topic/${currentTopic.id}`, {
              payload: { exported: true },
            });
          }
          await onCopyToClipboard();
        }}
        noIconFill
        fullWidth
      >
        <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
          {textCopied ? <TickIcon width={16} height={16} /> : <CopyIcon />}
          <div>{textCopied ? "Copied" : "Copy to clipboard"}</div>
        </div>
      </SecondaryButton>
    );
  };

  useEffect(() => {
    if (coverImage?.generatingImage) {
      setTimeout(revalidateCoverImage, 5000);
    }
  }, [coverImage]);

  const {
    Editor,
    getTextHtml,
    currentText,
    markdownContent,
    initializeEditor,
  } = useEditor({
    initialText: text,
    updateTextRequest: updateOutputText,
    isUpdating: updatingOutput,
    editModeCondition:
      !disabled &&
      currentPageNumber === totalPageCount &&
      (writingTab === WRITING_TAB_TYPE.AI_WRITING_TAB ||
        currentTopic?.outputs?.length === 1),
    highlightTextOnSelect: false,
  });

  const getSharedOutputClipboardText = async () => {
    const { data } = await transport.post<SharedOutput>("/createSharedOutput", {
      outputId: currentOutput.id,
    });

    return new Blob(
      [
        `${currentOutput.tldr || TLDR_PLACEHOLDER}\n${
          window.location.origin
        }/s/${data.shortId}`,
      ],
      { type: "text/plain" }
    );
  };

  const onCreateVnoteLink = async (source: string) => {
    onPresentCreatingStoriedLink();

    let shortId = (currentOutput.sharedOutput as SharedOutput)?.shortId;

    if (!shortId) {
      const { data } = await transport.post<SharedOutput>(
        "/createSharedOutput",
        {
          outputId: currentOutput.id,
        }
      );

      shortId = data.shortId;
    }

    onDismissCreatingStoriedLink();
    navigate(`/s/${shortId}?source=${source}`);
  };

  const onShare = () => {
    // setCurrentHighlighted(null);

    const buttons = [
      {
        icon: copied ? CheckMarkIcon : CopyToClipboardIcon,
        label: "Copy To Clipboard",
        onClick: async () => {
          trackEvent("copied_to_clipboard", {
            topicId: currentTopic.id,
            outputId: currentOutput.id,
          });

          if (!!markdownContent?.current) {
            const htmlBlobArray = [];

            if (!!imageRef?.current) {
              htmlBlobArray.push(imageRef.current.innerHTML);
            }

            htmlBlobArray.push(convertMarkdownToHTML(currentText));

            const data = [
              new ClipboardItem({
                ["text/html"]: new Blob(htmlBlobArray, {
                  type: "text/html",
                }),

                ["text/plain"]: new Blob([currentText], {
                  type: "text/plain",
                }),
              }),
            ];

            await navigator.clipboard.write(data);

            onDismiss();
          }
        },
      },
      {
        icon: LinkedInIcon,
        label: "LinkedIn",
        onClick: async () => {
          if (!currentTopic.exported) {
            transport.patch(`/topic/${currentTopic.id}`, {
              payload: { exported: true },
            });
          }

          await exportToLinkedin();
        },
      },
      {
        icon: GoogleDocsIcon,
        label: "Google Docs",
        onClick: async () => {
          if (!currentTopic.exported) {
            transport.patch(`/topic/${currentTopic.id}`, {
              payload: { exported: true },
            });
          }

          await exportToGoogleDocs();
        },
      },
      {
        icon: TextMessagingIcon,
        label: "Text messaging",
        onClick: () => {
          onCreateVnoteLink("text");
        },
      },
      {
        icon: SlackIcon,
        label: "Slack",
        onClick: async () => {
          onCreateVnoteLink("slack");
        },
      },
      {
        icon: WhatsAppIcon,
        label: "WhatsApp",
        onClick: () => {
          onCreateVnoteLink("whatsapp");
        },
      },
    ];

    onPresentConfirmModal({
      confirmationMessage: "Share your output to:",
      withCloseBtn: true,
      // bodyContent: <SharedOutputTextArea output={currentOutput} />,
      actions: [
        <Grid>
          {buttons.map((button, index) => (
            <ShareButton
              key={index}
              icon={button.icon}
              label={button.label}
              onClick={button.onClick}
            />
          ))}
        </Grid>,
        <SideUIWrapper>
          <div>
            <div>
              <SideUIOrElementWrapper>
                <SideUILine />
                <SideUIOrElement>or</SideUIOrElement>
                <SideUILine />
              </SideUIOrElementWrapper>
              <br />
              <SecondaryButton
                fullWidth
                onClick={async () => {
                  await onCreateVnoteLink("general");
                }}
              >
                <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
                  <img width={20} height={20} src={LinkIcon} />
                  <div>Get a shareable link!</div>
                </div>
              </SecondaryButton>
              <br />
              <SideUIHint>
                Anyone with this link can view this version
              </SideUIHint>
            </div>
          </div>
          <div style={{ height: 0, marginBottom: -16 }} />
        </SideUIWrapper>,
        // <CopyTextButton />,
        // <SecondaryButton
        //   onClick={async () => {
        //     if (!currentTopic.exported) {
        //       transport.patch(`/topic/${currentTopic.id}`, {
        //         payload: { exported: true },
        //       });
        //     }

        //     await exportToGoogleDocs();
        //   }}
        // >
        //   <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        //     <img width={16} height={16} src={GIcon} />
        //     <div>Google Docs</div>
        //   </div>
        // </SecondaryButton>,
        // <SecondaryButton
        //   onClick={async () => {
        //     if (!currentTopic.exported) {
        //       transport.patch(`/topic/${currentTopic.id}`, {
        //         payload: { exported: true },
        //       });
        //     }

        //     await exportToLinkedin();
        //     await revalidateOutput();
        //   }}
        // >
        //   <div style={{ display: "flex", gap: 10, alignItems: "center" }}>
        //     <img width={20} height={20} src={LinkedInIcon} />
        //     <div>LinkedIn</div>
        //   </div>
        // </SecondaryButton>,
      ],
    });
  };

  const exportToLinkedin = useCallback(async () => {
    onPresentExportingToLinkedIn({ text: "Exporting to LinkedIn" });

    let linkedinOauth = null;

    try {
      linkedinOauth = JSON.parse(localStorage.getItem("linkedin_oauth"));
    } catch (_) {
      triggerLinkedinOauth(
        `exportlinkedin_${currentTopic.id}_${currentOutput.id}`
      );
      return;
    }

    const expiresIn = linkedinOauth?.expires_in;
    const issuedAt = linkedinOauth?.issued_at;
    const token = linkedinOauth?.access_token;
    const id = linkedinOauth?.id;

    if (
      !token ||
      !expiresIn ||
      !issuedAt ||
      !id ||
      Date.now() - issuedAt > 1000 * 60 * 59
    ) {
      triggerLinkedinOauth(
        `exportlinkedin_${currentTopic.id}_${currentOutput.id}`
      );
      return;
    }

    try {
      const resp = await transport.post("/shareLinkedinPost", {
        token,
        id,
        outputId: currentOutput.id,
      });

      onDismissExportingToLinkedIn();

      onPresentConfirmModal({
        confirmationMessage: "Successfully posted to LinkedIn",
        actions: [
          <PrimaryButton
            onClick={() => {
              trackEvent("opened_linkedin", {
                topicId: currentTopic.id,
                outputId: currentOutput.id,
              });

              window.open(
                `https://www.linkedin.com/feed/update/${resp.data.postId}/`,
                "_blank"
              );

              onDismiss();
            }}
          >
            Open in LinkedIn
          </PrimaryButton>,
          <SecondaryButton onClick={onDismiss}>Close</SecondaryButton>,
        ],
      });
    } catch (_) {
      onDismissExportingToLinkedIn();

      onPresentConfirmModal({
        confirmationMessage:
          "Something went wrong while trying to post to LinkedIn",
        actions: [
          <PrimaryButton
            onClick={() => {
              onDismiss();
            }}
          >
            Close
          </PrimaryButton>,
        ],
      });
    }

    return;
  }, [currentOutput]);

  const exportToGoogleDocs = useCallback(async () => {
    onPresentExportingToGoogleDocs();

    let googleOAuth = null;

    try {
      googleOAuth = JSON.parse(localStorage.getItem("google_oauth"));
    } catch (_) {
      triggerGoogleOauth(
        `exportgoogledocs_${currentTopic.id}_${currentOutput.id}`
      );
      return;
    }

    const expiresIn = googleOAuth?.expires_in;
    const issuedAt = googleOAuth?.issued_at;
    const token = googleOAuth?.access_token;

    if (
      !token ||
      !expiresIn ||
      !issuedAt ||
      Date.now() - issuedAt > 1000 * 60 * 59
    ) {
      triggerGoogleOauth(
        `exportgoogledocs_${currentTopic.id}_${currentOutput.id}`
      );
      return;
    }

    const htmlBlobArray = [];
    if (coverImage) {
      const dimensions = normalizeDimensions(
        coverImage.width || 768,
        coverImage.height || 256,
        640,
        256
      );

      htmlBlobArray.push(
        `<div style="text-align:center"><img src=${coverImage.imageFilePublicUrl} style="width:${dimensions.width}px;height:${dimensions.height}px"/></div><br/>`
      );
    }

    //Adding a newline after each paragraph
    const html = getTextHtml()
      .replaceAll("</p>", "</p><br/>")
      .replaceAll("</ul>", "</ul><br/>")
      .replaceAll("</ol>", "</ol><br/>");

    htmlBlobArray.push(html);

    let sharedOutput = currentOutput.sharedOutput as SharedOutput;

    if (!sharedOutput) {
      const { data } = await transport.post<SharedOutput>(
        "/createSharedOutput",
        {
          outputId: currentOutput.id,
        }
      );

      sharedOutput = data;
    }

    htmlBlobArray.push(
      `<a href=${window.location.origin}/s/${sharedOutput.shortId}>Crafted with Vnote</a>`
    );

    const file = new Blob(htmlBlobArray, {
      type: "text/html",
    });
    const metadata = {
      name: currentOutput.title,
      mimeType: "application/vnd.google-apps.document",
    };
    const form = new FormData();
    form.append(
      "metadata",
      new Blob([JSON.stringify(metadata)], {
        type: "application/json",
      })
    );
    form.append("file", file);

    let googleDocumentId = currentOutput.googleDocumentId;

    try {
      const requestConfig = {
        headers: {
          Authorization: `Bearer ${token}`,
        },
      };

      const { data } = !googleDocumentId
        ? await axios.post(
            "https://www.googleapis.com/upload/drive/v3/files?uploadType=multipart",
            form,
            requestConfig
          )
        : await axios.put(
            `https://www.googleapis.com/upload/drive/v2/files/${googleDocumentId}?uploadType=multipart`,
            form,
            requestConfig
          );

      googleDocumentId = data.id;

      transport.patch(`/output/${currentOutput.id}`, {
        payload: { googleDocumentId },
      });

      onDismissExportingToGoogleDocs();

      trackEvent("exported_google_docs", {
        topicId: currentTopic.id,
        outputId: currentOutput.id,
      });

      onPresentConfirmModal({
        confirmationMessage: "Document successfully exported to Google Docs",
        actions: [
          <PrimaryButton
            onClick={() => {
              trackEvent("opened_google_docs", {
                topicId: currentTopic.id,
                outputId: currentOutput.id,
              });

              window.open(
                `https://docs.google.com/document/d/${googleDocumentId}/edit`,
                "_blank"
              );
              onDismiss();
            }}
          >
            Open in Google Docs
          </PrimaryButton>,
          <SecondaryButton onClick={onDismiss}>Close</SecondaryButton>,
        ],
      });
    } catch (err) {
      onDismissExportingToGoogleDocs();

      trackError("error_exporting_google_docs", err, {
        topicId: currentTopic.id,
        outpudId: currentOutput.id,
        googleOAuth,
      });
    }
  }, [currentTopic, currentOutput, currentText, coverImage]);

  const removeGeneratedPicture = async () => {
    await transport.delete(`/image/${ImageType.TopicCover}/${currentTopic.id}`);
    await revalidateCoverImage();
  };

  useEffect(() => {
    revalidateCoverImage();

    const showExportModal = searchParams.get("show_export_modal");

    if (showExportModal && currentOutput) {
      onShare();

      setSearchParams((params) => {
        params.delete("show_export_modal");
        return params;
      });
    }
  }, [currentOutput]);

  useEffect(() => {
    const exportType = searchParams.get("export");

    if (!!exportType && currentOutput && currentText && !isLoadingCoverImage) {
      switch (exportType) {
        case "googledocs":
          exportToGoogleDocs();
          break;
        case "linkedin":
          exportToLinkedin();
          break;
        default:
          break;
      }

      setSearchParams((params) => {
        params.delete("export");
        return params;
      });
    }
  }, [currentOutput, currentText, currentTopic, isLoadingCoverImage]);

  useEffect(() => {
    initializeEditor();
  }, [currentOutput]);

  useEffect(() => {
    if (totalPageCount === 0) {
      setWritingTab(WRITING_TAB_TYPE.TRANSCRIPT_TAB);
    }
  }, [totalPageCount]);

  return (
    <MyCard
      className="outputViewerCard"
      style={{
        position: "relative",
        marginTop: isMobile ? (isSmallMobile ? 52 : 68) : 50,
        borderTopLeftRadius: 0,
        borderTopRightRadius: isMobile ? 0 : "",
      }}
    >
      <TabsWrapper>
        <Tab
          $isActive={writingTab === WRITING_TAB_TYPE.TRANSCRIPT_TAB}
          $isDisabled={disabled}
          onClick={() => {
            if (!disabled) {
              setWritingTab(WRITING_TAB_TYPE.TRANSCRIPT_TAB);
            }
          }}
        >
          Transcript
        </Tab>
        {totalPageCount > 0 && (
          <Tab
            $isDisabled={false}
            $isActive={writingTab === WRITING_TAB_TYPE.AI_WRITING_TAB}
            onClick={() => setWritingTab(WRITING_TAB_TYPE.AI_WRITING_TAB)}
          >
            AI writing
          </Tab>
        )}
      </TabsWrapper>
      {customWord && (
        <CustomWordsAddingComponent
          customWord={customWord}
          handleCorrectedWord={async (accepted: boolean) => {
            setCustomWord(null);

            await transport.post("/respondCustomWord", {
              customWord: customWord.newWord,
              accepted,
            });

            revalidateAccountMetaData();
          }}
        />
      )}
      <ReturnJSX
        if={writingTab === WRITING_TAB_TYPE.AI_WRITING_TAB}
        else={
          <div>
            {Editor}
            {currentTopic?.outputs?.length === 1 &&
              writingTab === WRITING_TAB_TYPE.TRANSCRIPT_TAB && (
                <div>
                  <br />
                  <PrimaryButton
                    tooltip="Generate"
                    disabled={disabled}
                    onClick={() => {
                      generateOutput(OutputGenerateReason.DEFAULT);
                    }}
                  >
                    <FaAngleRight size="1.3rem" />
                    Generate
                  </PrimaryButton>
                </div>
              )}
          </div>
        }
      >
        <HeaderContainer>
          <div className="output-pagination">
            <OutputPagination
              totalPageCount={totalPageCount}
              currentPageNumber={currentPageNumber}
              disabled={disabled}
              onPrev={onPrev}
              onNext={onNext}
            />
          </div>
          <HeaderIconsWrapper className="output-viewer-tools">
            <TextToSpeech
              key={currentOutput?.id}
              objectId={currentOutput?.id}
              objectType={TTSType.Output}
              disabled={disabled}
              sizeIncreased
            />
            <HeaderIconWrapper
              disabled={disabled}
              onClick={onShare}
              Icon={
                <ExportIcon
                  {...(isSmallMobile ? { width: 20, height: 20 } : {})}
                  className="output-share"
                  $disabled={disabled}
                />
              }
            />
            <ReturnJSX if={currentPageNumber > 0}>
              <ThreeDotsMenu
                disabled={disabled}
                onlyRemove={currentPageNumber === totalPageCount}
                removeVersionHandler={deleteOutput}
                removeAllVersionsHandler={deleteTopic}
                restoreVersionHandler={restoreVersion}
              />
            </ReturnJSX>
          </HeaderIconsWrapper>
        </HeaderContainer>
        {!text && !deletingOutput && !updatingOutput && (
          <ProcessingCircle subtext={"Generating Output"} />
        )}
        {deletingOutput && <ProcessingCircle subtext={"Deleting Output"} />}
        <ReturnJSX if={!!text && !deletingOutput}>
          <div>
            {currentOutput?.sharedOutput && (
              <SharableLinkWrapper>
                <img width={16} height={16} src={LinkGreyIcon} />
                <div>
                  A version of this was shared via sharable link.
                  <Link
                    to={`${window.location.origin}/s/${
                      (currentOutput.sharedOutput as SharedOutput).shortId
                    }`}
                  >
                    See that version here
                  </Link>
                </div>
              </SharableLinkWrapper>
            )}
            {coverImage && (
              <div
                className="generateImageRef"
                style={{
                  marginBottom: isMobile ? "24px" : "32px",
                  textAlign: "center",
                }}
                ref={imageRef}
              >
                {coverImage.generatingImage ? (
                  <Skeleton height={isMobile ? 160 : 220} />
                ) : (
                  <div
                    style={{
                      position: "relative",
                      display: "inline-block",
                    }}
                  >
                    <img
                      style={{
                        maxHeight: "256px",
                        maxWidth: "100%",
                      }}
                      src={coverImage.imageFilePublicUrl}
                    />
                    <CloseButtonWrapper onClick={removeGeneratedPicture}>
                      <CrossIcon width={16} height={16} $color="#000" />
                    </CloseButtonWrapper>
                  </div>
                )}
              </div>
            )}
            {Editor}
          </div>
        </ReturnJSX>
      </ReturnJSX>
    </MyCard>
  );
};

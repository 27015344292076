import { quickTimer } from "@considr-it/storied-utils";
import { Lines } from "../components/Oscillator/animations/Lines";
import lamejs from "lamejs";
import { trackError } from "@considr-it/storied-shared";
import { LiveClient } from "@deepgram/sdk";

interface Navigator {
  wakeLock?: {
    request: (type: "screen") => Promise<WakeLockSentinel>;
  };
}

interface WakeLockSentinel {
  release: () => Promise<void>;
}

export class MicRecorder {
  mediaStream: MediaStream = null;
  mediaRecorder: MediaRecorder = null;
  mp3Encoder: lamejs.Encoder = null;

  setSoundDetected = null;
  analyserNode: AnalyserNode = null;
  oscillatorCanvas = null;
  oscillatorData = null;

  isPaused = false;

  socket: LiveClient = null;

  audioContext: AudioContext;
  sourceAudioNode: AudioNode;

  partialBuffer: Blob[] = [];
  finalBuffer: Blob[] = [];

  wakeLock: WakeLockSentinel | null = null;

  acquireWakeLock = async () => {
    try {
      this.wakeLock = await (navigator as Navigator).wakeLock?.request(
        "screen"
      );
    } catch (err) {
      trackError("Wake Lock Acquire Error", err);
    }
  };

  releaseWakeLock = async () => {
    try {
      await this.wakeLock?.release();
    } catch (err) {
      trackError("Wake Lock Release Error", err);
    }
  };

  startOscillator = () => {
    const oscillatorData = new Uint8Array(this.analyserNode.frequencyBinCount);
    const pcmData = new Float32Array(this.analyserNode.fftSize);

    const animation = new Lines();

    this.setSoundDetected(true);
    const soundDetectedTimeout = setTimeout(
      () => this.setSoundDetected(false),
      5000
    );

    const onFrame = () => {
      if (!this.analyserNode) {
        return;
      }

      this.analyserNode.getByteFrequencyData(oscillatorData);
      this.analyserNode.getFloatTimeDomainData(pcmData);

      let sum = 0;
      for (const amplitude of oscillatorData) {
        sum += amplitude * amplitude;
      }

      const volume = Math.floor(Math.sqrt(sum / oscillatorData.length) - 20);

      if (volume > 0) {
        clearTimeout(soundDetectedTimeout);
        this.setSoundDetected(true);
      }

      if (this.oscillatorCanvas) {
        const { height, width } = this.oscillatorCanvas;
        const context = this.oscillatorCanvas.getContext("2d");

        context.clearRect(0, 0, width, height);
        animation.draw(oscillatorData, context);
      }

      window.requestAnimationFrame(onFrame);
    };

    window.requestAnimationFrame(onFrame);
  };

  initializeMediaStream = async () => {
    if (!navigator.mediaDevices?.getUserMedia) {
      alert(
        "Recording only works with the https protocol. Please check the URL and try again."
      );

      throw new Error("Recording only works with the https protocol");
    }

    this.mediaStream = await navigator.mediaDevices.getUserMedia({
      audio: {
        channelCount: 1,
        echoCancellation: true,
        sampleRate: 16000,
      },
    });

    this.audioContext = new AudioContext();
    this.mp3Encoder = new lamejs.Mp3Encoder(1, 16000, 128);
    this.partialBuffer = [];

    this.analyserNode = this.audioContext.createAnalyser();
    this.analyserNode.smoothingTimeConstant = 0.85;
    this.analyserNode.fftSize = 1024;

    this.startOscillator();

    this.sourceAudioNode = this.audioContext.createMediaStreamSource(
      this.mediaStream
    );

    this.sourceAudioNode.connect(this.analyserNode);

    this.mediaRecorder = new MediaRecorder(this.mediaStream, {
      audioBitsPerSecond: 128000,
    });

    this.mediaRecorder.addEventListener("dataavailable", (event) => {
      if (event.data.size > 0) {
        this.partialBuffer.push(event.data);
        this.finalBuffer.push(event.data);

        if (this.socket && this.socket.getReadyState() === 1) {
          this.socket.send(event.data);
        }
      }
    });

    if (this.socket) {
      this.mediaRecorder.start(100);
    } else {
      this.mediaRecorder.start(500);
    }

    const audioTrack = this.mediaStream.getAudioTracks()[0];

    return {
      label: audioTrack.label,
      deviceId:
        audioTrack.getCapabilities && audioTrack.getCapabilities().deviceId,
    };
  };

  start = async (
    socket: LiveClient,
    oscillatorCanvas = null,
    setSoundDetected = null
  ) => {
    this.setSoundDetected = setSoundDetected;
    this.oscillatorCanvas = oscillatorCanvas;
    this.socket = socket;

    this.finalBuffer = [];
    await this.acquireWakeLock();
    return await this.initializeMediaStream();
  };

  resume = async (socket: LiveClient) => {
    this.socket = socket;
    this.isPaused = false;

    return await this.initializeMediaStream();
  };

  pause = async () => {
    if (this.isPaused == false) {
      this.isPaused = true;

      await quickTimer(1500);
      await this.stop();
    }
  };

  stop = async () => {
    this.isPaused = false;

    this.mediaStream?.getAudioTracks()?.forEach((track) => track.stop());
    this.mediaStream = null;

    this.mediaRecorder?.stop();
    this.mediaRecorder = null;

    this.analyserNode?.disconnect();
    this.analyserNode?.disconnect();
    this.sourceAudioNode?.disconnect();
    await this.releaseWakeLock();
  };

  downSample = (
    buffer: Float32Array,
    inputSampleRate: number,
    outputSampleRate: number
  ) => {
    if (outputSampleRate === inputSampleRate) {
      return new Float32Array(buffer);
    }

    const sampleRateRatio = inputSampleRate / outputSampleRate;
    const newLength = Math.round(buffer.length / sampleRateRatio);
    const result = new Float32Array(newLength);
    let offsetResult = 0;
    let offsetBuffer = 0;

    while (offsetResult < result.length) {
      const nextOffsetBuffer = Math.round((offsetResult + 1) * sampleRateRatio);

      let accum = 0,
        count = 0;

      for (
        let i = offsetBuffer;
        i < nextOffsetBuffer && i < buffer.length;
        i++
      ) {
        accum += buffer[i];
        count++;
      }

      result[offsetResult] = accum / count;
      offsetResult++;
      offsetBuffer = nextOffsetBuffer;
    }

    return result;
  };

  convertFloat32To16BitPCM = (input: Float32Array) => {
    const output = new Int16Array(input.length);

    for (let i = 0; i < input.length; i++) {
      const s = Math.max(-1, Math.min(1, input[i]));
      // output[i] = s < 0 ? s * 32768 : s * 32767
      // output[i] = s * 32400
      output[i] = s < 0 ? s * 0x8000 : s * 0x7fff;
    }

    return output;
  };

  getFile = async (partial: boolean) => {
    const buffer = partial ? this.partialBuffer : this.finalBuffer;

    const blob = new Blob(buffer);

    const randomFileName = Date.now();
    const file = new File([blob], randomFileName.toString());

    // const arrayBuffer = await blob.arrayBuffer();
    // try {
    //   const audioBuff = await this.audioContext.decodeAudioData(arrayBuffer);

    //   const downSampled = this.downSample(
    //     audioBuff.getChannelData(0),
    //     this.audioContext.sampleRate,
    //     16000
    //   );

    //   const pcm = this.convertFloat32To16BitPCM(downSampled);
    //   const mp3 = this.mp3Encoder.encodeBuffer(pcm);

    //   file = new File([mp3], randomFileName.toString(), {
    //     type: "audio/mpeg",
    //   });
    // } catch (err) {
    //   trackError("decoding_failed", err);
    //   file = new File([blob], randomFileName.toString());
    // }

    return file;
  };
}

import React from 'react'
import { SVGProps, Svg } from '..'

export const UKFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_108_241)">
    <path d="M1 5H23V19.6668H1V5Z" fill="white"/>
    <path d="M13.375 4.99976H10.625V10.9581H1V13.7081H10.625V19.6664H13.375V13.7081H23V10.9581H13.375V4.99976Z" fill="#D80027"/>
    <path d="M17.9198 14.8842L22.9993 17.7062V14.8842H17.9198ZM14.3906 14.8842L22.9993 19.6667V18.3144L16.825 14.8842H14.3906ZM20.7063 19.6667L14.3906 16.1577V19.6667H20.7063Z" fill="#0052B4"/>
    <path d="M14.3896 14.8839L22.9984 19.6665V18.3141L16.824 14.8839H14.3896Z" fill="white"/>
    <path d="M14.3896 14.8839L22.9984 19.6665V18.3141L16.824 14.8839H14.3896Z" fill="#D80027"/>
    <path d="M4.88184 14.8839L1 17.0405V14.8839H4.88184ZM9.6087 15.4921V19.6665H2.09532L9.6087 15.4921Z" fill="#0052B4"/>
    <path d="M7.17431 14.8839L1 18.3141V19.6665L9.6087 14.8839H7.17431Z" fill="#D80027"/>
    <path d="M6.07955 9.78247L1 6.96046V9.78247H6.07955ZM9.6087 9.78247L1 4.99988V6.35228L7.17431 9.78247H9.6087ZM3.29307 4.99988L9.6087 8.50888V4.99988H3.29307Z" fill="#0052B4"/>
    <path d="M9.6087 9.78235L1 4.99976V6.35215L7.17431 9.78235H9.6087Z" fill="white"/>
    <path d="M9.6087 9.78235L1 4.99976V6.35215L7.17431 9.78235H9.6087Z" fill="#D80027"/>
    <path d="M19.1175 9.78276L22.9993 7.62616V9.78276H19.1175ZM14.3906 9.17458V5.00012H21.904L14.3906 9.17458Z" fill="#0052B4"/>
    <path d="M16.824 9.78247L22.9984 6.35228V4.99988L14.3896 9.78247H16.824Z" fill="#D80027"/>
    </g>
    <defs>
    <clipPath id="clip0_108_241">
    <rect width="22" height="14.6667" fill="white" transform="translate(1 5)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
import styled from "styled-components";

export const CardWrapper = styled.div`
  margin-left: auto;
  margin-right: auto;
  margin-top: 0;
  margin-bottom: 2rem;
  width: 100%;
  border: none;
  border-radius: 17px;
  box-shadow: 0px 4px 21px 0 rgba(0, 0, 0, 0.05);
  padding: 1rem;
  background: #fff;

  .generateImage {
    width: 100%;
    height: 126px;
    object-fit: contain;
  }

  .generateImageWrapper {
    position: relative;
    &::before {
      content: " ";
      position: absolute;
      left: 0;
      top: 0;
      width: 100%;
      height: 100%;
      background: rgb(0 0 0 / 50%);
      opacity: 0;
      transition: opacity 0.15s ease-in-out;
    }
    &:hover {
      .coverImageBtn {
        opacity: 1;
      }
      &::before {
        opacity: 1;
      }
    }
  }

  .coverImageBtn {
    white-space: nowrap;
    display: flex;
    gap: 10px;
    opacity: 0;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    margin: 0;
    transition: all 0.15s ease-in-out;
    background: transparent;
    border: 1px solid white;
    border-radius: 10px;
    padding: 10px 16px;
    cursor: pointer;
    color: white;
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 500;
    text-align: center;
    &:hover {
      border: 1px solid #f06c34;
      background: white;
      color: #f06c34;
      .magicWandIcon {
        fill: #f06c34;
      }
    }
  }

  .magicWandIcon {
    fill: white;
    width: 24px;
    transition: fill 0.15s ease-in-out;
  }

  .generateImageRef {
    position: relative;
  }
  .describeExamplesWrapper {
    display: flex;
    flex-direction: column;
    gap: 14px 0;
  }
`;

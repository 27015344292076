import styled from "styled-components";
import { NavLink } from "react-router-dom";

export const DocsPageWrapper = styled.div`
  margin-top: 40px;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;

export const NoSearchResultsWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  align-items: center;
  margin-top: 104px;
  img {
    max-width: 306px;
  }
  @media screen and (max-width: 767px) {
    margin-top: 80px;
  }
  color: #101212;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
`;

export const AuthorWrapper = styled.div`
  display: flex;
  gap: 4px;
  align-items: center;
  color: #6F6F6F;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  grid-area: author;
`;

export const TitleAndDescriptionWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 8px;
  grid-area: title-and-description;
  h4 {
    color: #101212;
    font-size: 20px;
    font-weight: 500;
    line-height: 26px;
    margin: 0;
  }
  div {
    color: #101212;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
`;

export const DateTxt = styled.div`
  display: flex;
  align-items: center;
  gap: 4px;
  font-family: Metropolis;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  grid-area: date-txt;
`;

export const DocsSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
`;
export const DocWrapper = styled.div<{ authorInfo?: boolean }>`
  position: relative;
  display: grid;
  padding: 16px;
  border-radius: 12px;
  background: #FFF;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  gap: 12px;
  grid-template-areas: 
    'date-txt date-txt icon'
    'title-and-description title-and-description icon'
    ${props => props.authorInfo && "'author author icon'"}
    'tag tag icon';
  @media screen and (max-width: 767px) {
    gap: 10px;
    grid-template-areas: 
      'date-txt date-txt icon'
      'title-and-description title-and-description icon'
      ${props => props.authorInfo ? "'author author icon'" : "'version version icon'"}
      'tag tag icon';
  }
`;

export const VerWrapper = styled.div`
  position: absolute;
  right: 16px;
  top: 16px;
  color: #6F6F6F;
  display: flex;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.32px;
  grid-area: version;
  @media screen and (max-width: 767px) {
    position: static;
  }
`;

export const TagWrapper = styled.div`
  display: flex;
  grid-area: tag;
`;

export const Tag = styled.div`
  display: flex;
  padding: 6px 10px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  border: 1px solid #A7A7A7;
  color: #A7A7A7;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
`;

export const CategoriesWrapperStand = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
    height: 42px;
    gap: 16px;
    margin-top: 16px;
    margin-bottom: 16px;
  }
`
export const CategoriesWrapper = styled.div`
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  @media screen and (max-width: 767px) {
    margin-top: 0;
    margin-bottom: 0;
    padding-left: 1.5rem;
    padding-right: 1.5rem;
    width: 100%;
    position: absolute;
    overflow-x: scroll;
    left: 0;
  }
`;
export const DocsSwitchButton = styled(NavLink)<{ $isActive?: boolean}>`
  display: flex;
  padding: 10px 16px;
  justify-content: center;
  text-align: center;
  align-items: center;
  border-radius: 40px;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  border: 1px solid ${props => (props.$isActive ? '#FF6D34' : '#C1C1C1')};     
  background: ${props => (props.$isActive ? '#FFF8F5' : '#FFF')};
  color: ${props => (props.$isActive ? ' #FF6D34' : '#BABABA')};
  text-decoration: none;
  white-space: nowrap;
`;

export const TitleWrap = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  color: #101212;
  font-family: 'Metropolis';
  font-size: 24px;
  font-weight: 600;
  img {
    width: 28px;
    height: 28px;
  }
`;

export const IconWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-self: flex-end;
  grid-area: icon;
`;

export const TitleSearchWrapper = styled.div`
  display: flex;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
`

export const SearchBoxInputWrap = styled.div`
  position: relative;
  img {
    position: absolute;
    left: 16px;
    top: 12px;
    width: 20px;
    height: 20px;
  }
  @media screen and (max-width: 767px) {
    flex-grow: 1;
  }
`
export const SearchBoxInputButton = styled.button`
  display: flex;
  justify-content: center;
  align-items: center;
  height: 40px;
  width: 46px;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
  background: #FFF;
  outline: 0;
  border: none;
  border-radius: 9px;
  img {
    width: 17.4px;
    height: 17.4px;
  }
`

export const SearchBoxInput = styled.input`
  border: none;
  box-shadow: 0px 4px 22px 0px rgba(0, 0, 0, 0.05);
  width: 330px;
  height: 46px;
  padding: 12px 16px;
  padding-left: 52px;
  border-radius: 9px;
  background: #FFF;
  transition: border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
  &:focus {
    border-color: #86b7fe;
    outline: 0;
    box-shadow: 0 0 0 0.25rem rgba(13, 110, 253, 0.25);
  }
  &::placeholder {
    color: #ABABAB;
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
  }
  @media screen and (max-width: 767px) {
    width: 100%;
  }
`
import styled from "styled-components";

export const NameWrapper = styled.div`
  display: flex;
  gap: 8px;
  p {
    margin-bottom: 0;
    font-size: 16px;
    line-height: 24px;
    font-weight: 500;
    @media screen and (max-width: 360px) {
      font-size: 14px;
      letter-spacing: -0.308px;
    }
  }
`;

export const DescriptionWrapper = styled.div`
  margin-top: 8px;
  color: #A7A7A7;
  font-size: 14px;
  font-weight: 500;
  line-height: 15.6px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 16.8px;
  }
`;

export const ImageWrapper = styled.div`
  display: flex;
  align-items: center;
`;

export const Container = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
  user-select: none;
  width: 100%;
`;

export const HeaderSection = styled.div<{ $isDropdownOnly: boolean, $isOpen: boolean }>`
  display: flex;
  align-items: center;
  padding: ${props => props.$isDropdownOnly ? '16px' : '8px'} 16px;
  gap: 10px;
  @media screen and (max-width: 767px) {
    gap: ${props => props.$isDropdownOnly ? "4px" : ""};
  }
  cursor: pointer;
  white-space: nowrap;
  background: ${props => props.$isDropdownOnly ? "white" : ""};
  border-radius: ${props => props.$isDropdownOnly ? '12px' : '100px'};
  border: 1.5px solid ${props => (props.$isDropdownOnly && !props.$isOpen) ? '#D1D1D1' : '#FF6D34'};
  min-width: ${props => props.$isDropdownOnly ? '180px' : ''};
`;

export const Placeholder = styled.span`
  color: #A7A7A7;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.352px;
`;

export const Label = styled.span`
  transition: color 0.3s ease;
  color: #101212;
  font-family: Metropolis;
  font-size: 16px;
  font-style: normal;
  font-weight: 500;
  line-height: 20.8px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
  }
`;

export const PopupContentWrapper = styled.div`
  display: flex;
  gap: 16px;
  flex-direction: column;
  padding: 56px 48px;
  background-color: white;
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    padding: 48px 24px 32px;
    max-width: 100%;
  }
`;

export const Title = styled.div`
  text-align: center;
  color: #101212;
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  @media screen and (max-width: 767px) {
    font-size: 24px;
    line-height: 31.2px;
  }
  @media screen and (max-width: 360px) {
    font-size: 20px;
    line-height: 26px;
  }
`;

export const Description = styled.div`
  text-align: center;
  color: #A7A7A7;
  font-family: Metropolis;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    line-height: 18.2px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 16.8px;
  }
`;

export const DropdownOptions = styled.div<{ $openToTop: boolean }>`
  top: ${props => props.$openToTop ? "-145px" : ""};
  position: absolute;
  max-height: 145px;
  min-height: ${props => props.$openToTop ? "145px" : ""};
  width: 100%;
  overflow-y: scroll;
  display: flex;
  flex-direction: column;
  scrollbar-width: thin;
  border-radius: ${props => props.$openToTop ? "12px" : "0px 0px 12px 12px"};
  box-shadow: 0px 4px 17.4px 0px rgba(0, 0, 0, 0.07);
  @media screen and (max-width: 767px) {
    top: ${props => props.$openToTop ? "-196px" : ""};
    max-height: 194px;
  }
`;
export const DropdownOption = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  padding: 12px 16px;
  background: #FFF;
  border-bottom: 1px solid #EBEBEB;
  &:hover {
    background: #FFF7F4;
  }
`;

export const PopupOptions = styled.div`
  width: 100%;
  max-height: 250px;
  overflow-y: scroll;
  display: flex;
  gap: 10px;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    max-height: 206px;
  }
`;

export const PopupOption = styled.div<{ $isSelected: boolean }>`
  cursor: pointer;
  border-radius: 10px;
  padding: 12px 16px;
  border: ${({ $isSelected }) => ($isSelected ? "1.5px solid #FF6D34" : "1px solid #EBEBEB;")};
  background: #FFF;
  &:hover {
    background: #FFF7F4;
  }
`;

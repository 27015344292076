import React from 'react'
import { SVGProps, Svg } from '..'

export const GlobeIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_5474_20367)">
      <path d="M10 0C4.49379 0 0 4.49414 0 10C0 15.5062 4.49414 20 10 20C15.5062 20 20 15.5059 20 10C20 4.49379 15.5059 0 10 0ZM7.03332 1.68527C6.34934 2.55926 5.85566 3.64047 5.51477 4.6875H2.95398C3.98324 3.32574 5.39746 2.2707 7.03332 1.68527ZM2.2052 5.85898H5.18781C4.92672 6.96918 4.77262 8.17113 4.73563 9.41406H1.1918C1.27594 8.13645 1.63293 6.9323 2.2052 5.85898ZM1.1918 10.5859H4.73563C4.77215 11.8144 4.92324 13.0026 5.17883 14.1016H2.18453C1.62437 13.0385 1.27492 11.8481 1.1918 10.5859ZM2.92418 15.2734H5.50203C5.84449 16.3362 6.34266 17.4321 7.03332 18.3147C5.38176 17.7237 3.95586 16.6542 2.92418 15.2734ZM9.41406 18.7279C8.10078 18.2828 7.20719 16.5806 6.73895 15.2734H9.41406V18.7279ZM9.41406 14.1016H6.38348C6.10934 13.0179 5.94691 11.8266 5.90793 10.5859H9.41406V14.1016ZM9.41406 9.41406H5.90793C5.94738 8.1584 6.11336 6.95344 6.39355 5.85938H9.41406V9.41406ZM9.41406 4.6875H6.75297C7.22168 3.39367 8.11113 1.71371 9.41406 1.27211V4.6875ZM17.046 4.6875H14.4852C14.1444 3.6407 13.6507 2.55941 12.9667 1.68527C14.6025 2.2707 16.0168 3.32574 17.046 4.6875ZM10.5859 1.27211C11.889 1.71379 12.7784 3.39398 13.247 4.6875H10.5859V1.27211ZM10.5859 5.85938H13.6064C13.8866 6.95344 14.0526 8.1584 14.0921 9.41406H10.5859V5.85938ZM10.5859 10.5855H14.0921C14.0531 11.8266 13.8907 13.0179 13.6165 14.1016H10.5859V10.5855ZM10.5859 18.7279V15.273H13.2611C12.7931 16.5798 11.8996 18.2827 10.5859 18.7279ZM12.9667 18.3147C13.6574 17.4321 14.1555 16.336 14.498 15.273H17.0758C16.0441 16.6542 14.6182 17.7237 12.9667 18.3147ZM17.8155 14.1016H14.8212C15.0768 13.0026 15.2279 11.8144 15.2644 10.5855H18.8082C18.7251 11.8481 18.3756 13.0385 17.8155 14.1016ZM18.8082 9.41406H15.2644C15.2274 8.17113 15.0733 6.96918 14.8122 5.85938H17.7948C18.3671 6.9323 18.7241 8.13645 18.8082 9.41406Z" fill="#FF6D34"/>
    </g>
    <defs>
      <clipPath id="clip0_5474_20367">
        <rect width="20" height="20" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
);

  
import React from 'react'
import { SVGProps, Svg } from '..'

export const MagicIcon: React.FC<SVGProps> = (props) => {
  return (
    <Svg viewBox="0 0 24 24" {...props}>
      <g clipPath="url(#clip0_2807_6044)">
        <path d="M7.67566 10.8555C7.75 11.1337 7.98686 11.3376 8.27139 11.3716L12.1644 11.8357L12.6286 15.7288C12.6627 16.0173 12.8712 16.2516 13.1447 16.3245C13.4228 16.3987 13.7176 16.2963 13.8899 16.0667L16.238 12.9273L19.8411 14.4717C20.1057 14.5848 20.4121 14.5261 20.6156 14.3227C20.819 14.1193 20.8778 13.8129 20.7647 13.5482L19.2201 9.94514L22.3595 7.59707C22.5892 7.42471 22.6917 7.12996 22.6173 6.85181C22.5431 6.57356 22.3061 6.36867 22.0216 6.33571L18.1287 5.87156L17.6645 1.97854C17.631 1.6935 17.4266 1.45706 17.1483 1.38281C16.8702 1.30846 16.5754 1.41098 16.4031 1.64062L14.0551 4.78003L10.4519 3.23559C10.1873 3.12239 9.88099 3.18117 9.67746 3.38461C9.47402 3.58804 9.41533 3.89442 9.52844 4.15907L11.0729 7.76217L7.93347 10.1102C7.70383 10.2826 7.60146 10.5773 7.67566 10.8555Z" />
        <path d="M20.6156 4.37892L21.6099 3.38456C21.8848 3.10973 21.8848 2.66503 21.6099 2.3902C21.3352 2.11538 20.8904 2.11538 20.6156 2.3902L19.6212 3.38456C19.3464 3.65939 19.3464 4.10419 19.6212 4.37892C19.896 4.65375 20.3408 4.65375 20.6156 4.37892Z" />
        <path d="M13.5089 2.74121C13.8842 2.64071 14.1066 2.25516 14.006 1.87988L13.642 0.521348C13.5404 0.14602 13.1559 -0.0763549 12.7806 0.0241451C12.4053 0.124645 12.1829 0.510192 12.2835 0.885473L12.6475 2.244C12.7471 2.61572 13.1306 2.84199 13.5089 2.74121Z" />
        <path d="M16.7843 14.9659C16.4089 15.0664 16.1866 15.452 16.2871 15.8273L16.6512 17.1858C16.7507 17.5575 17.1343 17.7838 17.5126 17.683C17.8879 17.5825 18.1103 17.1969 18.0098 16.8217L17.6456 15.4631C17.5446 15.0884 17.1591 14.8649 16.7843 14.9659Z" />
        <path d="M8.53707 6.35454L7.17854 5.99046C6.80321 5.88996 6.41767 6.11224 6.31717 6.48757C6.21634 6.86641 6.44317 7.24957 6.81437 7.3489L8.1729 7.71307C8.54823 7.81357 8.93368 7.59119 9.03423 7.21587C9.13421 6.84007 8.91086 6.45452 8.53707 6.35454Z" />
        <path d="M21.2589 10.4913C21.1581 10.8701 21.3849 11.2532 21.7561 11.3526L23.1146 11.7167C23.4899 11.8172 23.8754 11.5949 23.9759 11.2196C24.0765 10.8443 23.8522 10.4578 23.4788 10.3583L22.1203 9.99412C21.7449 9.89362 21.3594 10.116 21.2589 10.4913Z" />
        <path d="M1.2005 23.7939C1.47524 24.0687 1.92004 24.0687 2.19486 23.7939L11.1107 14.8782L10.8988 13.1012L9.12205 12.8894L0.206051 21.8052C-0.0686836 22.08 -0.0686836 22.5247 0.206051 22.7996L1.2005 23.7939Z" />
      </g>
      <defs>
        <clipPath id="clip0_2807_6044">
          <rect width="24" height="24" />
        </clipPath>
      </defs>
    </Svg>
  )
}

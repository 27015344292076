import { LiveStory } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { useProcessingModal } from "../../hooks/use-processing-modal";

export const SharedLiveStoryRedirect = () => {
  const { shortId } = useParams();
  const { transport } = useGlobal();
  const navigate = useNavigate();

  const { onPresent, onDismiss } = useProcessingModal({
    text: "Importing...",
  });

  useEffect(() => {
    onPresent();
    transport
      .post<LiveStory>("/copySharedLiveStory", { shortId })
      .then((resp) => {
        if (resp.data) {
          navigate(`/draft/${resp.data.id}`);
        } else {
          navigate("/");
        }

        onDismiss();
      });
  }, [transport]);

  return <></>;
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const EditUndoIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#fa8152"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M8 7v4L2 6l6-5v4h5a8 8 0 1 1 0 16H4v-2h9a6 6 0 1 0 0-12H8z"></path>
  </Svg>
);

  
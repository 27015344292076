import { useEffect } from "react";
import { useNavigate } from "react-router-dom";

export const GoogleCallback = () => {
  const navigate = useNavigate();

  useEffect(() => {
    const fragmentString = location.hash.substring(1);

    const params = {};
    let regex = /([^&=]+)=([^&]*)/g,
      m: string[];
    while ((m = regex.exec(fragmentString))) {
      params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
    }

    if (Object.keys(params).length > 0) {
      if (params["access_token"] && params["state"]) {
        const [_, topicId, outputId] = params["state"].split("_");

        localStorage.setItem(
          "google_oauth",
          JSON.stringify({ ...params, issued_at: Date.now() })
        );

        navigate(`/topic/${topicId}/${outputId}?export=googledocs`);
      }
    }
  }, []);

  return <></>;
};

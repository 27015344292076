import { Accordion } from "react-bootstrap";
import TypeOrRecordForm from "../../../components/TypeOrRecordForm";
import {
  OutputGenerateReason,
  RecordingObjectType,
} from "@considr-it/storied-enums";
import { ToolDescription, TitleWrapper } from "../writing-feedback.style";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import { LampHint } from "../../../components/LampHint";
import { CrossIcon, HeadIcon, LockIcon } from "../../../components/IconsSvg";
import { useCallback, useMemo, useState } from "react";
import { truncate } from "../../../utils/utils";
import { SecondaryButton } from "../../../components/Buttons";
import { useGlobal } from "@considr-it/storied-shared";
import { useMicRecorder } from "../../../hooks/use-mic-recorder";
import { Instruction } from "@considr-it/storied-entities/src/models";
import { useDocument } from "../../../hooks/use-document";
import { useUpgradeToProModal } from "../../../hooks/use-upgrade-to-pro-modal";
import { useFeaturesLocking } from "../../../hooks/use-features-locking";

const LetMeExplain = ({
  eventKey,
  activeKey,
  generateOutput,
  disabled,
  forMobile = false,
  children = undefined,
}) => {
  const { transport, isSmallMobile } = useGlobal();
  const { onPresent: showUpgradeToProModal } = useUpgradeToProModal();
  const { isLocked } = useFeaturesLocking();
  const {
    setProcessingOutputType,
    currentHighlighted,
    setCurrentHighlighted,
    setMagicWandReplace,
    currentOutput,
  } = useIdeaFlow();

  const { currentTopic } = useDocument();

  const { isBusy } = useMicRecorder();
  const [isProcessing, setIsProcessing] = useState<boolean>(false);

  const onEntry = useCallback(
    async (objectId: string, transcript: string) => {
      setIsProcessing(true);

      const { data: instructions } = await transport.post<Instruction>(
        "/createInstruction",
        {
          instructionsId: objectId,
          topicId: currentTopic.id,
          magicWandText: currentHighlighted?.[0].text || "",
          transcript,
        }
      );

      if (currentHighlighted) {
        const { data } = await transport.post<{
          replacedText: string;
          updatedOutputText: string;
        }>("/magicWandReplace", {
          instructionsId: instructions.id,
          outputId: currentOutput.id,
        });

        setMagicWandReplace(data);
      } else {
        generateOutput(OutputGenerateReason.LET_ME_EXPLAIN, {
          instructionsId: instructions.id,
        });
      }

      setIsProcessing(false);
    },
    [currentHighlighted, currentOutput, generateOutput, currentTopic]
  );

  const typeOrRecordForm = useMemo(() => {
    return (
      <TypeOrRecordForm
        style={forMobile && { marginBottom: 0 }}
        textarea
        disabled={disabled}
        preProcess={() => {
          if (!currentHighlighted) {
            setProcessingOutputType(OutputGenerateReason.LET_ME_EXPLAIN);
          }
        }}
        onEntry={onEntry}
        placeHolderText={
          forMobile
            ? "Try `Rewrite with half the words`"
            : "Type here or record => "
        }
        objectType={RecordingObjectType.LET_ME_EXPLAIN}
      />
    );
  }, [forMobile, disabled, currentHighlighted, currentOutput, currentTopic]);

  const removeHighlightingButton = useMemo(
    () => (
      <SecondaryButton
        noIconFill
        extraSmall
        style={{ display: "inline" }}
        onClick={() => setCurrentHighlighted(null)}
      >
        <CrossIcon />
      </SecondaryButton>
    ),
    [setCurrentHighlighted]
  );

  if (forMobile) {
    const iconSize = isSmallMobile
      ? { width: 20, height: 20 }
      : { width: 24, height: 24 };
    return (
      <>
        <TitleWrapper onClick={isLocked ? showUpgradeToProModal : null}>
          <HeadIcon style={{ marginRight: 8 }} {...iconSize} />
          <p>Describe changes</p>
          {isLocked ? (
            <div style={{ marginLeft: "auto" }}>
              <LockIcon />
            </div>
          ) : (
            children
          )}
        </TitleWrapper>
        {isLocked ? null : (
          <div>
            <ToolDescription>
              {!!currentHighlighted ? (
                <div>
                  How would you like to change{" "}
                  <strong style={{ color: "#fa8152" }}>
                    "{truncate(currentHighlighted.text, 100)}"
                  </strong>
                  {"  "}
                  {!isBusy && !isProcessing && removeHighlightingButton}
                </div>
              ) : (
                "Say what you'd like to change"
              )}
            </ToolDescription>
            {typeOrRecordForm}
          </div>
        )}
      </>
    );
  }

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={activeKey === eventKey ? "accordion-active" : ""}
    >
      <Accordion.Header>
        <HeadIcon
          style={{ marginRight: 10 }}
          $inactive={activeKey !== eventKey}
        />
        Describe changes
      </Accordion.Header>
      <Accordion.Body>
        <ToolDescription className="mb-0">
          {!!currentHighlighted ? (
            <div>
              How would you like to change{" "}
              <strong style={{ color: "#fa8152" }}>
                "{truncate(currentHighlighted.text, 100)}"
              </strong>
              {"  "}
              {!isBusy && !isProcessing && removeHighlightingButton}
            </div>
          ) : (
            "If you want to change something, describe it below and we'll rewrite it for you."
          )}
        </ToolDescription>
        {typeOrRecordForm}
        <div className="describeExamplesWrapper">
          <LampHint text="“Rewrite this with half as many words!”" />
          <LampHint text="“Turn the first section of bullet points into a paragraph”" />
          <LampHint text="“Translate this into Spanish”" />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default LetMeExplain;

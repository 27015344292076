import styled from "styled-components";

export const PlansWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  position: relative;
  @media screen and (max-width: 767px) {
    margin-top: 24px;
  }
`;
export const Title = styled.div`
  color: #101212;
  font-size: 20px;
  font-weight: 600;
  line-height: 26px;
  letter-spacing: -0.44px;
  @media screen and (max-width: 767px) {
    font-size: 18px;
    line-height: 23.4px;
    letter-spacing: -0.396px;
  }
`;
export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
export const Card = styled.div`
  display: flex;
  padding: 16px 24px;
  justify-content: space-between;
  align-items: center;
  align-self: stretch;
  border-radius: 16px;
  background: white;
  box-shadow: 0px 4px 30px 0px rgba(54, 54, 54, 0.10);
  @media screen and (max-width: 767px) {
    flex-direction: column;
    align-items: flex-start;
    position: relative;
  }
`;
export const LeftBlock = styled.div`
  display: flex;
  align-items: flex-start;
  flex-direction: column;
  gap: 8px;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;
export const RightBlock = styled(LeftBlock)`
  align-items: flex-end;
  @media screen and (max-width: 767px) {
    display: block;
    width: 100%;
  }
`;
export const PriceWrapper = styled.div`
  text-align: end;
  display: flex;
  gap: 8px;
  flex-direction: column;
  @media screen and (max-width: 767px) {
    gap: 4px;
    position: absolute;
    top: 16px;
    right: 24px;
  }
`;
export const Description = styled.div`
  color: #6F728F;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
`;
export const PlanDescription = styled.div`
  color: #6F728F;
  font-size: 12px;
  line-height: 15.6px;
  letter-spacing: -0.264px;
`;
export const TextButton = styled.div`
  display: flex;
  height: 46px;
  padding: 12px 16px;
  justify-content: center;
  align-items: center;
  color: #FF6D34;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  cursor: pointer;
  @media screen and (max-width: 767px) {
    padding: 0;
    height: unset;
    margin-top: 16px;
  }
`;
export const FeatureBlock = styled.div`
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  display: flex;
  align-items: center;
  gap: 10px;
  align-self: stretch;
`;
export const SiteLink = styled.div`
  margin-top: 24px;
  color: #FF6D34;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  cursor: pointer;
  svg {
    path {
      stroke: #FF6D34;
    }
  }
  @media screen and (max-width: 767px) {
    margin-top: 0;
  }
`;
export const Toggler = styled.div`
  display: flex;
  gap: 16px;
  position: absolute;
  right: 0;
  top: -68px;
  @media screen and (max-width: 767px) {
    gap: 8px;
    top: -60px;
  }
`;
export const BillingButton = styled.div<{ $isActive: boolean }>`
  display: flex;
  height: 37px;
  padding: 10px 16px;
  justify-content: center;
  align-items: center;
  border-radius: 100px;
  background: ${props => props.$isActive ? "#FF6D34" : "white"};
  border: 1px solid ${props => props.$isActive ? "#FF6D34" : "#D1D1D1"};
  color: ${props => props.$isActive ? "white" : "#101212"};
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  transition: all .2s;
  cursor: pointer;
`;
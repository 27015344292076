import showdown from "showdown";

showdown.setOption("emoji", true);
showdown.setOption("simpleLineBreaks", true);
showdown.setOption("smoothLivePreview", true);
showdown.setOption("smartIndentationFix", true);
showdown.setOption("strikethrough", true);
showdown.setOption("tables", true);
showdown.setOption("noHeaderId", true);

export const upperCaseFirstChar = (text: string) => {
  if (!text || text.length === 0) {
    return "";
  }

  return text.charAt(0).toUpperCase() + text.slice(1);
};

export const getNumWords = (text: string) => {
  if (!text) {
    return 0;
  } else {
    return text.trim().split(" ").length;
  }
};

export const convertMarkdownToHTML = (markdownText: string) => {
  if (markdownText?.length > 0) {
    const converter = new showdown.Converter();
    const htmlText = converter.makeHtml(markdownText) as string;

    return htmlText;
  }

  return "";
};

export const timeString = (sec_num: number) => {
  var hours = Math.floor(sec_num / 3600);
  var minutes = Math.floor((sec_num - hours * 3600) / 60);
  var seconds = sec_num - hours * 3600 - minutes * 60;

  var str_hours = hours < 10 ? "0" + hours : hours;
  var str_minutes = minutes < 10 ? "0" + minutes : minutes;
  var str_seconds = seconds < 10 ? "0" + seconds : seconds;

  return str_hours === "00"
    ? str_minutes + ":" + str_seconds
    : str_hours + ":" + str_minutes + ":" + str_seconds;
};

export const extractJSON = (str: string) => {
  let firstOpen, firstClose, candidate;
  firstOpen = str.indexOf("{", firstOpen + 1);
  do {
    firstClose = str.lastIndexOf("}");
    if (firstClose <= firstOpen) {
      return null;
    }
    do {
      candidate = str.substring(firstOpen, firstClose + 1);
      try {
        const res = JSON.parse(candidate);
        return res;
      } catch (_) {}
      firstClose = str.substr(0, firstClose).lastIndexOf("}");
    } while (firstClose > firstOpen);
    firstOpen = str.indexOf("{", firstOpen + 1);
  } while (firstOpen != -1);
};

export const splitTextInChunks = (text: string, chunkSize: number) => {
  const subStrings = [];

  let startIndex = 0;
  let endIndex = 0;

  while (endIndex < text.length) {
    endIndex = Math.min(text.length, startIndex + chunkSize);
    const newSubString = text.substring(startIndex, endIndex);

    if (endIndex === text.length) {
      subStrings.push(newSubString.trim());
      break;
    }

    while (
      newSubString[endIndex - startIndex] !== "." &&
      newSubString[endIndex - startIndex] !== "\n"
    ) {
      endIndex--;

      if (endIndex === startIndex) {
        endIndex = startIndex + chunkSize;
        break;
      }
    }

    endIndex++;

    subStrings.push(text.substring(startIndex, endIndex).trim());
    startIndex = endIndex + 1;
  }

  return subStrings;
};

//Levenshtein distance tells us how different are 2 words, more exactly, the number of edits we would need to make to a in order to get b.
export const getLvDistance = (a: string, b: string) => {
  a = a.toLowerCase().replaceAll(" ", "");
  b = b.toLowerCase().replaceAll(" ", "");

  function _min(d0, d1, d2, bx, ay) {
    return d0 < d1 || d2 < d1
      ? d0 > d2
        ? d2 + 1
        : d0 + 1
      : bx === ay
      ? d1
      : d1 + 1;
  }

  if (a === b) {
    return 0;
  }

  if (a.length > b.length) {
    var tmp = a;
    a = b;
    b = tmp;
  }

  var la = a.length;
  var lb = b.length;

  while (la > 0 && a.charCodeAt(la - 1) === b.charCodeAt(lb - 1)) {
    la--;
    lb--;
  }

  var offset = 0;

  while (offset < la && a.charCodeAt(offset) === b.charCodeAt(offset)) {
    offset++;
  }

  la -= offset;
  lb -= offset;

  if (la === 0 || lb < 3) {
    return lb;
  }

  var x = 0;
  var y;
  var d0;
  var d1;
  var d2;
  var d3;
  var dd;
  var dy;
  var ay;
  var bx0;
  var bx1;
  var bx2;
  var bx3;

  var vector = [];

  for (y = 0; y < la; y++) {
    vector.push(y + 1);
    vector.push(a.charCodeAt(offset + y));
  }

  var len = vector.length - 1;

  for (; x < lb - 3; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    bx1 = b.charCodeAt(offset + (d1 = x + 1));
    bx2 = b.charCodeAt(offset + (d2 = x + 2));
    bx3 = b.charCodeAt(offset + (d3 = x + 3));
    dd = x += 4;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      ay = vector[y + 1];
      d0 = _min(dy, d0, d1, bx0, ay);
      d1 = _min(d0, d1, d2, bx1, ay);
      d2 = _min(d1, d2, d3, bx2, ay);
      dd = _min(d2, d3, dd, bx3, ay);
      vector[y] = dd;
      d3 = d2;
      d2 = d1;
      d1 = d0;
      d0 = dy;
    }
  }

  for (; x < lb; ) {
    bx0 = b.charCodeAt(offset + (d0 = x));
    dd = ++x;
    for (y = 0; y < len; y += 2) {
      dy = vector[y];
      vector[y] = dd = _min(dy, d0, dd, bx0, vector[y + 1]);
      d0 = dy;
    }
  }

  return dd;
};

// Gets the % of how much the words are different -> cat-dog are 100% different (3/3), cat-cats are 25% different (1/4),  Mircha-Mircea are 16% different (1/6)
export const getPercentageOfWordChanged = (
  firstWord: string,
  secondWord: string
) => {
  const lvDistance = getLvDistance(firstWord, secondWord);
  const maxLength = Math.max(firstWord.length, secondWord.length);

  return Math.round((lvDistance * 100) / maxLength);
};

export const isCharUpperCase = (c: string) => {
  return c ? c.toUpperCase() === c : false;
};

export const isCharLowerCase = (c: string) => {
  return c ? c.toLowerCase() === c : false;
};

export const removeNonAlphanumeric = (inputString: string) => {
  return inputString ? inputString.replace(/[^a-zA-Z0-9]/g, "") : "";
};

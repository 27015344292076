import { SharedOutputReview, User } from "@considr-it/storied-entities";
import { RecordingObjectType } from "@considr-it/storied-enums";
import { Transport, useAccount, useGlobal } from "@considr-it/storied-shared";
import { ChangeEvent, useEffect, useRef, useState } from "react";
import { FaFloppyDisk } from "react-icons/fa6";
import { AudioPlayer } from "../../../components/AudioPlayer";
import { DisabledPlayIcon } from "../../../components/IconsSvg/Icons/DisabledPlay";
import { storiedOrange } from "../../../constants";
import { useClickOutside } from "../../../hooks/use-click-outside";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import {
  Avatar,
  AvatarContainer,
  ContextMenu,
  ContextMenuButton,
  ContextMenuItem,
  ConversationThread,
  Message,
  MessageContainer,
  MessageContent,
  MessageHeader,
  MessageText,
  ModalContentWrapper,
  PlayButton,
  Timestamp,
  Username,
} from "./review-item.style";

interface IReviewItemProps {
  sharedOutputReviewId: string;
  handleDelete: (reviewId: string) => void;
}

export const ReviewItem = ({
  sharedOutputReviewId,
  handleDelete,
}: IReviewItemProps) => {
  const [isEditMode, setIsEditMode] = useState<boolean>(false);
  const [activeMenu, setActiveMenu] = useState(null);
  const { account } = useAccount();
  const { transport } = useGlobal();
  const contextMenuRef = useRef(null);
  const [isPulsing, setIsPulsing] = useState(false);

  useEffect(() => {
    if (isPulsing) {
      const timer = setTimeout(() => setIsPulsing(false), 500);
      return () => clearTimeout(timer);
    }
  }, [isPulsing]);

  useClickOutside(contextMenuRef, () => {
    setActiveMenu(false);
  });

  const {
    data: sharedOutputReview,
    revalidate: revalidateReview,
    isLoading: isLoadingReview,
  } = Transport.useTransportSWR<SharedOutputReview>(
    transport,
    `/sharedOutputReview/${sharedOutputReviewId}`
  );

  const toggleMenu = (reviewId: string) => {
    setActiveMenu(activeMenu === reviewId ? null : reviewId);
  };

  const user = sharedOutputReview?.owner as User;

  if (!user || isLoadingReview) {
    return <></>;
  }

  const isOwnerViewing =
    (sharedOutputReview.owner as User).id.toString() === account.id.toString();

  return (
    <ConversationThread>
      <Message>
        <MessageContainer>
          {user.id === account.id.toString() && !isEditMode && (
            <ContextMenuButton
              onClick={() => {
                toggleMenu(sharedOutputReview.id);
              }}
            >
              ...
            </ContextMenuButton>
          )}
          {activeMenu === sharedOutputReview.id && (
            <ContextMenu ref={contextMenuRef}>
              <ContextMenuItem
                onClick={() => {
                  setActiveMenu(null);
                  setIsEditMode(true);
                }}
              >
                Edit
              </ContextMenuItem>
              <ContextMenuItem
                onClick={() => {
                  setActiveMenu(null);
                  handleDelete(sharedOutputReview.id);
                }}
              >
                Delete
              </ContextMenuItem>
              {sharedOutputReview.filePath && (
                <ContextMenuItem
                  onClick={() => {
                    setActiveMenu(null);
                    transport.patch(
                      `/sharedOutputReview/${sharedOutputReview.id}`,
                      {
                        payload: {
                          isDisabledAudio: !sharedOutputReview.isDisabledAudio,
                        },
                      }
                    );
                    sharedOutputReview.isDisabledAudio =
                      !sharedOutputReview.isDisabledAudio;
                  }}
                >
                  {sharedOutputReview.isDisabledAudio
                    ? "Enable Audio"
                    : "Disable Audio"}
                </ContextMenuItem>
              )}
            </ContextMenu>
          )}

          <MessageContent>
            <MessageHeader>
              <AvatarContainer>
                <Avatar
                  src={user.profile.imageUrl}
                  referrerPolicy="no-referrer"
                />
                {sharedOutputReview.isDisabledAudio && isOwnerViewing && (
                  <DisabledPlayIcon />
                )}
                {!sharedOutputReview.isDisabledAudio &&
                  sharedOutputReview.filePath &&
                  !isEditMode && (
                    <PlayButton>
                      <AudioPlayer
                        itemId={sharedOutputReview.id}
                        itemModel={RecordingObjectType.SHARED_OUTPUT_REVIEW}
                      />
                    </PlayButton>
                  )}
              </AvatarContainer>
              <div>
                <Username>{user.profile?.name}</Username>
                <Timestamp>
                  {new Date(sharedOutputReview.updated).toLocaleString()}
                </Timestamp>
                {!isEditMode && (
                  <MessageText>{sharedOutputReview.transcript}</MessageText>
                )}
              </div>
            </MessageHeader>
          </MessageContent>
          {isEditMode && (
            <EditableMessage
              content={sharedOutputReview.transcript}
              onSave={async (newText) => {
                setIsEditMode(false);

                if (sharedOutputReview.transcript !== newText) {
                  sharedOutputReview.transcript = newText;
                  await transport.patch(
                    `/sharedOutputReview/${sharedOutputReview.id}`,
                    { payload: { transcript: newText } }
                  );

                  await revalidateReview();
                }
              }}
            />
          )}
        </MessageContainer>
      </Message>
    </ConversationThread>
  );
};

const EditableMessage = ({ content, onSave }) => {
  const [textAreaValue, setTextAreaValue] = useState<string>(content);
  const textAreaRef = useRef<HTMLTextAreaElement>(null);

  const setTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setTextAreaValue(e.target.value);
  };

  useEffect(() => {
    textAreaRef.current.focus();
  }, []);

  const handleSave = async () => {
    await onSave(textAreaValue);
  };

  return (
    <ModalContentWrapper>
      <FaFloppyDisk
        size="18px"
        className="edit-output-icon"
        onClick={handleSave}
        style={{ color: storiedOrange, cursor: "pointer" }}
      />

      <textarea
        onChange={setTextAreaHandler}
        onBlur={handleSave}
        value={textAreaValue}
        ref={textAreaRef}
      />
    </ModalContentWrapper>
  );
};

import styled from "styled-components";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Components
import { ButtonCTA } from "./onboarding-carousel.style";
// Icons
import { ArrowUpperRightIcon, PenCircleIcon } from "../IconsSvg";
// Styles
const Point = styled.div`
  position: relative;
`;
const PointNum = styled.div`
  position: absolute;
  transform: translate(-50%, -50%);
  top: 50%;
  left: 50%;
  color: #101212;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 767px) {
    font-size: 12.6px;
    font-weight: 500;
    line-height: 16px;
    letter-spacing: -0.277px;
  }
`;
const ListWrapper = styled.div`
  margin-top: 16px;
  display: flex;
  flex-direction: column;
  gap: 16px;
`;
const ItemWrapper = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
`;
const ItemText = styled.div`
  color: #101212;
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  @media screen and (max-width: 767px) {
    font-size: 16px;
    line-height: 20.8px;
    letter-spacing: -0.352px;
  }
`;

export const Step2 = () => {
  const { isMobile } = useGlobal();
  const iconSizes = isMobile ? { width: 21, height: 21 } : {};
  return (
    <div>
      <ListWrapper>
        <ItemWrapper>
          <Point>
            <PenCircleIcon {...iconSizes} />
            <PointNum>1</PointNum>
          </Point>
          <ItemText>Describe the format you want</ItemText>
        </ItemWrapper>
        <ItemWrapper>
          <Point>
            <PenCircleIcon {...iconSizes} />
            <PointNum>2</PointNum>
          </Point>
          <ItemText>Provide the details</ItemText>
        </ItemWrapper>
      </ListWrapper>

      <ButtonCTA onClick={() => window.open("/whytalk", "_blank")}>
        Read our Why Talk page
        <ArrowUpperRightIcon style={{ marginBottom: 2 }} />
      </ButtonCTA>
    </div>
  );
};

import React from "react";
import styled from "styled-components";

const StyledChevron = styled.svg<{ $isOpen: boolean, $special: boolean }>`
  ${props => props.$special ? "margin-left: auto" : ""};
  transition: transform 0.3s ease;
  height: 14px;
  width: 14px;
  path {
    stroke: ${props => props.$special ? "#D1D1D1" : "#fa8152"};
  }
  ${({ $isOpen }) =>
    $isOpen &&
    `
    path {
      stroke: #fa8152;
    }
    transform: rotate(180deg);
  `}
`;

export const Chevron: React.FC<{ isOpen: boolean, special: boolean  }> = ({ isOpen, special }) => (
  <StyledChevron
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="14"
    viewBox="0 0 14 14"
    fill="none"
    $isOpen={isOpen}
    $special={special}
  >
    <path
      d="M2 4L7.5 9.5L13 4"
      stroke="#101212"
      strokeWidth="1.5"
      strokeLinecap="round"
      strokeLinejoin="round"
    />
  </StyledChevron>
);

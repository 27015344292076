// Custom hooks
import { usePayment } from "./use-payment";
// Constants
import { PaymentTier } from "@considr-it/storied-enums";
import { useDocument } from "./use-document";

export const useFeaturesLocking = () => {
  const { paymentInfo } = usePayment();
  const { basicTopicsCount } = useDocument();

  const isLocked =
    paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_EXPIRED ||
    (paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_BASIC &&
      basicTopicsCount >= paymentInfo.basicTopicsThreshold);

  return { isLocked };
};

import { useEffect, useState } from "react";
import { Accordion, Form } from "react-bootstrap";
import { FormCheckWrapper, TitleWrapper } from "../writing-feedback.style";
import { useDocument } from "../../../hooks/use-document";
import { OutputGenerateReason } from "@considr-it/storied-enums";
import { LampHint } from "../../../components/LampHint";
import { DocIcon } from "../../../components/IconsSvg";

const ChooseWritingStyle = ({
  eventKey,
  activeKey,
  generateOutput,
  disabled,
  forMobile = false,
  children = undefined,
}) => {
  const { currentTopic, revalidateTopic } = useDocument();
  const [isCustomWritingStyle, setIsCustomWritingStyle] =
    useState<boolean>(false);

  const [writingStyle, setWritingStyle] = useState<string>("");

  useEffect(() => {
    if (!!currentTopic.activeWritingStyle && !writingStyle) {
      setWritingStyle(currentTopic.activeWritingStyle);
    }
  }, [currentTopic]);

  const cardBody = (
    <FormCheckWrapper>
      {currentTopic.writingStyles.map((ws, index) => {
        return (
          <Form.Check
            disabled={disabled}
            name="writing-style-option"
            type="radio"
            key={`writing-style-${index}`}
            id={`writing-style-${index}`}
            label={ws}
            onClick={async () => {
              if (ws !== writingStyle) {
                setWritingStyle(ws);
                setIsCustomWritingStyle(false);

                await generateOutput(OutputGenerateReason.WRITING_STYLE, {
                  writingStyle: ws,
                });

                await revalidateTopic();
              }
            }}
            checked={
              !isCustomWritingStyle &&
              writingStyle.toLowerCase() === ws.toLowerCase()
            }
            onChange={() => {}}
          />
        );
      })}
    </FormCheckWrapper>
  );

  if (forMobile) {
    return (
      <>      
        <TitleWrapper>
          <DocIcon style={{ marginRight: 8 }} />
          <p>Choose the doc type</p>
          {children}
        </TitleWrapper>
        {cardBody}
      </>
    )
  }

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={activeKey === eventKey ? "accordion-active" : ""}
    >
      <Accordion.Header>
        <DocIcon
          style={{ marginRight: 10 }}
          $inactive={activeKey !== eventKey}
        />
        Change the document type
      </Accordion.Header>
      <Accordion.Body>
        {cardBody}
        <div style={{ marginTop: 16 }}>
          <LampHint
            text="Want a different format? Use “describe changes.” 
              It can do anything. Limerick? You name it."
          />
        </div>
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default ChooseWritingStyle;

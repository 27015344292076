import { useNavigate, useParams } from "react-router-dom";
// Custom hooks
import { useConfirmModal } from "../../hooks/use-confirm-modal";
// Components
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
// Styles
import {
  IconWrapper,
  DocsSection,
  DocWrapper,
  DateTxt,
  TitleAndDescriptionWrapper,
  AuthorWrapper,
  VerWrapper,
  TagWrapper,
  Tag,
} from "./my-documents.style";
// Icons
import { BinIcon, ExportIcon, PersonIcon } from "../../components/IconsSvg";
// Constants
import { OutputState } from "@considr-it/storied-enums";
import {
  Base,
  LiveQuestion,
  LiveStory,
  Output,
  SharedOutputMetaData,
  Topic,
} from "@considr-it/storied-entities";
// Helpers
import { getFormattedDate, truncate } from "../../utils/utils";
import { useProcessingModal } from "../../hooks/use-processing-modal";

interface ICardsProps {
  docs: any[];
  getDocType: (_obj: Base) => string;
  removeDoc: (_obj: Base) => Promise<void>;
  convertLiveStory: (liveStory: LiveStory) => Promise<string>;
}

export const Cards = ({
  docs,
  getDocType,
  removeDoc,
  convertLiveStory,
}: ICardsProps) => {
  const navigate = useNavigate();
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const {
    onPresent: onPresentConvertingLiveStory,
    onDismiss: onDissmissConvertingLiveStory,
  } = useProcessingModal({ text: "Converting your record..." });

  const triggerDeleteItemModal = (item: Base) => {
    onPresentConfirmModal({
      confirmationMessage: "Are you sure you want to delete this document?",
      actions: [
        <PrimaryButton
          onClick={async () => {
            onDismiss();
            await removeDoc(item);
          }}
        >
          Delete
        </PrimaryButton>,
        <SecondaryButton onClick={onDismiss}>Close</SecondaryButton>,
      ],
    });
  };

  const renderTopic = (topic: Topic) => {
    const lastFinishedOutput = [...(topic.outputs as Output[])]
      .reverse()
      .find((o: Output) => o.state === OutputState.FINISHED) as Output;
    const title = lastFinishedOutput?.title || topic.transcript;
    const tldr = lastFinishedOutput?.tldr || topic.transcript;
    const isShared = topic.outputs.some((t) => !!t?.sharedOutput);

    return (
      <DocWrapper onClick={() => navigate(`/topic/${topic.id}`)} key={topic.id}>
        <DateTxt>
          {isShared && <ExportIcon width={22} height={22} $color="#4CBF05" />}
          {`Modified on: ${getFormattedDate(
            lastFinishedOutput?.updated || topic.updated
          )}`}
        </DateTxt>
        <TitleAndDescriptionWrapper>
          <h4 data-dd-privacy="mask">{truncate(title, 100)}</h4>
          <div data-dd-privacy="mask">{truncate(tldr, 200)}</div>
        </TitleAndDescriptionWrapper>
        <IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            triggerDeleteItemModal(topic);
          }}
        >
          <BinIcon />
        </IconWrapper>
        {topic.outputs.length && (
          <VerWrapper>Versions: {topic.outputs.length - 1}</VerWrapper>
        )}
        <TagWrapper>
          <Tag>
            {topic.outputs.length > 1 ? "Edited with AI" : "In Progress"}
          </Tag>
        </TagWrapper>
      </DocWrapper>
    );
  };

  const renderLiveStory = (liveStory: LiveStory) => {
    return (
      <DocWrapper
        authorInfo={false}
        key={liveStory.id}
        onClick={async () => {
          if (!!liveStory.isDraft) {
            navigate(`/draft/${liveStory.id}`);
          } else {
            onPresentConvertingLiveStory();
            const topicId = await convertLiveStory(liveStory);
            onDissmissConvertingLiveStory();

            navigate(`/topic/${topicId}`);
          }
        }}
      >
        <DateTxt>{`Modified on: ${getFormattedDate(
          liveStory?.updated
        )}`}</DateTxt>
        <TitleAndDescriptionWrapper>
          <div>
            <h4 data-dd-privacy="mask">
              {truncate(
                (liveStory.liveQuestions[0] as LiveQuestion)?.shortQuestion,
                100
              )}
            </h4>
            <div data-dd-privacy="mask">
              {!!liveStory.isDraft
                ? liveStory.outline
                : truncate(liveStory.subTranscripts[0], 200)}
            </div>
          </div>
        </TitleAndDescriptionWrapper>
        <IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            triggerDeleteItemModal(liveStory);
          }}
        >
          <BinIcon />
        </IconWrapper>
        <TagWrapper>
          <Tag>{!!liveStory.isDraft ? "Draft" : "In Progress"}</Tag>
        </TagWrapper>
      </DocWrapper>
    );
  };

  const renderSharedOutputMetaData = (
    sharedOutputMetaData: SharedOutputMetaData
  ) => {
    const sharedOutput: any = sharedOutputMetaData.sharedOutput;
    return (
      <DocWrapper
        authorInfo
        key={sharedOutputMetaData.id}
        onClick={() => navigate(`/s/${sharedOutput.shortId}`)}
      >
        <DateTxt>{`Modified on: ${getFormattedDate(
          sharedOutput.created
        )}`}</DateTxt>
        <TitleAndDescriptionWrapper>
          <h4>{truncate(sharedOutput.title, 100)}</h4>
          <div>{sharedOutput.tldr || truncate(sharedOutput.text, 200)}</div>
        </TitleAndDescriptionWrapper>
        <AuthorWrapper>
          <PersonIcon width={20} height={20} />
          <div>Created by:</div>
          <div>{sharedOutput.ownerName}</div>
        </AuthorWrapper>
        <IconWrapper
          onClick={(e) => {
            e.stopPropagation();
            triggerDeleteItemModal(sharedOutputMetaData);
          }}
        >
          <BinIcon />
        </IconWrapper>
        <TagWrapper>
          <Tag>Shared with me</Tag>
        </TagWrapper>
      </DocWrapper>
    );
  };

  const renderDocs = () =>
    docs.map((obj: Base) => {
      const docType = getDocType(obj);
      switch (docType) {
        case "topic":
          return renderTopic(obj as Topic);
        case "live-story":
          return renderLiveStory(obj as LiveStory);
        case "shared-output":
          return renderSharedOutputMetaData(obj as SharedOutputMetaData);
      }
    });
  return <DocsSection>{renderDocs()}</DocsSection>;
};

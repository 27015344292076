import { PaymentTier } from "@considr-it/storied-enums";
import { usePayment } from "../../hooks/use-payment";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
import { PrimaryButton } from "../Buttons";
import { useAccount } from "@considr-it/storied-shared";
import { ConfirmationModal } from "../Modals/ConfirmModal";
import { useDocument } from "../../hooks/use-document";

export const TrialAccountNotification = () => {
  const { paymentInfo, revalidatePaymentInfo } = usePayment();
  const { account } = useAccount();
  const { totalTopicsCount } = useDocument();
  const { onDismiss } = useConfirmModal();

  if (
    !account.isAnonymous &&
    paymentInfo.paymentTier === PaymentTier.PAYMENT_TIER_PRO_TRIAL &&
    !paymentInfo.trialStartedAt &&
    totalTopicsCount > 0 &&
    !localStorage.getItem("trial_account_notification_v2")
  ) {
    return (
      <ConfirmationModal
        params={{
          confirmationMessage: `You've started your ${paymentInfo.trialDurationDays}-day trial`,
          description:
            "We're going to help you refine and communicate ideas like never before. Let's go!",
          actions: [
            <PrimaryButton
              fullWidth
              onClick={async () => {
                localStorage.setItem("trial_account_notification_v2", "1");
                revalidatePaymentInfo();

                onDismiss();
              }}
            >
              Let's Go
            </PrimaryButton>,
          ],
          withCloseBtn: false,
          disableOnHide: true,
        }}
      />
    );
  } else {
    return <></>;
  }
};

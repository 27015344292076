import SoundIcon from "./soundIcon.svg";
import SoundIconGrey from "./soundIconGrey.svg";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { useState } from "react";
import { trackEvent } from "@considr-it/storied-shared";
import { TTSType } from "@considr-it/storied-enums";
import { useMicRecorder } from "../../hooks/use-mic-recorder";

export const QuestionAudioPlayer = () => {
  const [isPlayerMuted, setIsPlayerMuted] = useState<boolean>(false);

  const { record } = useIdeaFlow();

  const {
    currentLiveQuestion,
    canPlayQuestionAudio,
    playQuestionAudio,
    stopQuestionAudio,
  } = record;

  const { isPausedRecording } = useMicRecorder();

  return (
    isPausedRecording &&
    currentLiveQuestion?.spokenQuestion &&
    canPlayQuestionAudio && (
      <img
        width={24}
        height={24}
        src={isPlayerMuted ? SoundIconGrey : SoundIcon}
        onClick={() => {
          trackEvent("toggle_question_audio", { isPlayerMuted });

          if (isPlayerMuted) {
            playQuestionAudio({
              itemId: currentLiveQuestion.id,
              itemModel: TTSType.LiveQuestion,
            });
          } else {
            stopQuestionAudio();
          }

          setIsPlayerMuted((v) => !v);
        }}
      />
    )
  );
};

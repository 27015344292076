import React, { ReactNode } from 'react';

interface ReturnJSXProps {
  else?: ReactNode;
  if: any;
  children: ReactNode;
}

export const ReturnJSX: React.FC<ReturnJSXProps> = ({ if: condition, else: elseContent, children }) =>
  condition ? <>{children}</> : elseContent ? <>{elseContent}</> : null;

import React from 'react'
import { SVGProps, Svg } from '..'

export const CrownIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M6.99805 14.5C6.99805 15.0525 7.44595 15.5004 7.99846 15.5004H16.0018C16.5543 15.5004 17.0022 15.0525 17.0022 14.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.99805 14.4949V9.52574C6.99805 9.34127 7.09959 9.17178 7.26224 9.08474C7.42488 8.9977 7.62223 9.00724 7.77572 9.10957L9.9993 10.5919L11.584 8.21494C11.6767 8.07578 11.8329 7.99219 12.0002 7.99219C12.1674 7.99219 12.3236 8.07578 12.4164 8.21494L14.001 10.5919L16.2245 9.10955C16.378 9.00722 16.5754 8.99768 16.738 9.08473C16.9007 9.17177 17.0022 9.34127 17.0022 9.52574V14.4949" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <circle cx="12.0003" cy="11.9959" r="9.00375" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);


import styled from "styled-components";

export const Description = styled.div`
  font-size: 18px;
  font-weight: 400;
  line-height: 23.4px;
  margin-bottom: 24px;
`;

export const ContentWrapper = styled.div`
  padding: 40px;
  max-width: 518px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  width: 100%;
  background-color: white;
  border-radius: 24px;
  color: #101212;
`;

const StyledImg = styled.svg`
  margin-bottom: 32px;
`;

export const Title = styled.div`
  text-align: center;
  font-size: 28px;
  font-weight: 600;
  line-height: 36.4px;
  margin-bottom: 16px;
  letter-spacing: -0.616px;
`;

export const LockerImg: React.FC = () => (
  <StyledImg
    xmlns="http://www.w3.org/2000/svg"
    width="201"
    height="200"
    viewBox="0 0 201 200"
    fill="none"
  >
    <g clipPath="url(#clip0_7053_44981)">
      <rect
        x="0.5"
        width="200"
        height="200"
        rx="100"
        fill="url(#paint0_linear_7053_44981)"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M162.297 37.2773C163.102 35.7787 164.665 34.8438 166.366 34.8438C168.067 34.8438 169.631 35.7787 170.435 37.2773L174.024 43.8452C174.449 44.626 175.091 45.2675 175.872 45.6927L182.439 49.2815C183.938 50.0862 184.873 51.6496 184.873 53.3506C184.873 55.0516 183.938 56.6149 182.439 57.4197L175.872 61.0085C175.091 61.4337 174.449 62.0752 174.024 62.856L170.435 69.4238C169.631 70.9224 168.067 71.8574 166.366 71.8574C164.665 71.8574 163.102 70.9224 162.297 69.4238L158.708 62.856C158.283 62.0752 157.642 61.4337 156.861 61.0085L150.293 57.4197C148.794 56.6149 147.859 55.0516 147.859 53.3506C147.859 51.6496 148.794 50.0862 150.293 49.2815L156.861 45.6927C157.642 45.2675 158.283 44.626 158.708 43.8452L162.297 37.2773Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M214.21 55.1689C214.814 54.045 215.986 53.3438 217.262 53.3438C218.538 53.3438 219.71 54.045 220.314 55.1689L223.005 60.0948C223.324 60.6804 223.805 61.1616 224.391 61.4805L229.317 64.172C230.441 64.7756 231.142 65.9481 231.142 67.2239C231.142 68.4996 230.441 69.6721 229.317 70.2757L224.391 72.9673C223.805 73.2862 223.324 73.7673 223.005 74.3529L220.314 79.2788C219.71 80.4027 218.538 81.104 217.262 81.104C215.986 81.104 214.814 80.4027 214.21 79.2788L211.519 74.3529C211.2 73.7673 210.719 73.2862 210.133 72.9673L205.207 70.2757C204.083 69.6721 203.382 68.4996 203.382 67.2239C203.382 65.9481 204.083 64.7756 205.207 64.172L210.133 61.4805C210.719 61.1616 211.2 60.6804 211.519 60.0948L214.21 55.1689Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M183.626 87.8871C184.33 86.5759 185.698 85.7578 187.186 85.7578C188.675 85.7578 190.043 86.5759 190.747 87.8871L193.887 93.6338C194.259 94.317 194.82 94.8783 195.503 95.2503L201.25 98.3905C202.561 99.0947 203.379 100.463 203.379 101.951C203.379 103.439 202.561 104.807 201.25 105.511L195.503 108.652C194.82 109.024 194.259 109.585 193.887 110.268L190.747 116.015C190.043 117.326 188.675 118.144 187.186 118.144C185.698 118.144 184.33 117.326 183.626 116.015L180.486 110.268C180.114 109.585 179.552 109.024 178.869 108.652L173.122 105.511C171.811 104.807 170.993 103.439 170.993 101.951C170.993 100.463 171.811 99.0947 173.122 98.3905L178.869 95.2503C179.552 94.8783 180.114 94.317 180.486 93.6338L183.626 87.8871Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.717 90.5947C152.717 90.7225 152.613 90.8261 152.485 90.8261C152.358 90.8261 152.254 90.7225 152.254 90.5947C152.254 90.4669 152.358 90.3634 152.485 90.3634"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M152.485 90.3697C152.613 90.3697 152.716 90.4732 152.716 90.601"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.845 39.4697C203.845 39.5975 203.741 39.7011 203.613 39.7011C203.485 39.7011 203.382 39.5975 203.382 39.4697C203.382 39.3419 203.485 39.2384 203.613 39.2384"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M203.613 39.2447C203.674 39.2447 203.733 39.269 203.776 39.3124C203.82 39.3558 203.844 39.4147 203.844 39.476"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M0.297103 108.277C1.10185 106.779 2.66519 105.844 4.36621 105.844C6.06722 105.844 7.63056 106.779 8.43531 108.277L12.0241 114.845C12.4493 115.626 13.0908 116.267 13.8716 116.693L20.4395 120.281C21.9381 121.086 22.873 122.65 22.873 124.351C22.873 126.052 21.9381 127.615 20.4395 128.42L13.8716 132.008C13.0908 132.434 12.4493 133.075 12.0241 133.856L8.43531 140.424C7.63056 141.922 6.06722 142.857 4.36621 142.857C2.66519 142.857 1.10185 141.922 0.297103 140.424L-3.29168 133.856C-3.71689 133.075 -4.35838 132.434 -5.13917 132.008L-11.707 128.42C-13.2056 127.615 -14.1406 126.052 -14.1406 124.351C-14.1406 122.65 -13.2056 121.086 -11.707 120.281L-5.13917 116.693C-4.35838 116.267 -3.71689 115.626 -3.29168 114.845L0.297103 108.277Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M52.2101 126.169C52.8137 125.045 53.9862 124.344 55.2619 124.344C56.5377 124.344 57.7102 125.045 58.3138 126.169L61.0053 131.095C61.3243 131.68 61.8054 132.162 62.391 132.48L67.3169 135.172C68.4408 135.776 69.1421 136.948 69.1421 138.224C69.1421 139.5 68.4408 140.672 67.3169 141.276L62.391 143.967C61.8054 144.286 61.3243 144.767 61.0053 145.353L58.3138 150.279C57.7102 151.403 56.5377 152.104 55.2619 152.104C53.9862 152.104 52.8137 151.403 52.2101 150.279L49.5185 145.353C49.1996 144.767 48.7185 144.286 48.1329 143.967L43.207 141.276C42.0831 140.672 41.3818 139.5 41.3818 138.224C41.3818 136.948 42.0831 135.776 43.207 135.172L48.1329 132.48C48.7185 132.162 49.1996 131.68 49.5185 131.095L52.2101 126.169Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        fillRule="evenodd"
        clipRule="evenodd"
        d="M21.6259 158.887C22.33 157.576 23.6979 156.758 25.1863 156.758C26.6747 156.758 28.0426 157.576 28.7467 158.887L31.8869 164.634C32.2589 165.317 32.8203 165.878 33.5035 166.25L39.2501 169.391C40.5614 170.095 41.3794 171.463 41.3794 172.951C41.3794 174.439 40.5614 175.807 39.2501 176.511L33.5035 179.652C32.8203 180.024 32.2589 180.585 31.8869 181.268L28.7467 187.015C28.0426 188.326 26.6747 189.144 25.1863 189.144C23.6979 189.144 22.33 188.326 21.6259 187.015L18.4857 181.268C18.1137 180.585 17.5523 180.024 16.8691 179.652L11.1225 176.511C9.81124 175.807 8.99316 174.439 8.99316 172.951C8.99316 171.463 9.81124 170.095 11.1225 169.391L16.8691 166.25C17.5523 165.878 18.1137 165.317 18.4857 164.634L21.6259 158.887Z"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M60.121 175.259C60.121 175.387 60.0174 175.49 59.8896 175.49C59.7619 175.49 59.6583 175.387 59.6583 175.259C59.6583 175.131 59.7619 175.027 59.8896 175.027"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M59.8892 175.034C60.017 175.034 60.1205 175.137 60.1205 175.265"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.8446 110.47C41.8446 110.597 41.741 110.701 41.6133 110.701C41.4855 110.701 41.3819 110.597 41.3819 110.47C41.3819 110.342 41.4855 110.238 41.6133 110.238"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M41.6128 110.245C41.6742 110.245 41.733 110.269 41.7764 110.312C41.8198 110.356 41.8442 110.415 41.8442 110.476"
        stroke="#FCF78B"
        strokeOpacity="0.12"
        strokeWidth="6.9375"
        strokeLinecap="round"
        strokeLinejoin="round"
      />
      <path
        d="M143.574 95.7234H133.814V71.0968C133.814 52.627 118.964 37.4104 100.494 37.3595C82.0259 37.3086 66.9853 52.3175 66.9853 70.774V95.7234H57.2258C54.5874 95.7234 52.4478 97.8622 52.4478 100.501V157.704C52.4478 160.343 54.5874 162.482 57.2258 162.482H143.574C146.213 162.482 148.352 160.342 148.352 157.704V100.501C148.352 97.8622 146.212 95.7234 143.574 95.7234ZM119.099 126.162L116.862 141.064C116.859 141.084 116.856 141.105 116.853 141.125C116.407 143.816 114.104 145.77 111.376 145.77H89.424C86.6955 145.77 84.3924 143.816 83.947 141.125C83.9437 141.105 83.9403 141.084 83.9378 141.064L81.7006 126.162C81.4479 124.562 81.8991 122.943 82.941 121.714C84.0037 120.461 85.5536 119.742 87.1927 119.742C88.4798 119.742 89.7385 120.2 90.7378 121.033L92.5863 122.573L95.2456 115.924C96.0939 113.805 98.1167 112.435 100.4 112.435C102.683 112.435 104.706 113.805 105.554 115.924L108.213 122.573L110.062 121.033C111.061 120.2 112.32 119.742 113.607 119.742C115.246 119.742 116.796 120.461 117.859 121.714C118.901 122.943 119.352 124.562 119.099 126.162ZM81.3202 71.0175C81.3202 60.5139 89.706 51.7987 100.209 51.6952C110.815 51.591 119.479 60.1911 119.479 70.774V95.7234H81.3202V71.0175Z"
        fill="url(#paint1_linear_7053_44981)"
      />
      <path
        opacity="0.35"
        d="M131.629 107.664H69.1706C66.5313 107.664 64.3926 109.804 64.3926 112.443V145.753C64.3926 148.392 66.5313 150.532 69.1706 150.532H131.629C134.268 150.532 136.407 148.392 136.407 145.753V112.443C136.407 109.804 134.268 107.664 131.629 107.664ZM119.099 126.158L116.862 141.059C116.859 141.079 116.856 141.1 116.852 141.12C116.407 143.812 114.104 145.766 111.375 145.766H89.4238C86.6953 145.766 84.3922 143.812 83.9468 141.12C83.9434 141.1 83.9401 141.079 83.9376 141.059L81.7004 126.158C81.4476 124.557 81.8989 122.938 82.9408 121.71C84.0035 120.457 85.5533 119.738 87.1924 119.738C88.4795 119.738 89.7383 120.196 90.7376 121.029L92.5861 122.569L95.2453 115.92C96.0937 113.8 98.1165 112.43 100.4 112.43C102.683 112.43 104.705 113.8 105.554 115.92L108.213 122.569L110.062 121.029C111.061 120.196 112.32 119.738 113.607 119.738C115.246 119.738 116.796 120.457 117.858 121.71C118.901 122.938 119.352 124.557 119.099 126.158Z"
        fill="url(#paint2_linear_7053_44981)"
      />
      <path
        d="M99.1188 117.477L95.0327 127.692C94.6844 128.563 93.5878 128.841 92.8675 128.24L88.0682 124.241C87.0856 123.422 85.614 124.264 85.8227 125.526L88.0627 140.448C88.1729 141.114 88.7491 141.603 89.4245 141.603H111.376C112.052 141.603 112.628 141.114 112.738 140.448L114.978 125.526C115.187 124.264 113.715 123.422 112.733 124.241L107.933 128.24C107.213 128.84 106.116 128.563 105.768 127.692L101.682 117.477C101.219 116.32 99.5816 116.32 99.1188 117.477Z"
        fill="url(#paint3_linear_7053_44981)"
      />
      <path
        opacity="0.35"
        d="M90.7501 138.682L89.3076 129.072L90.9985 130.481C91.7702 131.125 92.7467 131.479 93.7481 131.479C95.5184 131.479 97.0867 130.417 97.7436 128.775L100.401 122.133L103.058 128.775C103.715 130.418 105.283 131.479 107.053 131.479C108.055 131.479 109.031 131.125 109.803 130.481L111.493 129.072L110.051 138.682H90.7501Z"
        fill="url(#paint4_linear_7053_44981)"
      />
    </g>
    <defs>
      <linearGradient
        id="paint0_linear_7053_44981"
        x1="-78"
        y1="7"
        x2="298"
        y2="140"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FF7A00" />
        <stop offset="1" stopColor="#D5480C" />
      </linearGradient>
      <linearGradient
        id="paint1_linear_7053_44981"
        x1="100.4"
        y1="37.3594"
        x2="100.4"
        y2="162.482"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCEF64" />
        <stop offset="1" stopColor="#FCC44B" />
      </linearGradient>
      <linearGradient
        id="paint2_linear_7053_44981"
        x1="100.4"
        y1="150.532"
        x2="100.4"
        y2="107.664"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCEF64" />
        <stop offset="1" stopColor="#FCC44B" />
      </linearGradient>
      <linearGradient
        id="paint3_linear_7053_44981"
        x1="100.4"
        y1="116.609"
        x2="100.4"
        y2="141.603"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCEF64" />
        <stop offset="1" stopColor="#FCC44B" />
      </linearGradient>
      <linearGradient
        id="paint4_linear_7053_44981"
        x1="100.401"
        y1="138.682"
        x2="100.401"
        y2="122.133"
        gradientUnits="userSpaceOnUse"
      >
        <stop stopColor="#FCEF64" />
        <stop offset="1" stopColor="#C3993E" />
      </linearGradient>
      <clipPath id="clip0_7053_44981">
        <rect x="0.5" width="200" height="200" rx="100" fill="white" />
      </clipPath>
    </defs>
  </StyledImg>
);

import styled from "styled-components";
import { Image, Nav } from "react-bootstrap";

export const UserName = styled.p`
  margin-bottom: 4px;
  color: #101212;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
`;

export const UserEmail = styled.p`
  margin-bottom: 0;
  color: #a7a7a7;
  font-family: Metropolis;
  font-size: 14px;
  font-weight: 400;
  line-height: 18.2px;
  letter-spacing: -0.32px;
`;

export const PlaceholderPic = styled.div`
  width: 40px;
  height: 40px;
  border-radius: 20px;
  color: #ff6d34;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  background-color: #ffefe8;
  display: flex;
  align-items: center;
  justify-content: center;
`;

export const Userpic = styled.div`
  width: 40px;
  height: 40px;
  img {
    width: 40px;
    height: 40px;
    border-radius: 20px;
  }
`;

export const UserInfo = styled.div`
  display: flex;
  gap: 10px;
  align-items: center;
  cursor: default;
`;

export const NavbarWrapper = styled.div`
  padding: 2rem 0 0;
  justify-content: center;
  align-items: center;
  @media screen and (max-width: 767px) {
    padding: 0;
    margin-top: 16px;
    margin-bottom: 16px;
    nav {
      padding: 0;
      min-height: 44px;
    }
  }
`;

export const LogoIcon = styled(Image)`
  height: 36px;
  margin-right: auto;
  @media screen and (max-width: 360px) {
    height: 28.36px;
  }
`;

export const MenuLink = styled(Nav.Link)`
  display: flex;
  align-items: center;
  font-family: "Metropolis";
  margin: 0 12px;
  font-weight: 500;
  font-size: 16px;
  min-width: 80px;
  justify-content: center;
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const MobileMenuLink = styled.a`
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
    gap: 8px;
    text-decoration: none;
    color: #101212;
    font-size: 16px;
    font-weight: 500;
    line-height: 24px;
    letter-spacing: -0.308px;
  }
`;

export const ButtonWrapper = styled.div`
  margin-left: 12px;
  white-space: nowrap;
  @media screen and (max-width: 767px) {
    margin-left: 0;
  }
`;

export const MenuGroup = styled(Nav)`
  display: flex;
  margin-left: auto;
  .dropdown {
    display: flex;
    margin: 0;
    margin-left: 12px;
  }
  .dropdown-toggle {
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    background-color: transparent;
    border: none;
    padding: 0;
    margin: 0;
    display: flex;
    gap: 12px;
    align-items: center;
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    padding: 0;
    border-radius: 7px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);
  }
  .dropdown-item {
    padding: 16px;
    &:first-child {
      padding-top: 12px;
    }
    &:last-child {
      padding-bottom: 12px;
    }
    &:hover {
      background: var(--BG-for-tools, #fff9f6);
      border-radius: 7px;
    }
  }
  hr {
    height: 1px;
    margin: 0 16px;
    color: #ebebeb;
    opacity: 1;
  }
`;

export const ItemWrapper = styled.div`
  font-weight: 600;
  color: #101212;
  color: #101212;
  @media screen and (max-width: 360px) {
    font-family: Metropolis;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    display: flex;
    align-items: center;
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  display: flex;
  gap: 10px;
  align-items: center;
`;

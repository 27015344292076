import React from 'react'
import { SVGProps, Svg } from '..'

export const PlayIcon: React.FC<SVGProps> = (props) => (
    <Svg
      xmlns="http://www.w3.org/2000/svg"
      width="24"
      height="24"
      viewBox="0 0 24 24"
      fill="none"
      {...props}
    >
      <path fillRule="evenodd" clipRule="evenodd" d="M21 12V12C21 16.971 16.971 21 12 21V21C7.029 21 3 16.971 3 12V12C3 7.029 7.029 3 12 3V3C16.971 3 21 7.029 21 12Z" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
      <path fillRule="evenodd" clipRule="evenodd" d="M10.9406 9.05795L14.8226 11.3539C15.3136 11.6439 15.3136 12.3549 14.8226 12.6449L10.9406 14.9409C10.4406 15.2369 9.80859 14.8759 9.80859 14.2949V9.70395C9.80859 9.12295 10.4406 8.76195 10.9406 9.05795V9.05795Z" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    </Svg>
  );
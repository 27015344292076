import { Form } from "react-bootstrap";
import { useAuth0 } from "@auth0/auth0-react";
import { useFormik } from "formik";
import { useState } from "react";
// Components
import { PrimaryButton } from "../../Buttons";
import { BasicModal } from "../BasicModal";
import { ProcessingCircle } from "../../ProcessingCircle";
// Custom Hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
// Utils
import * as Validator from "@considr-it/storied-utils/src/validators";
// Styles
import {
  FormSection,
  ExternalLink,
  CheckmarkLabel,
  Header,
  ButtonGroupWrapper,
} from "./complete-profile-modal.style";

export default function CompleteProfileModal() {
  const { user } = useAuth0();
  const { transport } = useGlobal();
  const { revalidateAccount, setAccount } = useAccount();

  const { given_name, family_name, email: mainEmail, picture: imageUrl } = user;

  const [checked, setChecked] = useState(false);
  const [submitting, setSubmitting] = useState(false);

  const profileForm = useFormik({
    initialValues: {
      givenName: given_name || "",
      familyName: family_name || "",
    },
    validationSchema: Validator.basic,
    onSubmit: async ({ givenName, familyName }) => {
      if (submitting) return;

      setSubmitting(true);

      await transport.post("/account", {
        mainEmail,
        profile: {
          imageUrl,
          givenName,
          familyName,
          name: `${givenName} ${familyName}`,
        },
      });

      setAccount(null);
      await revalidateAccount();
    },
  });

  return (
    <BasicModal show={true}>
      <Header>Let's complete your profile</Header>
      <FormSection onSubmit={profileForm.handleSubmit}>
        <div>
          <Form.Group className="mb-3">
            <Form.Label htmlFor="given_name">
              First name<span> (required)</span>
            </Form.Label>

            <Form.Control
              type="text"
              disabled={submitting}
              id="given_name"
              value={profileForm.values.givenName}
              placeholder="Enter your first name"
              aria-describedby="passwordHelpBlock"
              onChange={profileForm.handleChange("givenName")}
            />
          </Form.Group>

          <Form.Group className="mb-3">
            <Form.Label htmlFor="family_name">
              Last name<span> (required)</span>
            </Form.Label>
            <Form.Control
              type="text"
              disabled={submitting}
              id="family_name"
              value={profileForm.values.familyName}
              placeholder="Enter your last name"
              aria-describedby="passwordHelpBlock"
              onChange={profileForm.handleChange("familyName")}
            />
          </Form.Group>
        </div>

        <Form.Check
          type="checkbox"
          disabled={submitting}
          label={
            <CheckmarkLabel>
              {`I have read and agreed with `}
              <ExternalLink
                target="_blank"
                href="https://www.vnote.ai/terms-of-service"
              >
                Terms of Service
              </ExternalLink>
              {` and `}
              <ExternalLink
                target="_blank"
                href="https://www.vnote.ai/privacy-policy"
              >
                Privacy Policy
              </ExternalLink>
              {` presented by Vnote`}
            </CheckmarkLabel>
          }
          onChange={() => setChecked((prevChecked) => !prevChecked)}
          id="disabled-custom-switch"
        />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
          }}
        ></div>
      </FormSection>
      <ButtonGroupWrapper>
        {submitting ? (
          <ProcessingCircle />
        ) : (
          <PrimaryButton
            disabled={!checked || !profileForm.isValid || submitting}
            onClick={profileForm.submitForm}
          >
            Finish
          </PrimaryButton>
        )}
      </ButtonGroupWrapper>
    </BasicModal>
  );
}

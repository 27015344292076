import styled from "styled-components";

const StyledButton = styled.button<{
  $isActive: boolean;
}>`
  display: flex;
  justify-content: center;
  align-items: center;
  color: #fff;
  border: 2px solid #fff;
  background: ${(props) => (props.$isActive ? "#101212" : "#FFF")};
  width: 28px;
  height: 28px;
  padding: 0;
  border-radius: 4px;
  svg {
    fill: ${(props) => (props.$isActive ? "#FFF" : "#101212")};
    transition: fill 0.2s ease-in-out;
  }

  &:disabled {
    background: #e3e3e3;
    color: rgba(255, 255, 255, 0.67);
    svg { fill: rgba(255, 255, 255, 0.67); }
    &:hover {
      background: #e3e3e3;
      color: rgba(255, 255, 255, 0.67);
      cursor: not-allowed;
    }
    pointer-events: none;
  }

  &:hover {
    background: ${(props) => (props.$isActive ? "#101212" : "#FFF")};
  }
`;

interface BtnProps {
  children: React.ReactNode;
  tooltip?: string;
  isActive?: boolean;
  [key: string]: any;
}

export const EditorButton: React.FC<BtnProps> = ({
  children,
  tooltip = "",
  isActive = false,
  ...nativeButtonProps
}) => (
  <StyledButton
    title={tooltip}
    type={nativeButtonProps?.type || 'button'}
    $isActive={isActive}
    {...nativeButtonProps}
  >
    {children}
  </StyledButton>
);

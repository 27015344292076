import { useEffect } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import styled from "styled-components";
// Components
import { PrimaryButton } from "../Buttons";
// Utils
import * as Validator from "@considr-it/storied-utils/src/validators";
import { useImageUpload } from "../../hooks/use-image-upload";
// Styles
export const FormSection = styled.form`
  width: 100%;
  display: flex;
  gap: 8px;
  .form-control {
    height: 46px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #c1c1c1;
    &::placeholder {
      color: #9b9b9b;
      font-size: 16px;
      line-height: 130%;
      letter-spacing: 0.32px;
    }
  }
`;
export const ErrorWrapper = styled.p`
  margin-bottom: -4px;
  margin-top: -10px;
  height: 0;
  font-size: 14px;
  color: red;
`;

export const ExternalImageInput = ({ cb }) => {
  const { handleExternalImageUpload, externalImageState } = useImageUpload();
  const imageForm = useFormik({
    initialValues: { link: "" },
    validationSchema: Validator.image,
    onSubmit: async ({ link }) => {
      await handleExternalImageUpload(link, cb);
    },
  });
  const error = imageForm.errors.link;

  useEffect(() => {
    imageForm.setErrors(
      externalImageState.error ? { link: externalImageState.error } : {}
    );
  }, [externalImageState]);

  return (
    <>
      <FormSection onSubmit={imageForm.handleSubmit}>
        <Form.Control
          name="link"
          value={imageForm.values.link}
          onChange={imageForm.handleChange}
          type="text"
          placeholder="Insert your link"
        />
        <PrimaryButton
          disabled={!imageForm.isValid}
          type="submit"
          loading={externalImageState.uploading}
        >
          Upload
        </PrimaryButton>
      </FormSection>
      <ErrorWrapper>{error}</ErrorWrapper>
    </>
  );
};

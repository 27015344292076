import { useMemo } from "react";
import { Accordion, Stack } from "react-bootstrap";
import TypeOrRecordForm from "../../../components/TypeOrRecordForm";
import {
  OutputGenerateReason,
  QuestionType,
  RecordingObjectType,
} from "@considr-it/storied-enums";
import { SpanQA, TitleWrapper, ToolDescription } from "../writing-feedback.style";
import { useDocument } from "../../../hooks/use-document";
import { useGlobal } from "@considr-it/storied-shared";
import { PrimaryButton, SecondaryButton } from "../../../components/Buttons";
import { Idea, Question } from "@considr-it/storied-entities";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import { DialogIcon } from "../../../components/IconsSvg";

const SharpenWithQuestions = ({
  eventKey,
  activeKey,
  disabled,
  generateOutput,
  forMobile = false,
  children = undefined,
}) => {
  const { currentTopic, revalidateTopic } = useDocument();
  const { transport } = useGlobal();
  const { setProcessingOutputType } = useIdeaFlow();

  const questions = useMemo(() => {
    return currentTopic.questions as Question[];
  }, [currentTopic]);

  const unusedAnsweredQuestions = useMemo(() => {
    return questions?.filter(
      (q) => !(q as Question).usedInOutput && !!(q as Question).answer
    );
  }, [questions]);

  const isAllQuestionsUsed = useMemo(() => {
    return (
      questions?.filter((q) => !(q as Question).usedInOutput)?.length === 0
    );
  }, [questions]);

  const ButtonComponent =
    unusedAnsweredQuestions.length > 0 ? PrimaryButton : SecondaryButton;

  const cardBody = (
    <>
      <ToolDescription>
        Answer 1 or more questions and we’ll incorporate your response into
        writing.
      </ToolDescription>
      {questions.map((q: Question, index: number) => {
        let objectType = RecordingObjectType.QUESTION;

        switch (q.type) {
          case QuestionType.ACTION: {
            objectType = RecordingObjectType.ACTION_QUESTION;
            break;
          }
          case QuestionType.PERSPECTIVE: {
            objectType = RecordingObjectType.PERSPECTIVE_QUESTION;
            break;
          }
          case QuestionType.POP: {
            objectType = RecordingObjectType.POP_QUESTION;
            break;
          }
        }

        return (
          <Stack key={index}>
            <p className="mb-0">
              <SpanQA
                style={{ color: "#101212" }}
                data-dd-privacy="mask"
              >
                Q: {q.question}
              </SpanQA>
            </p>
            {!!q.answer && (
              <div>
                <p className="mb-0">
                  <SpanQA
                    style={{ color: "green" }}
                    data-dd-privacy="mask"
                  >
                    A:{" "}
                    {(q.answer as Idea).summary +
                      (q.usedInOutput ? "  *" : "")}
                  </SpanQA>
                </p>
                <br />
              </div>
            )}
            {!q.answer && (
              <TypeOrRecordForm
                disabled={disabled}
                  onEntry={async (objectId: string, transcript: string) => {
                    await transport.post("/answerQuestion", {
                      ideaId: objectId,
                      transcript,
                      questionId: q.id,
                    });

                    await revalidateTopic();
                  }}
                  placeHolderText="Type here or record => "
                objectType={objectType}
              />
            )}
          </Stack>
        );
      })}

      {questions.some((q) => !!q.usedInOutput) && (
        <i>(*) - has been used in generating output</i>
      )}

      {!isAllQuestionsUsed && (
        <ButtonComponent
          fullWidth
          disabled={disabled || unusedAnsweredQuestions?.length === 0}
          onClick={async () => {
            setProcessingOutputType(OutputGenerateReason.QUESTIONS);

            await generateOutput(OutputGenerateReason.QUESTIONS, {
              answeredQuestions: unusedAnsweredQuestions.map(
                (uq) => (uq as Question).id
              ),
            });

            await revalidateTopic();
          }}
        >
          Regenerate writing
        </ButtonComponent>
      )}
    </>
  );

  if (forMobile) {
    return (
      <>
        <TitleWrapper>
          <DialogIcon style={{ marginRight: 8 }} />
          <p>Identify the gaps</p>
          {children}
        </TitleWrapper>
        {cardBody}
      </>
    )
  }

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={
        (activeKey === eventKey ? "accordion-active" : "") +
        " identify-gaps-tour"
      }
    >
      <Accordion.Header>
        <DialogIcon style={{ marginRight: 10 }} $inactive={activeKey !== eventKey} />
        Identify the gaps
      </Accordion.Header>
      <Accordion.Body>
        {cardBody}
      </Accordion.Body>
    </Accordion.Item>
  );
};

export default SharpenWithQuestions;

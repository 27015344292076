import React from 'react'
import { SVGProps, Svg } from '..'

export const CancelIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="15"
    viewBox="0 0 14 15"
    fill="none"
    {...props}
  >
    <path d="M7.02734 0.5C3.18254 0.5 0 3.62785 0 7.47266C0 11.3175 3.18254 14.5 7.02734 14.5C10.8721 14.5 14 11.3175 14 7.47266C14 3.62785 10.8721 0.5 7.02734 0.5ZM10.4787 9.76379C10.7986 10.0837 10.7986 10.6041 10.4787 10.9243C10.1612 11.2414 9.64086 11.2466 9.3182 10.9243L7.02734 8.63258L4.68152 10.9245C4.3616 11.2445 3.84125 11.2445 3.52105 10.9245C3.20113 10.6046 3.20113 10.0843 3.52105 9.76406L5.81246 7.47266L3.52105 5.18125C3.20113 4.86105 3.20113 4.3407 3.52105 4.02078C3.84125 3.70086 4.3616 3.70086 4.68152 4.02078L7.02734 6.31273L9.3182 4.02078C9.63758 3.70141 10.1579 3.70031 10.4787 4.02078C10.7986 4.3407 10.7986 4.86105 10.4787 5.18125L8.18727 7.47266L10.4787 9.76379Z" fill="#D1D1D1"/>
  </Svg>
);
  
    
import { useState, useMemo, useCallback } from "react";
// Components
import { BasicModal } from "../components/Modals/BasicModal";

export const useBasicModal = ({
  withCloseBtn = true,
  onCloseCb = null,
  withLogo = false,
  contentWrapper = null,
  disableOnHide = false,
} = {}) => {
  const [show, setShow] = useState<boolean>(false);
  const closeModal = useCallback(() => {
    setShow(false);
    if (onCloseCb) onCloseCb();
  }, [setShow, onCloseCb]);
  const toggleModal = useCallback(() => setShow(s => !s), [setShow]);

  const BasicModalComponent = useMemo(() => ({ children }) => (
    <BasicModal
      show={show}
      closeModal={closeModal}
      withCloseBtn={withCloseBtn}
      withLogo={withLogo}
      contentWrapper={contentWrapper}
      disableOnHide={disableOnHide}
    >
      {children}
    </BasicModal>
  ), [
      show,
      closeModal,
      onCloseCb,
      withLogo,
      withCloseBtn,
      contentWrapper,
      disableOnHide
    ]);

  return ({
    show,
    setShow,
    toggleModal,
    BasicModal: BasicModalComponent,
  });
};

import styled from "styled-components";

export const FormWrap = styled.div<{ $textareaStyles: boolean }>`
  position: relative;
  display: flex;
  gap: 16px;
  margin-top: 16px;
  margin-bottom: 16px;
  height: 46px;
  min-height: 46px;
  ${({ $textareaStyles }) => $textareaStyles && `
    flex-direction: column;
    height: unset;
    textarea {
      height: 46px;
      max-height: 104px;
      padding-top: 10px;
      resize: none;
      overflow-y: auto;
      &::placeholder {
      }
    }
  `};
`;
export const RecordButtonWrap = styled.div`
  position: absolute;
  right: 0;
  top: 50%;
  transform: translate(0, -50%);
`;
export const WaveArcsSmallCanvas = styled.canvas<{ isVisible: boolean }>`
  width: calc(100% - 98px);
  height: 46px;
  position: absolute;
  bottom: 6px;
  left: 0;
  right: 44px;
  visibility: ${props => props.isVisible ? 'visible' : 'hidden'};
`;

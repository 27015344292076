import { PingResponse } from "@considr-it/storied-entities";
import { Transport } from "@considr-it/storied-shared";
import { Auth0Provider } from "@auth0/auth0-react";
import { Auth0Wrapper } from "./Auth0Wrapper";
import { useEffect } from "react";
import { GlobalProvider } from "@considr-it/storied-shared";
import { Helmet } from "react-helmet";
import packageInfo from "../package.json";

export const RootWrapper = () => {
  useEffect(() => {
    const hostMap = new Map([
      ["storied.work", "app.vnote.ai"],
      ["app.bestoried.io", "app.vnote.ai"],
      ["dev.storied.work", "dev.vnote.ai"],
    ]);

    const newHost = hostMap.get(window.location.host);

    if (!!newHost) {
      window.location.host = newHost;
    }
  }, []);

  const { data: pingData } = Transport.useTransportSWR<PingResponse>(
    Transport.publicTransport,
    "ping",
    {
      refreshInterval: 120000,
    }
  );

  useEffect(() => {
    if (pingData) {
      console.log(`App - ${packageInfo.version} Sv - ${pingData.version}`);
    }
  }, [pingData]);

  if (!pingData) {
    return <></>;
  }

  return (
    <Auth0Provider
      domain={pingData.oAuthDomain}
      clientId={pingData.oAuthId}
      authorizationParams={{
        redirect_uri: window.location.origin,
        audience: pingData.oAuthAudience,
        prompt: "login",
      }}
      cacheLocation={"localstorage"}
      useRefreshTokens={true}
      useRefreshTokensFallback={true}
    >
      {process.env.REACT_APP_ENV === "prod" && (
        <Helmet>
          <script>
            {`(function(h,o,t,j,a,r){
        h.hj=h.hj||function(){(h.hj.q=h.hj.q||[]).push(arguments)};
        h._hjSettings={hjid:${process.env.REACT_APP_HOTJAR_ID},hjsv:6};
        a=o.getElementsByTagName('head')[0];
        r=o.createElement('script');r.async=1;
        r.src=t+h._hjSettings.hjid+j+h._hjSettings.hjsv;
        a.appendChild(r);
    })(window,document,'https://static.hotjar.com/c/hotjar-','.js?sv=');`}
          </script>
        </Helmet>
      )}

      <GlobalProvider pingData={pingData} isAdmin={false}>
        <Auth0Wrapper />
      </GlobalProvider>
    </Auth0Provider>
  );
};

import styled from "styled-components";

export const EditorWrapper = styled.div`
  .remirror-editor {
    font-family: Metropolis;
    letter-spacing: 0.4px;
    word-spacing: 2px;
    overflow: visible;
    white-space: pre-line;
    outline: none;
    box-shadow: none;
    padding: 0;
    & > *:first-child {
      margin-top: 16px;
    }
    h1,
    h2,
    h3,
    h4,
    h5,
    h6 {
      font-weight: 600;
    }
    h1 {
      margin: 28px 0 16px;
      @media screen and (max-width: 767px) {
        font-size: 24px;
      }
    }
    h2 {
      margin: 28px 0 14px;
      @media screen and (max-width: 767px) {
        font-size: 20px;
      }
    }
    h3 {
      margin: 28px 0 12px;
    }
    h4,
    h5,
    h6,
    p,
    blockquote,
    ul,
    form,
    ol {
      margin: 8px 0;
    }
    &:focus {
      box-shadow: none;
    }
    &:active {
      box-shadow: none;
    }
  }
  .remirror-editor-wrapper {
    padding: 0;
    margin-top: 0;
  }
`;

export const ToolbarWrapper = styled.div`
  display: flex;
  align-items: center;
  justify-content: space-between;
`;

import { toast } from "react-toastify";

export const useToast = (isError: boolean = true) => {
  const dismissToasts = () => toast.dismiss();
  const failureToast = (text, props = {}) => {
    if (isError) {
      toast.error(text, {
        ...props,
      });
    } else {
      toast.error(text, { ...props });
    }
  };
  return { failureToast, dismissToasts };
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const SpainFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_102_55780)">
    <g clipPath="url(#clip1_102_55780)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#F93939"/>
    <path d="M20.9048 4H3.09524C1.93807 4 1 4.95513 1 6.13333V17.8667C1 19.0449 1.93807 20 3.09524 20H20.9048C22.0619 20 23 19.0449 23 17.8667V6.13333C23 4.95513 22.0619 4 20.9048 4Z" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 8.26666H23V15.7333H1V8.26666Z" fill="#FFDA2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 10.6368V13.3248C10.4286 14.0715 9.72459 14.6688 8.85716 14.6688H6.76192C5.89659 14.6667 5.19049 14.0661 5.19049 13.3227V10.6347C5.19049 10.0245 5.65982 9.51467 6.30516 9.34934C6.50001 8.79467 7.09925 9.29174 7.80954 9.29174C8.52401 9.29174 9.11906 8.79787 9.31392 9.3504C9.95716 9.52 10.4286 10.0309 10.4286 10.6368Z" fill="#D4AF2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 11.4667H11.4762V14.6667H10.4286V11.4667ZM4.14288 11.4667H5.1905V14.6667H4.14288V11.4667Z" fill="#CBCBCB"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 13.6H11.4762V14.6667H10.4286V13.6ZM4.14288 13.6H5.1905V14.6667H4.14288V13.6Z" fill="#1A47B8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 10.4H11.4762V11.4667H10.4286V10.4ZM4.14288 10.4H5.1905V11.4667H4.14288V10.4Z" fill="#D4AF2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.2381 10.4H7.28572V12H6.2381V10.4ZM8.33334 12.5333H9.38096V14.1333H8.33334V12.5333Z" fill="#AF010D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M8.33331 10.4H9.38093V12H8.33331V10.4Z" fill="#AE6A3E"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.2381 12.5333H7.28572V14.1333H6.2381V12.5333Z" fill="#FFDA2C"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.28572 10.4L6.2381 9.33334H9.38096L8.33334 10.4H7.28572Z" fill="#AF010D"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M7.28571 8.26666H8.33332V9.33333H7.28571V8.26666Z" fill="#D4AF2C"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_102_55780">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_102_55780">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
import { useState, useEffect, Dispatch, SetStateAction } from "react";
import styled from "styled-components";
// Components
import Checkbox from "../Checkbox";
import { CustomStarts } from "../CustomStarts";
// Custom hooks
import { useIdeaFlow } from "../../hooks/use-idea-flow";
// Styles
import { Description } from "./onboarding-carousel.style";
import { useOnboarding } from "../../hooks/use-onboarding";
const InputBlockWrapper = styled.div`
  display: flex;
  align-items: flex-start;
  gap: 10px;
  width: 100%;
  margin-top: 24px;
`;
const Block = styled.div`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 10px;
  color: #101212;
  p {
    margin-bottom: 0;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: -0.352px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      line-height: 18.2px;
      letter-spacing: -0.32px;
    }
  }
  label {
    font-size: 18px;
    font-weight: 500;
    line-height: 23.4px;
    letter-spacing: -0.396px;
    cursor: pointer;
    user-select: none;
    @media screen and (max-width: 767px) {
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.352px;
    }
  }
`;

interface Step5Props {
  setStepDisabled: Dispatch<SetStateAction<boolean>>;
}

export const Step5: React.FC<Step5Props> = ({ setStepDisabled }) => {
  const [checkedNoTemplate, setCheckNoTemplate] = useState<boolean>(false);
  const [checkedCustomStart, setCheckCustomStart] = useState<boolean>(false);
  const { setFinalRedirect } = useOnboarding();

  const { setCustomStartType } = useIdeaFlow();

  const toggleNoTemplate = () => {
    setCheckNoTemplate((v) => !v);
    setCheckCustomStart(false);
  };
  const toggleCustomStart = () => {
    setCheckCustomStart((v) => !v);
    setCheckNoTemplate(false);
  };
  const onSelectCb = () => {
    setCheckCustomStart(true);
    setCheckNoTemplate(false);
  };

  useEffect(() => {
    if (checkedNoTemplate) {
      setCustomStartType(null);
    }
  }, [checkedNoTemplate]);

  useEffect(() => {
    setStepDisabled(!checkedCustomStart && !checkedNoTemplate);
  }, [checkedCustomStart, checkedNoTemplate]);

  useEffect(() => {
    if (checkedCustomStart) {
      setFinalRedirect("/draft");
    } else {
      setFinalRedirect("/");
    }
  }, [checkedCustomStart]);

  return (
    <div>
      <Description>You have 2 options:</Description>
      <InputBlockWrapper>
        <Checkbox
          id="any"
          checked={checkedNoTemplate}
          onChange={toggleNoTemplate}
        />
        <Block>
          <label htmlFor="any">I'm ready to talk</label>
          <p>Describe the format you want and provide the details.</p>
        </Block>
      </InputBlockWrapper>
      <InputBlockWrapper>
        <Checkbox
          id="custom"
          checked={checkedCustomStart}
          onChange={toggleCustomStart}
        />
        <Block>
          <label htmlFor="custom">Start with an outline</label>
          <CustomStarts
            externalOpen={checkedCustomStart}
            dropdownOnly
            onSelectCb={onSelectCb}
            placeholderText="Choose template"
          />
        </Block>
      </InputBlockWrapper>
    </div>
  );
};

import * as yup from "yup";

export const basic = yup.object({
  givenName: yup
    .string()
    .min(1, "First name")
    .required("First name is required"),
  familyName: yup
    .string()
    .min(1, "Last name")
    .required("Last name is required"),
});

export const customWord = yup.object({
  customWord: yup
    .string()
    .matches(/^[A-Za-z]+(?:-[A-Za-z]+)*$/, 'Invalid word format')
    .min(2, 'The word is too short')
    .required('There is no word to submit'),
});

export const name = yup.object({
  givenName: yup
    .string()
    .min(1, "First name must be at least 1 character long")
    .matches(/^[\w\s]*\S[\w\s]*$/, 'First name must contain only alphabets and spaces')
    .required("First name is required"),
  familyName: yup
    .string()
    .min(1, "Last name must be at least 1 character long")
    .matches(/^[\w\s]*\S[\w\s]*$/, 'Last name must contain only alphabets and spaces')
    .required("Last name is required"),
});

export const image = yup.object({
  link: yup
    .string()
    .url('Link must be a valid URL')
    .required('Link is required'),
});

export const mobileConfirmProfile = yup.object({
  givenName: yup.string(),
  familyName: yup.string(),
});

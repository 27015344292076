import { format } from "date-fns";

export const getFormattedDate = (date: string | Date) =>
  format(new Date(date), "MM/dd/yyyy 'at' hh:mma");

export const truncate = (text: string, length: number) =>
  text?.length > length ? `${text.substring(0, length)}...` : text;

export const sortByDate = (entities: { [key: string]: any }[], field: string) =>
  [...entities].sort(
    (a, b) => new Date(b[field]).getTime() - new Date(a[field]).getTime()
  );

export const translate = (n: number) => {
  // Arrays to hold words for single-digit, double-digit, and below-hundred numbers
  const single_digit = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];

  const double_digit = [
    "Ten",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];

  const below_hundred = [
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  // Recursive function to translate the number into words

  let word = "";
  if (n < 10) {
    word = single_digit[n] + " ";
  } else if (n < 20) {
    word = double_digit[n - 10] + " ";
  } else if (n < 100) {
    let rem = translate(n % 10);
    word = below_hundred[(n - (n % 10)) / 10 - 2] + " " + rem;
  } else if (n < 1000) {
    word = single_digit[Math.trunc(n / 100)] + " Hundred " + translate(n % 100);
  } else if (n < 1000000) {
    word = translate(n / 1000).trim() + " Thousand " + translate(n % 1000);
  } else if (n < 1000000000) {
    word = translate(n / 1000000).trim() + " Million " + translate(n % 1000000);
  } else {
    word =
      translate(n / 1000000000).trim() +
      " Billion " +
      translate(n % 1000000000);
  }
  return word;
};

//This replaces the numbers in a string with their spelled version so for example "iPhone 14" -> "iPhone Fourteen"
export const replaceNumbersWithWords = (text: string) => {
  let prevNumber = "";
  let newString = "";

  for (let i = 0; i < text.length; i++) {
    const c = text.charAt(i);
    const numb = Number(c);

    if (numb > 0) {
      prevNumber += c;
    } else {
      if (!!prevNumber) {
        const newNumber = Number(prevNumber);
        prevNumber = "";

        newString += translate(newNumber);
        newString += c + " ";
      } else {
        newString += c;
      }
    }
  }

  if (!!prevNumber) {
    const newNumber = Number(prevNumber);
    newString += translate(newNumber);
  }

  return newString;
};

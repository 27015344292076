import { useCommands, useRemirrorContext } from "@remirror/react";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { useEffect } from "react";
import { Annotation } from "remirror/extensions";

export const AnnotationsUpdater = (state) => {
  const { annotationsList, setAnnotationsList } = useIdeaFlow();
  const { helpers } = useRemirrorContext({
    autoUpdate: false,
  });

  const { setAnnotations } = useCommands();

  useEffect(() => {
    return () => {
      setAnnotationsList({ annotations: [], reason: "" });
    };
  }, []);

  useEffect(() => {
    if (
      annotationsList?.reason === "init" ||
      annotationsList?.reason === "removeAnnotation" ||
      annotationsList?.reason === "addedAnnotation"
    ) {
      setAnnotations(
        annotationsList.annotations.map((annotation) => {
          return {
            id: annotation.id,
            from: annotation.from,
            to: annotation.to,
          };
        })
      );
    }
  }, [annotationsList]);

  useEffect(() => {
    let annotationsUpdated = false;

    const annotations = helpers.getAnnotations() as Annotation[];

    if (annotationsList?.annotations) {
      if (annotations.length !== annotationsList.annotations.length) {
        annotationsUpdated = true;
      } else {
        for (let i = 0; i < annotationsList.annotations.length; i++) {
          const first = annotations[i];
          const second = annotationsList.annotations[i];
          if (
            first.id !== second.id ||
            first.from !== second.from ||
            first.to !== second.to
          ) {
            annotationsUpdated = true;
            break;
          }
        }
      }
    }

    if (annotationsUpdated) {
      setAnnotationsList({
        annotations,
        reason: "editorUpdate",
      });
    }
  }, [state]);

  return <></>;
};

import { useState, ChangeEvent } from "react";
// Custom hooks
import { useConfirmModal } from "./use-confirm-modal";
import { PrimaryButton } from "../components/Buttons";
import { useDocument } from "./use-document";
import { trackError, useGlobal } from "@considr-it/storied-shared";
import {
  ErrorCodes,
  ImageType,
  UploadImageOperationType,
} from "@considr-it/storied-enums";

// Constants
interface ImageUploadState {
  image: string | any | null;
  error: string | null;
  uploading?: boolean;
}
interface UseImageUploadReturn {
  handleLocalImageChange: (
    event: ChangeEvent<HTMLInputElement>
  ) => Promise<void>;
  handleLocalImageRemove: () => Promise<void>;
  handleClickInput: (event: ChangeEvent<HTMLInputElement>) => void;
  handleExternalImageUpload: (imageUrl: string, cb: () => {}) => Promise<void>;
  localImageState: ImageUploadState;
  externalImageState: ImageUploadState;
}
const allowedImgFormats = ["image/jpeg", "image/jpg", "image/png"];
const maxImgSize = 3 * 1024 * 1024;
const possibleErrors = {
  size: "Maximum file size allowed is 3MB.",
  format: "Please select a valid image format (JPG, JPEG, PNG).",
  network: "Failed to fetch the image",
};

export const useImageUpload = (): UseImageUploadReturn => {
  const { currentTopic, revalidateTopic } = useDocument();
  const { transport } = useGlobal();

  const [localImageState, setLocalImageState] = useState<ImageUploadState>({
    image: null,
    error: null,
  });
  const [externalImageState, setExternalImageState] =
    useState<ImageUploadState>({
      image: null,
      error: null,
      uploading: false,
    });

  const { onPresentConfirmModal, onDismiss } = useConfirmModal();

  const handleLocalImageChange = async (event) => {
    const [file] = event.target.files as File[];

    if (!file) return;
    try {
      const { size, type } = file;
      if (!allowedImgFormats.includes(type)) {
        throw new Error(possibleErrors.format);
      }

      if (size > maxImgSize) {
        throw new Error(possibleErrors.size);
      }
      await transport.post(
        `/uploadCoverImage/${UploadImageOperationType.UploadImageOperationType_File}/${currentTopic.id}`,
        file
      );

      await revalidateTopic();
    } catch (error) {
      setLocalImageState({
        image: null,
        error: error.message,
      });
      onPresentConfirmModal({
        confirmationMessage: "Image uploading error",
        description: error.message,
        actions: [<PrimaryButton onClick={onDismiss}>OK</PrimaryButton>],
      });
    }
  };

  const handleLocalImageRemove = async (): Promise<void> => {
    setLocalImageState({ image: null, error: null });

    await transport.delete(
      `/image/${ImageType.TopicEditorTools}/${currentTopic.id}`
    );
  };

  const handleClickInput = (event: ChangeEvent<HTMLInputElement>) => {
    if (localImageState.image && !localImageState.error) {
      return;
    }
    event.target.value = null;
    setLocalImageState({ image: null, error: null });
  };

  const handleExternalImageUpload = async (imageUrl: string, cb: () => {}) => {
    setExternalImageState((state) => ({
      ...state,
      error: null,
      uploading: true,
    }));
    let isError = false;
    try {
      await transport.post(
        `/uploadCoverImage/${UploadImageOperationType.UploadImageOperationType_Link}/${currentTopic.id}`,
        {
          imageUrl,
        }
      );

      await revalidateTopic();

      cb?.();
    } catch (error) {
      trackError("image_upload_error", { error });

      setExternalImageState((state) => ({
        ...state,
        error:
          error?.response?.status == ErrorCodes.INVALID_IMAGE_FILE_TYPE
            ? "Only JPG, JPEG and PNG images are accepted"
            : error.message,
        uploading: false,
      }));

      isError = true;
    }
    if (!isError) {
      setExternalImageState((state) => ({ ...state, uploading: false }));
    }
  };

  return {
    localImageState,
    externalImageState,
    handleLocalImageChange,
    handleLocalImageRemove,
    handleClickInput,
    handleExternalImageUpload,
  };
};

import { useState } from "react";
import Dropdown from 'react-bootstrap/Dropdown';
import { MouseEvent } from 'react';
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Styles
import { 
  MenuButton,
  MenuWrapper 
} from "./three-dots.style";
// Icons
import { RemoveVerIcon, RestoreIcon, BinIcon, ThreeDotsIcon } from "../IconsSvg";



interface ThreeDotsMenuProps {
  disabled: boolean;
  onlyRemove: boolean;
  removeVersionHandler: (event: MouseEvent<HTMLDivElement>) => void | null;
  removeAllVersionsHandler: (event: MouseEvent<HTMLDivElement>) => void | null;
  restoreVersionHandler: (event: MouseEvent<HTMLDivElement>) => void;
}

export const ThreeDotsMenu: React.FC<ThreeDotsMenuProps> = ({
  disabled,
  onlyRemove,
  removeVersionHandler,
  removeAllVersionsHandler,
  restoreVersionHandler,
 }) => {
  const { isSmallMobile } = useGlobal();
  const [menuActive, setMenuActive] = useState<boolean>(false);

  const handleToggle = () => {
    setMenuActive(!menuActive);
  };

  const threeDotsSizes = isSmallMobile ? { width: 20, height: 20 } : { width: 28, height: 28 };
  const restIconsSizes = isSmallMobile ? { width: 20, height: 20 } : { width: 24, height: 24 };

  return (
    <MenuWrapper>
      <Dropdown show={menuActive} onToggle={disabled ? null : handleToggle} align="end">
        <Dropdown.Toggle
          id="dropdown-basic-button"
          variant="transparent"
        >
          <ThreeDotsIcon {...threeDotsSizes}  $disabled={disabled} />
        </Dropdown.Toggle>

        <Dropdown.Menu>
          {onlyRemove ? (
            <Dropdown.Item>
              <MenuButton onClick={removeVersionHandler}>
                <RemoveVerIcon {...restIconsSizes} />
                Delete this version
              </MenuButton>
            </Dropdown.Item>
          ) : (
            <Dropdown.Item>
              <MenuButton onClick={restoreVersionHandler}>
                <RestoreIcon {...restIconsSizes} />
                Restore to this version
              </MenuButton>
            </Dropdown.Item>
          )}
          <hr />
          <Dropdown.Item>
            <MenuButton onClick={removeAllVersionsHandler}>
              <BinIcon {...restIconsSizes} />
              Delete all versions
            </MenuButton>
          </Dropdown.Item>
        </Dropdown.Menu>
      </Dropdown>
    </MenuWrapper>
  );
};

import { useState } from "react";
import { Form } from "react-bootstrap";
import { useFormik } from "formik";
import styled from "styled-components";
// Custom hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
import useConfirmModal from "../../hooks/use-confirm-modal";
// Components
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
// Utils
import * as Validator from "@considr-it/storied-utils/src/validators";
// Icons
import { CancelIcon } from "../../components/IconsSvg";
// Styles
export const CategoryDescription = styled.div`
  color: #6F6F6F;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  margin-top: 16px;
  @media screen and (max-width: 767px) {
    margin-top: -14px;
    margin-bottom: -8px;
    color: #6F6F6F;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: -0.32px;
  }
`;
const FormSection = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  margin-top: 32px;
  width: 100%;
  min-width: 300px;
  .form-group {
    width: 25%;
    min-width: 300px;
    @media screen and (max-width: 767px) {
      width: 100%;
      min-width: 100px;
    }
  }
  @media screen and (max-width: 767px) {
    min-width: 100px;
  }
  .form-control {
    height: 46px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #c1c1c1;
    color: #101212;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: -0.352px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      letter-spacing: -0.308px;
    }
    &::placeholder {
      color: #a7a7a7;
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.352px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        letter-spacing: -0.308px;
      }
    }
`;
const ErrorWrapper = styled.p`
  margin: 0;
  margin-top: -32px;
  margin-bottom: -32px;
  line-height: 32px;
  height: 32px;
  font-size: 14px;
  color: red;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  @media screen and (max-width: 767px) {
    display: block;
  }
`;
const CustomWordsWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
  justify-content: flex-start;
  gap: 16px;
`;
const CustomWord = styled.div`
  display: inline-flex;
  padding: 8px 16px;
  align-items: center;
  gap: 16px;
  border-radius: 100px;
  border: 1px solid #ff6d34;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 767px) {
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: -0.32px;
  }
`;
const IconWrapper = styled.div`
  width: 14px;
  height: 20.8px;
  cursor: pointer;
`;

export const CustomWordsForm = () => {
  const { accountMetaData, revalidateAccountMetaData } = useAccount();
  const { transport } = useGlobal();
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const [loading, setLoading] = useState<boolean>(false);
  const { isMobile } = useGlobal();

  const customWordsForm = useFormik({
    initialValues: {
      customWord: "",
    },
    validationSchema: Validator.customWord,
    onSubmit: async ({ customWord }, actions) => {
      setLoading(true);

      await transport.post("/addCustomWord", { customWord });

      await revalidateAccountMetaData();
      actions.resetForm({ values: { customWord: "" } });

      setLoading(false);
    },
  });

  const exsistingCustomWords: string[] = accountMetaData?.customWords
    ? accountMetaData.customWords
    : [];

  return (
    <>
      <CategoryDescription>
        Add names or specific terms that you want Vnote to spell correctly
      </CategoryDescription>
      <FormSection onSubmit={customWordsForm.handleSubmit}>
        <Form.Group
          className="form-group"
          controlId="customWordsForm.customWord"
        >
          <Form.Control
            name="customWord"
            value={customWordsForm.values.customWord}
            onChange={customWordsForm.handleChange}
            type="text"
            placeholder="Type your word here"
          />
        </Form.Group>

        <ErrorWrapper>{customWordsForm.errors.customWord}</ErrorWrapper>

        <ButtonsWrapper>
          <PrimaryButton
            fullWidth={isMobile}
            disabled={!customWordsForm.isValid}
            type="submit"
            loading={loading}
          >
            Submit
          </PrimaryButton>
        </ButtonsWrapper>

        <ReturnJSX if={exsistingCustomWords.length}>
          <CustomWordsWrapper>
            {exsistingCustomWords.map((w) => (
              <CustomWord key={w}>
                <span>{w}</span>
                <IconWrapper
                  onClick={() => {
                    onPresentConfirmModal({
                      confirmationMessage: `Remove the word "${w}"?`,
                      description:
                        "We will remove this word, so the system will not recognize it anymore in your speech",
                      actions: [
                        <PrimaryButton
                          onClick={async () => {
                            await onDismiss();

                            await transport.post("/removeCustomWord", {
                              customWord: w,
                            });

                            await revalidateAccountMetaData();
                            // TODO implement custom word removing
                          }}
                        >
                          Remove
                        </PrimaryButton>,
                        <SecondaryButton onClick={onDismiss}>
                          Cancel
                        </SecondaryButton>,
                      ],
                    });
                  }}
                >
                  <CancelIcon />
                </IconWrapper>
              </CustomWord>
            ))}
          </CustomWordsWrapper>
        </ReturnJSX>
      </FormSection>
    </>
  );
};

import { useEffect, useRef } from "react";
import { useChainedCommands, useCommands, useHelpers } from "@remirror/react";
import { Selection } from "remirror";
// Styles
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { storiedOrange } from "../../constants";

interface IHighlightComponentProps {
  currentSelection: Selection;
}

export const HighlightingComponent = ({
  currentSelection,
}: IHighlightComponentProps) => {
  const chained = useChainedCommands();
  const { removeTextHighlight } = useCommands();
  const { getTextBetween } = useHelpers();

  const {
    currentHighlighted,
    setCurrentHighlighted,
    magicWandReplace,
    setMagicWandReplace,
    currentSelectedAnnotation,
    setCurrentSelectedAnnotation,
  } = useIdeaFlow();

  const previousSelection = useRef<Selection | { from: number; to: number }>(
    null
  );

  useEffect(() => {
    return () => {
      setCurrentHighlighted(null);
      setCurrentSelectedAnnotation(null);
    };
  }, []);

  useEffect(() => {
    if (currentHighlighted) {
      chained
        .removeTextHighlight({
          selection: { from: 0, to: Number.MAX_VALUE },
        })
        .setTextHighlight(storiedOrange, {
          selection: currentHighlighted.selection,
        })
        .run();
    } else {
      removeTextHighlight({
        selection: { from: 0, to: Number.MAX_VALUE },
      });
    }
  }, [currentHighlighted]);

  useEffect(() => {
    if (currentSelectedAnnotation) {
      setCurrentHighlighted({
        selection: {
          from: currentSelectedAnnotation.from,
          to: currentSelectedAnnotation.to,
        },
        text: currentSelectedAnnotation.text,
      });
    } else {
      setCurrentHighlighted(null);
    }
  }, [currentSelectedAnnotation]);

  useEffect(() => {
    if (currentSelection) {
      setCurrentHighlighted({
        selection: currentSelection,
        text: getTextBetween(currentSelection.from, currentSelection.to),
      });
    } else {
      setCurrentHighlighted(null);
    }
  }, [currentSelection]);

  useEffect(() => {
    if (!!magicWandReplace) {
      const newSelection = {
        from: previousSelection.current.from,
        to:
          previousSelection.current.from +
          magicWandReplace.replacedText.length +
          magicWandReplace.replacedText.split("\n").length,
      };

      chained
        .setContent(magicWandReplace.updatedOutputText)
        //   .setTextHighlight("Highlight", { selection: newSelection })
        //   .setTextColor("HighlightText", { selection: newSelection })
        .run();

      // setCurrentHighlighted({
      //   selection: newSelection,
      //   text: magicWandReplace.replacedText,
      // });

      previousSelection.current = newSelection;

      setMagicWandReplace(null);
    }
  }, [magicWandReplace]);

  return <></>;
};

import { createContext, useContext, useEffect, useState } from "react";
import {
  getCustomStartTypeByUrlName,
  Transport,
} from "@considr-it/storied-shared";
import { Image, Output } from "@considr-it/storied-entities";
import { useGlobal } from "@considr-it/storied-shared";
import { useDocument } from "./use-document";
import {
  CustomStartType,
  ImageType,
  OutputGenerateReason,
} from "@considr-it/storied-enums";
import { useMakeRecord } from "./use-make-record";
import { useSearchParams } from "react-router-dom";
import { Annotation } from "remirror/extensions";

export enum WRITING_TAB_TYPE {
  TRANSCRIPT_TAB,
  AI_WRITING_TAB,
}

export const useIdeaFlowProvider = () => {
  const [searchParams, setSearchParams] = useSearchParams();

  const [initialisingWritingFeedback, setInitialisingWritingFeedback] =
    useState<boolean>(false);

  const [magicWandReplace, setMagicWandReplace] = useState<{
    replacedText: string;
    updatedOutputText: string;
  }>(null);

  const [currentHighlighted, setCurrentHighlighted] = useState<{
    selection: { from: number; to: number };
    text: string;
  }>(null);

  const [annotationsList, setAnnotationsList] = useState<{
    annotations: Annotation[];
    reason: string;
  }>({ annotations: [], reason: "" });

  const [currentSelectedAnnotation, setCurrentSelectedAnnotation] =
    useState<Annotation>(null);

  const { transport } = useGlobal();
  const { currentTopic } = useDocument();
  const [customStartType, setCustomStartType] = useState<CustomStartType>();

  const [outputIndex, setOutputIndex] = useState<number>(-1);

  const [writingTab, setWritingTab] = useState<WRITING_TAB_TYPE>(
    WRITING_TAB_TYPE.AI_WRITING_TAB
  );

  const [processingOutputType, setProcessingOutputType] =
    useState<OutputGenerateReason>(null);
  const [streaming, setStreaming] = useState<boolean>(false);

  const [deletingOutput, setDeletingOutput] = useState<boolean>(false);
  const [updatingOutput, setUpdatingOutput] = useState<boolean>(false);

  const {
    data: currentOutput,
    revalidate: revalidateOutput,
    isLoading: isLoadingOutput,
  } = Transport.useTransportSWR<Output>(
    transport,
    outputIndex > -1 &&
      !!currentTopic &&
      currentTopic.outputs?.length > outputIndex
      ? `/output/${currentTopic.outputs[outputIndex]}`
      : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: true,
      revalidateOnMount: true,
    }
  );

  const {
    data: coverImage,
    revalidate: revalidateCoverImage,
    isLoading: isLoadingCoverImage,
  } = Transport.useTransportSWR<Image>(
    transport,
    !!currentTopic ? `/image/${ImageType.TopicCover}/${currentTopic.id}` : null,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      revalidateOnMount: true,
    }
  );

  // useEffect(() => {
  //   setCurrentHighlighted(null);
  // }, [currentOutput]);

  useEffect(() => {
    const customStartTypeUrl = searchParams.get("t");
    const customStartType = getCustomStartTypeByUrlName(customStartTypeUrl);

    if (customStartType) {
      setCustomStartType(customStartType);

      setSearchParams((params) => {
        params.delete("t");
        return params;
      });
    }
  }, []);

  const record = useMakeRecord(customStartType);

  return {
    initialisingWritingFeedback,
    setInitialisingWritingFeedback,

    processingOutputType,
    setProcessingOutputType,

    outputIndex,
    setOutputIndex,

    currentOutput,
    revalidateOutput,
    isLoadingOutput,

    streaming,
    setStreaming,

    updatingOutput,
    setUpdatingOutput,

    deletingOutput,
    setDeletingOutput,

    coverImage,
    revalidateCoverImage,

    isLoadingCoverImage,

    writingTab,
    setWritingTab,

    currentHighlighted,
    setCurrentHighlighted,

    annotationsList,
    setAnnotationsList,

    currentSelectedAnnotation,
    setCurrentSelectedAnnotation,

    customStartType,
    setCustomStartType,

    magicWandReplace,
    setMagicWandReplace,

    record,
  };
};

export const IdeaFlowProvider = ({ children }) => {
  const ideaFlowProvider = useIdeaFlowProvider();

  return (
    <IdeaFlowContext.Provider value={ideaFlowProvider}>
      {children}
    </IdeaFlowContext.Provider>
  );
};

export type IdeaFlowProps = ReturnType<typeof useIdeaFlowProvider>;
export const IdeaFlowContext = createContext<IdeaFlowProps>(null);
export const useIdeaFlow = () => useContext(IdeaFlowContext);

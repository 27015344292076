import styled from "styled-components";

export const LampHintWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  gap: 8px;
  p {
    margin-bottom: 0;
    font-family: "Metropolis", sans-serif;
    color: #6f6f6f;
    font-weight: 400;
    font-size: 14px;
    line-height: 18px;
    letter: -2.2%;
  }
`;

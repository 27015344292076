import { useRef, useState } from "react";
import { Form } from "react-bootstrap";
import { BsPencil } from "react-icons/bs";
import { useFormik } from "formik";
import styled from "styled-components";
// Custom hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
// Components
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
// Constants
import { storiedOrange } from "../../constants";
// Utils
import * as Validator from "@considr-it/storied-utils/src/validators";
// Styles
const FormSection = styled.form`
  width: 100%;
  display: flex;
  flex-direction: column;
  gap: 32px;
  .form-control {
    height: 46px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #c1c1c1;
    color: #101212;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: -0.352px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 400;
      line-height: 18.2px;
      letter-spacing: -0.308px;
    }
    &::placeholder {
      color: #a7a7a7;
      font-size: 16px;
      line-height: 20.8px;
      letter-spacing: -0.352px;
      @media screen and (max-width: 767px) {
        font-size: 14px;
        font-weight: 400;
        line-height: 18.2px;
        letter-spacing: -0.308px;
      }
    }
  }
  .form-label {
    margin-bottom: 6px;
    color: #101212;
    font-size: 16px;
    font-weight: 500;
    line-height: 20.8px;
    letter-spacing: -0.352px;
    @media screen and (max-width: 767px) {
      font-size: 14px;
      font-weight: 500;
      line-height: 18.2px;
      letter-spacing: -0.308px;
    }
  }
  .form-group {
    position: relative;
  }
  .pencil-icon {
    position: absolute;
    top: 42px;
    right: 14px;
  }
`;
const ErrorWrapper = styled.p`
  margin: 0;
  margin-top: -32px;
  margin-bottom: -32px;
  line-height: 32px;
  height: 32px;
  font-size: 14px;
  color: red;
`;
const ControlsWrapper = styled.div`
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-gap: 32px;
  margin-top: 32px;
  width: 80%;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr;
    margin-top: 0;
    width: 100%;
    gap: 16px;
  }
`;
const ButtonsWrapper = styled.div`
  display: grid;
  width: calc(40% - 16px);
  grid-template-columns: 0.7fr 1fr;
  gap: 16px;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 1fr;
    width: 100%;
  }
`;

export const PersonalInfoForm = () => {
  const { account, revalidateAccount } = useAccount();
  const { transport } = useGlobal();
  const givenNameRef = useRef(null);
  const familyNameRef = useRef(null);
  const [loading, setLoading] = useState<boolean>(false);

  const nameForm = useFormik({
    initialValues: {
      givenName: account?.profile?.givenName,
      familyName: account?.profile?.familyName,
    },
    validationSchema: Validator.name,
    onSubmit: async ({ givenName, familyName }, actions) => {
      setLoading(true);

      await transport.patch("/account", {
        payload: {
          profile: {
            imageUrl: account.profile.imageUrl,
            givenName,
            familyName,
            name: `${givenName} ${familyName}`,
          },
        },
      });

      await revalidateAccount();
      actions.resetForm({ values: { givenName, familyName } });

      setLoading(false);
    },
  });

  const errorGivenName = nameForm.errors.givenName;
  const errorFamilyName = nameForm.errors.familyName;

  return (
    <>
      <FormSection onSubmit={nameForm.handleSubmit}>
        <ControlsWrapper>
          <Form.Group className="form-group" controlId="nameForm.givenName">
            <Form.Label>First name</Form.Label>
            <Form.Control
              name="givenName"
              value={nameForm.values.givenName}
              onChange={nameForm.handleChange}
              type="text"
              placeholder="Enter your first name"
              ref={givenNameRef}
            />
            <BsPencil
              size="18px"
              onClick={() => givenNameRef.current?.focus()}
              className="pencil-icon"
              style={{
                color: storiedOrange,
                cursor: "pointer",
              }}
            />
          </Form.Group>
          <Form.Group className="form-group" controlId="nameForm.familyName">
            <Form.Label>Last name</Form.Label>
            <Form.Control
              name="familyName"
              value={nameForm.values.familyName}
              onChange={nameForm.handleChange}
              type="text"
              placeholder="Enter your last name"
              ref={familyNameRef}
            />
            <BsPencil
              size="18px"
              onClick={() => familyNameRef.current?.focus()}
              className="pencil-icon"
              style={{
                color: storiedOrange,
                cursor: "pointer",
              }}
            />
          </Form.Group>
        </ControlsWrapper>

        <ErrorWrapper>{errorGivenName || errorFamilyName}</ErrorWrapper>

        <ReturnJSX if={nameForm.dirty}>
          <ButtonsWrapper>
            <SecondaryButton fullWidth onClick={nameForm.handleReset}>
              Cancel
            </SecondaryButton>
            <PrimaryButton
              fullWidth
              disabled={!nameForm.isValid}
              type="submit"
              loading={loading}
            >
              Save changes
            </PrimaryButton>
          </ButtonsWrapper>
        </ReturnJSX>
      </FormSection>
    </>
  );
};

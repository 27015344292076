import React from 'react'
import { SVGProps, Svg } from '..'

export const RocketIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M5 3V3.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M5 7V6.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M7 5H6.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3 5H3.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19 17V17.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19 21V20.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M21 19H20.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M17 19H17.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.99998 20L4.35353 19.6465" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.82815 20L6.47459 19.6465" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M3.99998 17.1716L4.35353 17.5252" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.99987 17L6.47474 17.5251" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M6.39258 14H14.0001" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M9.5 10.5H17.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.1303 9.86945L10.9139 17.0858C10.1329 17.8668 8.86654 17.8668 8.08549 17.0858L6.91391 15.9142C6.13286 15.1332 6.13286 13.8669 6.91391 13.0858L14.1256 5.87415" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M17.7678 9.61433L14.386 6.23259C14.012 5.85856 13.9001 5.29604 14.1025 4.80734C14.3049 4.31864 14.7818 4 15.3108 4H18.6925C19.0394 4 19.372 4.13779 19.6173 4.38305C19.8626 4.62832 20.0004 4.96096 20.0004 5.30782V8.68956C20.0004 9.21852 19.6817 9.6954 19.193 9.89783C18.7043 10.1003 18.1418 9.98837 17.7678 9.61433Z" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
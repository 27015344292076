export function removeTextOutsideBraces(input: string): string {
  const firstBraceIndex = input.indexOf("{");
  const lastBraceIndex = input.lastIndexOf("}");

  const firstBracketIndex = input.indexOf("[");
  const lastBracketIndex = input.lastIndexOf("]");

  if (
    (firstBraceIndex === -1 || lastBraceIndex === -1) &&
    (firstBraceIndex === -1 || lastBracketIndex === -1)
  ) {
    return input;
  }

  return input.slice(
    Math.min(firstBraceIndex, firstBracketIndex),
    Math.max(lastBraceIndex, lastBracketIndex) + 1
  );
}

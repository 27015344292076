import {
  CustomStartType,
  OutputGenerateReason,
  OutputState,
  SocketNamespace,
} from "@considr-it/storied-enums";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Accordion, Stack } from "react-bootstrap";
import { FiPlus } from "react-icons/fi";
import { useNavigate, useParams } from "react-router-dom";
import socketIOClient, { Socket } from "socket.io-client";
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { useConfirmModal } from "../../hooks/use-confirm-modal";
import { useUpgradeToProModal } from "../../hooks/use-upgrade-to-pro-modal";
import MyCard from "../../components/MyCard";
import { OutputViewer } from "../../components/OutputViewer";
import { ProcessingCircle } from "../../components/ProcessingCircle";
import { trackError, trackEvent, useAccount } from "@considr-it/storied-shared";
import { useDocument } from "../../hooks/use-document";
import { useGlobal } from "@considr-it/storied-shared";
import { useIdeaFlow, WRITING_TAB_TYPE } from "../../hooks/use-idea-flow";
import ChooseWritingStyle from "./WritingFeedbackSettings/choose-writing-style";
import ClarifyMainIdea from "./WritingFeedbackSettings/clarify-main-idea";
import LetMeExplain from "./WritingFeedbackSettings/let-me-explain";
import SharpenWithQuestions from "./WritingFeedbackSettings/sharpen-with-questions";
import {
  PanelLabel,
  SummaryBanner,
  WritingFeedbackWrapper,
  ButtonsWrapper,
  RestoreThisVersionPanelWrapper,
  RestoreVerIconWrapper,
  RestoreVerButtonWrapper,
} from "./writing-feedback.style";
import { WritingProgress } from "../../components/WritingProgress";
import GenerateCoverImage from "./WritingFeedbackSettings/generate-cover-image";
import { ArrowIcon, EditAIIcon, FileIcon } from "../../components/IconsSvg";
import { LoadingAnimation } from "../../components/LoadingAnimation";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
import { MobileFeedback } from "../../components/MobileFeedback";
import { ConfirmationModal } from "../../components/Modals/ConfirmModal";
import { StickyWrapper } from "../../components/StickyWrapper";
import { useFeaturesLocking } from "../../hooks/use-features-locking";

export const WritingFeedback = () => {
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const { onPresent: showUpgradeToProModal } = useUpgradeToProModal();
  const {
    initialisingWritingFeedback,
    setInitialisingWritingFeedback,
    processingOutputType,
    setProcessingOutputType,
    outputIndex,
    setOutputIndex,
    currentOutput,
    revalidateOutput,
    isLoadingOutput,
    streaming,
    setStreaming,
    deletingOutput,
    setDeletingOutput,
    updatingOutput,
    writingTab,
    setWritingTab,
  } = useIdeaFlow();
  const { isMobile, transport } = useGlobal();

  const { topicId, outputId } = useParams();
  const outputProcessingTimeout = useRef(null);

  const {
    currentTopic,
    revalidateTopic,
    isLoadingTopic,
    currentTopicId,
    setCurrentTopicId,
    refreshTopicsCount,
  } = useDocument();

  const { account, login } = useAccount();

  const healthCheckTimeoutRef = useRef(null);
  const socketRef = useRef<Socket>(null);
  const accordionRef = useRef(null);
  const [streamedOutput, setStreamedOutput] = useState<string>("");
  const [activeKey, setActiveKey] = useState<string>("");
  const [outputState, setOutputState] = useState<OutputState>(null);
  const { isLocked } = useFeaturesLocking();

  const navigate = useNavigate();

  useEffect(() => {
    if (!isLocked) {
      setActiveKey("4");
      return;
    }
    if (isLocked && accordionRef.current) {
      const accordionButtons =
        accordionRef.current.querySelectorAll(".accordion-button");
      accordionButtons.forEach((button) => {
        button.classList.add("accordionLocked");
      });
    }
  }, [isLocked, accordionRef.current]);

  useEffect(() => {
    setCurrentTopicId(topicId);

    return () => {
      closeSocket();
    };
  }, []);

  useEffect(() => {
    setOutputState(currentOutput?.state);
  }, [currentOutput]);

  useEffect(() => {
    if (outputState === OutputState.FINISHED && !initialisingWritingFeedback) {
      setProcessingOutputType(null);

      if (outputProcessingTimeout.current) {
        clearTimeout(outputProcessingTimeout.current);
        outputProcessingTimeout.current = null;
      }
    }
  }, [outputState, initialisingWritingFeedback]);

  useEffect(() => {
    if (
      outputState === OutputState.PROCESSING &&
      !!currentOutput &&
      !initialisingWritingFeedback &&
      !streaming
    ) {
      setProcessingOutputType(currentOutput.reason);
      outputProcessingTimeout.current = setTimeout(revalidateOutput, 10000);
    }
  }, [outputState, currentOutput, streaming, initialisingWritingFeedback]);

  useEffect(() => {
    if (
      !!currentOutput &&
      !!currentTopic?.fromFake &&
      initialisingWritingFeedback &&
      !streaming
    ) {
      setStreaming(true);
      setStreamedOutput("");
      setInitialisingWritingFeedback(false);

      const allWords = currentOutput.text.split(" ");
      const fakeOutputStreamInterval = setInterval(() => {
        setStreamedOutput((prevOutput) => {
          let newOutput = prevOutput + allWords.shift();

          if (allWords.length === 0) {
            revalidateTopic();
            clearInterval(fakeOutputStreamInterval);
            setStreaming(false);
          } else {
            newOutput += " ";
          }

          return newOutput;
        });
      }, 50);
    }
  }, [currentTopic, currentOutput, streaming]);

  useEffect(() => {
    if (!!currentTopic) {
      if (
        initialisingWritingFeedback &&
        !currentTopic.fromFake &&
        !streaming &&
        !processingOutputType
      ) {
        if (
          !!currentTopic.customStartType &&
          currentTopic.customStartType != CustomStartType.TAKE_A_NOTE
        ) {
          generateOutput(OutputGenerateReason.CUSTOM_START);
        } else {
          generateOutput(OutputGenerateReason.DEFAULT);
        }
      } else if (
        !initialisingWritingFeedback &&
        currentTopic.outputs.length === 0 &&
        !processingOutputType &&
        !streaming
      ) {
        handleOutputError("client_output_missing");
      } else {
        setOutputIndex(currentTopic.outputs.length - 1);
      }
    }
  }, [
    currentTopic,
    processingOutputType,
    streaming,
    initialisingWritingFeedback,
  ]);

  useEffect(() => {
    if (!outputId) {
      if (writingTab === WRITING_TAB_TYPE.TRANSCRIPT_TAB) {
        setOutputIndex(0);
      } else if (currentTopic?.outputs?.length > 1) {
        setOutputIndex(currentTopic.outputs.length - 1);
      }
    }
  }, [writingTab]);

  useEffect(() => {
    if (currentTopic) {
      if (outputId) {
        setOutputIndex(() => {
          const outputIndex = currentTopic?.outputs?.indexOf(outputId as any);
          if (outputIndex === 0) {
            setWritingTab(WRITING_TAB_TYPE.TRANSCRIPT_TAB);
          }

          return outputIndex;
        });

        navigate(`/topic/${currentTopic.id}` + window.location.search);
      }
    } else {
      setWritingTab(WRITING_TAB_TYPE.AI_WRITING_TAB);
    }
  }, [outputId, currentTopic]);

  const generateOutput = useCallback(
    async (reason: OutputGenerateReason, config = {}) => {
      setProcessingOutputType(reason);
      // healthCheckTimeoutRef.current = setTimeout(() => {
      //   handleOutputError("timeout");

      //   closeSocket();
      // }, 15000);

      await initializeSocket();
      setStreamedOutput("");

      setWritingTab(WRITING_TAB_TYPE.AI_WRITING_TAB);

      try {
        await transport.post("/generateOutput", {
          topicId: currentTopic.id,
          socketId: socketRef.current?.id,
          reason: reason,
          ...config,
        });

        await revalidateTopic();
        setInitialisingWritingFeedback(false);
      } catch (err) {
        closeSocket();
      }
    },
    [currentTopic, outputIndex, streaming, processingOutputType, transport]
  );

  const closeSocket = async () => {
    if (healthCheckTimeoutRef.current) {
      clearTimeout(healthCheckTimeoutRef.current);
    }

    socketRef.current?.close();
    socketRef.current?.disconnect();
    socketRef.current = null;

    revalidateOutput();

    setStreaming(false);
    setProcessingOutputType(null);
    setInitialisingWritingFeedback(false);
    setOutputState(OutputState.FINISHED);
  };

  const handleOutputError = (reason: any) => {
    trackError("client_socket_output_stream_error", null, {
      outputId: currentOutput?.id,
      topicId: currentTopic?.id,
      reason,
    });

    onPresentConfirmModal({
      confirmationMessage: "Unexpected error while generating output",
      actions: [
        processingOutputType === OutputGenerateReason.CUSTOM_START && (
          <PrimaryButton
            onClick={() => {
              onDismiss();
              generateOutput(OutputGenerateReason.CUSTOM_START);
            }}
          >
            Retry
          </PrimaryButton>
        ),
        <SecondaryButton
          onClick={() => {
            window.location.reload();
          }}
        >
          Close
        </SecondaryButton>,
      ],
    });
  };

  const initializeSocket = () => {
    return new Promise((resolve) => {
      let startTime = Date.now();

      socketRef.current = socketIOClient(
        process.env.REACT_APP_SERVER + SocketNamespace.OutputStream,
        {
          withCredentials: true,
          transports: ["websocket"],
        }
      );

      socketRef.current.on("connect", () => {
        let socketInitializationDuration = (Date.now() - startTime) / 1000;
        setStreaming(true);

        trackEvent("socket_initialized", { socketInitializationDuration });

        if (healthCheckTimeoutRef.current) {
          clearTimeout(healthCheckTimeoutRef.current);
        }

        // healthCheckTimeoutRef.current = setTimeout(() => {
        //   handleOutputError("timeout");

        //   closeSocket();
        // }, 15000);

        resolve(true);
      });

      socketRef.current.on("connect_error", (error) => {
        trackError("socket_connect_error", error);
        revalidateTopic();

        closeSocket();

        resolve(true);
      });

      socketRef.current.on("data", (data) => {
        //clearTimeout(healthCheckTimeoutRef.current);
        // healthCheckTimeoutRef.current = setTimeout(() => {
        //   handleOutputError("timeout");

        //   closeSocket();
        // }, 15000);

        setStreamedOutput((prevOutput) => prevOutput + data);
      });

      socketRef.current.on("output_error", () => {
        handleOutputError("output_error");

        revalidateTopic();
        closeSocket();
      });

      socketRef.current.on("output_done", () => {
        closeSocket();
      });

      socketRef.current.on("disconnect", async (reason) => {
        if (reason !== "io client disconnect") {
          handleOutputError(reason);

          revalidateTopic();
          closeSocket();
        }
      });
    });
  };

  const deleteOutput = async () => {
    setDeletingOutput(true);
    onDismiss();

    await transport.delete(`/output/${currentOutput.id}`);
    await revalidateTopic();

    setDeletingOutput(false);
  };

  const deleteTopic = async () => {
    setDeletingOutput(true);
    onDismiss();
    await transport.delete(`/topic/${topicId}`);

    refreshTopicsCount();
    setCurrentTopicId(null);

    setDeletingOutput(false);
    navigate("/documents");
  };

  const restoreVersion = async () => {
    setDeletingOutput(true);
    onDismiss();

    await transport.post(
      `/restoreVersion/${currentTopic.id}/${currentOutput.id}`
    );

    await revalidateTopic();

    setDeletingOutput(false);
  };

  const restoreVersionModal = () => {
    onPresentConfirmModal({
      confirmationMessage: "Are you sure you want to restore this version?",
      description:
        "Restoring this version will delete all versions after this draft.",
      actions: [
        <PrimaryButton onClick={restoreVersion}>Restore</PrimaryButton>,
        <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>,
      ],
    });
  };

  const RestoreThisVersionPanel = (
    <RestoreThisVersionPanelWrapper>
      <RestoreVerIconWrapper>
        <FileIcon width={35} height={35} />
      </RestoreVerIconWrapper>
      <RestoreVerButtonWrapper>
        <div>
          Sorry, but only the last version can be edited. Would you like to
          restore to this version?
        </div>
        <SecondaryButton
          onClick={restoreVersionModal}
          style={{ maxWidth: 200 }}
        >
          Restore to this version
        </SecondaryButton>
      </RestoreVerButtonWrapper>
    </RestoreThisVersionPanelWrapper>
  );

  const EditorToolsPanelContent = useMemo(() => {
    return (
      <Accordion
        ref={accordionRef}
        activeKey={activeKey}
        onSelect={
          isLocked ? showUpgradeToProModal : (e) => setActiveKey(e?.[0])
        }
        className="mt-4 mx-2"
      >
        <div className="editor-tools-questions">
          <SharpenWithQuestions
            disabled={processingOutputType || streaming}
            eventKey="0"
            activeKey={activeKey}
            generateOutput={generateOutput}
          />
        </div>

        <div className="editor-tools-main-idea">
          <ClarifyMainIdea
            disabled={processingOutputType || streaming}
            eventKey="1"
            activeKey={activeKey}
            generateOutput={generateOutput}
          />
        </div>

        <div className="editor-tools-document-type">
          <ChooseWritingStyle
            disabled={processingOutputType || streaming}
            eventKey="2"
            activeKey={activeKey}
            generateOutput={generateOutput}
          />
        </div>

        <div className="editor-tools-generate-image">
          <GenerateCoverImage eventKey="3" activeKey={activeKey} />
        </div>

        <div className="editor-tools-let-me-explain">
          <LetMeExplain
            disabled={processingOutputType || streaming}
            eventKey="4"
            activeKey={activeKey}
            generateOutput={generateOutput}
          />
        </div>
      </Accordion>
    );
  }, [
    activeKey,
    currentTopic,
    currentOutput,
    streaming,
    processingOutputType,
    isLocked,
  ]);

  if (initialisingWritingFeedback) {
    return (
      <Stack className="processing-circle-wrapper">
        <ProcessingCircle
          text="Transforming your ideas into writing..."
          subtext="It may take a few seconds! ☺️"
          customAnimation
        />
      </Stack>
    );
  }

  if (isLoadingTopic || !currentTopicId) {
    return <LoadingAnimation circle />;
  }

  if (!currentTopic) {
    if (account.isAnonymous && account.loggedInHistory.length === 0) {
      return (
        <ConfirmationModal
          params={{
            confirmationMessage: "Login to access your document!",
            actions: [
              <div>
                <PrimaryButton fullWidth onClick={() => login("login")}>
                  Login
                </PrimaryButton>
              </div>,
            ],
            withCloseBtn: false,
            disableOnHide: true,
          }}
        />
      );
    } else {
      return (
        <h2>
          This content does not exist or {"\n"}
          you do not have permission {"\n"}
          to view it!
        </h2>
      );
    }
  }

  return (
    <>
      <ReturnJSX if={isMobile}>
        <ButtonsWrapper>
          <SecondaryButton
            fullWidth
            noIconFill
            style={{
              border: "none",
              paddingLeft: 0,
              paddingRight: 0,
              justifyContent: "flex-start",
            }}
            onClick={() => navigate("/documents")}
          >
            <ArrowIcon />
            Back to all docs
          </SecondaryButton>
          <SecondaryButton fullWidth onClick={() => navigate("/")}>
            <FiPlus size="1.3rem" />
            New record
          </SecondaryButton>
        </ButtonsWrapper>
      </ReturnJSX>
      <ReturnJSX if={!isMobile}>
        <SummaryBanner>
          <WritingProgress />
          <SecondaryButton onClick={() => navigate("/")}>
            <FiPlus size="1.3rem" />
            Start a new recording
          </SecondaryButton>
        </SummaryBanner>
      </ReturnJSX>
      <WritingFeedbackWrapper>
        {isMobile &&
          (!!streamedOutput || !!currentOutput?.text) &&
          !updatingOutput &&
          !deletingOutput &&
          !(
            currentTopic?.outputs?.length === 1 &&
            writingTab === WRITING_TAB_TYPE.TRANSCRIPT_TAB
          ) && (
            <MobileFeedback
              isLastOutput={currentTopic?.outputs?.length - 1 === outputIndex}
              currentTopic={currentTopic}
              processingOutputType={processingOutputType}
              streaming={streaming}
              generateOutput={generateOutput}
              restoreVersionModal={restoreVersionModal}
            />
          )}
        {!processingOutputType &&
        currentTopic?.outputs?.length > 0 &&
        !currentOutput &&
        isLoadingOutput ? (
          <ProcessingCircle subtext={"Loading Output"} />
        ) : (
          <OutputViewer
            generateOutput={generateOutput}
            text={
              outputState === OutputState.PROCESSING || streaming
                ? streamedOutput
                : currentOutput?.text
            }
            disabled={streaming || !!processingOutputType || deletingOutput}
            totalPageCount={currentTopic.outputs.length - 1}
            currentPageNumber={outputIndex}
            onPrev={() => {
              setOutputIndex((prev) => prev - 1);
            }}
            onNext={() => {
              setOutputIndex((prev) => prev + 1);
            }}
            deleteOutput={() => {
              onPresentConfirmModal({
                confirmationMessage:
                  "Are you sure you want to delete this version?",
                description: "This action cannot be undone.",
                actions: [
                  <PrimaryButton onClick={deleteOutput}>Delete</PrimaryButton>,
                  <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>,
                ],
              });
            }}
            deleteTopic={() => {
              onPresentConfirmModal({
                confirmationMessage:
                  "Are you sure you want to delete all versions?",
                description: "This action cannot be undone.",
                actions: [
                  <PrimaryButton onClick={deleteTopic}>
                    Delete All Versions
                  </PrimaryButton>,
                  <SecondaryButton onClick={onDismiss}>Cancel</SecondaryButton>,
                ],
              });
            }}
            restoreVersion={restoreVersionModal}
          />
        )}
        {!isMobile &&
          !(
            currentTopic?.outputs?.length === 1 &&
            writingTab === WRITING_TAB_TYPE.TRANSCRIPT_TAB
          ) && (
            <StickyWrapper>
              <MyCard>
                <PanelLabel>
                  <EditAIIcon />
                  Edit with AI
                </PanelLabel>
                <p className="ms-2">Improve the writing with the tools below</p>
                {currentTopic?.outputs?.length - 1 === outputIndex
                  ? EditorToolsPanelContent
                  : RestoreThisVersionPanel}
              </MyCard>
            </StickyWrapper>
          )}
      </WritingFeedbackWrapper>
    </>
  );
};

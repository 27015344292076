import { CustomStartType } from "@considr-it/storied-enums";
import { LiveQuestion } from "@considr-it/storied-entities";

export class CustomStartConfig {
  liveQuestion: Partial<LiveQuestion>;
  versionShortName: string;
  urlShortName: string;
  liveQuestionPromptName: string;
  liveQuestionThreshold: number;
  rewritePromptName: string;
  forceFirstQuestion: boolean;
  docTypeName?: string;
  outline: string;
}

export const getCustomStartConfig = (
  customStartType: CustomStartType
): Partial<CustomStartConfig> => {
  switch (customStartType) {
    case CustomStartType.TAKE_A_NOTE:
      return {
        liveQuestion: {
          shortQuestion: "Take a note",
          questionContext:
            "Capture an idea before it's lost. State the big idea then the details.",
          spokenQuestion: null,
        },
        rewritePromptName: "Default_Rewrite_CC_V2",
        liveQuestionPromptName: "Questions_AddMore_V4",
        liveQuestionThreshold: 4,
        urlShortName: "note",
        versionShortName: "Journal",
        forceFirstQuestion: false,
        docTypeName: "Note",
        outline: `I want to create a journal entry

The main idea is …
        
It should hit on these topics
- thing 1
- thing 2`,
      };

    case CustomStartType.MEETING_PREP:
      return {
        liveQuestion: {
          shortQuestion: "Prepare for a meeting",
          questionContext: "Who are you meeting with? What's the main goal?",
          spokenQuestion: null,
        },
        rewritePromptName: "MeetingPrep_Rewrite_CC",
        liveQuestionPromptName: "Questions_MeetingPrep",
        liveQuestionThreshold: 3,
        urlShortName: "agenda",
        versionShortName: "The Agenda",
        forceFirstQuestion: false,
        docTypeName: "Meeting Agenda",
        outline: `I want to make an agenda for a meeting

The main idea is …
        
The meeting is with …
The main goal is …`,
      };

    case CustomStartType.HOLIDAY_NOTE:
      return {
        liveQuestion: {
          shortQuestion: "Craft a holiday note! 🎁🙏",
          questionContext:
            "Show appreciation for someone who helped you this year. Start by saying who it was and what they did.",
          spokenQuestion: null,
        },
        rewritePromptName: "HolidayNote_Rewrite_CC",
        liveQuestionPromptName: "Questions_HolidayNote",
        liveQuestionThreshold: 1,
        urlShortName: "holidaynote",
        versionShortName: "Gratitude",
        forceFirstQuestion: false,
        docTypeName: "Holiday Note",
        outline: "",
      };

    case CustomStartType.LINKEDIN_POST:
      return {
        liveQuestion: {
          shortQuestion: "Write a LinkedIn post",
          questionContext:
            "Engage your network by sharing an idea. End with a question to help them engage!",
          spokenQuestion: null,
        },
        rewritePromptName: "LIpost_Rewrite_CC",
        liveQuestionPromptName: "Questions_LIpost",
        liveQuestionThreshold: 2,
        urlShortName: "lipost",
        versionShortName: "LIpost",
        forceFirstQuestion: true,
        docTypeName: "LinkedIn Post",
        outline: `I want to write a linkedin post

The main idea is …
        
Here's my personal insight
        
It could end with this question …`,
      };

    case CustomStartType.NEW_YEARS_RESOLUTIONS:
      return {
        liveQuestion: {
          shortQuestion: "Write Resolutions for 2024",
          questionContext:
            "Reflect on 2023. Start by saying what went well, then say what would make 2024 great. 🪩",
          spokenQuestion: null,
        },
        rewritePromptName: "NewYearsResolutions_Rewrite_CC",
        liveQuestionPromptName: "Questions_NewYearsResolutions",
        liveQuestionThreshold: 3,
        urlShortName: "newyearsresolutions",
        versionShortName: "Resolutions",
        docTypeName: "New Year Revolution",
        forceFirstQuestion: false,
        outline: "",
      };

    case CustomStartType.PRODUCT_HEROS_JOURNEY:
      return {
        liveQuestion: {
          shortQuestion: "Your customer is a hero. What's their challenge?",
          questionContext:
            "Envision your customer as the hero in their own story, with your brand as their guiding ally.",
          spokenQuestion:
            "Envision your customer as the hero in their own story, with your brand as their guiding ally. What are your hero's challenges?",
        },
        rewritePromptName: "ProductHerosJourney_Rewrite_CC",
        liveQuestionPromptName: "Questions_ProductHerosJourney",
        liveQuestionThreshold: 5,
        urlShortName: "producthero",
        versionShortName: "ProductHero",
        forceFirstQuestion: true,
        docTypeName: "Product Hero",
        outline: `I want to describe our customers hero’s journey

Here's our customers world today
        
Here's what they're searching for
        
Here is how our product serves as a guide
        
They will emerged transformed with this outcome`,
      };

    case CustomStartType.END_OF_DAY:
      return {
        liveQuestion: {
          shortQuestion: "End of day debrief 🌅",
          questionContext:
            "Get better every day. What is the most important thing you did today?",
          spokenQuestion: null,
        },
        rewritePromptName: "EOD_Rewrite_CC",
        liveQuestionPromptName: "Questions_EOD",
        liveQuestionThreshold: 4,
        urlShortName: "eod",
        versionShortName: "EoD Recap",
        forceFirstQuestion: false,
        docTypeName: "End of Day",
        outline: `I want to write an end-of-day reflection

The most important thing I did today is…
        
One challenge I faced is
        
Here's how I'll get better`,
      };

    case CustomStartType.MEETING_RECAP:
      return {
        liveQuestion: {
          shortQuestion: "Synthesize notes after a meeting",
          questionContext:
            "Start with the context, then describe the things that matter most.",
          spokenQuestion: null,
        },
        rewritePromptName: "MeetingRecap_Rewrite_CC",
        liveQuestionPromptName: "Questions_MeetingRecap",
        liveQuestionThreshold: 3,
        urlShortName: "recap",
        versionShortName: "The Recap",
        docTypeName: "Meeting Recap",
        forceFirstQuestion: false,
        outline: `I want to synthesize ideas after a meeting

Here's the context. Who was there. Why
        
Her's what mattered most
        
This will happen next`,
      };

    case CustomStartType.EMAIL:
      return {
        liveQuestion: {
          shortQuestion: "Work out a hard email",
          questionContext:
            "Talk out an email like you're talking to a friend. We'll do the rest.",
          spokenQuestion: null,
        },
        rewritePromptName: "Email_Rewrite_CC",
        liveQuestionPromptName: "Questions_Email",
        liveQuestionThreshold: 2,
        urlShortName: "email",
        versionShortName: "Email",
        docTypeName: "Email",
        forceFirstQuestion: false,
        outline: `I want to draft and email

What they need to know is
        
Here's the request`,
      };

    case CustomStartType._NICK_HACK:
      return {
        liveQuestion: {
          shortQuestion: "Talk out your idea",
          questionContext:
            "Start by describing what you want to create, then provide the details",
          spokenQuestion:
            "Hey there! This app helps people create clear writing. Please describe what document or plan you'd like to create, and then tell us the details.",
        },
        rewritePromptName: "Nick_Hack_Rewrite_CC",
        liveQuestionPromptName: "Questions_Nick_Hack",
        urlShortName: "nickdemo",
        versionShortName: "Demo",
        docTypeName: "Nick Hack",
        forceFirstQuestion: true,
        outline: "",
      };

    case CustomStartType.PEAK_OUTCOME:
      return {
        liveQuestion: {
          shortQuestion:
            "Imagine you business's success. What's the headlines in 4 years?",
          questionContext:
            "Think about your ultimate business goals. What does your business's financial success look like? What would the headline be?",
          spokenQuestion:
            "Project yourself into the future to your business's ultimate goal. What does your exit look like? Is it an IPO? Getting acquired? Describe that the headlines would look like on that day!",
        },
        rewritePromptName: "PeakOutcome_Rewrite",
        liveQuestionPromptName: "Questions_PeakOutcome",
        liveQuestionThreshold: 3,
        urlShortName: "peakoutcome",
        versionShortName: "Peak Outcome",
        forceFirstQuestion: true,
        docTypeName: "Peak Outcome",
        outline: `Consider your company's ultimate financial success
Project yourself forward several years and describe the press release.
On that day:
- Our financial result is ... (eg. $100m IPO, $200m acquisition, etc)
- The headline will be ... (state the headline)
- The business was possible because ... (why it was acheivable)
- The personal motivation was ... (why it is your outcome)`,
      };

    default:
      return {
        liveQuestion: {
          shortQuestion: "Talk out your idea",
          questionContext:
            "Start by describing what you want to create, then provide the details.",
          spokenQuestion:
            "Hey there! This app helps people create clear writing. Please describe what document or plan you'd like to create, and then tell us the details.",
        },
        liveQuestionPromptName: "Questions_AddMore_V4",
        urlShortName: null,
        forceFirstQuestion: false,
        outline: `I want to create a <document type>
        
Here are the details:
- Component 1
- Component 2`,
      };
  }
};

export const getCustomStartTypeByUrlName = (urlName: string) => {
  for (const customStartType of Object.values(CustomStartType)) {
    const customStartConfig = getCustomStartConfig(customStartType);
    if (customStartConfig.urlShortName === urlName) {
      return customStartType;
    }
  }

  return null;
};

export const getCustomStartUrlNameByType = (
  customStartType: CustomStartType
) => {
  const customStartConfig = getCustomStartConfig(customStartType);

  return customStartConfig.urlShortName;
};

export const getCustomStartTypeByDocType = (docType: string) => {
  if (docType) {
    for (const customStartType of Object.values(CustomStartType)) {
      const customStartConfig = getCustomStartConfig(customStartType);
      if (
        customStartConfig.docTypeName.toLowerCase().trim() ===
        docType.toLowerCase().trim()
      ) {
        return customStartType;
      }
    }
  }

  return null;
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const StarIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7124_26168)">
      <path d="M23.9655 9.24825C23.8829 8.99362 23.6628 8.80814 23.398 8.7697L15.9579 7.68849L12.6305 0.946609C12.5121 0.706562 12.2677 0.554688 12 0.554688C11.7324 0.554688 11.488 0.706562 11.3695 0.946609L8.04195 7.68844L0.602009 8.7697C0.337213 8.80814 0.117088 8.99362 0.0344479 9.24816C-0.0482863 9.50278 0.0207136 9.7822 0.212338 9.96895L5.59583 15.2168L4.32514 22.6269C4.27991 22.8908 4.38838 23.1573 4.60489 23.3147C4.82141 23.4719 5.10838 23.4927 5.34538 23.3681L12 19.8695L18.6544 23.3681C18.7573 23.4222 18.8696 23.4489 18.9815 23.4489C19.1273 23.4489 19.2724 23.4036 19.3949 23.3147C19.6114 23.1573 19.7198 22.8907 19.6746 22.6269L18.4036 15.2168L23.7876 9.96895C23.9793 9.78215 24.0483 9.50273 23.9655 9.24825Z" fill="#FFD66E"/>
      <path d="M23.9655 9.24825C23.8828 8.99362 23.6628 8.80814 23.398 8.7697L15.9579 7.68849L12.6305 0.946609C12.5121 0.706562 12.2677 0.554688 12 0.554688V19.8695L18.6544 23.3681C18.7573 23.4222 18.8696 23.4489 18.9815 23.4489C19.1273 23.4489 19.2725 23.4037 19.3949 23.3147C19.6114 23.1573 19.7199 22.8907 19.6746 22.627L18.4035 15.2168L23.7876 9.96895C23.9793 9.78215 24.0483 9.50273 23.9655 9.24825Z" fill="#FFD66E"/>
    </g>
    <defs>
      <clipPath id="clip0_7124_26168">
        <rect width="24" height="24" fill="white"/>
      </clipPath>
    </defs>
  </Svg>
);
  
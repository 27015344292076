import { BasicModal } from "../BasicModal";
import pdfToText from "react-pdftotext";
import styled from "styled-components";
// Custom hooks
import { trackEvent, useGlobal } from "@considr-it/storied-shared";
// Components
import { PrimaryButton } from "../../Buttons";
import { EditAIIcon } from "../../IconsSvg";
import TypeOrRecordForm from "../../TypeOrRecordForm";
import {
  ApiKeyType,
  OutlineType,
  RecordingObjectType,
  SttProvider,
} from "@considr-it/storied-enums";
import { useNavigate } from "react-router-dom";
import { LiveStory } from "@considr-it/storied-entities";
import { useMicRecorder } from "../../../hooks/use-mic-recorder";
import { FiUpload } from "react-icons/fi";
import { useState } from "react";
import { LineBreakText } from "./magic-outline.style";
import { ProcessingCircle } from "../../ProcessingCircle";

// Styles
const HeaderSection = styled.div`
  display: flex;
  width: 100%;
  gap: 12px;
  font-size: 24px;
  font-weight: 600;
  line-height: 24px;
`;
const ContentSection = styled.div`
  display: flex;
  flex-direction: column;
  gap: 16px;
  margin-top: 20px;
`;
const Text = styled.p`
  margin: 0;
  font-size: 16px;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.022em;
`;
const ContentHeader = styled.h3`
  font-size: 20px;
  font-weight: 500;
  line-height: 28px;
  letter-spacing: -0.022em;
  text-align: left;
`;

export const MagicOutlineModal = ({ isOpen, setClose }) => {
  const { isMobile, transport } = useGlobal();
  const isSubmit = true;
  const { isBusy } = useMicRecorder();
  const navigate = useNavigate();
  const [errorMessage, setErrorMessage] = useState("");
  const [isProcessingOutline, setIsProcessingOutline] =
    useState<boolean>(false);

  const processOutline = async (
    transcript: string,
    outlineType: OutlineType
  ) => {
    const { data } = await transport.post<LiveStory>(
      "/createLiveStoryFromMagicOutline",
      {
        transcript,
        outlineType,
      }
    );

    navigate(`/draft/${data.id}`);
  };

  // contentWrapper?: any;
  return (
    <BasicModal
      withCloseBtn={!isBusy && !isProcessingOutline}
      show={isOpen}
      closeModal={setClose}
      style={{ padding: 24 }}
      disableOnHide={isBusy || isProcessingOutline}
    >
      {isProcessingOutline ? (
        <ProcessingCircle subtext={"Creating your outline..."} />
      ) : (
        <div>
          <HeaderSection>
            <EditAIIcon width={24} height={24} />
            Let's get started!
          </HeaderSection>
          <ContentSection>
            <Text>
              We'll help you set up a draft so it's easier to create a GREAT
              document with voice.
            </Text>
            <ContentHeader>What you want to create?</ContentHeader>
            <Text>
              State your idea. We'll use it to create an outline, organize
              questions, and define the initial writing type.
            </Text>
            <TypeOrRecordForm
              onEntry={(
                _objectId: string,
                transcript: string,
                sttProvider: SttProvider
              ) => {
                setIsProcessingOutline(true);

                const outlineType = !!sttProvider
                  ? OutlineType.OUTLINE_TYPE_FILE_AUDIO_RECORDED
                  : OutlineType.OUTLINE_TYPE_FILE_TEXT;

                processOutline(transcript, outlineType);
              }}
              placeHolderText="Type here or record => "
              objectType={RecordingObjectType.MAGIC_OUTLINE}
              style={{ marginTop: 0, marginBottom: 0 }}
            />
            <LineBreakText>
              <h6>or</h6>
            </LineBreakText>
            {errorMessage && <h6 style={{ color: "red" }}>*{errorMessage}</h6>}
            <input
              id={`fileInput`}
              accept=".mp3,.pdf,.txt,.jpg,.jpeg,.png"
              type="file"
              style={{ display: "none" }}
              onChange={async (e: any) => {
                let file = e.target.files[0] as File;

                if (file.size > 10 * 1024 * 1024) {
                  setErrorMessage("Maximum file size is 10MB");

                  return;
                }

                setIsProcessingOutline(true);

                let outlineType = null;
                let fileType = "";

                switch (file.type) {
                  case "image/jpeg":
                  case "image/png": {
                    outlineType = OutlineType.OUTLINE_TYPE_FILE_IMAGE;
                    fileType = "image";
                    break;
                  }
                  case "application/pdf": {
                    outlineType = OutlineType.OUTLINE_TYPE_FILE_PDF;

                    const text = await pdfToText(file);

                    processOutline(text, outlineType);

                    return;
                  }
                  case "audio/mpeg": {
                    outlineType = OutlineType.OUTLINE_TYPE_FILE_AUDIO_UPLOADED;
                    fileType = "audio";
                    break;
                  }
                  case "text/plain": {
                    outlineType = OutlineType.OUTLINE_TYPE_FILE_TEXT;
                    const text = await file.text();

                    processOutline(text, outlineType);

                    return;
                  }
                }

                const { data } = await transport.post(
                  `/summarizeAsset/${fileType}`,
                  file
                );

                processOutline(data, outlineType);
              }}
            />
            <PrimaryButton
              onClick={() => {
                document.getElementById(`fileInput`).click();
              }}
              disabled={isBusy}
            >
              Upload File (audio/text/image)
              <FiUpload />
            </PrimaryButton>
          </ContentSection>
        </div>
      )}
    </BasicModal>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const ThreeDotsIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M19.8962 12.4993C19.8962 12.856 19.607 13.1452 19.2503 13.1452C18.8936 13.1452 18.6045 12.856 18.6045 12.4993C18.6045 12.1427 18.8936 11.8535 19.2503 11.8535C19.607 11.8535 19.8962 12.1427 19.8962 12.4993" stroke="#FF6D34" strokeWidth="1.93669" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.1457 12.4993C12.1457 12.856 11.8565 13.1452 11.4998 13.1452C11.1432 13.1452 10.854 12.856 10.854 12.4993C10.854 12.1427 11.1432 11.8535 11.4998 11.8535C11.8565 11.8535 12.1457 12.1427 12.1457 12.4993" stroke="#FF6D34" strokeWidth="1.93669" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M4.39616 12.4993C4.39616 12.856 4.10701 13.1452 3.75033 13.1452C3.39364 13.1452 3.10449 12.856 3.10449 12.4993C3.10449 12.1427 3.39364 11.8535 3.75033 11.8535C4.10701 11.8535 4.39616 12.1427 4.39616 12.4993" stroke="#FF6D34" strokeWidth="1.93669" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

  
import React from 'react'
import { SVGProps, Svg } from '..'

export const BigPauseIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="14"
    height="22"
    viewBox="0 0 14 22"
    fill="none"
    {...props}
  >
    <rect width="11.7858" height="18.3334" transform="translate(1 2)" fill="white"/>
    <rect x="0.0178223" y="0.46875" width="5.34725" height="21.389" rx="1.52778" fill="#FF6D34"/>
    <rect x="8.42065" y="0.46875" width="5.34725" height="21.389" rx="1.52778" fill="#FF6D34"/>
  </Svg>
);

  
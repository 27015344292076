import React from 'react'
import { SVGProps, Svg } from '..'

export const EditRedoIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="#fa8152"
    {...props}
  >
    <path fill="none" d="M0 0h24v24H0z"></path>
    <path d="M16 7h-5a6 6 0 1 0 0 12h9v2h-9a8 8 0 1 1 0-16h5V1l6 5-6 5V7z"></path>
  </Svg>
);

  
import { useMemo, useEffect, useRef, cloneElement, useState } from "react";
// Components
import { HeaderIconWrapper } from "../HeaderIconWrapper";
import { ProcessingCircle } from "../ProcessingCircle";
// Custom hooks
import { usePlayer } from "@considr-it/storied-shared";
import { useGlobal, trackEvent } from "@considr-it/storied-shared";
// Icons
import { PlayIcon, PauseIcon } from "../IconsSvg";
// Types
import { TTS } from "@considr-it/storied-entities/src/models/audio";
import { TTSType } from "@considr-it/storied-enums";

interface TextToSpeechProps {
  disabled?: boolean;
  autoplay?: boolean;
  objectId: string;
  objectType: TTSType;
  hideWhileLoading?: boolean;
  sizeIncreased?: boolean;
}

export const TextToSpeech: React.FC<TextToSpeechProps> = ({
  disabled,
  objectId,
  objectType,
  autoplay = false,
  hideWhileLoading = false,
  sizeIncreased = false,
}) => {
  const [loading, setLoading] = useState<boolean>(false);
  const { transport, isSmallMobile } = useGlobal();
  const { state, controls, setup } = usePlayer();
  const triggeredPlayRef = useRef<boolean>(autoplay);
  const checkFieldIntervalRef = useRef(null);
  const [generatingTts, setGeneratingTTS] = useState<boolean>(null);

  const fetchTts = async () => {
    setGeneratingTTS(true);
    const resp = await transport.get<TTS>(`/tts/${objectType}/${objectId}`);

    const tts = resp?.data;

    if (tts) {
      setGeneratingTTS(tts.generatingTextToSpeech);
    } else {
      clearInterval(checkFieldIntervalRef.current);
      checkFieldIntervalRef.current;

      triggeredPlayRef.current = false;

      setGeneratingTTS(null);
      setLoading(false);
    }
  };

  useEffect(() => {
    if (state.canPlay) {
      setLoading(false);
    }
  }, [state]);

  useEffect(() => {
    if (hideWhileLoading) {
      setup({ itemId: objectId, itemModel: objectType });
    }
  }, []);

  useEffect(() => {
    if (autoplay) {
      fetchTts();
      triggeredPlayRef.current = true;
    }
  }, []);

  useEffect(() => {
    return () => {
      if (checkFieldIntervalRef.current) {
        clearInterval(checkFieldIntervalRef.current);
        checkFieldIntervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    if (generatingTts && !checkFieldIntervalRef.current) {
      setLoading(true);
      checkFieldIntervalRef.current = setInterval(fetchTts, 5000);
    } else if (generatingTts === false) {
      if (!!checkFieldIntervalRef.current) {
        clearInterval(checkFieldIntervalRef.current);
        checkFieldIntervalRef.current = null;
      }

      if (triggeredPlayRef.current) {
        controls.togglePlay({ itemId: objectId, itemModel: objectType });
        triggeredPlayRef.current = false;
      }
    }
  }, [generatingTts]);

  const IconSize = isSmallMobile
    ? { width: 20, height: 20 }
    : sizeIncreased
    ? { width: 28, height: 28 }
    : {};

  const Icon = useMemo(
    () =>
      state.paused ? (
        <PlayIcon $disabled={disabled} {...IconSize} />
      ) : (
        <PauseIcon $disabled={disabled} {...IconSize} />
      ),
    [state]
  );

  if (!hideWhileLoading && loading) {
    return <ProcessingCircle circleSize={sizeIncreased ? "28px" : "24px"} />;
  }

  return (
    <HeaderIconWrapper
      disabled={disabled}
      onClick={() => {
        trackEvent("toggle_tts", { objectId, objectType });
        if (generatingTts === null) {
          fetchTts();
          triggeredPlayRef.current = true;
        } else {
          setLoading(true);
          controls.togglePlay({ itemId: objectId, itemModel: objectType });
        }
      }}
      Icon={Icon}
    />
  );
};

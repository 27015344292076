import React from 'react'
import { SVGProps, Svg } from '..'

export const MicOutlineIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect x="8.5" y="1.99585" width="7.00292" height="12.5052" rx="3.5" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.5033 10.4994C19.5033 14.6433 16.1441 18.0025 12.0002 18.0025V18.0025C7.85633 18.0025 4.49707 14.6433 4.49707 10.4994" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0002 21.0037V18.0024" stroke="#FF6D34" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);
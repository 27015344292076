import { createContext, useContext, useEffect, useState } from "react";
import {
  useAccount,
  useGlobal,
  useLocalCached,
} from "@considr-it/storied-shared";
import { PaidPlan, PaymentInfo } from "@considr-it/storied-entities";
import { loadStripe, Stripe } from "@stripe/stripe-js";
import { BillingCycle, PaymentTier } from "@considr-it/storied-enums";

export const usePaymentProvider = () => {
  const { transport } = useGlobal();
  const { account } = useAccount();

  const [stripe, setStripe] = useState<Stripe>(null);

  useEffect(() => {
    const initializeStripe = async () => {
      const data = await loadStripe(process.env.REACT_APP_STRIPE_PUBLIC_KEY);
      setStripe(data);
    };

    initializeStripe();
  }, []);

  const { localObject: paymentInfo, revalidate: revalidatePaymentInfo } =
    useLocalCached<PaymentInfo>(
      account ? "paymentInfo" : null,
      "payment_info",
      120000
    );

  const { localObject: paidPlans, revalidate: revalidatePaidPlans } =
    useLocalCached<PaidPlan[]>(paymentInfo ? "paidPlans" : null, "paid_plans");

  const startCheckoutProcess = async (
    paymentTier: PaymentTier,
    billingCycle: BillingCycle
  ) => {
    const { data } = await transport.post("/checkout", {
      paymentTier,
      billingCycle,
    });

    if (data.sessionId) {
      await stripe.redirectToCheckout({ sessionId: data.sessionId });
    }
  };

  const cancelSubscribtion = async () => {
    await transport.post("/cancelSubscription");
  };

  const manageBilling = async () => {
    const {
      data: { url },
    } = await transport.get("/manageBillingUrl");

    window.open(url, "_blank");
  };

  useEffect(() => {
    revalidatePaymentInfo();
  }, [account]);

  useEffect(() => {
    revalidatePaidPlans();
  }, [paymentInfo]);

  return {
    paymentInfo,
    revalidatePaymentInfo,

    paidPlans,
    revalidatePaidPlans,

    cancelSubscribtion,
    manageBilling,
    startCheckoutProcess,
  };
};

export const PaymentProvider = ({ children }) => {
  const paymentProvider = usePaymentProvider();

  return (
    <PaymentContext.Provider value={paymentProvider}>
      {children}
    </PaymentContext.Provider>
  );
};

export type PaymentProps = ReturnType<typeof usePaymentProvider>;
export const PaymentContext = createContext<PaymentProps>(null);
export const usePayment = () => useContext(PaymentContext);

import React, { ReactElement } from "react";
import { HeaderIcon } from "./header-icon-wrapper.style";

interface HeaderIconProps {
  onClick: () => void;
  Icon: ReactElement;
  disabled?: boolean;
  title?: string;
}

export const HeaderIconWrapper: React.FC<HeaderIconProps> = ({
  onClick,
  Icon,
  disabled = false,
  title = "",
}) => {
  return (
    <HeaderIcon title={title} onClick={disabled ? () => {} : onClick}>
      {Icon}
    </HeaderIcon>
  );
};

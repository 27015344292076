import styled from "styled-components";
import { MouseEvent } from 'react';

export const MenuWrapper = styled.div`
  .dropdown-toggle {
    background-color: transparent;
    border: none;
    padding: 0;
    img {
      width: 24px;
      height: 24px;
      cursor: pointer;
    }
  }
  .dropdown-toggle::after {
    display: none;
  }
  .dropdown-menu {
    padding: 0;
    border-radius: 7px;
    border: none;
    box-shadow: 0px 0px 10px 0px rgba(0, 0, 0, 0.08);\
    margin-top: 12px;
  }
  .dropdown-item {
    padding: 0;
    &:hover {
      background: var(--BG-for-tools, #FFF9F6);
      border-radius: 7px;
    }
  }
  hr {
    height: 1px;
    margin: 0 16px;
    color: #EBEBEB;
    opacity: 1; 
  }
  button {
    margin: 0;
  }
`;

export const MenuButton = styled.div<MenuButtonProps>`
  cursor: pointer;
  padding: 12px 16px;
  display: flex;
  align-items: center;
  gap: 10px;
  color: #FF6D34;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  border-radius: 7px;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 16px;
  }
  img {
    width: 22px;
    height: 22px;
  }
`;
interface MenuButtonProps {
    onClick: (event: MouseEvent<HTMLDivElement>) => void;
  }
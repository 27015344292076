import styled from "styled-components";

export const FancyBackgroundWrapper = styled.div`  
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: flex-start;
  z-index: -1;
  overflow: hidden;
`;

export const FancyBackgroundImg = styled.img`
  max-width: 854px; 
  max-height: 748px;
  width: 100%;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
`;

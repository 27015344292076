import { useNavigate, useParams } from "react-router-dom";
import { useState, useEffect, ChangeEvent, useCallback } from "react";
import { Stack } from "react-bootstrap";
// Custom hooks
import {
  getCustomStartConfig,
  getCustomStartTypeByDocType,
  useGlobal,
} from "@considr-it/storied-shared";
import { useIdeaFlow } from "../../hooks/use-idea-flow";
import { useMicRecorder } from "../../hooks/use-mic-recorder";
// Components
import { QuestionAudioPlayer } from "../../components/QuestionAudioPlayer";
import {
  BackButton,
  WhiteButton,
  SecondaryButton,
  PrimaryButton,
} from "../../components/Buttons";
import { CustomStarts } from "../../components/CustomStarts";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
import { InputLanguages } from "../../components/InputLanguages";
import { ProcessingCircle } from "../../components/ProcessingCircle";
// Styles
import {
  ContentWrapper,
  TopWrapper,
  Title,
  Textarea,
  BottomWrapper,
  RecordButton,
  IconWrapper,
  InputLanguagesWrapper,
  QuestionWrapper,
  QuestionText,
  ExplanationText,
  DoctypeInput,
  MobileContentWrapper,
  FieldsWrapper,
  TopButtonsWrapper,
} from "./draft.styles";
// Icons
import {
  BigPauseIcon,
  MicIcon,
  RepeatIcon,
  File2Icon,
  ChatIcon,
  ExportIcon,
  CopyIcon,
} from "../../components/IconsSvg";
import {
  LiveQuestion,
  LiveStory,
  SharedLiveStory,
} from "@considr-it/storied-entities";
import { BasicModal } from "../../components/Modals/BasicModal";
import { useProcessingModal } from "../../hooks/use-processing-modal";
import useConfirmModal from "../../hooks/use-confirm-modal";
import { PaymentTier } from "@considr-it/storied-enums";
import { usePayment } from "../../hooks/use-payment";
import { useUpgradeToProModal } from "../../hooks/use-upgrade-to-pro-modal";
import { useFeaturesLocking } from "../../hooks/use-features-locking";

export const Draft = () => {
  const [isModal, setModal] = useState<boolean>(false);
  const { liveStoryId } = useParams();
  const { isMobile, transport } = useGlobal();
  const [fieldsWrapperActive, setFieldsWrapperActive] =
    useState<boolean>(false);
  const [outline, setOutline] = useState("");
  const [docTypeValue, setDocTypeValue] = useState("");
  const navigate = useNavigate();
  const setTextAreaHandler = (e: ChangeEvent<HTMLTextAreaElement>) => {
    setOutline(e.target.value);
  };
  const [isPendingSave, setIsPendingSave] = useState<boolean>();

  const { onPresent: showUpgradeToProModal } = useUpgradeToProModal();

  const { onPresentConfirmModal, onDismiss: onDismissConfirmationModal } =
    useConfirmModal();

  const { onPresent: onPresentSharingLiveStory } = useProcessingModal({
    text: "Preparing to share...",
  });

  const {
    onPresent: onPresentLoadingLiveStory,
    onDismiss: onDismissLoadingLiveStory,
  } = useProcessingModal({
    text: "Loading data...",
  });

  const {
    initialisingWritingFeedback,
    setInitialisingWritingFeedback,
    customStartType,
    setCustomStartType,
    record,
  } = useIdeaFlow();

  const goToHomePage = () => {
    navigate("/");
  };

  const goToDocs = () => navigate("/documents");

  const {
    pauseRecording,
    startRecording,
    stopRecording,
    getQuestion,

    currentLiveQuestion,
    isInitialisingLiveStory,
    setIsInitialisingLiveStory,
    isGeneratingQuestion,
    time,
    currentTranscriptChunk,
    currentLiveStory,
    setCurrentLiveStory,
    setCurrentLiveQuestion,

    cleanupRecordingFlow,

    liveTranscript,
  } = record;

  const { isInitialising, isPausing, isRecording, isPausedRecording, isBusy } =
    useMicRecorder();

  const { isLocked } = useFeaturesLocking();

  useEffect(() => {
    return () => {
      cleanupRecordingFlow();
    };
  }, []);

  useEffect(() => {
    if (isLocked) {
      showUpgradeToProModal();
    }
  }, [isLocked]);

  useEffect(() => {
    if (liveStoryId) {
      onPresentLoadingLiveStory();
      setIsInitialisingLiveStory(true);
      transport.get<LiveStory>(`/liveStory/${liveStoryId}`).then((resp) => {
        if (resp.data) {
          setOutline(resp.data.outline || "");
          setDocTypeValue(resp.data.docType || "");
          setCurrentLiveStory(resp.data);
          setCurrentLiveQuestion(
            (resp.data.liveQuestions as LiveQuestion[])[
              resp.data.liveQuestions.length - 1
            ]
          );
        } else {
          setCurrentLiveStory(null);
          setCurrentLiveQuestion(null);
        }

        navigate("/draft");
        setIsInitialisingLiveStory(false);
        onDismissLoadingLiveStory();
      });
    }
  }, [liveStoryId]);

  useEffect(() => {
    if (customStartType) {
      setDocTypeValue(getCustomStartConfig(customStartType).docTypeName);
    }
  }, [customStartType]);

  // Handlers
  const saveDraft = useCallback(
    async (outline: string, docTypeVal: string) => {
      if (!!currentLiveStory) {
        const { data } = await transport.patch<LiveStory>(
          `/liveStory/${currentLiveStory.id}`,
          {
            payload: {
              outline: outline,
              docType: docTypeVal,
              customStartType: customStartType,
            },
          }
        );

        setCurrentLiveStory(data);
      }
    },
    [transport, currentLiveStory, customStartType]
  );

  const shareDraft = async () => {
    onPresentSharingLiveStory();
    const { data } = await transport.post<SharedLiveStory>("/shareLiveStory", {
      liveStoryId: currentLiveStory.id,
    });

    const url = `${window.location.origin}/sls/${data.shortId}`;

    onPresentConfirmModal({
      confirmationMessage: "Share your outline",
      description: url,
      actions: [
        <PrimaryButton
          onClick={() => {
            navigator.clipboard.writeText(url);
            onDismissConfirmationModal();
          }}
          noIconFill
          fullWidth
        >
          <div
            style={{
              display: "inline-block",
              gap: 10,
              alignItems: "center",
            }}
          >
            <CopyIcon />
            Copy to clipboard
          </div>
        </PrimaryButton>,
        <SecondaryButton onClick={onDismissConfirmationModal}>
          Close
        </SecondaryButton>,
      ],
    });
  };

  useEffect(() => {
    if (
      (!!outline || !!docTypeValue) &&
      !currentLiveStory &&
      !isInitialisingLiveStory
    ) {
      setIsInitialisingLiveStory(true);

      transport
        .post<LiveStory>("/createLiveStory", {
          customStartType,
          isDraft: true,
          outline,
          doctype: docTypeValue,
        })
        .then((resp) => {
          setCurrentLiveStory(resp.data);
          setIsInitialisingLiveStory(false);
        });
    }
  }, [currentLiveStory, outline, isInitialisingLiveStory, docTypeValue]);

  useEffect(() => {
    const customStartByDocType = getCustomStartTypeByDocType(docTypeValue);
    setCustomStartType(customStartByDocType);
  }, [docTypeValue]);

  useEffect(() => {
    if (
      !currentLiveStory ||
      (outline === currentLiveStory.outline &&
        docTypeValue === currentLiveStory.docType)
    ) {
      return;
    }

    let debounceTimer: NodeJS.Timeout;
    const debouncedSave = () => {
      clearInterval(debounceTimer);
      debounceTimer = setTimeout(async () => {
        await saveDraft(outline, docTypeValue);
        setIsPendingSave(false);
      }, 1500);

      setIsPendingSave(true);
    };

    debouncedSave();
    return () => {
      if (debounceTimer) {
        clearInterval(debounceTimer);
      }
    };
  }, [outline, docTypeValue, saveDraft, currentLiveStory]);

  useEffect(() => {
    if (
      !!currentLiveQuestion?.spokenQuestion &&
      isPausedRecording &&
      isMobile
    ) {
      setModal(true);
    }
  }, [currentLiveQuestion, isPausedRecording]);

  const loaderCond = !!(
    isInitialisingLiveStory ||
    isInitialising ||
    isGeneratingQuestion ||
    isPausing
  );

  const showTimeDesktop = (isRecording || isPausedRecording) && !loaderCond;
  const showTimeMobile = isRecording && !loaderCond;

  const RecordButtonUI = (
    <RecordButton
      style={{ width: isMobile ? "100%" : "" }}
      onClick={async () => {
        if (isRecording) {
          if (isModal) {
            setModal(false);
          }

          await pauseRecording();
        } else {
          if (!isPausedRecording) {
            const inputLanguagesWrapper = document.getElementById(
              "input-languages-wrapper"
            );
            inputLanguagesWrapper?.classList.add("hide");
          }

          await startRecording({ isDraft: true, outline: "" });
        }
      }}
    >
      <IconWrapper>
        {loaderCond ? (
          <ProcessingCircle />
        ) : isRecording ? (
          <BigPauseIcon />
        ) : (
          <MicIcon />
        )}
      </IconWrapper>
      {loaderCond
        ? "Processing... "
        : isRecording
        ? "Pause"
        : isPausedRecording
        ? `${isMobile ? (isModal ? "Answer" : "Add More?") : "Add More"}`
        : "Record"}
      <ReturnJSX if={isMobile ? showTimeMobile : showTimeDesktop}>
        <div>({time})</div>
      </ReturnJSX>
    </RecordButton>
  );

  const QuestionUI = (
    <QuestionWrapper>
      <QuestionText>
        <ChatIcon width={20} height={20} />
        {currentLiveQuestion?.shortQuestion}
        <QuestionAudioPlayer />
      </QuestionText>
      <ExplanationText>{currentLiveQuestion?.spokenQuestion}</ExplanationText>
    </QuestionWrapper>
  );

  const QuestionMobileUI = (
    <QuestionWrapper>
      <QuestionText>
        <ChatIcon width={20} height={20} />
        {currentLiveQuestion?.shortQuestion}
        <QuestionAudioPlayer />
      </QuestionText>
      <ExplanationText>
        {currentLiveQuestion?.spokenQuestion ||
          (isGeneratingQuestion && "Generating Question")}
      </ExplanationText>
      <BottomWrapper>
        {RecordButtonUI}
        <ReturnJSX
          if={
            isPausedRecording &&
            !isGeneratingQuestion &&
            !!currentTranscriptChunk.current
          }
        >
          <WhiteButton
            onClick={getQuestion}
            style={{ width: isMobile ? "100%" : "" }}
          >
            <RepeatIcon />
            Get Question
          </WhiteButton>
        </ReturnJSX>
      </BottomWrapper>
    </QuestionWrapper>
  );

  const DraftUI = (
    <>
      <TopWrapper>
        <BackButton onClick={goToHomePage} text="Back to home page" />
        <TopButtonsWrapper>
          {!!outline && !isBusy && (
            <SecondaryButton
              disabled={isPendingSave}
              onClick={shareDraft}
              style={{ width: isMobile ? "100%" : "" }}
              noIconFill
            >
              <ExportIcon />
              {isMobile ? "" : "Share"}
            </SecondaryButton>
          )}
          <SecondaryButton disabled={isPendingSave} onClick={goToDocs}>
            {isPendingSave ? "Saving..." : "Go to docs"}
          </SecondaryButton>
        </TopButtonsWrapper>
      </TopWrapper>
      <Title>Doc type</Title>
      <FieldsWrapper
        style={{
          border: fieldsWrapperActive ? "1.5px solid rgb(255, 109, 52)" : "",
        }}
      >
        <DoctypeInput
          disabled={isBusy}
          placeholder="e.g. Meeting summary"
          value={docTypeValue}
          onFocus={() => setFieldsWrapperActive(true)}
          onBlur={() => setFieldsWrapperActive(false)}
          onChange={(e) => {
            const docType = e.target.value;
            setDocTypeValue(docType);
          }}
        />
        {!isBusy && (
          <CustomStarts dropdownOnly placeholderText="Choose template" />
        )}
      </FieldsWrapper>
      <Title>Outline</Title>
      <Textarea
        value={outline}
        onChange={setTextAreaHandler}
        placeholder={getCustomStartConfig(customStartType).outline}
        disabled={isBusy && !isPausedRecording}
      />
      <ReturnJSX if={!isModal}>
        <BottomWrapper>
          {!isBusy && (
            <InputLanguagesWrapper id="input-languages-wrapper">
              <InputLanguages
                dropdownOnly
                openToTop
                placeholderText="Choose the input language"
              />
            </InputLanguagesWrapper>
          )}
          {RecordButtonUI}
          <ReturnJSX if={isPausedRecording && !isGeneratingQuestion}>
            <ReturnJSX if={!!currentTranscriptChunk.current}>
              <WhiteButton
                onClick={getQuestion}
                style={{ width: isMobile ? "100%" : "" }}
              >
                <RepeatIcon />
                Get Question
              </WhiteButton>
            </ReturnJSX>
            {!isMobile && (
              <WhiteButton
                onClick={async () => {
                  setInitialisingWritingFeedback(true);
                  await stopRecording();
                }}
                style={{ width: isMobile ? "100%" : "" }}
              >
                <File2Icon /> Convert to writing
              </WhiteButton>
            )}
          </ReturnJSX>
        </BottomWrapper>
      </ReturnJSX>
      {isPausedRecording && !isGeneratingQuestion && isMobile && (
        <RecordButton
          onClick={async () => {
            setInitialisingWritingFeedback(true);
            await stopRecording();
          }}
          style={{ width: "100%", marginTop: 16 }}
          $isMobile={isMobile}
          $isConvertToWriting={true}
        >
          <File2Icon /> Convert to writing
        </RecordButton>
      )}
    </>
  );

  if (initialisingWritingFeedback) {
    return (
      <Stack className="processing-circle-wrapper">
        <ProcessingCircle
          text="Transforming your ideas into writing..."
          subtext="It may take a few seconds! ☺️"
          customAnimation
        />
      </Stack>
    );
  }

  if (isMobile) {
    return (
      <>
        {DraftUI}
        <BasicModal
          show={isModal}
          contentWrapper={MobileContentWrapper}
          closeModal={() => setModal(false)}
          withCloseBtn={!isGeneratingQuestion}
          disableOnHide={true}
        >
          {QuestionMobileUI}
        </BasicModal>
        <br />
        {liveTranscript}
      </>
    );
  }

  return (
    <>
      <ContentWrapper>{DraftUI}</ContentWrapper>
      {!!currentLiveQuestion?.spokenQuestion && QuestionUI}
      {liveTranscript}
    </>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const ItalyFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_331)">
    <g clipPath="url(#clip1_104_331)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H8V20H1V4Z" fill="#249F58"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M16 4H23V20H16V4Z" fill="#F93939"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_104_331">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_104_331">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
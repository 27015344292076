import { ReactNode, useCallback, useState, useMemo, useEffect } from "react";
// Components
import { BasicModal } from "../Modals/BasicModal";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { Chevron } from "../Chevron";
// Styles
import {
  Container,
  HeaderSection,
  Label,
  NameWrapper,
  ImageWrapper,
  PopupContentWrapper,
  PopupOptions,
  PopupOption,
  DropdownOptions,
  DropdownOption,
  Title,
  Description,
  DescriptionWrapper,
  Placeholder,
} from "./popup-dropdown.style";

interface Option {
  value: string;
  label: string;
  image?: any;
  description?: string;
}

interface PopupDropdownProps {
  options: Option[];
  onSelect: (option: Option) => void;
  defaultOption: Option;
  icon?: ReactNode;
  popupTitle?: string;
  popupDescription?: string;
  dropdownOnly?: boolean;
  externalOpen?: boolean;
  placeholderText?: string;
  openToTop?: boolean;
}

export const PopupDropdown: React.FC<PopupDropdownProps> = ({
  icon = null,
  options,
  onSelect,
  defaultOption,
  popupTitle = "",
  popupDescription = "",
  dropdownOnly = false,
  externalOpen = false,
  placeholderText = "",
  openToTop = false,
}) => {
  const [isOpen, setIsOpen] = useState<boolean>(false);
  const toggleOpen = useCallback(() => setIsOpen((v) => !v), [setIsOpen]);
  const setClosed = useCallback(() => setIsOpen(false), [setIsOpen]);
  const sortOptions = useCallback(
    (a, b) =>
      a.value === defaultOption.value
        ? -1
        : b.value === defaultOption.value
        ? 1
        : 0,
    [defaultOption]
  );
  const sortedOptions = useMemo(
    () => options.sort(sortOptions),
    [options, sortOptions]
  );
  useEffect(() => {
    setIsOpen(externalOpen);
  }, [externalOpen]);
  return (
    <>
      <Container className="popup-dropdown">
        <HeaderSection
          onClick={toggleOpen}
          $isOpen={isOpen}
          $isDropdownOnly={dropdownOnly}
          className="popup-dropdown-header"
        >
          <ReturnJSX if={dropdownOnly} else={icon}>
            {defaultOption?.image}
          </ReturnJSX>
          <ReturnJSX
            if={dropdownOnly && !defaultOption}
            else={<Label>{defaultOption.label}</Label>}
          >
            <Placeholder>{placeholderText}</Placeholder>
          </ReturnJSX>
          <Chevron isOpen={isOpen} special={dropdownOnly} />
        </HeaderSection>
        <ReturnJSX if={dropdownOnly && isOpen}>
          <DropdownOptions
            $openToTop={openToTop}
            className="popup-dropdown-list"
          >
            {sortedOptions.map((option) => (
              <DropdownOption
                key={option.value}
                $isSelected={option.value === defaultOption.value}
                onClick={() => {
                  onSelect(option);
                  setClosed();
                }}
              >
                <NameWrapper>
                  {option?.image && <ImageWrapper>{option.image}</ImageWrapper>}
                  <p>{option.label}</p>
                </NameWrapper>
                {option?.description && (
                  <DescriptionWrapper>{option.description}</DescriptionWrapper>
                )}
              </DropdownOption>
            ))}
          </DropdownOptions>
        </ReturnJSX>
      </Container>
      {!dropdownOnly && (
        <BasicModal
          closeModal={setClosed}
          withLogo
          withCloseBtn={false}
          show={isOpen}
          contentWrapper={PopupContentWrapper}
        >
          <Title>{popupTitle}</Title>
          <Description>{popupDescription}</Description>
          <PopupOptions>
            {sortedOptions.map((option) => (
              <PopupOption
                key={option.value}
                $isSelected={option.value === defaultOption.value}
                onClick={() => {
                  onSelect(option);
                  setClosed();
                }}
              >
                <NameWrapper>
                  {option?.image && <ImageWrapper>{option.image}</ImageWrapper>}
                  <p>{option.label}</p>
                </NameWrapper>
                {option?.description && (
                  <DescriptionWrapper>{option.description}</DescriptionWrapper>
                )}
              </PopupOption>
            ))}
          </PopupOptions>
        </BasicModal>
      )}
    </>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const USAFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_102_55785)">
    <g clipPath="url(#clip1_102_55785)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M1 4H10.4286V11.4667H1V4Z" fill="#1A47B8"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.4286 4V5.06667H23V4H10.4286ZM10.4286 6.13333V7.2H23V6.13333H10.4286ZM10.4286 8.26667V9.33333H23V8.26667H10.4286ZM10.4286 10.4V11.4667H23V10.4H10.4286ZM1 12.5333V13.6H23V12.5333H1ZM1 14.6667V15.7333H23V14.6667H1ZM1 16.8V17.8667H23V16.8H1ZM1 18.9333V20H23V18.9333H1Z" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M2.04761 5.06668V6.13335H3.09523V5.06668H2.04761ZM4.14285 5.06668V6.13335H5.19046V5.06668H4.14285ZM6.23808 5.06668V6.13335H7.2857V5.06668H6.23808ZM8.33332 5.06668V6.13335H9.38094V5.06668H8.33332ZM7.2857 6.13335V7.20001H8.33332V6.13335H7.2857ZM5.19046 6.13335V7.20001H6.23808V6.13335H5.19046ZM3.09523 6.13335V7.20001H4.14285V6.13335H3.09523ZM2.04761 7.20001V8.26668H3.09523V7.20001H2.04761ZM4.14285 7.20001V8.26668H5.19046V7.20001H4.14285ZM6.23808 7.20001V8.26668H7.2857V7.20001H6.23808ZM8.33332 7.20001V8.26668H9.38094V7.20001H8.33332ZM2.04761 9.33335V10.4H3.09523V9.33335H2.04761ZM4.14285 9.33335V10.4H5.19046V9.33335H4.14285ZM6.23808 9.33335V10.4H7.2857V9.33335H6.23808ZM8.33332 9.33335V10.4H9.38094V9.33335H8.33332ZM7.2857 8.26668V9.33335H8.33332V8.26668H7.2857ZM5.19046 8.26668V9.33335H6.23808V8.26668H5.19046ZM3.09523 8.26668V9.33335H4.14285V8.26668H3.09523Z" fill="white"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_102_55785">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_102_55785">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
// Styles
import {
  LoadingAnimationWrapper,
  StripesWrapper,
  BackgroundStripe,
  LoadingStripe,
  CircleWrapper,
  StyledSpinner,
} from "./loading-animation.style";
// Pictures
import Logo from "../NavBar/logo.svg";

interface LoadingAnimationProps {
  circle?: boolean;
}

export const LoadingAnimation: React.FC<LoadingAnimationProps> = ({ circle = false }) => {
  const windowHeight = window?.innerHeight || 0;
  if (circle) {
    return (
      <CircleWrapper style={{ height: windowHeight }}>
        <StyledSpinner />
      </CircleWrapper>
    )
  }
  return (
    <LoadingAnimationWrapper>
      <img src={Logo} />
      <StripesWrapper>
        <BackgroundStripe />
        <LoadingStripe />
      </StripesWrapper>
    </LoadingAnimationWrapper>
  );
}

import styled from "styled-components";

export const ContentWrapper = styled.div`
  padding: 24px;
  padding-bottom: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #101212;
  border-radius: 17px;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  margin-top: 24px;
  min-width: 650px;
  max-width: calc(1184px - 20%);
  margin-bottom: 40px;
`;

export const MobileContentWrapper = styled.div`
  padding: 24px;
  padding-bottom: 16px;
  margin: auto;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: white;
  color: #101212;
  border-radius: 17px;
`;

export const TopWrapper = styled.div`
  display: flex;
  width: 100%;
  justify-content: space-between;
  align-items: center;
  margin-bottom: 24px;
`;

export const TopButtonsWrapper = styled.div`
  display: flex;
  gap: 16px;
`;
  
export const Title = styled.div`
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  letter-spacing: -0.352px;
  width: 100%;
  margin-bottom: 10px;
  margin-top: 16px;
`;

export const Textarea = styled.textarea`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 40px 12px 14px;
  border: 1.5px solid rgb(209, 209, 209);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  resize: none;
  overflow: auto;
  height: 20vh;
  scrollbar-width: thin;
  scrollbar-color: transparent transparent;
  &:disabled {
    cursor: default;
    background-color: white !important;
    border: 1.5px solid rgb(209, 209, 209) !important;
    color: unset !important;
  }
  &:focus {
    border: 1.5px solid rgb(255, 109, 52);
    outline: none;
  }
  @media screen and (max-width: 767px) {
    height: 20vh;
  }
  &::-webkit-scrollbar {
    width: 12px;
  }
  &::-webkit-scrollbar-thumb {
    background-color: transparent;
    border-radius: 8px;
  }
  &::-webkit-scrollbar-thumb:hover {
    background-color: #c1c1c1;
  }
  &::-webkit-scrollbar-track {
    background-color: transparent;
  }
`;

export const BottomWrapper = styled.div`
  margin-top: 24px;
  display: flex;
  gap: 24px;
  justify-content: center;
  width: 100%;
  @media screen and (max-width: 767px) {
    gap: 16px;
  }
`;

export const RecordButton = styled.div<{ $isMobile?: boolean,  $isConvertToWriting?: boolean }>`
  display: flex;
  min-width: 178px;
  justify-content: center;
  @media screen and (max-width: 767px) {
    min-width: unset;
    max-width: 100%;
    height: 57.6px;
  }
  @media screen and (max-width: 360px) {
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
    height: 57.6px;
  }
  padding: 4.8px 8px;
  align-items: center;
  gap: 4px;
  border-radius: 16px;
  background: #ff6d34;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  cursor: pointer;
  color: white;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.352px;
  transition: all 0.2s ease-in-out;
  &:disabled {
    background: #ababab;
    color: rgba(255, 255, 255, 0.67);
    &:hover {
      background: #ababab;
      color: rgba(255, 255, 255, 0.67);
    }
  }
  ${props => props.$isConvertToWriting ? `
    svg {
      fill: white;
    }
  ` : ""}
`;

export const IconWrapper = styled.div`
  width: 46px;
  height: 46px;
  border-radius: 46px;
  @media screen and (max-width: 767px) {
    min-width: 40px;
    max-width: 40px;
    height: 40px;
    border-radius: 40px;
  }
  @media screen and (max-width: 360px) {
    min-width: 32px;
    max-width: 32px;
    max-height: 32px;
    border-radius: 32px;
  }
  background: white;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 3px solid #ffbca1;
`;

export const InputLanguagesWrapper = styled.div`
  display: flex;
  &.hide {
    display: none;
  }
`;

export const QuestionWrapper = styled.div`
  margin: -16px auto auto;
  /* min-width: 650px; */
  max-width: calc(1184px - 20%);
  @media screen and (max-width: 767px) {
    margin: 0;
    padding: 0;
    box-shadow: none;
    min-width: 100%;
    max-width: none;
  }
  border-radius: 16px;
  background: #fff;
  display: flex;
  padding: 12px 16px;
  flex-direction: column;
  align-items: center;
  gap: 8px;
  box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
  img {
    cursor: pointer;
    transition: background-color 0.3s ease;
    &:hover {
      background-color: #fffbf9;
    }
  }
`;

export const QuestionText = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  gap: 8px;
  @media screen and (max-width: 767px) {
    max-width: 75%;
    text-align: center;
  }
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.352px;
`;

export const ExplanationText = styled.div`
  margin-top: 8px;
  color: #a7a7a7;
  text-align: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 19.6px;
  letter-spacing: -0.308px;
`;

export const DoctypeInput = styled.input`
  width: 100%;
  height: 100%;
  border-radius: 8px;
  text-align: start;
  font-size: 16px;
  font-weight: 400;
  line-height: 20px;
  padding: 12px 40px 12px 14px;
  border: 1.5px solid rgb(209, 209, 209);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  resize: none;
  overflow: auto;
  height: 54.4px;
  display: block;
  &:focus {
    border: 1.5px solid rgb(255, 109, 52);
    outline: none;
  }
`;

export const FieldsWrapper =  styled.div`
  display: grid;
  grid-template-columns: 1.6fr 0.4fr;
  grid-template-rows: 1fr;
  width: 100%;
  border: 1.5px solid rgb(209, 209, 209);
  box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
  border-radius: 8px;
  background: white;
  position: relative;
  input {
    padding-right: 12px;
    border: none;
    box-shadow: none;
    &:focus {
      border: none;
    }
  }
  .popup-dropdown {
    position: static;
  }
  .popup-dropdown-header {
    border: none;
    justify-content: flex-end;
    height: 54.4px;
    min-width: 100%;
    padding-left: 0;
    svg {
      path {
        stroke: #fa8152 !important;
      }
      margin-left: unset;
    }
  }
  .popup-dropdown-list {
    border-radius: 12px;
    right: 0;
    width: 260px;
    @media screen and (max-width: 767px) {
      min-height: unset;
    }
  }
    
  
`;
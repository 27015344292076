import {
  createContext,
  useCallback,
  useContext,
  useEffect,
  useMemo,
  useState,
} from "react";
import { MicRecorder } from "../utils/mic-recorder";
import { useRef } from "react";
import {
  useGlobal,
  trackError,
  trackEvent,
  useAccount,
} from "@considr-it/storied-shared";
import { RecordingObjectType, SttProvider } from "@considr-it/storied-enums";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
import { useConfirmModal } from "../hooks/use-confirm-modal";
import { DeepGramStt } from "../classes/DeepGramStt";

export enum MicRecorderState {
  IDLE,
  RECORDING,
  PAUSED_RECORDING,
  INITIALISING,
  PAUSING,
  PROCESSING_AUDIO,
}

export const useMicRecorderProvider = () => {
  const { account } = useAccount();
  const [soundDetected, setSoundDetected] = useState<boolean>(true);
  const [recordingObjectType, setRecordingObjectType] =
    useState<RecordingObjectType>(null);

  const onProcessRef =
    useRef<
      (
        _objectId: string,
        _transcript: string,
        _sttProvider: SttProvider
      ) => Promise<void>
    >(null);

  const [micRecorderState, setMicRecorderState] = useState<MicRecorderState>(
    MicRecorderState.IDLE
  );

  const [defaultAudioDevice, setDefaultAudioDevice] = useState<{
    label: string;
    deviceId: string;
  }>(null);

  const { accountMetaData } = useAccount();

  const { onPresentConfirmModal, retryModal, onDismiss } = useConfirmModal();

  const deepGramStt = useRef<DeepGramStt>(new DeepGramStt());

  const micRecorder = useRef(new MicRecorder());
  const { transport } = useGlobal();

  const isRecording = useMemo(() => {
    return micRecorderState === MicRecorderState.RECORDING;
  }, [micRecorderState]);

  const isInitialising = useMemo(() => {
    return micRecorderState === MicRecorderState.INITIALISING;
  }, [micRecorderState]);

  const isPausedRecording = useMemo(() => {
    return micRecorderState === MicRecorderState.PAUSED_RECORDING;
  }, [micRecorderState]);

  const isProcessingAudio = useMemo(() => {
    return micRecorderState === MicRecorderState.PROCESSING_AUDIO;
  }, [micRecorderState]);

  const isPausing = useMemo(() => {
    return micRecorderState === MicRecorderState.PAUSING;
  }, [micRecorderState]);

  const isBusy = useMemo(() => {
    return micRecorderState !== MicRecorderState.IDLE;
  }, [micRecorderState]);

  const transcriptRef = useRef<string>(null);
  const sttProviderRef = useRef<SttProvider>(null);

  const onDeviceChange = useCallback(async () => {
    const devices = await navigator.mediaDevices.enumerateDevices();

    const findDevice = devices.find(
      (d) => d.deviceId === defaultAudioDevice?.deviceId
    );

    if (!findDevice && isRecording) {
      trackEvent("audio_device_disconnected", {
        audioDevice: defaultAudioDevice,
      });

      setDefaultAudioDevice(null);
    }
  }, [defaultAudioDevice, isRecording]);

  useEffect(() => {
    navigator.mediaDevices.addEventListener("devicechange", onDeviceChange);

    return () => {
      navigator.mediaDevices.removeEventListener(
        "devicechange",
        onDeviceChange
      );
    };
  }, [onDeviceChange]);

  const start = async (
    objectType: RecordingObjectType,
    onProcess,
    onTranscribe: (_: string) => void,
    oscillatorCanvas = null
  ) => {
    if (isPausedRecording) {
      try {
        if (onTranscribe) {
          setMicRecorderState(MicRecorderState.INITIALISING);
          await deepGramStt.current.init(
            onTranscribe,
            transport,
            account.language,
            accountMetaData.customWords || [],
            account.profile?.name
          );
        }

        trackEvent("resuming_recording", { objectType, defaultAudioDevice });
        const audioDevice = await micRecorder.current.resume(
          deepGramStt.current.socket
        );

        setDefaultAudioDevice(audioDevice);
        setMicRecorderState(MicRecorderState.RECORDING);
      } catch (err) {
        trackError("error_resuming_recording", err, {
          objectType,
          defaultAudioDevice,
        });

        setMicRecorderState(MicRecorderState.IDLE);
        setRecordingObjectType(null);

        onPresentConfirmModal({
          confirmationMessage:
            "Something went wrong while initialising your microphone, please try again!",
          actions: [
            <SecondaryButton onClick={onDismiss}>Close</SecondaryButton>,
          ],
        });
      }
    } else {
      transcriptRef.current = "";
      setMicRecorderState(MicRecorderState.INITIALISING);
      setRecordingObjectType(objectType);

      trackEvent("starting_recording", { objectType, defaultAudioDevice });

      try {
        if (onTranscribe) {
          await deepGramStt.current.init(
            onTranscribe,
            transport,
            account.language,
            accountMetaData.customWords || [],
            account.profile?.name
          );
        }

        const audioDevice = await micRecorder.current.start(
          deepGramStt.current.socket,
          oscillatorCanvas,
          setSoundDetected
        );

        setDefaultAudioDevice(audioDevice);

        setMicRecorderState(MicRecorderState.RECORDING);
        onProcessRef.current = onProcess;
      } catch (err) {
        trackError("error_starting_recording", err, {
          objectType,
          defaultAudioDevice,
        });

        setMicRecorderState(MicRecorderState.IDLE);
        setRecordingObjectType(null);

        onPresentConfirmModal({
          confirmationMessage: "Check your microphone permissions",
          actions: [
            <SecondaryButton onClick={() => window.location.reload()}>
              Close
            </SecondaryButton>,
          ],
        });
      }
    }
  };

  const pause = async () => {
    setMicRecorderState(MicRecorderState.PAUSING);
    await micRecorder.current.pause();
    let transcript = deepGramStt.current.end();

    trackEvent("paused_recording", {
      objectType: recordingObjectType,
      transcriptLength: transcript.length,
      defaultAudioDevice,
    });

    if (!transcript) {
      const file = await micRecorder.current.getFile(true);

      if (!!file) {
        trackEvent("transcribing_audio", {
          fileSize: file.size / 1024 / 1024,
          defaultAudioDevice,
        });
        const { data } = await transport.post("/transcribeAudio", file);
        transcript = data.transcript;
      }
    }

    transcriptRef.current += transcript + "\n";
    setMicRecorderState(MicRecorderState.PAUSED_RECORDING);

    return transcript;
  };

  const stop = async (discardRecording: boolean = false) => {
    if (discardRecording) {
      await micRecorder.current.stop();
      deepGramStt.current.end();
      setMicRecorderState(MicRecorderState.IDLE);
      transcriptRef.current = null;

      return;
    }

    transcriptRef.current = transcriptRef.current?.trim() || "";
    setMicRecorderState(MicRecorderState.PROCESSING_AUDIO);

    try {
      trackEvent("ended_recording", {
        objectType: recordingObjectType,
        defaultAudioDevice,
      });

      await micRecorder.current.stop();

      await retryModal(
        "No transcript could be extracted from your recording.",
        async () => {
          await transcribeAndProcessTranscript();
        },
        () => window.location.reload(),
        "Retry",
        "Cancel",
        "error_transcribing_audio",
        3
      );
    } catch (audioErr) {
      trackError("error_processing_audio", audioErr);
      onPresentConfirmModal({
        confirmationMessage:
          "Unexpected error occurred while processing your audio.",
        actions: [
          <PrimaryButton onClick={() => window.location.reload()}>
            Close
          </PrimaryButton>,
        ],
      });
    }

    setRecordingObjectType(null);
    setMicRecorderState(MicRecorderState.IDLE);
  };

  const transcribeAndProcessTranscript = async () => {
    let finalTranscript = transcriptRef.current || "";
    let sttProvider = sttProviderRef.current;

    const {
      data: { objectId },
    } = await transport.post("/createObject", {
      objectType: recordingObjectType,
      transcript: finalTranscript,
    });

    if (!finalTranscript) {
      const file = await micRecorder.current.getFile(false);

      if (!!file) {
        const { data } = await transport.post(
          `/uploadAudio/${recordingObjectType}/${objectId}/true`,
          file
        );

        finalTranscript = data.transcript;
        sttProvider = data.sttProvider;
      }
    } else {
      setTimeout(async () => {
        micRecorder.current
          .getFile(false)
          .then((file: File) => {
            transport
              .post(
                `/uploadAudio/${recordingObjectType}/${objectId}/false`,
                file
              )
              .catch((err) => {
                trackError("silent_upload_audio_error", err, {
                  objectId,
                  recordingObjectType,
                });
              });
          })
          .catch((err) => {
            trackError("get_file_error", err, {
              objectId,
              recordingObjectType,
            });
          });
      });
    }

    if (!!finalTranscript) {
      await retryModal(
        "Unexpected error occurred while processing the transcript.",
        async () => {
          await onProcessRef.current(objectId, finalTranscript, sttProvider);
        },
        () => window.location.reload(),
        "Retry",
        "Cancel",
        "error_processing_transcript",
        3
      );
    } else {
      //transport.post(`/deleteObject/${recordingObjectType}/${objectId}`);

      onPresentConfirmModal({
        confirmationMessage: "We couldn't detect any text in your audio.",
        actions: [
          <SecondaryButton
            onClick={() => {
              window.location.reload();
            }}
          >
            Close
          </SecondaryButton>,
        ],
      });

      trackError(
        "missing_transcript",
        {},
        {
          objectId,
          recordingObjectType,
          defaultAudioDevice,
          sttProvider,
          finalTranscript,
          transcriptRef: transcriptRef.current,
          sttProviderRef: sttProviderRef.current,
        }
      );
    }
  };

  return {
    start,
    stop,
    pause,

    isPausing,
    isProcessingAudio,

    recordingObjectType,
    isRecording,
    isPausedRecording,
    isInitialising,

    micRecorderState,

    isBusy,

    defaultAudioDevice,
    soundDetected,
  };
};

export const MicRecorderProvider = ({ children }) => {
  const micRecorderProvider = useMicRecorderProvider();

  return (
    <MicRecorderContext.Provider value={micRecorderProvider}>
      {children}
    </MicRecorderContext.Provider>
  );
};

export type MicRecorderProps = ReturnType<typeof useMicRecorderProvider>;
export const MicRecorderContext = createContext<MicRecorderProps>(null);
export const useMicRecorder = () => useContext(MicRecorderContext);

import React from 'react'
import { SVGProps, Svg } from '..'

export const CrossIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <path d="M5.58136 5.58136C5.68984 5.47288 5.86572 5.47288 5.9742 5.58136L18.4186 18.0258C18.5271 18.1343 18.5271 18.3102 18.4186 18.4186C18.3102 18.5271 18.1343 18.5271 18.0258 18.4186L5.58136 5.9742C5.47288 5.86572 5.47288 5.68984 5.58136 5.58136Z" stroke="#FF6D34" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M18.4186 5.58136L18.7722 5.22781L18.4186 5.58136C18.5271 5.68984 18.5271 5.86572 18.4186 5.9742L18.7631 6.31868L18.4186 5.9742L5.9742 18.4186L6.31868 18.7631L5.9742 18.4186C5.86572 18.5271 5.68984 18.5271 5.58136 18.4186L5.22781 18.7722L5.58136 18.4186C5.47288 18.3102 5.47288 18.1343 5.58136 18.0258L18.0258 5.58136C18.1343 5.47288 18.3102 5.47288 18.4186 5.58136Z" stroke="#FF6D34" strokeLinecap="round" strokeLinejoin="round"/>
  </Svg>
);

  
import styled from "styled-components";

export const WritingProgressBarWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
  cursor: pointer;
  
  @media screen and (max-width: 767px) {
    justify-content: center;
    background: white;
    border-radius: 9px;
    box-shadow: 0px 4px 21px 0px rgba(0, 0, 0, 0.05);
    padding: 10px 16px;
    justify-content: center;
    align-items: center;
  }
`;

export const BarWrapper = styled.div<{ $isActive?: boolean }>`
  position: relative;
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 8px;

  font-size: 16px;
  font-weight: 500;
  font-family: "Metropolis", "Montserrat", serif;
  color: #101212;
  svg {
    width: 24px;
    height: 24px;
  }
  
  @media screen and (max-width: 767px) {
    line-height: 12px;
    flex-direction: column;
    font-size: 12px;
    span {
      white-space: nowrap;
      color: ${props => props.$isActive ? '#4CBF05' : '#C1C1C1'};
    }
    svg {
      width: 16px;
      height: 16px;
    }
  }
`;

export const IconCircleBackground = styled.div<{ $isActive?: string }>`
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 8px;
  background: ${props => props.$isActive ? '#CBF3B2' : '#EBEBEB'};
  border-radius: 50%;
`;

export const DividerWrapper = styled.div<{ fill: string }>`
  width: 64px;
  height: 1.5px;
  background: ${props => props.fill};
  position: relative;
  @media screen and (max-width: 767px) {
    width: 100%;
  }
  &:after {
    content: '';
    position: absolute;
    width: 7px;
    height: 7px;
    border-radius: 100%;
    background: ${props => props.fill};
    right: -3px;
    top: -3px;
  }
`;

export const ItemWrapper = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  grid-gap: 12px;
`;

export const CornerNumWrapper = styled.div`
  position: absolute;
  display: flex;
  align-items: center;
  justify-content: center;
  width: 20px;
  height: 20px;
  border-radius: 50%;
  font-size: 10px;
  line-height: 10px;
  background-color: #008000c2;
  top: -10px;
  left: 27px;
  color: white;
`;
import { CardWrapper } from "./my-card.style";

const MyCard = ({ children, ...props }) => {
  return (
    <CardWrapper {...props}>
      {children}
    </CardWrapper>
  );
};

export default MyCard;
import React from 'react'
import { SVGProps, Svg } from '..'

export const RepeatIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <path d="M14.4172 8.75H19.3328C19.5978 8.75 19.7425 9.05902 19.5729 9.26256L17.1151 12.2119C16.9902 12.3618 16.7599 12.3618 16.635 12.2119L14.1772 9.26256C14.0075 9.05902 14.1523 8.75 14.4172 8.75Z" fill="#FA8152"/>
    <path d="M0.667224 11.25H5.58282C5.84777 11.25 5.99251 10.941 5.82289 10.7374L3.36509 7.78808C3.24016 7.63816 3.00989 7.63816 2.88495 7.78808L0.427154 10.7374C0.257538 10.941 0.402274 11.25 0.667224 11.25Z" fill="#FA8152"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10 3.75C8.05941 3.75 6.32573 4.63371 5.17838 6.02299C4.95858 6.28913 4.56464 6.32671 4.29849 6.1069C4.03234 5.8871 3.99477 5.49316 4.21457 5.22701C5.5892 3.56253 7.6708 2.5 10 2.5C13.6772 2.5 16.7351 5.14563 17.3764 8.63709C17.3833 8.67463 17.3899 8.71227 17.3962 8.75H16.1251C15.5464 5.89723 13.0233 3.75 10 3.75ZM3.87492 11.25C4.45369 14.1028 6.97674 16.25 10 16.25C11.9406 16.25 13.6743 15.3663 14.8217 13.977C15.0415 13.7109 15.4354 13.6733 15.7016 13.8931C15.9677 14.1129 16.0053 14.5068 15.7855 14.773C14.4108 16.4375 12.3293 17.5 10 17.5C6.32289 17.5 3.265 14.8544 2.62367 11.3629C2.61677 11.3254 2.61016 11.2877 2.60382 11.25H3.87492Z" fill="#FA8152"/>
  </Svg>
);

  
import { useCallback, useEffect, useRef } from "react";

export const useIsMounted = () => {
  const ref = useRef(false);
  const isMounted = useCallback(() => ref.current, []);

  const safeEventCallback = useCallback(
    (cb) => () => {
      if (ref.current) cb();
    },
    []
  );

  useEffect(() => {
    ref.current = true;
    return () => {
      ref.current = false;
    };
  }, []);

  return {
    isMounted,
    safeEventCallback,
  };
};

import { useGlobal } from "@considr-it/storied-shared";
import { useNavigate } from "react-router-dom";
import styled from "styled-components";
// Icons
import { NoteIcon } from "../IconsSvg";
// Styles
const WriteNoteWrapper = styled.div`
  position: relative;
  display: inline-block;
  z-index: 1;
  user-select: none;
  display: flex;
  align-items: center;
  padding: 8px 16px;
  gap: 10px;
  cursor: pointer;
  border-radius: 100px;
  border: 1.5px solid #ff6d34;
  color: #101212;
  font-size: 16px;
  font-weight: 500;
  line-height: 20.8px;
  white-space: nowrap;
  user-select: none;
  @media screen and (max-width: 360px) {
    font-size: 12px;
    line-height: 16.8px;
  }
`;

export const WriteNote = () => {
  const navigate = useNavigate();
  const { isSmallMobile } = useGlobal()
  const iconSize = isSmallMobile ? 14 : 20;
  return (
    <WriteNoteWrapper onClick={() => navigate("draft")}>
      <NoteIcon height={iconSize} width={iconSize} />
      Start a draft
    </WriteNoteWrapper>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const NoteIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="25"
    height="24"
    viewBox="0 0 25 24"
    fill="none"
    {...props}
  >
    <path d="M17.0215 10.3125C17.0215 9.79472 16.6018 9.375 16.084 9.375H6.42773C5.90995 9.375 5.49023 9.79472 5.49023 10.3125C5.49023 10.8303 5.90995 11.25 6.42773 11.25H16.084C16.6018 11.25 17.0215 10.8303 17.0215 10.3125Z" fill="#FF6D34"/>
    <path d="M6.42773 13.125C5.90995 13.125 5.49023 13.5447 5.49023 14.0625C5.49023 14.5803 5.90995 15 6.42773 15H12.2923C12.8101 15 13.2298 14.5803 13.2298 14.0625C13.2298 13.5447 12.8101 13.125 12.2923 13.125H6.42773Z" fill="#FF6D34"/>
    <path d="M8.63103 22.125H5.49561C4.46173 22.125 3.62061 21.2839 3.62061 20.25V3.75C3.62061 2.71613 4.46173 1.875 5.49561 1.875H17.0217C18.0555 1.875 18.8967 2.71613 18.8967 3.75V9.51562C18.8967 10.0334 19.3164 10.4531 19.8342 10.4531C20.3519 10.4531 20.7717 10.0334 20.7717 9.51562V3.75C20.7717 1.68225 19.0894 0 17.0217 0H5.49561C3.42786 0 1.74561 1.68225 1.74561 3.75V20.25C1.74561 22.3177 3.42786 24 5.49561 24H8.63103C9.14881 24 9.56853 23.5803 9.56853 23.0625C9.56853 22.5447 9.14881 22.125 8.63103 22.125Z" fill="#FF6D34"/>
    <path d="M22.4321 13.5726C21.3355 12.476 19.5513 12.4759 18.4554 13.5718L13.3084 18.7074C13.1991 18.8165 13.1184 18.9509 13.0735 19.0986L11.9526 22.7888C11.8532 23.116 11.9398 23.4713 12.1786 23.716C12.3575 23.8994 12.6005 23.9989 12.8497 23.9989C12.9331 23.9989 13.0173 23.9877 13.0999 23.9648L16.8839 22.9166C17.0396 22.8735 17.1816 22.7909 17.2959 22.6767L22.4322 17.5501C23.5288 16.4535 23.5288 14.6692 22.4321 13.5726ZM16.1458 21.1755L14.2421 21.7028L14.7992 19.8687L18.2722 16.4034L19.5982 17.7295L16.1458 21.1755ZM21.107 16.2237L20.9254 16.405L19.5995 15.0791L19.7805 14.8985C20.1461 14.5329 20.7408 14.5329 21.1064 14.8985C21.4719 15.264 21.4719 15.8587 21.107 16.2237Z" fill="#FF6D34"/>
    <path d="M16.084 5.625H6.42773C5.90995 5.625 5.49023 6.04472 5.49023 6.5625C5.49023 7.08028 5.90995 7.5 6.42773 7.5H16.084C16.6018 7.5 17.0215 7.08028 17.0215 6.5625C17.0215 6.04472 16.6018 5.625 16.084 5.625Z" fill="#FF6D34"/>
  </Svg>
);

import styled from "styled-components";

export const ConversationThread = styled.div`
  font-family: Arial, sans-serif;
  border: 1px solid #e1e4e8;
  border-radius: 8px;
  background-color: white;
`;

export const Message = styled.div`
  padding: 12px;
  border-bottom: 1px solid #e1e4e8;
`;

export const AvatarContainer = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  margin-right: 12px;
`;

export const Avatar = styled.img`
  width: 40px;
  height: 40px;
  border-radius: 50%;
  margin-bottom: 10px;
`;

export const PlayButton = styled.div`
  width: 20px;
  height: 20px;
  color: white;
  border-radius: 50%;
  display: flex;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  font-size: 10px;
`;

export const MessageContainer = styled.div`
  position: relative;
`;

export const MessageHeader = styled.div`
  display: flex;
  align-items: flex-start;
  margin-bottom: 6px;
`;

export const UserInfo = styled.div`
  display: flex;
  align-items: center;
  margin-bottom: 2px;
`;

export const Username = styled.span`
  font-weight: bold;
  margin-right: 4px;
`;

export const Timestamp = styled.span`
  color: #657786;
  font-size: 14px;
  display: block;
  margin-bottom: 4px;
`;

export const MessageText = styled.p`
  margin: 0;
  line-height: 1.4;
  white-space: pre-wrap;
`;

export const MessageContent = styled.div`
  flex: 1;
`;

export const ContextMenuButton = styled.button`
  position: absolute;
  top: 0;
  right: 0;
  background: none;
  border: none;
  font-size: 18px;
  color: #657786;
  cursor: pointer;
  padding: 5px;
`;

export const ContextMenu = styled.div`
  position: absolute;
  top: 25px;
  right: 0;
  background-color: white;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  box-shadow: 0 2px 10px rgba(0, 0, 0, 0.1);
  z-index: 10;
`;

export const ContextMenuItem = styled.button`
  display: block;
  width: 100%;
  padding: 8px 12px;
  background: none;
  border: none;
  text-align: left;
  cursor: pointer;
  font-size: 14px;
  color: #333;

  &:hover {
    background-color: #f5f8fa;
  }
`;

export const EditableMessage = styled.div`
  position: relative;
  width: 100%;
`;

export const EditTextarea = styled.textarea`
  width: 100%;
  min-height: 20px;
  padding: 0;
  border: 1px solid #e1e4e8;
  border-radius: 4px;
  font-size: 14px;
  line-height: 1.4;
  font-family: inherit;
  resize: none;
  overflow: hidden;
  background-color: #f8f9fa;
  white-space: pre-wrap;
`;

export const SaveButton = styled.button`
  position: absolute;
  bottom: 8px;
  right: 8px;
  background-color: #fa8152;
  color: white;
  border: none;
  border-radius: 50%;
  width: 24px;
  height: 24px;
  font-size: 14px;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  transition: background-color 0.2s;

  &:hover {
    background-color: #1a91da;
  }
`;

export const ModalContentWrapper = styled.div`
  textarea {
    border-radius: 8px;
    text-align: start;
    font-family: Arial, sans-serif;
    font-size: 16px;
    font-weight: 400;
    line-height: 20px;
    width: 100%;
    /* padding: 6px 40px 12px 14px; */
    border: 1px solid #c1c1c1;
    box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    resize: none;
    overflow: auto;
    scrollbar-width: thin;
    scrollbar-color: transparent transparent;
    &::-webkit-scrollbar {
      width: 12px;
    }
    &::-webkit-scrollbar-thumb {
      background-color: transparent;
      border-radius: 8px;
    }
    &::-webkit-scrollbar-thumb:hover {
      background-color: #c1c1c1;
    }
    &::-webkit-scrollbar-track {
      background-color: transparent;
    }
  }

  position: relative;
  .edit-output-icon {
    position: absolute;
    right: 0px;
    top: -50px;
    &:hover {
      fill: #fa8152;
    }
  }
`;

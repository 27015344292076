import { useEffect, useRef } from "react";
import { useNavigate, useParams } from "react-router-dom";
// Components
import { PrimaryButton, SecondaryButton } from "../../components/Buttons";
import { ReturnJSX } from "../../components/ReturnJSX/ReturnJSX";
import { CustomWordsForm } from "./CustomWordsForm";
import { PersonalInfoForm } from "./PersonalInfoForm";
import { PlansForm } from "./PlansForm";
import { StickyWrapper } from "../../components/StickyWrapper";
// Icons
import {
  ArrowIcon,
  BinIcon,
  CrownIcon,
  ExitIcon,
  FilesIcon,
  PersonIcon,
} from "../../components/IconsSvg";
// Custom hooks
import useConfirmModal from "../../hooks/use-confirm-modal";
import { useAccount, useGlobal } from "@considr-it/storied-shared";
// Styles
import {
  ProfileDesktopWrapper,
  Section,
  Title,
  Menu,
  MenuItemsWrapper,
  MenuItem,
  CategoryTitle,
  ProfileMobileWrapper,
  TitleMobile,
  MenuMobile,
  MobileMenuItem,
  BackToSettingsButton,
} from "./profile.style";


export const Profile = () => {
  const { account } = useAccount();
  const { category } = useParams();
  const { isMobile } = useGlobal();
  const { logout, deleteAccount } = useAccount();
  const navigate = useNavigate();
  const menuMobileRef = useRef(null);
  const {
    onPresentConfirmModal: openLogoutModal,
    onDismiss: closeLogoutModal,
  } = useConfirmModal();
  const {
    onPresentConfirmModal: openDeleteAccModal,
    onDismiss: closeDeleteAccModal,
  } = useConfirmModal();

  const goToPersonalInfo = () => navigate("/profile/personal");
  const goToCustomWords = () => navigate("/profile/custom-words");
  const goToProfile = () => navigate("/profile");
  const goToPlans = () => navigate("/profile/plans");

  const setLogoutModal = () => {
    openLogoutModal({
      confirmationMessage: "Are you sure you want to log out?",
      actions: [
        <PrimaryButton onClick={logout}>Log out</PrimaryButton>,
        <SecondaryButton onClick={closeLogoutModal}>Cancel</SecondaryButton>,
      ],
    });
  };

  const setDeleteAccModal = () => {
    openDeleteAccModal({
      confirmationMessage: "Are you sure you want to delete your account?",
      description:
        "This action cannot be undone. You will lose all of your documents.",
      actions: [
        <PrimaryButton onClick={deleteAccount}>
          Delete my account
        </PrimaryButton>,
        <SecondaryButton onClick={closeDeleteAccModal}>Cancel</SecondaryButton>,
      ],
    });
  };

  useEffect(() => {
    if (menuMobileRef?.current && isMobile && !category) {
      const el = menuMobileRef.current;
      const bottomNavBar = document.getElementById("bottom-nav-bar");
      const height =
        bottomNavBar.getBoundingClientRect().top - 
        el.getBoundingClientRect().top -
        32;
      el.style.height = `${height}px`;
    }
  }, [menuMobileRef, isMobile, category]);

  if (account.isAnonymous) {
    return <></>;
  }

  return (
    <>
      <ReturnJSX if={!isMobile}>
        <ProfileDesktopWrapper>
          <StickyWrapper>
            <Section style={{ maxHeight: 440, height: 440 }}>
              <Title>Settings</Title>
              <Menu>
                <MenuItemsWrapper>
                  <MenuItem
                    $isActive={!category || category === "personal"}
                    onClick={goToPersonalInfo}
                  >
                    <PersonIcon />
                    Personal information
                  </MenuItem>
                  <MenuItem
                    $isActive={category === "custom-words"}
                    onClick={goToCustomWords}
                  >
                    <FilesIcon />
                    Custom words
                  </MenuItem>
                  <MenuItem
                    $isActive={category === "plans"}
                    onClick={goToPlans}
                  >
                    <CrownIcon />
                    Plans
                  </MenuItem>
                </MenuItemsWrapper>
                <MenuItemsWrapper>
                  <MenuItem onClick={setLogoutModal}>
                    <ExitIcon width={24} height={24} />
                    Log out
                  </MenuItem>
                  <MenuItem onClick={setDeleteAccModal}>
                    <BinIcon />
                    Delete my account
                  </MenuItem>
                </MenuItemsWrapper>
              </Menu>
            </Section>
          </StickyWrapper>
          <Section>
            <ReturnJSX if={!category || category === "personal"}>
              <div>
                <CategoryTitle>
                  <PersonIcon />
                  Personal information
                </CategoryTitle>
                <PersonalInfoForm />
              </div>
            </ReturnJSX>
            <ReturnJSX if={category === "custom-words"}>
              <div>
                <CategoryTitle>
                  <FilesIcon />
                  Custom words
                </CategoryTitle>
                <CustomWordsForm />
              </div>
            </ReturnJSX>
            <ReturnJSX if={category === "plans"}>
              <div>
                <CategoryTitle style={{ height: 37 }}>
                  <CrownIcon />
                  Plans
                </CategoryTitle>
                <PlansForm />
              </div>
            </ReturnJSX>
          </Section>
        </ProfileDesktopWrapper>
      </ReturnJSX>
      <ReturnJSX if={isMobile && !category}>
        <ProfileMobileWrapper>
          <TitleMobile>Settings</TitleMobile>
          <MenuMobile ref={menuMobileRef}>
            <MenuItemsWrapper>
              <MobileMenuItem onClick={goToPersonalInfo}>
                <PersonIcon />
                Personal information
                <ArrowIcon className="icon" />
              </MobileMenuItem>
              <MobileMenuItem onClick={goToCustomWords}>
                <FilesIcon />
                Custom words
                <ArrowIcon className="icon" />
              </MobileMenuItem>
              <MobileMenuItem onClick={goToPlans}>
                <CrownIcon />
                Plans
                <ArrowIcon className="icon" />
              </MobileMenuItem>
            </MenuItemsWrapper>
            <MenuItemsWrapper>
              <MobileMenuItem onClick={setLogoutModal}>
                <ExitIcon width={24} height={24} />
                Log out
              </MobileMenuItem>
              <MobileMenuItem onClick={setDeleteAccModal}>
                <BinIcon />
                Delete my account
              </MobileMenuItem>
            </MenuItemsWrapper>
          </MenuMobile>
        </ProfileMobileWrapper>
      </ReturnJSX>
      <ReturnJSX if={isMobile && category}>
        <BackToSettingsButton onClick={goToProfile}>
          <ArrowIcon />
          Back to all settings
        </BackToSettingsButton>
      </ReturnJSX>
      <ReturnJSX if={isMobile && category === "personal"}>
        <TitleMobile>Personal settings</TitleMobile>
        <PersonalInfoForm />
      </ReturnJSX>
      <ReturnJSX if={isMobile && category === "custom-words"}>
        <TitleMobile>Custom words</TitleMobile>
        <CustomWordsForm />
      </ReturnJSX>
      <ReturnJSX if={isMobile && category === "plans"}>
        <TitleMobile style={{ height: 37 }}>Plans</TitleMobile>
        <PlansForm />
      </ReturnJSX>
    </>
  );
};

// Components
import { ConfirmationModal } from "../components/Modals/ConfirmModal";
import { PrimaryButton, SecondaryButton } from "../components/Buttons";
// Custom hooks
import { useModal } from "./use-modal";
// Utils
import { trackError } from "@considr-it/storied-shared";

export const useConfirmModal = () => {
  const [onPresentConfirmModal, onDismiss] = useModal(<ConfirmationModal />);

  const retryModal = async (
    mainMessage: string,
    onRetry: () => any,
    onCancel: () => void,
    retryLabel: string,
    cancelLabel: string,
    errorLogName: string,
    maxRetries: number,
    retryCount = 0
  ) => {
    await new Promise(async (resolve) => {
      try {
        await onRetry();
        resolve(true);
      } catch (err) {
        trackError(errorLogName, err);

        if (retryCount < maxRetries) {
          onPresentConfirmModal({
            confirmationMessage: mainMessage,
            actions: [
              <PrimaryButton
                onClick={async () => {
                  await retryModal(
                    mainMessage,
                    onRetry,
                    onCancel,
                    retryLabel,
                    cancelLabel,
                    errorLogName,
                    maxRetries,
                    retryCount + 1
                  );
                }}
              >
                {retryLabel}
              </PrimaryButton>,
              <SecondaryButton onClick={onCancel}>
                {cancelLabel}
              </SecondaryButton>,
            ],
          });
        } else {
          onPresentConfirmModal({
            confirmationMessage:
              "Something bad happened, please help us with some more context at support@vnote.ai",
            actions: [
              <PrimaryButton
                onClick={() => {
                  window.location.reload();
                }}
              >
                Close
              </PrimaryButton>,
            ],
          });
        }
      }
    });
  };

  return { onPresentConfirmModal, onDismiss, retryModal };
};

export default useConfirmModal;

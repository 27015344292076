import styled from "styled-components";

export const BottomNavBarWrapper = styled.div<{ $addHeight: boolean }>`
  display: none;
  position: fixed;
  bottom: 0;
  left: 0;
  right: 0;
  height: ${(props) => (props.$addHeight ? "88px" : "64px")};
  border-radius: 24px 24px 0px 0px;
  background: #fff;
  z-index: 1;
  filter: drop-shadow(0px -4px 17.8px rgba(32, 10, 1, 0.06));
  align-items: center;
  padding: ${(props) =>
    props.$addHeight ? "0px 24px 32px 24px" : "0px 24px 8px 24px"};
  @media screen and (max-width: 767px) {
    &.fade-out {
      opacity: 0;
      pointer-events: none;
      cursor: default;
    }
    display: flex;
    transition: opacity 1s ease-in-out;
    opacity: 1;
  }
  @media screen and (max-width:  360px) {
    border-radius: 16px 16px 0px 0px;
    height: ${(props) => (props.$addHeight ? "80px" : "56px")};
    padding: ${(props) =>
      props.$addHeight ? "0px 24px 24px 24px" : "0px 20px 8px 20px"};
  }
`;

export const ButtonsWrapper = styled.div`
  display: flex;
  height: 44px;
  width: 100%;
  justify-content: space-between;
`;

export const MenuItem = styled.div`
  width: 60px;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  cursor: pointer;
  gap: 4px;
`;

export const Text = styled.div<{ $inactive: boolean }>`
  color: ${(props) => (!props.$inactive ? "#FF6D34" : "#919191")};
  text-align: center;
  font-family: Metropolis;
  font-size: 12px;
  font-weight: 500;
  line-height: 15.6px;
  @media screen and (max-width: 360px) {
    font-size: 10px;
    font-weight: 500;
    line-height: 13px;
    letter-spacing: -0.22px;
  }
`;

import { Modal } from "react-bootstrap";
import styled from "styled-components";
// Components
import { ReturnJSX } from "../../ReturnJSX/ReturnJSX";
import { StyledModalLogo } from "../../StyledModalLogo";
// Icons
import CloseBtn from "./closeBtn.svg";
// Styles
const CloseBtnWrap = styled.div`
  width: 24px;
  height: 24px;
  position: absolute;
  right: 20px;
  top: 20px;
  cursor: pointer;
  z-index: 1;
  img {
    width: 24px;
    height: 24px;
  }
`;
const DefaultContentWrapper = styled.div`
  max-width: 518px;
  padding: 64px 80px;
  margin: auto;
  display: flex;
  flex-direction: column;
  justify-content: center;
  width: 100%;
  background-color: white;
  border-radius: 16px;
  @media screen and (max-width: 767px) {
    padding: 24px;
    padding-top: 64px;
    padding-bottom: 32px;
  }
`;

export const BasicModal = ({
  show,
  closeModal = () => {},
  withCloseBtn = true,
  children,
  withLogo = false,
  contentWrapper = null,
  disableOnHide = false,
  transparentBackdrop = false,
  wideModal = false,
  style = {},
}) => {
  const ContentWrapper = contentWrapper ?? DefaultContentWrapper;
  return (
    <Modal
      backdropClassName={transparentBackdrop ? "modal-transparent" : ""}
      dialogClassName={wideModal ? "modal-wide" : ""}
      show={show}
      centered
      onHide={!disableOnHide ? closeModal : () => {}}
    >
      <ReturnJSX if={withLogo}>
        <StyledModalLogo />
      </ReturnJSX>
      <ReturnJSX if={withCloseBtn}>
        <CloseBtnWrap onClick={closeModal}>
          <img src={CloseBtn} />
        </CloseBtnWrap>
      </ReturnJSX>
      <ContentWrapper style={style}>{children}</ContentWrapper>
    </Modal>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const MicIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="20"
    height="20"
    viewBox="0 0 20 20"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_7409_31131)">
      <path d="M16.6512 9.12262C16.6512 8.81328 16.4004 8.5625 16.091 8.5625C15.7817 8.5625 15.5309 8.81328 15.5309 9.12262C15.5309 12.1082 13.102 14.5371 10.1164 14.5371C7.13085 14.5371 4.70191 12.1082 4.70191 9.12262C4.70191 8.81328 4.45112 8.5625 4.14179 8.5625C3.83245 8.5625 3.58167 8.81328 3.58167 9.12262C3.58167 12.5372 6.21427 15.348 9.55629 15.6331V18.1592H6.53164C6.2223 18.1592 5.97151 18.41 5.97151 18.7194C5.97151 19.0287 6.2223 19.2795 6.53164 19.2795H13.7012C14.0105 19.2795 14.2613 19.0287 14.2613 18.7194C14.2613 18.41 14.0105 18.1592 13.7012 18.1592H10.6765V15.6331C14.0185 15.348 16.6512 12.5372 16.6512 9.12262Z" fill="#FF6D34"/>
      <path d="M12.2083 9.68612C11.899 9.68612 11.6482 9.43533 11.6482 9.126C11.6482 8.81666 11.899 8.56587 12.2083 8.56587H14.2621V7.29627H11.6108C11.3015 7.29627 11.0507 7.04548 11.0507 6.73615C11.0507 6.42681 11.3015 6.17603 11.6108 6.17603H14.2621V4.90642H12.2083C11.899 4.90642 11.6482 4.65563 11.6482 4.3463C11.6482 4.03696 11.899 3.78618 12.2083 3.78618H14.2478C14.0782 1.76033 12.3911 0.164062 10.3412 0.164062H9.89314C7.84332 0.164062 6.15608 1.76037 5.98652 3.78618H8.02607C8.3354 3.78618 8.58619 4.03696 8.58619 4.3463C8.58619 4.65563 8.3354 4.90642 8.02607 4.90642H5.97229V6.17603H8.62353C8.93286 6.17603 9.18365 6.42681 9.18365 6.73615C9.18365 7.04548 8.93286 7.29627 8.62353 7.29627H5.97229V8.56587H8.02607C8.3354 8.56587 8.58619 8.81666 8.58619 9.126C8.58619 9.43533 8.3354 9.68612 8.02607 9.68612H5.98685C6.15791 11.6913 7.8444 13.2709 9.89314 13.2709H10.3412C12.39 13.2709 14.0765 11.6913 14.2475 9.68612H12.2083Z" fill="#FF6D34"/>
    </g>
    <defs>
      <clipPath id="clip0_7409_31131">
        <rect width="19.1188" height="19.1188" fill="white" transform="translate(0.557617 0.164062)"/>
      </clipPath>
    </defs>
  </Svg>
);

  
import React from 'react'
import { SVGProps, Svg } from '..'

export const ChinaFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_237)">
    <g clipPath="url(#clip1_104_237)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#F93939"/>
    <path d="M20.9048 4H3.09524C1.93807 4 1 4.95513 1 6.13333V17.8667C1 19.0449 1.93807 20 3.09524 20H20.9048C22.0619 20 23 19.0449 23 17.8667V6.13333C23 4.95513 22.0619 4 20.9048 4Z" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M6.75992 11.712L5.21991 12.5365L5.51325 10.7904L4.26868 9.55302L5.98992 9.30022L6.75992 7.71088L7.52887 9.30022L9.25011 9.55302L8.00344 10.7904L8.29887 12.5354L6.75992 11.712ZM10.4287 7.19995H11.4763V8.26662H10.4287V7.19995ZM11.4763 9.33328H12.5239V10.4H11.4763V9.33328ZM11.4763 11.4666H12.5239V12.5333H11.4763V11.4666ZM10.4287 13.6H11.4763V14.6666H10.4287V13.6Z" fill="#FFDA2C"/>
    </g>
    </g>
    <defs>
    <clipPath id="clip0_104_237">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    <clipPath id="clip1_104_237">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    </clipPath>
    </defs>
  </Svg>
);

  
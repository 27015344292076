import { useEffect, useState, useRef } from "react";
import { Accordion } from "react-bootstrap";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
import { useIdeaFlow } from "../../../hooks/use-idea-flow";
import { useImageUpload } from "../../../hooks/use-image-upload";
import { useDocument } from "../../../hooks/use-document";
import useConfirmModal from "../../../hooks/use-confirm-modal";
// Components
// import TypeOrRecordForm from "../../../components/TypeOrRecordForm";
import {
  InputButton,
  PrimaryButton,
  SecondaryButton,
} from "../../../components/Buttons";
import { ProcessingCircle } from "../../../components/ProcessingCircle";
// Icons
import { ImageIcon, MagicIcon } from "../../../components/IconsSvg";
// Constants
import { Image } from "@considr-it/storied-entities";
import { ImageType, RecordingObjectType } from "@considr-it/storied-enums";
// Styles
import {
  ButtonsWrapper,
  HRLine,
  OrElement,
  OrElementWrapper,
  TitleWrapper,
} from "../writing-feedback.style";
import { ExternalImageInput } from "../../../components/ExternalmageInput";

const GenerateCoverImage = ({
  eventKey,
  activeKey,
  forMobile = false,
  openDefaultSetting = null,
  children = undefined,
}) => {
  const { handleClickInput, handleLocalImageChange, handleLocalImageRemove } =
    useImageUpload();
  const { currentTopic } = useDocument();
  const [showGenerateButton, setShowGenerateButton] = useState<boolean>(true);
  const { onPresentConfirmModal, onDismiss } = useConfirmModal();
  const { transport } = useGlobal();
  const { currentOutput } = useIdeaFlow();
  const [image, setImage] = useState<Image>(null);
  const [generatingEditorToolsImage, setGeneratingEditorToolsImage] =
    useState<boolean>(false);
  const { revalidateCoverImage } = useIdeaFlow();
  const [imageLoaded, setImageLoaded] = useState<boolean>(false);
  const checkFieldIntervalRef = useRef(null);

  const fetchImage = async () => {
    const resp = await transport.get<Image>(
      `/image/${ImageType.TopicEditorTools}/${currentTopic.id}`
    );

    if (resp.data) {
      setGeneratingEditorToolsImage(resp.data.generatingImage);
      setImage(resp.data);
    } else {
      setGeneratingEditorToolsImage(false);
      setImage(null);
    }
  };

  useEffect(() => {
    return () => {
      if (checkFieldIntervalRef.current) {
        clearInterval(checkFieldIntervalRef.current);
        checkFieldIntervalRef.current = null;
      }
    };
  }, []);

  useEffect(() => {
    fetchImage();
  }, [currentTopic]);

  useEffect(() => {
    if (generatingEditorToolsImage && !checkFieldIntervalRef.current) {
      checkFieldIntervalRef.current = setInterval(fetchImage, 5000);
    } else if (!generatingEditorToolsImage && !!checkFieldIntervalRef.current) {
      clearInterval(checkFieldIntervalRef.current);
      checkFieldIntervalRef.current = null;
    }
  }, [generatingEditorToolsImage]);

  const generateNewCoverImage = async () => {
    setGeneratingEditorToolsImage(true);

    await transport.post(
      `/generateCoverImage/${ImageType.TopicEditorTools}/${currentTopic.id}`,
      {
        topicId: currentOutput.topic,
        outputId: currentOutput.id,
      }
    );

    fetchImage();
  };

  const insertCoverImage = async () => {
    setImage(null);
    await transport.post("/insertCoverImage", { topicId: currentTopic.id });
    await revalidateCoverImage();
    await handleLocalImageRemove();
    if (forMobile) {
      openDefaultSetting();
    }
  };

  const openUploadingModal = () => {
    onPresentConfirmModal({
      confirmationMessage: "Upload your custom picture",
      actions: [
        <ExternalImageInput cb={onDismiss} />,
        <OrElementWrapper>
          <HRLine />
          <OrElement>or</OrElement>
          <HRLine />
        </OrElementWrapper>,
        <InputButton
          type="file"
          accept=".jpg, .jpeg"
          onChange={async (e) => {
            onDismiss();
            setGeneratingEditorToolsImage(true);
            await handleLocalImageChange(e);
            setGeneratingEditorToolsImage(false);
          }}
          onClick={handleClickInput}
          fullWidth
        >
          Upload your local image
        </InputButton>
      ],
    });
  }

  const cardBody = (
    <>
      {generatingEditorToolsImage && <ProcessingCircle />}
      {!generatingEditorToolsImage &&
        (image?.imageFilePublicUrl ? (
          <div>
            <div className="generateImageWrapper">
              <img
                src={image.imageFilePublicUrl}
                width="100%"
                className="generateImage"
                onLoad={() => setImageLoaded(true)}
              />

              {imageLoaded && !image.isCustom && (
                <button
                  type="button"
                  className="coverImageBtn"
                  onClick={() => {
                    generateNewCoverImage();
                  }}
                >
                  <MagicIcon className="magicWandIcon" /> Generate new
                </button>
              )}
              {imageLoaded && image.isCustom && (
                <PrimaryButton
                  className="coverImageBtn"
                  onClick={openUploadingModal}
                >
                  Set another image
                </PrimaryButton>
              )}
            </div>
            <ButtonsWrapper style={{ marginTop: 16 }}>
              <SecondaryButton fullWidth onClick={insertCoverImage}>
                Insert into doc
              </SecondaryButton>
              <SecondaryButton
                fullWidth
                onClick={async () => {
                  setGeneratingEditorToolsImage(true);
                  setImage(null);
                  await handleLocalImageRemove();
                  setGeneratingEditorToolsImage(false);
                }}
              >
                Cancel
              </SecondaryButton>
            </ButtonsWrapper>
          </div>
        ) : (
          <>
            {/* <TypeOrRecordForm
              style={{ margin: 0 }}
              textarea
              disabled={false}
              onEntry={async (_objectId: string, transcript: string) => {
                setGeneratingEditorToolsImage(true);
                await transport.post(
                  `/generateCoverImage/${ImageType.TopicEditorTools}/${currentTopic.id}`,
                  {
                    topicId: currentOutput.topic,
                    outputId: currentOutput.id,
                    transcript,
                  }
                );
                fetchImage();
              }}
              onInteractionStartCb={() => setShowGenerateButton(false)}
              onInteractionEndCb={() => setShowGenerateButton(true)}
              placeHolderText="Describe what should it be =>"
              objectType={RecordingObjectType.IMAGE_DESCRIPTION}
            /> */}
            {showGenerateButton && (
              <>
                {/* <OrElementWrapper>
                  <HRLine />
                  <OrElement>or</OrElement>
                  <HRLine />
                </OrElementWrapper> */}
                <ButtonsWrapper>
                  <PrimaryButton onClick={generateNewCoverImage} fullWidth>
                    Generate Image
                  </PrimaryButton>
                  <PrimaryButton onClick={openUploadingModal} fullWidth>
                    Upload Image
                  </PrimaryButton>
                </ButtonsWrapper>
              </>
            )}
          </>
        ))}
    </>
  );

  if (forMobile) {
    return (
      <>
        <TitleWrapper>
          <ImageIcon style={{ marginRight: 8 }} />
          <p>Generate cover image</p>
          {children}
        </TitleWrapper>
        {cardBody}
      </>
    );
  }

  return (
    <Accordion.Item
      eventKey={eventKey}
      className={activeKey === eventKey ? "accordion-active" : ""}
    >
      <Accordion.Header>
        <ImageIcon
          style={{ marginRight: 10 }}
          $inactive={activeKey !== eventKey}
        />
        Generate cover image
      </Accordion.Header>
      <Accordion.Body>{cardBody}</Accordion.Body>
    </Accordion.Item>
  );
};

export default GenerateCoverImage;

import React from 'react'
import { SVGProps, Svg } from '..'

export const JapanFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_252)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="white"/>
    <path d="M12 15.7334C14.025 15.7334 15.6666 14.0619 15.6666 12.0001C15.6666 9.93819 14.025 8.26672 12 8.26672C9.97494 8.26672 8.33331 9.93819 8.33331 12.0001C8.33331 14.0619 9.97494 15.7334 12 15.7334Z" fill="#F93939"/>
    </g>
    <defs>
    <clipPath id="clip0_104_252">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
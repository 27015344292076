import { useEffect, useRef, useState } from "react";
import styled from "styled-components";
// Components
import { PrimaryButton, SecondaryButton } from "../Buttons";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
// Icons
import { CheckmarkIcon } from "../IconsSvg";
// Custom hooks
import { useGlobal } from "@considr-it/storied-shared";
// Styles
const CustomWordsComponentWrapper = styled.div<{ $isPWA: boolean }>`
  display: flex;
  flex-wrap: nowrap;
  padding: 8px 16px;
  align-items: center;
  justify-content: center;
  gap: 16px;
  position: fixed;
  background: white;
  border-radius: 10px;
  box-shadow: 0px 2px 26.7px 0px rgba(54, 54, 54, 0.2);
  z-index: 1;
  @media screen and (max-width: 767px) {
    display: inline-flex;
    flex-wrap: nowrap;
    gap: 6px;
    position: fixed;
    width: calc(100% - 40px);
    bottom: 0;
    margin-top: auto;
    margin-bottom: ${(props) => (props.$isPWA ? 216 : 192)}px;
    left: 50%;
    transform: translate(-50%, 0);
  }
`;
const Label = styled.div`
  @media screen and (max-width: 767px) {
    white-space: wrap;
  }
  color: #101212;
  font-family: Metropolis;
  font-size: 16px;
  font-style: normal;
  font-weight: 400;
  line-height: 20.8px;
  letter-spacing: -0.352px;
`;
const ButtonsWrapper = styled.div`
  display: flex;
  gap: 10px;
  width: 128.36px;
  align-items: center;
  justify-content: center;
`;
const StatusWrapper = styled.div`
  color: #ff6d34;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 8px;
  height: 46px;
  font-family: Metropolis;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  width: 128.36px;
`;

interface CustomWordsAddingComponentProps {
  customWord: { oldWord: string; newWord: string };
  handleCorrectedWord: (accepted: boolean) => void;
}

export const CustomWordsAddingComponent = ({
  customWord,
  handleCorrectedWord,
}: CustomWordsAddingComponentProps) => {
  const hintRef = useRef(null);
  const [showButtons, setShowButtons] = useState(true);
  const { isMobile, isPWA } = useGlobal();

  useEffect(() => {
    if (customWord && hintRef.current && !isMobile) {
      const viewerCard = document.getElementsByClassName('outputViewerCard');
      const range = document.getSelection().getRangeAt(0);
      const rangeRect = range.getBoundingClientRect();
      hintRef.current.style.top = rangeRect.top + 24 + "px";
      hintRef.current.style.left = viewerCard[0].getBoundingClientRect().left + 20 + "px";
      hintRef.current.style.maxWidth = viewerCard[0].getBoundingClientRect().width - 40 + "px";
    }
  }, [customWord, isMobile, hintRef]);

  if (customWord) {
    return (
      <CustomWordsComponentWrapper ref={hintRef} $isPWA={isPWA}>
        <Label>
          Is <strong>{customWord.newWord}</strong> the correct spelling of this
          word? Add it to your custom words dictionary.
        </Label>
        <ReturnJSX
          if={showButtons}
          else={
            <StatusWrapper>
              <CheckmarkIcon width={16} height={16} />
              Added
            </StatusWrapper>
          }
        >
          <ButtonsWrapper>
            <PrimaryButton
              onClick={() => {
                setShowButtons(false);
                setTimeout(() => {
                  handleCorrectedWord(true);
                  setShowButtons(true);
                }, 1000);
              }}
            >
              Yes
            </PrimaryButton>
            <SecondaryButton
              onClick={() => {
                handleCorrectedWord(false);
              }}
            >
              No
            </SecondaryButton>
          </ButtonsWrapper>
        </ReturnJSX>
      </CustomWordsComponentWrapper>
    );
  }
};

import styled from "styled-components";
import { storiedOrange } from "../../../constants";

export const Header = styled.p`
  margin-bottom: 0;
  color: #101212;
  text-align: center;
  font-size: 24px;
  font-weight: 600;
  line-height: 28.8px;
  @media screen and (max-width: 767px) {
    font-size: 20px;
    font-weight: 600;
    line-height: 26px;
  }
`;

export const FormSection = styled.form`
  width: 100%;
  text-align: left;
  .form-control {
    height: 46px;
    padding: 10px 14px;
    border-radius: 8px;
    border: 1px solid #C1C1C1;
    // box-shadow: 0px 1px 2px 0px rgba(0, 0, 0, 0.05);
    &::placeholder {
      color: #C1C1C1;
      font-size: 14px;
      line-height: 130%;
      letter-spacing: 0.32px;
    }
  }
  label {
    font-weight: 500;
    span {
      color: #C1C1C1;
      font-size: 12px;
      font-weight: 400;
      letter-spacing: 0.32px;
    }
  }
  .form-check-input {
    width: 16px;
    height: 16px;
    border-radius: 2px;
    border: 1px solid #FF6D34;
    cursor: pointer;
    margin-top: 1px !important;
  }
`;

export const CheckmarkLabel = styled.p`
  margin-bottom: 0;
  color: #6F6F6F;
  font-size: 14px;
  font-style: normal;
  font-weight: 400;
  line-height: 18.2px;
  a {
    text-decoration: none;
  }
  // letter-spacing: -0.32px;
`;

export const ExternalLink = styled.a`
  color: ${storiedOrange};
  text-decoration: underline;
`;

export const ButtonGroupWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 16px;
  width: 100%;
`;
import { ReactElement } from "react";
// Custom hooks
import { useAccount, useGlobal } from "@considr-it/storied-shared";
// Styles
import { InputLanguagesWrapper } from "./input-languages.style";
// Components
import { PopupDropdown } from "../PopupDropdown";
// Utils
import { checkSupportFlagEmoji } from "../../utils/check-support-flag-emoji";
import { trackEvent } from "@considr-it/storied-shared";
// Icons
import {
  GlobeIcon,
  USAFlagIcon,
  FranceFlagIcon,
  SpainFlagIcon,
  UkraineFlagIcon,
  IndiaFlagIcon,
  JapanFlagIcon,
  RussiaFlagIcon,
  SwedenFlagIcon,
  ChinaFlagIcon,
  PortugalFlagIcon,
  NetherlandsFlagIcon,
  TurkeyFlagIcon,
  GermanyFlagIcon,
  IndonesiaFlagIcon,
  SouthKoreaFlagIcon,
  ItalyFlagIcon,
  UKFlagIcon,
} from "../IconsSvg";

const flagEmojiSupported = checkSupportFlagEmoji();

interface InputLanguagesOption {
  value: string;
  label: string;
  image: string | ReactElement;
}

const inputLanguagesData: InputLanguagesOption[] = [
  {
    value: "en-US",
    label: "English (US)",
    image: flagEmojiSupported ? "🇺🇸" : <USAFlagIcon />,
  },
  {
    value: "en-GB",
    label: "English (GB)",
    image: flagEmojiSupported ? "🇬🇧" : <UKFlagIcon />,
  },
  {
    value: "es",
    label: "Spanish",
    image: flagEmojiSupported ? "🇪🇸" : <SpainFlagIcon />,
  },
  {
    value: "pt",
    label: "Portuguese",
    image: flagEmojiSupported ? "🇵🇹" : <PortugalFlagIcon />,
  },
  {
    value: "uk",
    label: "Ukrainian",
    image: flagEmojiSupported ? "🇺🇦" : <UkraineFlagIcon />,
  },
  {
    value: "zh",
    label: "Chinese",
    image: flagEmojiSupported ? "🇨🇳" : <ChinaFlagIcon />,
  },
  {
    value: "nl",
    label: "Dutch",
    image: flagEmojiSupported ? "🇳🇱" : <NetherlandsFlagIcon />,
  },
  {
    value: "fr",
    label: "French",
    image: flagEmojiSupported ? "🇫🇷" : <FranceFlagIcon />,
  },
  {
    value: "ro",
    label: "Romanian",
    image: flagEmojiSupported ? "🇷🇴" : <FranceFlagIcon />,
  },
  {
    value: "de",
    label: "German",
    image: flagEmojiSupported ? "🇩🇪" : <GermanyFlagIcon />,
  },
  {
    value: "hi",
    label: "Hindi",
    image: flagEmojiSupported ? "🇮🇳" : <IndiaFlagIcon />,
  },
  {
    value: "id",
    label: "Indonesian",
    image: flagEmojiSupported ? "🇮🇩" : <IndonesiaFlagIcon />,
  },
  {
    value: "it",
    label: "Italian",
    image: flagEmojiSupported ? "🇮🇹" : <ItalyFlagIcon />,
  },
  {
    value: "ja",
    label: "Japanese",
    image: flagEmojiSupported ? "🇯🇵" : <JapanFlagIcon />,
  },
  {
    value: "ko",
    label: "Korean",
    image: flagEmojiSupported ? "🇰🇷" : <SouthKoreaFlagIcon />,
  },
  {
    value: "ru",
    label: "Russian",
    image: flagEmojiSupported ? "🇷🇺" : <RussiaFlagIcon />,
  },
  {
    value: "sv",
    label: "Swedish",
    image: flagEmojiSupported ? "🇸🇪" : <SwedenFlagIcon />,
  },
  {
    value: "tr",
    label: "Turkish",
    image: flagEmojiSupported ? "🇹🇷" : <TurkeyFlagIcon />,
  },
];
export const InputLanguages = ({
  dropdownOnly = false,
  placeholderText = "",
  openToTop = false,
  ...rest
}) => {
  const { transport, isSmallMobile } = useGlobal();
  const { setAccount, account } = useAccount();

  const handleDropdownSelect = async (
    inputLanguagesOption: InputLanguagesOption
  ) => {
    setAccount((prev) => {
      const newAccount = { ...prev };
      newAccount.language = inputLanguagesOption.value;

      return newAccount;
    });

    transport.patch("/account", {
      payload: { language: inputLanguagesOption.value },
    });
    trackEvent("changed_language", { language: inputLanguagesOption.value });
  };

  const currentLangPos =
    inputLanguagesData.find((v) => account.language === v.value) ||
    inputLanguagesData[0];

  const selectedLanguage = currentLangPos || inputLanguagesData[0];
  const reducedSizes = isSmallMobile ? { width: 14, height: 14 } : {};
  return (
    <InputLanguagesWrapper {...rest} >
      <PopupDropdown
        icon={<GlobeIcon {...reducedSizes} />}
        options={inputLanguagesData}
        onSelect={handleDropdownSelect}
        defaultOption={selectedLanguage}
        popupTitle="Select input language"
        popupDescription="Select the preferred language and we’ll help you organize your ideas in English."
        dropdownOnly={dropdownOnly}
        placeholderText={placeholderText}
        openToTop={openToTop}
      />
    </InputLanguagesWrapper>
  );
};

import { Base } from "@considr-it/storied-entities";
import { useEffect, useState } from "react";
import { useGlobal } from "./use-global";
import { useTransportSWR } from "./use-transport";

export const useLocalCached = <T extends Base | Base[]>(
  apiRoute: string,
  localCacheKey: string,
  refreshInterval: number = 0
) => {
  const { transport } = useGlobal();

  const [localObject, setLocalObject] = useState<Partial<T>>(
    localStorage.getItem(localCacheKey)
      ? JSON.parse(localStorage.getItem(localCacheKey))
      : null
  );

  const { data, revalidate, isLoading, isValidating } = useTransportSWR<T>(
    transport,
    apiRoute,
    {
      revalidateOnFocus: false,
      revalidateIfStale: false,
      refreshInterval,
    }
  );

  useEffect(() => {
    if (!!data) {
      setLocalObject(data);
    }
  }, [data, transport]);

  useEffect(() => {
    if (localObject) {
      localStorage.setItem(localCacheKey, JSON.stringify(localObject));
    } else {
      localStorage.removeItem(localCacheKey);
    }
  }, [localObject]);

  return {
    localObject,
    setLocalObject,

    serverObject: data,
    revalidate,
    isLoading,
    isValidating,
  };
};

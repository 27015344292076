import { AnyExtension, ExtensionPriority } from "remirror";
import {
  BlockquoteExtension,
  BoldExtension,
  BulletListExtension,
  CodeExtension,
  HeadingExtension,
  ItalicExtension,
  LinkExtension,
  ListItemExtension,
  MarkdownExtension,
  OrderedListExtension,
  StrikeExtension,
  TableExtension,
  TrailingNodeExtension,
  HorizontalRuleExtension,
  HardBreakExtension,
  TextHighlightExtension,
  TextColorExtension,
  AnnotationExtension,
} from "remirror/extensions";

export const extensions = () => {
  return [
    new LinkExtension({
      autoLink: true,
      defaultTarget: "_blank",
      extraAttributes: {
        rel: "noopener noreferrer",
      },
    }),
    new BoldExtension(null),
    new StrikeExtension(),
    new ItalicExtension(),
    new HeadingExtension(null),
    new BlockquoteExtension(),
    new BulletListExtension({ enableSpine: true }),
    new OrderedListExtension(),
    new ListItemExtension({
      priority: ExtensionPriority.High,
      enableCollapsible: true,
    }),
    new CodeExtension(),
    new TrailingNodeExtension(),
    new TableExtension(),
    new MarkdownExtension({
      copyAsMarkdown: false,
    }),
    new HorizontalRuleExtension(),
    new HardBreakExtension(),
    new TextHighlightExtension({}),
    new TextColorExtension({}),
    new AnnotationExtension(),
  ] as AnyExtension[];
};

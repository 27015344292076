import styled from "styled-components";
import { SVGProps } from './types'
import { storiedDisabled, storiedInactive } from "../../constants";

const getColor = (disabled, inactive, color) => {
  if (disabled) {
    return storiedDisabled;
  }
  if (inactive) {
    return storiedInactive;
  }
  return color;
}

export const Svg = styled.svg<SVGProps>`
  flex-shrink: 0;
  ${(props) => (props.$disabled || props.$inactive || props.$color) && `
  path, rect, circle {
    stroke: ${getColor(props.$disabled, props.$inactive, props.$color)};
  }
  `}
`;

Svg.defaultProps = {
  color: "text",
  width: "20px",
  xmlns: "http://www.w3.org/2000/svg",
};


import { useMicRecorder } from "../../hooks/use-mic-recorder";
import { PrimaryButton, SecondaryButton } from ".";
import { FiMic } from "react-icons/fi";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { ProcessingCircle } from "../ProcessingCircle";
import { useGlobal } from "@considr-it/storied-shared";

export const RecordButton = ({
  processTranscript,
  objectType,
  onTranscribeLive = null,
  disabled = false,
  oscillatorCanvas = null,
}) => {
  const { isMobile } = useGlobal();
  const { start, stop, recordingObjectType, isProcessingAudio, isRecording } =
    useMicRecorder();
  const isThisRecording = isRecording && recordingObjectType === objectType;

  if (isThisRecording) {
    return (
      <PrimaryButton
        onClick={() => stop()}
        style={isMobile 
        ? {
          height: 34,
          fontSize: 12,
          padding: 8,
          borderRadius: 10,
          width: "auto",
        } 
        : {
          padding: "5px 16px",
          fontSize: 14,
          height: 32,
        }}
      >
        Submit
      </PrimaryButton>
    );
  }

  return (
    <SecondaryButton
      onClick={async () => {
        await start(
          objectType,
          processTranscript,
          onTranscribeLive,
          oscillatorCanvas
        );
      }}
      style={{ marginRight: 16, padding: 6 }}
      disabled={
        isProcessingAudio || (isRecording && !isThisRecording) || disabled
      }
      noIconFill
      extraSmall
    >
      <ReturnJSX
        if={isProcessingAudio && recordingObjectType === objectType}
        else={<FiMic />}
      >
        <ProcessingCircle circleSize="16px" disabledColor />
      </ReturnJSX>
    </SecondaryButton>
  );
};

import styled from "styled-components";
// Styles
const StyledButton = styled.button<{
  $noIconFill: boolean;
}>
`
  display: flex;
  white-space: nowrap;
  padding: 16px;
  align-items: center;
  gap: 8px;
  border-radius: 16px;
  background: #FFF;
  box-shadow: 0px 6px 14.6px 0px rgba(46, 46, 46, 0.08);
  border: none;
  transition: all 0.2s ease-in-out;
  color: #FF6D34;
  font-size: 16px;
  font-weight: 500;
  line-height: 22.4px;
  letter-spacing: -0.352px;
  @media screen and (max-width: 360px) {
    padding: 10px 12px;
    font-size: 12px;
    font-weight: 500;
    line-height: 16.8px;
  }
  svg {
    ${(props) => (props.$noIconFill ? "" : "fill: #FFF")};
    transition: fill 0.2s ease-in-out;
  }
  &:disabled {
    background: #EBEBEB;
    color: #C1C1C1;
    ${props => props.$noIconFill ? "" : "svg { fill: #C1C1C1 }" };
    svg {
      path {
        stroke: #C1C1C1;
      }
    }
    &:hover {
      background: #EBEBEB;
      color: #C1C1C1;
    }
  }
  &:hover {
    background: #FFF7F4;
  }
`;

interface WhiteButton {
  children: React.ReactNode;
  noIconFill?: boolean;
  [key: string]: any;
}


export const WhiteButton: React.FC<WhiteButton> = ({ noIconFill, children, ...nativeButtonProps }) => {
  return (
    <StyledButton $noIconFill={noIconFill}  {...nativeButtonProps}>
      {children}
    </StyledButton>
  );
};

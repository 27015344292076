import { useState, useRef } from "react";
import { Form } from "react-bootstrap";
// Custom Hooks
import { useMicRecorder } from "../../hooks/use-mic-recorder";
import { useGlobal } from "@considr-it/storied-shared";
// Components
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
import { RecordButton, PrimaryButton } from "../Buttons";
import DebugFeature from "../DebugFeature";
// Styles
import {
  FormWrap,
  RecordButtonWrap,
  WaveArcsSmallCanvas,
} from "./type-or-record-form.style";
// Icons
import { FiUpload } from "react-icons/fi";
// Helpers
import { SttProvider } from "@considr-it/storied-enums";
import { upperCaseFirstChar } from "@considr-it/storied-utils";
import { trackEvent } from "@considr-it/storied-shared";

const TypeOrRecordForm = ({
  preProcess = () => {},
  onEntry,
  placeHolderText,
  objectType,
  disabled = false,
  textarea = false,
  style = {},
  onInteractionStartCb = () => {},
  onInteractionEndCb = () => {},
}) => {
  const [newDataEntry, setNewDataEntry] = useState<string>("");
  const [isProcessing, setIsProcessing] = useState<boolean>(false);
  const canvasRef = useRef<HTMLCanvasElement>(null);
  const { transport, isMobile } = useGlobal();

  const { isBusy, recordingObjectType } = useMicRecorder();

  const handleAutoExpand = (event) => {
    const textarea = event.target;
    textarea.style.height = "46px";
    const scrollHeight = Math.min(textarea.scrollHeight, 104);
    textarea.style.height = `${Math.max(46, scrollHeight)}px`;
  };

  const onSubmit = async () => {
    preProcess();

    setIsProcessing(true);

    const transcript = upperCaseFirstChar(newDataEntry);

    const {
      data: { objectId },
    } = await transport.post("/createObject", {
      objectType,
      transcript,
    });

    await onEntry(objectId, transcript);
    setIsProcessing(false);

    setNewDataEntry("");
    onInteractionEndCb();
  };

  return (
    <Form
      onSubmit={async (e) => {
        e.preventDefault();
        onSubmit();
      }}
    >
      <Form.Group className="align-items-center">
        <FormWrap $textareaStyles={textarea} style={style ? style : undefined}>
          <ReturnJSX if={recordingObjectType !== objectType}>
            <Form.Control
              disabled={isBusy || isProcessing || disabled}
              type="text"
              as={textarea ? "textarea" : "input"}
              placeholder={placeHolderText}
              value={newDataEntry}
              onKeyDown={(e) => {
                if (e.key === "Enter" && !e.shiftKey && !isMobile) {
                  onSubmit();
                }
              }}
              onInput={textarea ? handleAutoExpand : null}
              onChange={(e) => {
                onInteractionStartCb();
                setNewDataEntry(e.target.value);
                if (e.target.value === "") {
                  onInteractionEndCb();
                }
              }}
            />
          </ReturnJSX>

          <WaveArcsSmallCanvas
            ref={canvasRef}
            isVisible={recordingObjectType === objectType}
          />

          <ReturnJSX if={newDataEntry}>
            <PrimaryButton disabled={isProcessing || disabled} type="submit">
              Submit
            </PrimaryButton>
          </ReturnJSX>

          <ReturnJSX if={!newDataEntry}>
            <RecordButtonWrap>
              <RecordButton
                disabled={disabled}
                oscillatorCanvas={canvasRef.current}
                processTranscript={async (
                  objectId: string,
                  transcript: string,
                  sttProvider: SttProvider
                ) => {
                  await preProcess();
                  await onEntry(objectId, transcript, sttProvider);
                }}
                objectType={objectType}
              />
            </RecordButtonWrap>
          </ReturnJSX>
        </FormWrap>
      </Form.Group>
    </Form>
  );
};

export default TypeOrRecordForm;

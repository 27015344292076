import React from 'react'
import { SVGProps, Svg } from '..'

export const TurkeyFlagIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <g clipPath="url(#clip0_104_257)">
    <rect x="1" y="4" width="22" height="16" rx="2" fill="#F93939"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M15.6687 13.2288L14.4514 13.8795L14.684 12.5003L13.6992 11.5221L15.0611 11.3195L15.6687 10.0651L16.2764 11.3205L17.6383 11.5232L16.6535 12.4992L16.8861 13.8784" fill="white"/>
    <path fillRule="evenodd" clipRule="evenodd" d="M10.9524 15.7334C12.9774 15.7334 14.619 14.0619 14.619 12.0001C14.619 9.93819 12.9774 8.26672 10.9524 8.26672C8.92732 8.26672 7.28571 9.93819 7.28571 12.0001C7.28571 14.0619 8.92732 15.7334 10.9524 15.7334ZM12 14.6667C13.4457 14.6667 14.619 13.4721 14.619 12.0001C14.619 10.5281 13.4457 9.33339 12 9.33339C10.5543 9.33339 9.38094 10.5281 9.38094 12.0001C9.38094 13.4721 10.5543 14.6667 12 14.6667Z" fill="white"/>
    </g>
    <defs>
    <clipPath id="clip0_104_257">
    <rect width="22" height="16" fill="white" transform="translate(1 4)"/>
    </clipPath>
    </defs>
  </Svg>
);

  
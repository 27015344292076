export const normalizeDimensions = (
  srcWidth: number,
  srcHeight: number,
  maxWidth: number,
  maxHeight: number
) => {
  const ratio = Math.min(maxWidth / srcWidth, maxHeight / srcHeight);
  return {
    width: Math.floor(srcWidth * ratio),
    height: Math.floor(srcHeight * ratio),
  };
};

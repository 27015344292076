import { useState, useRef, useEffect, useCallback } from "react";
// Components
import { Editor } from "../components/Editor/Editor";
// Storied libs
import { convertMarkdownToHTML } from "@considr-it/storied-utils";
import { Annotation } from "remirror/extensions";

export const useEditor = ({
  initialText,
  updateTextRequest,
  editModeCondition,
  isUpdating,
  highlightTextOnSelect,
}) => {
  const [currentMarkdown, setCurrentMarkdown] = useState<string | null>(null);
  const markdownContent = useRef<HTMLDivElement | null>(null);
  const [lastSavedText, setLastSavedText] = useState<string | null>(null);

  const handleSave = useCallback(async () => {
    await updateTextRequest(lastSavedText, currentMarkdown);
    setLastSavedText(currentMarkdown);
  }, [currentMarkdown, lastSavedText]);

  const getTextHtml = () => convertMarkdownToHTML(currentMarkdown);

  const initializeEditor = useCallback(() => {
    setCurrentMarkdown(initialText);
    setLastSavedText(initialText);
  }, [initialText]);

  useEffect(() => {
    if (
      !currentMarkdown ||
      convertMarkdownToHTML(lastSavedText) ===
        convertMarkdownToHTML(currentMarkdown) ||
      !editModeCondition
    ) {
      return;
    }

    let debounceTimer: NodeJS.Timeout;
    const debouncedSave = () => {
      clearInterval(debounceTimer);
      debounceTimer = setTimeout(handleSave, 1500);
    };
    debouncedSave();
    return () => {
      if (debounceTimer) {
        clearInterval(debounceTimer);
      }
    };
  }, [handleSave, lastSavedText, currentMarkdown]);

  return {
    handleSave,
    markdownContent,
    currentText: currentMarkdown,
    getTextHtml,
    initializeEditor,
    Editor: (
      <Editor
        initialText={initialText}
        editModeCondition={editModeCondition}
        markdownContent={markdownContent}
        setCurrentMarkdown={setCurrentMarkdown}
        isUpdating={isUpdating}
        highlightTextOnSelect={highlightTextOnSelect}
        // Later we can modify it to add some conditions to hide the save button
      />
    ),
  };
};

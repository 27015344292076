import { useAuth0 } from "@auth0/auth0-react";
import AppWrapper from "./AppWrapper";
import { AccountProvider } from "@considr-it/storied-shared";
import { PaymentProvider } from "./hooks/use-payment";
export const Auth0Wrapper = () => {
  const { isLoading } = useAuth0();

  if (isLoading) {
    return <></>;
  }

  return (
    <AccountProvider>
      <PaymentProvider>
        <AppWrapper />
      </PaymentProvider>
    </AccountProvider>
  );
};

import { useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { useGlobal } from "@considr-it/storied-shared";

export const LinkedInCallback = () => {
  const { transport } = useGlobal();
  const navigate = useNavigate();

  useEffect(() => {
    if (!location.hash) {
      window.location.href = window.location.href.replace("?code=", "?#code=");
    } else {
      const fragmentString = location.hash;

      const params = {};
      let regex = /([^&=]+)=([^&]*)/g,
        m: string[];
      while ((m = regex.exec(fragmentString))) {
        params[decodeURIComponent(m[1])] = decodeURIComponent(m[2]);
      }

      const code = params["#code"];
      transport.post("/linkedinAccessToken", { code }).then((resp) => {
        localStorage.setItem(
          "linkedin_oauth",
          JSON.stringify({ ...resp.data, issued_at: Date.now() })
        );

        const [_, topicId, outputId] = params["state"].split("_");
        navigate(`/topic/${topicId}/${outputId}?export=linkedin`);
      });
    }
  }, []);

  return <></>;
};

import ReactDOM from "react-dom/client";
import "./App.css";
import { RootWrapper } from "./RootWrapper";
import * as serviceWorkerRegistration from "./serviceWorkerRegistration";

const root = ReactDOM.createRoot(document.getElementById("root"));

root.render(<RootWrapper />);

serviceWorkerRegistration.register();

import { Button, Image } from "react-bootstrap";
import styled from "styled-components";
import { storiedOrange } from "../../constants";
import { HiLightBulb } from "react-icons/hi";

export const CloseButtonWrapper = styled.div`
  position: absolute;
  top: 10px;
  right: 10px;
  display: flex;
  border-radius: 100%;
  background: rgba(255, 255, 255, 0.33);
  backdrop-filter: blur(3.391735553741455px);
  padding: 4px;
  cursor: pointer;
`;

export const IntroSubtitle = styled.p`
  margin: 0;
  color: #6f6f6f;
  text-align: center;
  font-size: 20px;
  font-weight: 500;
  line-height: 26px;
  margin-bottom: 56px;

  @media screen and (max-width: 767px) {
    margin-bottom: 32px;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    padding-left: 32px;
    padding-right: 32px;
  }
  @media screen and (max-width: 360px) {
    margin-bottom: 24px;
    padding-left: 0;
    padding-right: 0;
    font-size: 14px;
    font-weight: 400;
    line-height: 18.2px;
    letter-spacing: -0.32px;
  }
`;

export const TitleWrapper = styled.div`
  display: flex;
  gap: 24px;
  align-items: center;
  justify-content: center;
  margin-bottom: 24px;
  @media screen and (max-width: 767px) {
    text-align: center;
    display: block;
  }
  h2 {
    margin-bottom: 0;
    @media screen and (max-width: 767px) {
      display: inline;
      line-height: 30px;
      vertical-align: middle;
    }
    @media screen and (max-width: 360px) {
      font-size: 24px;
      font-weight: 600;
      line-height: 28.8px;
    }
  }
  @media screen and (max-width: 767px) {
    margin-bottom: 16px;
  }
  img {
    cursor: pointer;
    transition: background-color 0.3s ease;
    border-radius: 50%;
    width: 40px;
    height: 40px;
    &:hover {
      background-color: #fffbf9;
    }
    @media screen and (max-width: 767px) {
      width: 30px;
      height: 30px;
      display: inline-flex;
      line-height: 30px;
      vertical-align: middle;
      margin-left: 8px;
    }
  }
`;

export const ActionsWrapper = styled.div`
  display: flex;
  gap: 24px;
  justify-content: center;
  margin-left: -76px;
  margin-right: -76px;
  margin-top: 24px;
  @media screen and (max-width: 767px) {
    margin: 0;
    margin-top: 8px;
    flex-direction: column;
    gap: 16px;
    position: relative;
    height: 88px;
    width: calc(100vw - 40px);
    button {
      position: absolute;
      top: 0;
      width: 100% !important;
    }
    button:last-child {
      top: 52.4px;
    }
  }
  img {
    width: 20px;
    height: 20px;
  }
`;

export const IdeaFlowWrapper = styled.div`
  max-width: 53rem;
  margin: auto;
  padding-top: 28px;
  @media screen and (max-width: 360px) {
    padding-top: 0;
  }
`;

export const RecordButtonWrapper = styled.div`
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 1rem;
  @media screen and (max-width: 767px) {
    grid-template-columns: 1fr 215px 1fr;
  }
  @media screen and (max-width: 360px) {
    grid-template-columns: 1fr 148.5px 1fr;
  }
`;

export const ExampleWrapper = styled.div`
  background-color: white;
  font-size: 18px;
  padding: 12px 16px;
  margin: 56px auto 0;
  border-radius: 7px;
  max-width: 600px;
  text-align: center;
`;

export const ExampleIcon = styled(HiLightBulb)`
  margin-top: -4px;
  margin-right: 10px;
  width: 24px;
  height: 24px;
`;

export const ExampleLink = styled.a`
  color: ${storiedOrange};
  margin-left: 10px;
  font-size: 16px;
  font-weight: 600;
  cursor: pointer;
`;

export const StartBtnWrapper = styled.div`
  position: relative;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;
`;

export const WaveArcsCanvas = styled.canvas`
  position: absolute;
  width: calc(100% + 300px);
  height: calc(100% + 100px);
  top: -50px;
  left: -150px;

  @media screen and (max-width: 767px) {
    width: calc(100% + 150px);
    left: -75px;
  }
`;

export const StartBtn = styled(Button)`
  width: 100%;
  max-width: 270px;
  border-radius: 50%;
  aspect-ratio: 1 / 1;
  background-color: #ff6d34 !important;
  border-color: #ffbca1 !important;
  border-width: 18px;
  font-size: 24px;
  font-weight: 500;
  outline: 18px solid #ffefe8 !important;
  position: inherit;
  z-index: 1;

  &:hover:active,
  &:focus:active,
  &:hover {
    border-color: #ffbca1 !important;
  }

  @media screen and (max-width: 767px) {
    border-width: 12px;
    outline-width: 12px !important;
    font-size: 18px;
  }
`;

export const StartBtnIcon = styled(Image)`
  height: 56px;
  margin: 0 auto 16px;

  @media screen and (max-width: 767px) {
    height: 48px;
    margin-bottom: 12px;
  }
  @media screen and (max-width: 360px) {
    height: 32px;
    margin-bottom: 8px;
  }
`;

export const RightLineWrapper = styled.div`
  position: absolute;
  right: -314px;
  top: 40px;

  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const RightLineLabel = styled.p`
  padding-left: 75px;
  font-weight: 400;
  font-family: "Single Day", cursive;
  font-size: 20px;
  color: rgba(16, 18, 18, 0.77);
`;

export const RightLine = styled(Image)`
  rotate: -7deg;
`;

export const QuestionBoxWrapper = styled.div`
  position: absolute;
  bottom: 1rem;
  width: calc(100% - 2rem);
  border: 1px solid rgba(0, 0, 0, 0.175);
  border-radius: 8px;
  padding: 0 1rem;
`;

export const HintWrapper = styled.div`
  margin-top: 20px;
  display: flex;
  justify-content: center;
  background-color: white;
  border-radius: 7px;
  padding: 12px 16px 12px 16px;
  gap: 8px;
  align-content: center;
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.022em;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  &.fade-out {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
`;

export const LinkWrapper = styled.a`
  font-size: 14px;
  font-weight: 500;
  line-height: 24px;
  letter-spacing: -0.022em;
  text-align: left;
  color: #ff6d34;
  cursor: pointer;
`;

export const BottomButtonsWrapper = styled.div`
  margin-top: -40px;
  width: 100%;
  justify-content: center;
  display: flex;
  gap: 16px;
  transition: opacity 1s ease-in-out;
  opacity: 1;
  &.fade-out {
    opacity: 0;
    pointer-events: none;
    cursor: default;
  }
  @media screen and (max-width: 360px) {
    margin-top: -60px;
  }
`;

import styled from "styled-components";
import { ArrowIcon, ArrowLongIcon } from "../IconsSvg";
// Styles
const BackToSettingsButton = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
  color: #ff6d34;
  font-size: 14px;
  font-weight: 500;
  line-height: 18.2px;
  letter-spacing: -0.308px;
  cursor: pointer;
`;
const ArrowIconWrapper = styled.div`
  display: none;
  @media screen and (max-width: 767px) {
    display: flex;
  }
`;
const ArrowLongIconWrap = styled.div`
  display: flex;
  transform: rotate(90deg);
  @media screen and (max-width: 767px) {
    display: none;
  }
`;

export const BackButton = ({ onClick, text = "" }) => {
  return (
    <BackToSettingsButton onClick={onClick}>
      <ArrowIconWrapper>
        <ArrowIcon />
      </ArrowIconWrapper>
      <ArrowLongIconWrap>
        <ArrowLongIcon />
      </ArrowLongIconWrap>
      {text}
    </BackToSettingsButton>
  );
};

import React from 'react'
import { SVGProps, Svg } from '..'

export const MuteIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="24"
    height="24"
    viewBox="0 0 24 24"
    fill="none"
    {...props}
  >
    <rect x="8.49951" y="1.99609" width="7.00292" height="12.5052" rx="3.5" stroke="#F12A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M19.5033 10.4995C19.5033 14.6434 16.1441 18.0026 12.0002 18.0026V18.0026C7.85633 18.0026 4.49707 14.6434 4.49707 10.4995" stroke="#F12A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M12.0002 21.0037V18.0024" stroke="#F12A2A" strokeWidth="1.5" strokeLinecap="round" strokeLinejoin="round"/>
    <path d="M2 2L21 21" stroke="#F12A2A" strokeWidth="1.5" strokeLinecap="round"/>
  </Svg>
);

  
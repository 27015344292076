import React from 'react'
import { SVGProps, Svg } from '..'

export const CheckmarkIcon: React.FC<SVGProps> = (props) => (
  <Svg
    xmlns="http://www.w3.org/2000/svg"
    width="10"
    height="11"
    viewBox="0 0 10 11"
    fill="none"
    {...props}
  >
    <svg xmlns="http://www.w3.org/2000/svg" width="10" height="11" viewBox="0 0 10 11" fill="none">
      <path d="M4.34325 8.55261L4.33524 8.56116C4.20843 8.69642 4.03562 8.77526 3.85029 8.78268M4.34325 8.55261L3.85029 8.78268M4.34325 8.55261L4.35061 8.54348L9.54822 2.09164L9.30487 1.89559L9.54823 2.09163C9.57518 2.05818 9.62475 2.05263 9.65861 2.07976L9.85405 1.83592L9.65861 2.07976C9.69198 2.10651 9.69708 2.15463 9.67052 2.18762C9.67051 2.18763 9.67051 2.18764 9.6705 2.18764L4.4659 8.64817L4.46587 8.64821L4.46261 8.65208C4.46256 8.65212 4.46252 8.65217 4.46248 8.65222C4.30572 8.82675 4.09183 8.92733 3.85669 8.93676L3.85643 8.93677C3.84449 8.93726 3.83253 8.9375 3.82069 8.9375C3.59942 8.9375 3.39196 8.85514 3.23012 8.70192L3.23063 8.7024L3.01468 8.92828M4.34325 8.55261L3.86921 9.24901C3.85301 9.24967 3.8368 9.25 3.82069 9.25C3.51917 9.25 3.2346 9.13678 3.01468 8.92828M3.85029 8.78268C3.6601 8.79032 3.47689 8.72201 3.33914 8.59143L3.33817 8.59052L0.444995 5.8245C0.444988 5.82449 0.444981 5.82448 0.444974 5.82448C0.413729 5.79463 0.363863 5.7957 0.333942 5.8268M3.85029 8.78268L0.333942 5.8268M3.01468 8.92828L3.22968 8.7015M3.01468 8.92828L0.12032 6.16113C-0.0354221 6.01222 -0.0406565 5.7656 0.108621 5.61027L3.22968 8.7015M3.22968 8.7015L0.33628 5.93526C0.305476 5.90581 0.304497 5.85744 0.333942 5.8268M3.22968 8.7015L0.333942 5.8268" stroke="#FF6D34" strokeWidth="0.625"/>
    </svg>
  </Svg>
);

  

import styled from "styled-components";
import { InfoIcon } from "../IconsSvg";
// Components
import { InputLanguages } from "../InputLanguages";
// Styles
import { Description } from "./onboarding-carousel.style";
const InputLanguagesWrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: 24px;
  gap: 8px;
  @media screen and (max-width: 767px) {
    margin-top: 16px;
  }
`;
const TitleWrapper = styled.div`
  display: flex;
  align-items: center;
  gap: 8px;
`;
const Title = styled.p`
  margin-bottom: 0;
  color: #101212;
  font-size: 18px;
  font-weight: 500;
  line-height: 23.4px;
  letter-spacing: -0.396px;
  @media screen and (max-width: 767px) {
    font-family: Metropolis;
    font-size: 16px;
    font-weight: 400;
    line-height: 20.8px;
    letter-spacing: -0.352px;
  }
`;

export const Step4 = () => {
  return (
    <div>
      <Description>
        Express your ideas in your native tongue and we'll help you transform it
        into clear English text.
      </Description>
      <InputLanguagesWrapper>
        <TitleWrapper>
          <div
            style={{ display: "flex", alignItems: "center" }}
            title="What is input language?

            The input language is a feature within 
            our app that allows users to speak in 
            their chosen language and receive 
            results in English."
          >
            <InfoIcon />
          </div>
          <Title>Choose the input language:</Title>
        </TitleWrapper>
        <InputLanguages
          dropdownOnly
          placeholderText="Choose the input language"
        />
      </InputLanguagesWrapper>
    </div>
  );
};

import React, { useContext, useCallback, createContext, useState, ReactElement } from "react";
interface ModalsContext {
  onPresent: (node: React.ReactNode, params: any, key?: string) => void;
  onDismiss: () => void;
}

export const ModalContext = createContext<ModalsContext>({
  onPresent: () => null,
  onDismiss: () => null,
});

export const ModalProvider = ({ children }) => {
  const [modalNode, setModalNode] = useState<React.ReactNode[]>([]);
  const [modalParams, setModalParams] = useState<any[]>([]);

  const handlePresent = (node: React.ReactNode, params?: any) => {
    setModalNode([...modalNode, node]);
    setModalParams([...modalParams, params]);
  };

  const handleDismiss = () => {
    setModalNode((prevModalNodes) => {
      const newModalNodes = [...prevModalNodes];
      newModalNodes.pop();

      return newModalNodes;
    });

    setModalParams((prevModalParams) => {
      const newModalParams = [...prevModalParams];
      newModalParams.pop();

      return newModalParams;
    });
  };

  return (
    <ModalContext.Provider
      value={{
        onPresent: handlePresent,
        onDismiss: handleDismiss,
      }}
    >
      {modalNode.map((node, index) => (
        <React.Fragment key={`modal_${index}`}>
          {React.isValidElement(node) &&
            React.cloneElement(node as ReactElement<any>, {
              params: modalParams[index],
              onDismiss: handleDismiss,
            })}
        </React.Fragment>
      ))}
      {children}
    </ModalContext.Provider>
  );
};


export const useModal = (
  modal: React.ReactNode
): [(params?: any) => void, () => void] => {
  const { onPresent, onDismiss } = useContext(ModalContext)
  const onPresentCallback = useCallback(
    (params?: any) => {
      onPresent(modal, params)
    },
    [modal, onPresent],
  )
  return [onPresentCallback, onDismiss]
}

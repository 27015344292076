import styled from "styled-components";
import { Image } from "react-bootstrap";
// Pictures
import Logo from "./logo.svg";


export const LogoImg = styled(Image)`
  width: 79px;
  height: 79px;
  border-radius: 15px;
  position: absolute;
  left: 50%;
  top: -32px;
  transform: translateX(-50%);
`

export const StyledModalLogo = () => {
  return (
    <LogoImg src={Logo} />
  );
};

import React, { useEffect, useRef, useState } from "react";
import { Remirror, ThemeProvider, useRemirror } from "@remirror/react";
// Custom hooks
import { useIdeaFlow } from "../../hooks/use-idea-flow";
// Components
import { extensions } from "./extensions";
import { Menu } from "./Menu";
import { HighlightingComponent } from "./HighlightingComponent";
import { EditorWrapper, ToolbarWrapper } from "./editor.style";
import { ReturnJSX } from "../ReturnJSX/ReturnJSX";
// Styles
import "@remirror/styles/all.css";
import { AnnotationsUpdater } from "./AnnotationsUpdater";

export const Editor: React.FC<{
  initialText: string;
  editModeCondition: boolean;
  markdownContent: React.RefObject<HTMLDivElement>;
  setCurrentMarkdown: (markdown: string) => void;
  highlightTextOnSelect: boolean;
  isUpdating: boolean;
}> = ({
  initialText,
  editModeCondition,
  markdownContent,
  setCurrentMarkdown,
  highlightTextOnSelect,
}) => {
  const { manager, state, setState } = useRemirror({
    extensions,
    stringHandler: "markdown",
  });

  const [currentSelection, setCurrentSelection] = useState(null);
  const onSelectChangeTimeoutRef = useRef(null);

  const {
    annotationsList,
    setCurrentSelectedAnnotation,
    setCurrentHighlighted,
  } = useIdeaFlow();

  useEffect(() => {
    if (initialText) {
      const editorState = manager.createState({
        content: initialText,
        stringHandler: "markdown",
      });
      setState(editorState);
    }
  }, [initialText, setState]);

  const snapSelectionToWord = () => {
    let sel = null;

    // Check for existence of window.getSelection() and that it has a
    // modify() method. IE 9 has both selection APIs but no modify() method.
    if (window.getSelection) {
      sel = window.getSelection();

      if (!sel.isCollapsed) {
        // Detect if selection is backwards
        const range = document.createRange();
        range.setStart(sel.anchorNode, sel.anchorOffset);
        range.setEnd(sel.focusNode, sel.focusOffset);
        const backwards = range.collapsed;
        range.detach();

        // modify() works on the focus of the selection

        const endNode = sel.focusNode,
          endOffset = sel.focusOffset;
        sel.collapse(sel.anchorNode, sel.anchorOffset);

        const direction = backwards
          ? ["backward", "forward"]
          : ["forward", "backward"];

        sel.modify("move", direction[0], "character");
        sel.modify("move", direction[1], "word");
        sel.extend(endNode, endOffset);
        sel.modify("extend", direction[1], "character");
        sel.modify("extend", direction[0], "word");

        return sel;
      }
    } else if ((sel = document["selection"]) && sel.type != "Control") {
      const textRange = sel.createRange();
      if (textRange.text) {
        textRange.expand("word");
        // Move the end back to not include the word's trailing space(s),
        // if necessary
        while (/\s$/.test(textRange.text)) {
          textRange.moveEnd("character", -1);
        }
        textRange.select();
      }
    }
  };

  const handleChange = (parameter) => {
    const { state } = parameter;
    let nextState = state;

    if (parameter.tr?.selectionSet) {
      if (highlightTextOnSelect) {
        clearTimeout(onSelectChangeTimeoutRef.current);

        onSelectChangeTimeoutRef.current = setTimeout(() => {
          if (Math.abs(state.selection.from - state.selection.to) > 0) {
            setCurrentSelection(state.selection);
          } else {
            setCurrentSelection(null);
          }
        }, 500);
      }

      let selectedAnnotation = null;
      let smallestSize = Number.MAX_SAFE_INTEGER;

      for (const annotation of annotationsList?.annotations) {
        const annotationDist = Math.abs(annotation.from - annotation.to);

        if (
          annotation.from <= state.selection.from &&
          annotation.to >= state.selection.to &&
          annotationDist < smallestSize
        ) {
          selectedAnnotation = annotation;
          smallestSize = annotationDist;
        }
      }

      setCurrentSelectedAnnotation(selectedAnnotation);
    }

    if (parameter.helpers.getMarkdown(state)) {
      // Without this check it would set an empty string which is unwanted behaviour
      setCurrentMarkdown(parameter.helpers.getMarkdown(state));
    }

    setState(nextState);
  };

  return (
    <EditorWrapper
      data-dd-privacy="mask"
      ref={markdownContent}
      onCopy={(e) => {
        const range = window.getSelection().getRangeAt(0);
        const rangeContents = range.cloneContents();
        const helper = document.createElement("div");

        helper.appendChild(rangeContents);

        let outerHTML = helper.outerHTML;

        outerHTML = outerHTML.replaceAll(" background-color:#fa8152", "");
        outerHTML = outerHTML.replaceAll(
          ` data-text-highlight-mark="#fa8152"`,
          ""
        );
        outerHTML = outerHTML.replaceAll("background: rgb(215, 215, 255)", "");
        outerHTML = outerHTML.replaceAll("<mark", "<text");
        outerHTML = outerHTML.replaceAll("</mark>", "</text>");

        helper.innerHTML = outerHTML;

        e.clipboardData.setData("text/plain", `${helper.innerText}`);
        e.clipboardData.setData("text/html", `${helper.innerHTML}`);

        e.preventDefault();
      }}
    >
      <ThemeProvider>
        <Remirror
          autoRender="end"
          manager={manager}
          editable={editModeCondition}
          state={state}
          onChange={handleChange}
        >
          <ReturnJSX if={editModeCondition}>
            <ToolbarWrapper>
              <Menu />
            </ToolbarWrapper>
          </ReturnJSX>
          {highlightTextOnSelect && (
            <>
              <HighlightingComponent currentSelection={currentSelection} />
              <AnnotationsUpdater state={state} />{" "}
            </>
          )}
        </Remirror>
      </ThemeProvider>
    </EditorWrapper>
  );
};
